import React from 'react'
import './reactcounter.css'

const ReactCounter = () => {
  return (
    <>

      <div className='compteur'
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
          
        }}
      
      >
        <div className="right">
          <h4>NOMBRE DE VISITEURS</h4>
        </div>
        <div className="left">
          <img src="https://counter10.optistats.ovh/private/compteurdevisite.php?c=mypj1hqwzahl1e78hsb9aa5yumkcaunx" 
          border="0"  alt="compteur html" ></img>
        </div>
      
      </div>
    </>
  )
}

export default ReactCounter