import styled from 'styled-components'
import '../../utils/style/index.scss'
import HamburgerMenuEcole from '../HamburgerMenuEcole'
import { HashLink } from 'react-router-hash-link'

const SchoolNavbarWrapper = styled.div`
    background-color: ${(prop) => prop.color};
    position: absolute;
    top: 2%;
    width: 100%;
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 936px) {
        top: 0px;
        justify-content: flex-start;

    }
`
const SchoolNavbar = styled.div`
    background-color: ${(prop) => prop.color};
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
    @media (max-width: 936px) {
        display: none;
    }
`
const NavItem = styled(HashLink)`
    text-decoration: none;
    font-size: 0.8em;
    text-align: center;
    cursor: pointer;
    @media (max-width: 936px) {
        display: none;
    }
`
const SchoolName = styled.div`
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
    @media (max-width: 936px) {
        /* top: 80px; */
        position: absolute;
        z-index: 2 !important;
        flex-direction: column !important;
        padding: 0;
    }
`
const SchoolNameText = styled.div`
    width: 75%;
    font-style: normal;
    font-weight: bold;
    font-size: 2.5em;
    text-align: center;
    color: #fff;
    @media (max-width: 936px) {
        width: 60%;
        font-size: 0.95em;
        margin: 2em;
    }
`
const MyLogo = styled.img`
    @media (max-width: 936px) {
        top: 150px;
        position: absolute;
    }
`

export default function HeaderEcole({
    couleurPrincipale,
    logo,
    acronyme,
    nom,
    EcoleItems,
}) {
    return (
        <SchoolNavbarWrapper>
            <MyLogo
                style={{
                    width: '100px',
                    height: '100px',
                    backgroundColor: '#fff',
                    borderRadius: '50%',
                }}
                src={logo}
                alt={acronyme}
                className="border-0 mb-2"
            />
            <SchoolNavbar color={couleurPrincipale}>
                {EcoleItems.map((item, index) => (
                    <NavItem
                        key={`${index}`}
                        className="fw-bold text-light text-uppercase me-5"
                        to={item.Link}
                    >
                        {item.text}
                    </NavItem>
                ))}
            </SchoolNavbar>
            <SchoolName>
                <SchoolNameText>{nom}</SchoolNameText>
                <HamburgerMenuEcole
                    itemcolor={couleurPrincipale}
                    showWidth="936px"
                    burgercolor="#fff"
                    items={EcoleItems}
                    Size="50px"
                />
            </SchoolName>
        </SchoolNavbarWrapper>
    )
}
