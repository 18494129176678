import styled from 'styled-components'
import '../../utils/style/index.scss'
import { useEffect, useRef, useState } from 'react'
import { Icons } from '../../datas'
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa'
import NewFloat from './NewFloat/NewFloat'

const JoinLink = styled.div`
    position: fixed;
    z-index: 10;
    display: flex;
    justify-content: space-around;
    max-width: 30vh;
    height: 75vh;
    align-items: center;
`
const LeftDiv = styled.div`
    display: ${(props) => (props.open ? 'flex' : 'none')};
    flex-direction: column;
    justify-content: space-around;
    align-items: space-around;
    margin-top: 100px;
    height: 64vh;
    margin-left: -3px;
    padding-right: 10px;
    padding-left: 10px;
    color: #079edb;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border-right: 1px solid #079edb; 
    border-top-right-radius: 28px;
    border-bottom-right-radius: 28px;
    @media (max-width: 600px) {
        height: 50vh;
    }
`
const Mybtn = styled.button`
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    color: #079edb;
    margin-left: ${(props) => (props.open ? '0px' : '0px')};
    padding: 5px;
    height: 7rem;
    margin-top: 100px;
    font-size: 17px;
    /* border: 1px solid #079edb; */
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border-left: 0px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    @media (max-width: 600px) {
        /* height: 13vh; */
        padding-left: 3px;
        font-size: 15px;
    }
`
const StyledIcon = styled.div`
    border-radius: 50%;
    border: 1px solid #fff;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #079edb;
    height: 35px;
    width: 35px;
    font-size: 25px;
    @media (max-width: 600px) {
        height: 30px;
        width: 30px;
        font-size: 20px;
    }
`
const StyledA = styled.a`
    color: #000;
`

function FlotButton() {
    const [isOpen, setisOpen] = useState(true)
    const refOne = useRef(null)

    useEffect(() => {
        const timer = setTimeout(() => {
            setisOpen(false)
        }, 5000)

        document.addEventListener('click', HandleClickOutside, true)

        return () => {
            clearTimeout(timer)
            document.removeEventListener('click', HandleClickOutside, true)
        }
    }, [])

    const HandleClickOutside = (e) => {
        try {
            if (!refOne.current.contains(e.target)) {
                setisOpen(false)
            }
        } catch (error) {}
    }
    return (

        <>
            {/* <NewFloat /> */}
            <JoinLink ref={refOne}>
                <LeftDiv open={isOpen} className="fade-in">
                    {Icons.map((profile, index) => (
                        <StyledA
                            href={`${profile.link}`}
                            key={`-${index}`}
                            title={profile.title}
                            target="_blank"
                        >
                            <StyledIcon key={`${profile.title}-${index}`}>
                                {profile.icon}
                            </StyledIcon>
                        </StyledA>
                    ))}
                </LeftDiv>
                <Mybtn
                    onClick={() => setisOpen(!isOpen)}
                    open={isOpen}
                    title="Réseaux-sociaux"
                >
                    {isOpen ? <FaChevronLeft /> : <FaChevronRight />}
                </Mybtn>
                

            </JoinLink>
        </>
        

    )
}

export default FlotButton
