import React from 'react'
import '../../utils/style/index.scss'
import styled from 'styled-components'
import { Outlet } from 'react-router-dom'

const TitleDiv = styled.div`
    width: 100%;
    background-color: rgb(0, 141, 54);
`

export const Departements = () => {
    return (
        <>
            <TitleDiv className="d-flex justify-content-center align-items-center text-light">
                <span
                    className="body-highlight mt-3 text-uppercase ps-2 mb-3 fs-1"
                    style={{ borderLeft: '10px solid #fff' }}
                >
                    Départements
                </span>
            </TitleDiv>
            <Outlet />
        </>
    )
}
