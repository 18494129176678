import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { Transitions } from '../../components/Transitions'

function importAll(r) {
    let images = {}
    r.keys().map((item, index) => (images[item.replace('./', '')] = r(item)))
    return images
}

const epreuves = importAll(
    require.context('../../assets/Epreuves', false, /\.(png|jpe?g|svg|mp4)$/)
)

const MyCol = styled(Col)`
    flex-basis: calc(100% / 5);
    @media (max-width: 1200px) {
        flex-basis: auto;
    }
`

const typeEpreuves = [
    {
        nom: 'a2gp',
        image: epreuves['A2GP.png'],
        // link:,
    },
    {
        nom: 'stic',
        image: epreuves['STIC.png'],
    },
    {
        nom: 'gic',
        image: epreuves['GIC.png'],
    },
    {
        nom: 'cae',
        image: epreuves['CAE.png'],
    },
    // {
    //     nom: 'ITA',
    //     image: epreuves['ITA.png'],
    // }
]

export const EpreuvesAccueil = () => {
    const settings = {
        xxl: 'auto',
        xl: 3,
        lg: 3,
        md: 4,
        sm: 4,
        xs: 6,
    }

    return (
        <Transitions>
            <Container className="mt-5">
                <Row className='d-flex align-items-center justify-content-center'>
                    {typeEpreuves.map((type, index) => (
                        <MyCol key={index} className="p-2" {...settings}>
                            <NavLink to={`${type.nom}`}>
                                <img
                                    src={type.image}
                                    alt=""
                                    style={{
                                        width: '100%',
                                        aspectRation: 3 / 2,
                                        objectFit: 'contain',
                                    }}
                                />
                                <p
                                    className="fw-bold fs-4 text-center p-2 text-dark"
                                    style={{ border: '3px solid #f47920', textTransform: 'uppercase' }}
                                >
                                    {type.nom}
                                </p>
                            </NavLink>
                        </MyCol>
                    ))}
                </Row>
            </Container>
        </Transitions>
    )
}
