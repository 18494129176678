import styled from 'styled-components'
import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { FaHome } from 'react-icons/fa'


const OpenIcon = styled(NavLink)`
    position: fixed;
    z-index: 9;
    top: 100px;
    right: 20px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    background-color: #fff;
    color: #079edb;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5em;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 50%;
    transition: all 1s ease-in-out;
    @media(max-width: 600px) {
        width: 40px;
        height: 40px;
    }
    /* &:hover{
        color: #079edb;
        background-color: #fff;
    } */
`

function HomeFlot() {
    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])
    return (
        <OpenIcon to="/" data-aos="fade-right" title='Accueil'>
            <FaHome style={{ width: '50px', height: '50px' }} />
        </OpenIcon>
    )
}

export default HomeFlot
