import React, { useState } from 'react'
import styled from 'styled-components'
import { Container } from 'react-bootstrap'
import { ecoles, NosConcours } from '../../datas'
import { useLocation } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { Transitions } from '../../components/Transitions'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

const MyContainer = styled(Container)`
    display: flex;
    flex-wrap: wrap;
`
const Items = styled(HashLink)`
    text-decoration: none;
    cursor: pointer;
    flex: 1 1 auto;
`
const Text = styled.div`
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-left: 5px solid ${(prop) => prop.color};
    font-weight: bold;
`
const SchoolNavbarWrapper = styled.div`
    background-color: ${(prop) => prop.color};
    display: flex;
    justify-content: center;
    align-items: center;
`
const SchoolName = styled.div`
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;
    @media (max-width: 730px) {
        flex-direction: column;
        justify-content: center;
    }
`
const SchoolNameText = styled.span`
    font-style: normal;
    font-weight: bold;
    font-size: 2.5em;
    padding-left: 10px;
    border-left: 10px solid #fff;
    text-align: center;
    color: #fff;
`
const MyLogo = styled.img`
    height: 124px;
    transition: transform 360ms ease-in-out;
    &:hover {
        transform: rotate(360deg);
    }
`
const Item = styled.div`
    padding: 10px;
    background-color: ${(prop) => prop.color};
    color: ${(prop) => prop.textColor};
    display: flex;
    border-bottom: 1px solid ${(prop) => prop.textColor};
    justify-content: space-between;
    align-items: center;
    cursor: pointer !important;
`
const Title = styled.div`
    &::before {
        content: '.';
        font-size: 4em;
        color: ${(prop) => prop.color};
    }
`
const Contenu = styled.div`
    border-left: 5px solid ${(prop) => prop.color};
    padding-left: 10px;
    margin-left: 30px;
`

export const PresentationAccueil = () => {
    const location = useLocation()
    const isIngenieur = location.pathname.includes('ingenieur')
    const [checkBoxState, setcheckBoxState] = useState([
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
    ])
    const HanldeCheck = (i) => {
        setcheckBoxState((prevState) =>
            prevState.map((item, idx) => (idx === i ? !item : false))
        )
    }
    return (
        <Transitions>
            <SchoolNavbarWrapper color={'#008D36'} className="mb-3">
                <SchoolName>
                    <div className="w-100 text-center text-uppercase">
                        <SchoolNameText>
                            {isIngenieur
                                ? 'Concours Ingénieur'
                                : 'Concours Bachelier'}
                        </SchoolNameText> 
                    </div>
                </SchoolName>
            </SchoolNavbarWrapper>
            {isIngenieur ? (
                <Container>
                    {NosConcours['ingenieur'].map((filiere, index) => (
                        <div key={`Concours--${index}`}>
                            <Item
                                className="text-uppercase my-1"
                                onClick={() => HanldeCheck(index)}
                                color={
                                    checkBoxState[index] ? '#008D36' : '#FFF'
                                }
                                textColor={
                                    checkBoxState[index] ? '#fff' : '#008D36'
                                }
                            >
                                <div style={{ cursor: 'pointer' }}>
                                    {filiere.titre}
                                </div>
                                {checkBoxState[index] ? (
                                    <FaChevronUp
                                        style={{ cursor: 'pointer' }}
                                    />
                                ) : (
                                    <FaChevronDown
                                        style={{ cursor: 'pointer' }}
                                    />
                                )}
                            </Item>
                            <div
                                className={
                                    checkBoxState[index]
                                        ? 'd-block mb-5 fade-in'
                                        : 'mb-5 d-none'
                                }
                            >
                                {filiere.description && (
                                    <span
                                        className="body-highlight mt-3"
                                        style={{ color: '#008D36' }}
                                    >
                                        {filiere.description}
                                    </span>
                                )}
                                {filiere.recap && (
                                    <div className="text-justify my-4">
                                        <img
                                            src={filiere.recap}
                                            alt=""
                                            width={'100%'}
                                        />
                                    </div>
                                )}

                                {filiere.corps.map((vol, index) => (
                                    <div
                                        className="text-start body-text border-0"
                                        key={`volume-${index}`}
                                    >
                                        {vol.sousTitre && (
                                            <Title
                                                className="fw-bold my-3"
                                                color={'#008D36'}
                                            >
                                                {vol.sousTitre}
                                            </Title>
                                        )}
                                        <Contenu color={'#008D36'}>
                                            <div className="mb-0 pt-1">
                                                {vol.contenu}
                                            </div>
                                        </Contenu>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                    <div key={`Concours--$10`}>
                        <Item
                            className="text-uppercase my-1"
                            onClick={() => HanldeCheck(10)}
                            color={checkBoxState[10] ? '#008D36' : '#FFF'}
                            textColor={checkBoxState[10] ? '#fff' : '#008D36'}
                        >
                            <div style={{ cursor: 'pointer' }}>
                                Dossier de candidature
                            </div>
                            {checkBoxState[10] ? (
                                <FaChevronUp style={{ cursor: 'pointer' }} />
                            ) : (
                                <FaChevronDown style={{ cursor: 'pointer' }} />
                            )}
                        </Item>
                        <div
                            className={
                                checkBoxState[10]
                                    ? 'd-block mb-5 fade-in my-3'
                                    : 'mb-5 d-none'
                            }
                        >
                            <div className="text-start body-text border-0">
                                <span
                                    className="body-highlight my-3"
                                    style={{ color: '#008D36' }}
                                >
                                    Il comporte les éléments ci-dessous :
                                </span>
                                <Contenu color={'#008D36'}>
                                    <div className=" my-3 pt-1">
                                        <Title color={'#008D36'}>
                                            Une (01) attestation d’inscription,
                                            une attestation d’assiduité pour
                                            l’année 2023-2024 et le bulletin de
                                            notes de 1ère année de Prépa (pour
                                            les candidats des classes
                                            préparatoires) ;
                                        </Title>
                                        <Title color={'#008D36'}>
                                            Une (01) photocopie du diplôme exigé
                                            ou de l’attestation d’admission et
                                            le bulletin de notes de DTS3 ou L3 ;{' '}
                                        </Title>
                                        <Title color={'#008D36'}>
                                            Une (01) photocopie du diplôme du
                                            baccalauréat ou de l’attestation de
                                            réussite ;
                                        </Title>
                                        <Title color={'#008D36'}>
                                            Une (01) attestation d’inscription
                                            (Prépa1, Première année
                                            universitaire) pour le concours ITA
                                            ;{' '}
                                        </Title>
                                        <Title color={'#008D36'}>
                                            Une (01) photo d’identité à fond
                                            blanc ;
                                        </Title>
                                        <Title color={'#008D36'}>
                                            Une (01) photocopie de la Carte
                                            Nationale d’Identité ou de
                                            l’Attestation d’Identité ou toute
                                            pièce équivalente ;
                                        </Title>
                                        <Title color={'#008D36'}>
                                            Un (01) curriculum vitae (CV) ;{' '}
                                        </Title>
                                        <Title color={'#008D36'}>
                                            Deux (02) exemplaires de l’imprimé
                                            de la préinscription en ligne ;{' '}
                                        </Title>
                                        <Title color={'#008D36'}>
                                            Une (01) quittance de droit
                                            d’inscription d’un montant de 20 000
                                            FCFA par concours ;
                                        </Title>
                                        <Title color={'#008D36'}>
                                            Un (01) reçu des frais de dossier
                                            d’un montant de 3 000 FCFA (payable
                                            une seule fois quel que soit le
                                            nombre de concours présentés).
                                        </Title>
                                        <Title color={'#008D36'}>
                                            Une (01) photo d'identité à prendre lors 
                                            du dépôt de dossier (1000 FCFA).
                                        </Title>
                                    </div>
                                </Contenu>
                                <div className="fw-bold">
                                    <span
                                        className="body-highlight my-3 fs-4"
                                        style={{ color: '#008D36' }}
                                    >
                                        NB :{' '}
                                    </span>
                                    Le candidat doit avoir 25 ans au plus à la
                                    date du 31 décembre de l’année du concours.
                                    Les inscriptions sont reçues à l’INP-HB
                                    (Scolarité) à Yamoussoukro et à l’Antenne
                                    d’Abidjan dès l’ouverture du concours.
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            ) : (
                <Container>
                    {NosConcours['bachelier'].map((concours, index) => (
                        <div key={`Concours--${index}`}>
                            <Item
                                className="text-uppercase my-1"
                                onClick={() => HanldeCheck(index)}
                                color={
                                    checkBoxState[index] ? '#008D36' : '#FFF'
                                }
                                textColor={
                                    checkBoxState[index] ? '#fff' : '#008D36'
                                }
                            >
                                <div style={{ cursor: 'pointer' }}>
                                    {concours.titre}
                                </div>
                                {checkBoxState[index] ? (
                                    <FaChevronUp
                                        style={{ cursor: 'pointer' }}
                                    />
                                ) : (
                                    <FaChevronDown
                                        style={{ cursor: 'pointer' }}
                                    />
                                )}
                            </Item>
                            <div
                                className={
                                    checkBoxState[index]
                                        ? 'd-block mb-5 fade-in'
                                        : 'mb-5 d-none'
                                }
                            >
                                <div className="mt-4">{concours.corps}</div>
                            </div>
                        </div>
                    ))}
                </Container>
            )}

            <Container key={`Concours--11`}>
                <Item
                    className="text-uppercase my-1"
                    onClick={() => HanldeCheck(11)}
                    color={checkBoxState[11] ? '#008D36' : '#FFF'}
                    textColor={checkBoxState[11] ? '#fff' : '#008D36'}
                >
                    <div style={{ cursor: 'pointer' }}>Écoles Concernées</div>
                    {checkBoxState[11] ? (
                        <FaChevronUp style={{ cursor: 'pointer' }} />
                    ) : (
                        <FaChevronDown style={{ cursor: 'pointer' }} />
                    )}
                </Item>
                <div
                    className={
                        checkBoxState[11]
                            ? 'd-block mb-5 fade-in'
                            : 'mb-5 d-none'
                    }
                >
                    <div className="mt-4">
                        <MyContainer className="mt-4 mb-4">
                            {isIngenieur
                                ? ecoles.map((ecole, index) =>
                                      ecole.alt.includes('CPGE') ||
                                      ecole.alt.includes('EDP') ||
                                      ecole.alt.includes('EFCPC') ? null : (
                                          <Items
                                              key={`ConcoursFilieres ${index}`}
                                              className="me-2 text-center text-dark"
                                              to={
                                                  ecole.alt.includes('ESPE')
                                                      ? `/ecoles/${ecole.alt.toLowerCase()}`
                                                      : `/ecoles/${ecole.alt.toLowerCase()}/cycles_et_filieres#filieres_concours_ingenieur`
                                              }
                                          >
                                              <MyLogo
                                                  src={ecole.logo}
                                                  alt={ecole.alt}
                                              />
                                              <Text color={ecole.couleur}>
                                                  {ecole.alt}
                                              </Text>
                                          </Items>
                                      )
                                  )
                                : ecoles.map((ecole, index) =>
                                      ecole.alt.includes('EDP') ||
                                      ecole.alt.includes('EFCPC') ? null : (
                                          <Items
                                              key={`ConcoursFilieres ${index}`}
                                              className="me-2 text-center text-dark"
                                              to={
                                                  ecole.alt.includes('ESPE')
                                                      ? `/ecoles/${ecole.alt.toLowerCase()}`
                                                      : `/ecoles/${ecole.alt.toLowerCase()}/cycles_et_filieres#filieres_concours_bachelier`
                                              }
                                          >
                                              <MyLogo
                                                  src={ecole.logo}
                                                  alt={ecole.alt}
                                              />
                                              <Text color={ecole.couleur}>
                                                  {ecole.alt}
                                              </Text>
                                          </Items>
                                      )
                                  )}
                        </MyContainer>
                    </div>
                </div>
            </Container>
        </Transitions>
    )
}
