import React, { useState } from 'react'
import styled from 'styled-components'
import '../../utils/style/index.scss'
import { school } from '../../datas'
import { Container } from 'react-bootstrap'
import {  useParams } from 'react-router-dom'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { Transitions } from '../../components/Transitions'
import HeaderEcoleItems from '../../components/HeaderEcoleItems'

// const FiliereWrapper = styled.div`
//     overflow: hidden;
// `

const SchoolNavbarWrapper = styled.div`
    background-color: ${(prop) => prop.color};
    display: flex;
    justify-content: center;
    align-items: center;
`
const SchoolName = styled.div`
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;
    @media (max-width: 730px) {
        flex-direction: column;
        justify-content: center;
    }
`
const SchoolNameText = styled.span`
    font-style: normal;
    font-weight: bold;
    font-size: 2.5em;
    padding-left: 10px;
    border-left: 10px solid #fff;
    text-align: center;
    color: #fff;
`
const Item = styled.div`
    padding: 10px;
    background-color: ${(prop) => prop.color};
    color: ${(prop) => prop.textColor};
    display: flex;
    border-bottom: 1px solid ${(prop) => prop.textColor};
    justify-content: space-between;
    align-items: center;
    cursor: pointer !important;
`
const Title = styled.div`
    &::before {
        content: '.';
        font-size: 4em;
        color: ${(prop) => prop.color};
    }
`
const SubtitleBody = styled.div``
const Contenu = styled.div`
    border-left: 5px solid ${(prop) => prop.color};
    padding-left: 10px;
    margin-left: 30px;
`
export const CycleEtFiliere = () => {
    const { schoolName } = useParams()
    const ecole = school[`${schoolName}`]
    const [checkBoxState, setcheckBoxState] = useState([
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
    ])
    const HanldeCheck = (i) => {
        setcheckBoxState((prevState) =>
            prevState.map((item, idx) => (idx === i ? !item : false))
        )
    }

    return ecole.filiereBachelier ? (
        <Transitions>
            <HeaderEcoleItems
                couleurPrincipale={ecole.couleurPrincipale}
                logo={ecole.logo}
                acronyme={ecole.alt}
                nom={'Nos filières'}
            />
            <Container>
                <div className="my-3">
                    <img
                        src={ecole.filieresConcoursDutIntroduction}
                        alt=""
                        width={'100%'}
                    />
                </div>
            </Container>
            <Container className="mb-5" id="filieres_concours_bachelier">
                <SchoolNavbarWrapper
                    color={ecole.couleurSecondaire}
                    className="my-3"
                >
                    <SchoolName>
                        <div className="w-100 text-center text-uppercase">
                            <SchoolNameText>Concours Bachelier</SchoolNameText>
                        </div>
                    </SchoolName>
                </SchoolNavbarWrapper>
                {ecole.filiereBachelier.map((filiere, index) => (
                    <div key={`filiere-${ecole.alt}-${index}`}>
                        <Item
                            className="text-uppercase my-1"
                            onClick={() => HanldeCheck(index)}
                            color={
                                checkBoxState[index]
                                    ? `${ecole.couleurPrincipale}`
                                    : '#FFF'
                            }
                            textColor={
                                checkBoxState[index]
                                    ? '#fff'
                                    : `${ecole.couleurPrincipale}`
                            }
                        >
                            <div style={{ cursor: 'pointer' }}>
                                {filiere.filiere}
                            </div>
                            {checkBoxState[index] ? (
                                <FaChevronUp style={{ cursor: 'pointer' }} />
                            ) : (
                                <FaChevronDown style={{ cursor: 'pointer' }} />
                            )}
                        </Item>
                        <div
                            className={
                                checkBoxState[index]
                                    ? 'd-block mb-5 fade-in'
                                    : 'mb-5 d-none'
                            }
                        >
                            <div className="text-justify mt-4">
                                {filiere.introduction}
                            </div>
                            {filiere.volume.map((vol, index) => (
                                <div
                                    className="text-start body-text border-0"
                                    key={`volume-${index}`}
                                >
                                    <Title
                                        className="fw-bold my-3"
                                        color={ecole.couleurPrincipale}
                                    >
                                        {vol.title}
                                    </Title>
                                    {vol.contenu.map((cont, index) => (
                                        <Contenu
                                            key={`contenu-${index}-${cont}`}
                                            color={ecole.couleurPrincipale}
                                        >
                                            <div className="fw-bold mb-0 pt-1">
                                                {cont.subtitle}
                                            </div>
                                            <SubtitleBody
                                                color={ecole.couleurRgba}
                                            >
                                                {cont.subtitleText}
                                            </SubtitleBody>
                                        </Contenu>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </Container>
            {ecole.filiereIngenieur && (
                <Container className="mb-5" id="filieres_concours_ingenieur">
                    <SchoolNavbarWrapper
                        color={ecole.couleurSecondaire}
                        className="my-3"
                    >
                        <SchoolName>
                            <div className="w-100 text-center text-uppercase">
                                <SchoolNameText>
                                    Concours Ingénieur
                                </SchoolNameText>
                            </div>
                        </SchoolName>
                    </SchoolNavbarWrapper>
                    {ecole.filiereIngenieur.map((filiere, index) => (
                        <div key={`filiere-${ecole.alt}-${index}`}>
                            <Item
                                className="text-uppercase my-1"
                                onClick={() => HanldeCheck(index)}
                                color={
                                    checkBoxState[index]
                                        ? `${ecole.couleurPrincipale}`
                                        : '#FFF'
                                }
                                textColor={
                                    checkBoxState[index]
                                        ? '#fff'
                                        : `${ecole.couleurPrincipale}`
                                }
                            >
                                <div style={{ cursor: 'pointer' }}>
                                    {filiere.filiere}
                                </div>
                                {checkBoxState[index] ? (
                                    <FaChevronUp
                                        style={{ cursor: 'pointer' }}
                                    />
                                ) : (
                                    <FaChevronDown
                                        style={{ cursor: 'pointer' }}
                                    />
                                )}
                            </Item>
                            <div
                                className={
                                    checkBoxState[index]
                                        ? 'd-block mb-5 fade-in'
                                        : 'mb-5 d-none'
                                }
                            >
                                <div className="text-justify mt-4">
                                    {filiere.introduction}
                                </div>
                                {filiere.volume.map((vol, index) => (
                                    <div
                                        className="text-start body-text border-0"
                                        key={`volume-${index}`}
                                    >
                                        <Title
                                            className="fw-bold my-3"
                                            color={ecole.couleurPrincipale}
                                        >
                                            {vol.title}
                                        </Title>
                                        {vol.contenu.map((cont, index) => (
                                            <Contenu
                                                key={`contenu-${index}-${cont}`}
                                                color={ecole.couleurPrincipale}
                                            >
                                                <div className="fw-bold mb-0 pt-1">
                                                    {cont.subtitle}
                                                </div>
                                                <SubtitleBody
                                                    color={ecole.couleurRgba}
                                                >
                                                    {cont.subtitleText}
                                                </SubtitleBody>
                                            </Contenu>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </Container>
            )}
        </Transitions>
    ) : (
        window.location.replace('/erreur')
    )
}
