import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import '../../utils/style/index.scss'
import styled from 'styled-components'
import { Card } from 'react-bootstrap'
import { Transitions } from '../../components/Transitions'

function importAll(r) {
    let images = {}
    r.keys().map((item, index) => (images[item.replace('./', '')] = r(item)))
    return images
}
const portail = importAll(
    require.context('../../assets/Portail', false, /\.(png|jpe?g|svg)$/)
)
const MyCard = styled(Card)`
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 90% !important;
    font-size: 0.85em;
    @media (max-width: 590px) {
        font-size: 0.7em;
    }
`
const TitleDiv = styled.div`
    width: 100%;
    background-color: rgb(0, 141, 54);
`
const associations = [
    {
        nom: "CPDEC",
        lien: 'https://cpdec.inphb.ci',
        image: portail['CPDEC.jpg'],
        alt: 'CPDEC',
        logo: portail['logoCPDEC.jpg'],
    },
    {
        nom: 'DESCOGEF',
        lien: 'https://descogef.inphb.ci/',
        image: portail['DESCOGEF.jpg'],
        alt: 'DESCOGEF',
        logo: portail['descogef1.png'],
    },
    {
        nom: 'CEA MEM',
        lien: 'https://cea-mem.inphb.ci/',
        image: portail['dep3.jpg'],
        alt: 'CEAMEM',
        logo: portail['ceamem1.png'],
    },
    {
        nom: 'CREFSEM',
        lien: 'https://crefsem.inphb.ci/',
        image: portail['dep4.jpg'],
        alt: 'CREFSEM',
        logo: portail['crefsem.png'],
    },
    {
        nom: 'IDSI',
        lien: 'https://datascience.inphb.ci/vues/datascience/',
        image: portail['DATASCIENCE.jpg'],
        alt: 'INTERNATIONALE DATA SCIENCE INSTITUTE',
    },
    {
        nom: 'CFMA',
        lien: 'http://www.aeronautique.inphb.ci/',
        image: portail['dep2.jpg'],
        alt: "Centre de Formation aux Métiers de l'Aéronautique",
    },
    {
        nom: 'VALOPRO',
        lien: 'https://cea-valopro.inphb.ci/',
        image: portail['VALOPRO.jpg'],
        alt: "VALOPRO",
    },
]
export const Centres = () => {
    return (
        <Transitions>
            <TitleDiv className="d-flex justify-content-center align-items-center text-light">
                <span
                    className="body-highlight mt-3 text-uppercase ps-2 mb-3 fs-1"
                    style={{ borderLeft: '10px solid #fff' }}
                >
                    Centres
                </span>
            </TitleDiv>
            <Container className="mt-5 d-flex flex-wrap align-items-center justify-content-center">
                <Row className="d-flex flex-wrap align-items-center justify-content-center">
                    {associations.map((association, index) => (
                        <Col
                            xxl={3}
                            xl={3}
                            lg={3}
                            md={6}
                            sm={6}
                            className="m-0 mb-3 d-flex justify-content-center p-0"
                            key={`Centres-${index}`}
                            
                            
                        >
                            <a href={association.lien} target="_blank" className="text-dark" rel='noreferrer'>
                                <MyCard>
                                    <Card.Img
                                        src={association.image}
                                        alt={association.alt}
                                        className="border-0"
                                        style={{ width: '100%', objectFit: 'contain', aspectRatio: 3/2 }}
                                    />
                                    <Card.Body
                                        style={{
                                            borderLeft: '5px solid #008D36',
                                        }}
                                        className="ps-1 py-3 d-flex align-items-center justify-content-between"
                                    >
                                        <div className="mb-2 fs-5">
                                            <strong>{association.nom}</strong>
                                        </div>
                                        {association.logo && <div style={{width: "30%"}}>
                                            <img src={association.logo} alt="" style={{width: "100%", objectFit: 'contain', aspectRatio: '4/2'}} />
                                        </div>}
                                        
                                    </Card.Body>
                                </MyCard>
                            </a>
                        </Col>
                    ))}
                </Row>
            </Container>
        </Transitions>
    )
}
