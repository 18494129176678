const colors = {
    bg: '#f3f3f3',
    header: '#240046',
    link: '#ff8500',
    title: '#7c2cbf',
    bg1: '#ee8303',
    bg2: '#f4a105',
    primary: '#5843E4',
    secondary: '#8186A0',
    backgroundLight: '#F9F9FC',
    backgroundLightx: '#F9F9FC',
}
export default colors
