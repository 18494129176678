import React from 'react'
import { useContext } from 'react'
import { Container } from 'react-bootstrap'
import styled from 'styled-components'
import { Transitions } from '../../components/Transitions'
import { MyContext } from '../../utils/context'
import Error from '../../components/Error'
import { Loader } from '../../components/Loader'
import DOMPurify from 'dompurify'
import photoDuDG from '../../assets/Portail/dg.png'

const TitleDiv = styled.div`
    width: 100%;
    background-color: rgb(0, 141, 54);
`
const TexteDuDG = styled.div`
    & > ul {
        & > li {
            list-style-type: disc;
            list-style-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Ccircle cx='4' cy='4' r='4' fill='%23008D36'/%3E%3C/svg%3E");
        }
    }
`
const ImageWrapper = styled.div`
    @media (max-width: 430px) {
        width: 100% !important;
        float: none !important;
    }
`
export const MotDuDirecteur = () => {
    const { motDuDG,  isLoading, error } = useContext(MyContext)

    const vraiMotDuDG = motDuDG.replace(/<\/?(table|tr|td|tbody)>/g, '')
    const reelMotDuDG = vraiMotDuDG.replace(/<td colspan="1" rowspan="1">/g, '')

    if (error) return <Error />

    return (
        <Transitions>
            {isLoading ? (
                <div className="d-flex justify-content-center align-items-center w-100">
                    <Loader />
                </div>
            ) : (
                <>
                    <TitleDiv className="d-flex justify-content-center align-items-center text-light">
                        <span
                            className="body-highlight mt-3 text-uppercase ps-2 mb-3 fs-1"
                            style={{ borderLeft: '10px solid #fff' }}
                        >
                            Mot du Directeur Général
                        </span>
                    </TitleDiv>
                    <Container className="mt-5">
                        <ImageWrapper
                            className="mb-3 me-3"
                            style={{ width: '30%', float: 'left' }}
                        >
                            <img
                                src={photoDuDG}
                                alt="DG de l'INP-HB"
                                style={{ width: '100%' }}
                            />
                            <div
                                className="m-0 d-flex align-items-center justify-content-center p-2"
                                style={{
                                    backgroundColor: '#fff',
                                    boxShadow:
                                        'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                }}
                            >
                                Dr Moussa Abdoul-Kader Diaby
                            </div>
                        </ImageWrapper>
                        <TexteDuDG
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(reelMotDuDG),
                            }}
                            className="body-text border-0 m-1 mb-5"
                        />
                        {/*     {motDuDG}
                    </div> */}
                    </Container>
                </>
            )}
        </Transitions>
    )
}
