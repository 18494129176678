import React from 'react'
import Logo from '../assets/Portail/logoINP.png'
import classes from './Menubpe.module.css'

export default function Menu_Bpe() {
  return (
    <>
        <nav className={classes.nav}>
            <div className={classes.bpelogo}><img src={Logo} alt /></div>
            <ul> 
                {/* <li>A Propos du BPE</li> */}
                {/* <li>Lien 2</li>
                <li>Lien 3</li>
                <li>Lien 4</li> */}
            </ul>
        </nav>
    </>
  )
}
