import React from 'react'
import { animated, to } from 'react-spring'
import styled from 'styled-components'
import { Card } from 'react-bootstrap'
import { HashLink } from 'react-router-hash-link'

const MyCard = styled(animated.div)`
    position: absolute;
    width: 100%;
    height: 100%;
    will-change: transform;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
`
const MyCardContent = styled(animated.div)`
    padding: 5px;
    width: 45vh;
    background-color: #f478208e;
    max-width: 300px;
    height: 450px;
    max-height: 570px;
    will-change: transform;
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`
class ActCard extends React.Component {
    render() {
        const { i, x, y, rot, scale, trans, bind, objs } = this.props
        const { titre, date, photoMini, recit } = objs[i]

        return (
            <MyCard
                key={i}
                style={{
                    transform: to(
                        [x, y],
                        (x, y) => `translate3d(${x}px,${y}px,0)`
                    ),
                }}
            >
                <MyCardContent
                    {...bind(i)}
                    style={{
                        transform: to([rot, scale], trans),
                    }}
                >
                    <HashLink
                        to={`actualites#actualite-` + i}
                        className="text-dark"
                    >
                        <Card
                            style={{
                                width: '100%',
                                height: '100%',
                                border: '0px',
                                borderRadius: 30,
                                overflow: 'hidden',
                                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                            }}
                        >
                            {photoMini && (
                                <Card.Img
                                    variant="top"
                                    src={Image} //`https://inphb-api.herokuapp.com/${photoMini.bucket}/${photoMini.key}`
                                    style={{ height: '50%' }}
                                />
                            )}

                            <Card.Body>
                                <Card.Title
                                    className="fw-bold text-start"
                                    style={{ fontSize: '1em' }}
                                >
                                    {titre}
                                </Card.Title>
                                <Card.Text
                                    className="mb-1 text-start"
                                    style={{ fontSize: '.75em' }}
                                >
                                    {new Date(date).toLocaleString([], {
                                        weekday: 'long',
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    })}{' '}
                                    :{' '}
                                    {new Date(date).toLocaleTimeString([], {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })}
                                </Card.Text>
                                <Card.Text
                                    dangerouslySetInnerHTML={{
                                        __html: `${recit
                                            .split(/\s+/)
                                            .slice(0, 15)
                                            .join(' ')}...`,
                                    }}
                                />
                            </Card.Body>
                        </Card>
                    </HashLink>
                </MyCardContent>
            </MyCard>
        )
    }
}

export default ActCard
