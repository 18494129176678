import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { Transitions } from '../../components/Transitions'

function importAll(r) {
    let images = {}
    r.keys().map((item, index) => (images[item.replace('./', '')] = r(item)))
    return images
}

const epreuves = importAll(
    require.context(
        '../../assets/Epreuves',
        false,
        /\.(png|jpe?g|svg|mp4|rar)$/
    )
)
const MyCol = styled(Col)`
    flex-basis: calc(100% / 5);
    cursor: pointer;
    @media (max-width: 1200px) {
        flex-basis: auto;
    }
`
export const SujetsAccueil = () => {
    const settings = {
        xxl: 2,
        xl: 3,
        lg: 3,
        md: 4,
        sm: 4,
        xs: 6,
    }

    return (
        <Transitions>
            <Container>
                <div className="mb-2">
                    <Row>
                    {/* '2013', '2014',  '2015', '2016', '2017', '2018',*/}
                        {[ '2019', '2022'].map(
                            (items, index) => (
                                <MyCol
                                    className="p-2 text-danger fs-5 d-flex flex-column align-items-center justify-content-center"
                                    key={index}
                                    {...settings}
                                >
                                    <NavLink
                                        to={`${items}`}
                                        style={{ textDecoration: 'none' }}
                                        alt={`SUJETS ${items}`}
                                        className="text-center"
                                        
                                    >
                                        <img
                                            src={epreuves['folder.svg']}
                                            alt=""
                                            style={{
                                                width: '80%',
                                                aspectRation: 3 / 2,
                                                objectFit: 'contain',
                                            }}
                                        />
                                        <span>Sujets {items}</span>
                                    </NavLink>
                                </MyCol>
                            )
                        )}
                    </Row>
                </div>
            </Container>
        </Transitions>
    )
}
