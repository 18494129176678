import React from 'react'
import { Col, NavbarBrand, Row } from 'react-bootstrap'
import { BiLocationPlus, BiTime } from 'react-icons/bi'
import styled from 'styled-components'

const EvenementCol = styled(Col)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2px;
    font-size: 1em;
    @media (max-width: 576px) {
        margin: 5px !important;
        flex-basis: 60% !important;
    }
    @media (max-width: 480px) {
        flex-basis: 70% !important;
    }
    @media (max-width: 420px) {
        flex-basis: 80% !important;
    }
    @media (max-width: 322px) {
        flex-basis: 90% !important;
    }
`
const EvenementColContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f1;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`

export const EvenementsDesktop = ({eventData}) => {
    return (
        <Row
            className="mb-5 w-100 d-flex flex-wrap justify-content-center"
            data-aos="fade-up"
        >
            {eventData[0]&&<EvenementCol className="mb-5" xxl={3} xl={3} lg={3} md={6} sm={6}>
                <EvenementColContainer>
                    <div>
                        <p className="body-text border-0 fw-bold p-0 mb-1">
                            {eventData[0].titre}
                        </p>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: eventData[0].description,
                            }}
                            style={{
                                fontSize: '.70em',
                            }}
                        />
                        <p>
                            <BiTime className="me-2" />
                            <span
                                style={{
                                    color: '#f47920',
                                }}
                            >
                                {new Date(eventData[0].date).toLocaleTimeString(
                                    [],
                                    {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    }
                                )}
                            </span>
                        </p>
                        <p>
                            <BiLocationPlus className="me-2" />
                            <span
                                style={{
                                    color: '#f47920',
                                }}
                            >
                                {eventData[0].lieu}
                            </span>
                        </p>
                    </div>
                    <NavbarBrand
                        className="align-items-center justify-content-center"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <img
                            style={{
                                width: '120px',
                                height: '120px',
                                borderRadius: '50%',
                                border: '3px solid #f47920',
                            }}
                            src={`https://inphb-api.herokuapp.com/${eventData[0].photo.bucket}/${eventData[0].photo.key}`}
                            alt={eventData[0].titre}
                        />
                        <span
                            className="fw-bold text-light p-2"
                            style={{
                                backgroundColor: '#f47920',
                                borderRadius: '50px',
                                marginTop: '-8px',
                            }}
                        >
                            {new Date(eventData[0].date).toLocaleString([], {
                                weekday: 'short',
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                            })}
                        </span>
                    </NavbarBrand>
                </EvenementColContainer>
            </EvenementCol>}
            {eventData[1]&&<EvenementCol className="mt-5" xxl={3} xl={3} lg={3} md={6} sm={6}>
                <EvenementColContainer>
                    <NavbarBrand
                        className="align-items-center justify-content-center"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <img
                            style={{
                                width: '120px',
                                height: '120px',
                                borderRadius: '50%',
                                border: '3px solid #f47920',
                            }}
                            src={`https://inphb-api.herokuapp.com/${eventData[1].photo.bucket}/${eventData[1].photo.key}`}
                            alt={eventData[1].titre}
                        />
                        <span
                            className="fw-bold text-light p-2"
                            style={{
                                backgroundColor: '#f47920',
                                borderRadius: '50px',
                                marginTop: '-8px',
                            }}
                        >
                            {new Date(eventData[1].date).toLocaleString([], {
                                weekday: 'short',
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                            })}
                        </span>
                    </NavbarBrand>
                    <div>
                        <p className="body-text border-0 fw-bold p-0 mb-1">
                            {eventData[1].titre}
                        </p>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: eventData[1].description,
                            }}
                            style={{
                                fontSize: '.70em',
                            }}
                        />
                        <p>
                            <BiTime className="me-2" />
                            <span
                                style={{
                                    color: '#f47920',
                                }}
                            >
                                {new Date(eventData[1].date).toLocaleTimeString(
                                    [],
                                    {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    }
                                )}
                            </span>
                        </p>
                        <p>
                            <BiLocationPlus className="me-2" />
                            <span
                                style={{
                                    color: '#f47920',
                                }}
                            >
                                {eventData[1].lieu}
                            </span>
                        </p>
                    </div>
                </EvenementColContainer>
            </EvenementCol>}
            {eventData[2]&&<EvenementCol className="mb-5" xxl={3} xl={3} lg={3} md={6} sm={6}>
                <EvenementColContainer>
                    <div>
                        <p className="body-text border-0 fw-bold p-0 mb-1">
                            {eventData[2].titre}
                        </p>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: eventData[2].description,
                            }}
                            style={{
                                fontSize: '.70em',
                            }}
                        />
                        <p>
                            <BiTime className="me-2" />
                            <span
                                style={{
                                    color: '#f47920',
                                }}
                            >
                                {new Date(eventData[2].date).toLocaleString(
                                    [],
                                    {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    }
                                )}
                            </span>
                        </p>
                        <p>
                            <BiLocationPlus className="me-2" />
                            <span
                                style={{
                                    color: '#f47920',
                                }}
                            >
                                {eventData[2].lieu}
                            </span>
                        </p>
                    </div>
                    <NavbarBrand
                        className="align-items-center justify-content-center"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <img
                            style={{
                                width: '120px',
                                height: '120px',
                                borderRadius: '50%',
                                border: '3px solid #f47920',
                            }}
                            src={`https://inphb-api.herokuapp.com/${eventData[2].photo.bucket}/${eventData[2].photo.key}`}
                            alt={eventData[2].titre}
                        />
                        <span
                            className="fw-bold text-light p-2"
                            style={{
                                backgroundColor: '#f47920',
                                borderRadius: '50px',
                                marginTop: '-8px',
                            }}
                        >
                            {new Date(eventData[2].date).toLocaleString([], {
                                weekday: 'short',
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                            })}
                        </span>
                    </NavbarBrand>
                </EvenementColContainer>
            </EvenementCol>}
            {eventData[3]&&<EvenementCol className="mt-5" xxl={3} xl={3} lg={3} md={6} sm={6}>
                <EvenementColContainer>
                    <NavbarBrand
                        className="align-items-center justify-content-center"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <img
                            style={{
                                width: '120px',
                                height: '120px',
                                borderRadius: '50%',
                                border: '3px solid #f47920',
                            }}
                            src={`https://inphb-api.herokuapp.com/${eventData[3].photo.bucket}/${eventData[3].photo.key}`}
                            alt={eventData[3].titre}
                        />
                        <span
                            className="fw-bold text-light p-2"
                            style={{
                                backgroundColor: '#f47920',
                                borderRadius: '50px',
                                marginTop: '-8px',
                            }}
                        >
                            {new Date(eventData[3].date).toLocaleString([], {
                                weekday: 'short',
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                            })}
                        </span>
                    </NavbarBrand>
                    <div>
                        <p className="body-text border-0 fw-bold p-0 mb-1">
                            {eventData[3].titre}
                        </p>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: eventData[3].description,
                            }}
                            style={{
                                fontSize: '.70em',
                            }}
                        />
                        <p>
                            <BiTime className="me-2" />
                            <span
                                style={{
                                    color: '#f47920',
                                }}
                            >
                                {new Date(eventData[3].date).toLocaleTimeString(
                                    [],
                                    {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    }
                                )}
                            </span>
                        </p>
                        <p>
                            <BiLocationPlus className="me-2" />
                            <span
                                style={{
                                    color: '#f47920',
                                }}
                            >
                                {eventData[3].lieu}
                            </span>
                        </p>
                    </div>
                </EvenementColContainer>
            </EvenementCol>}
        </Row>
    )
}
