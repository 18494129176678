import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import HeaderEcoleItems from '../../components/HeaderEcoleItems'
import { school } from '../../datas'

const Item = styled.div`
    padding: 10px;
    background-color: ${(prop) => prop.color};
    color: ${(prop) => prop.textColor};
    display: flex;
    border-bottom: 1px solid ${(prop) => prop.textcolor};
    justify-content: space-between;
    align-items: center;
    cursor: pointer !important;
`

export const CommentIntegrer = () => {
    const { schoolName } = useParams()
    const ecole = school[`${schoolName}`]
    const [checkBoxState, setcheckBoxState] = useState([
        false,
        false,
        false,
        false,
    ])
    const HanldeCheck = (i) => {
        setcheckBoxState((prevState) =>
            prevState.map((item, idx) => (idx === i ? !item : false))
        )
    }

    return (
        <>
            <HeaderEcoleItems
                couleurPrincipale={ecole.couleurPrincipale}
                logo={ecole.logo}
                acronyme={ecole.alt}
                nom={'Comment intégrer notre école'}
            />
            <Container className='my-5'>
                <img src={ecole.filieresConcoursDutIntroduction} alt="" width={"100%"}/>

               {ecole.commentIntegrer && ecole.commentIntegrer.map((integrer, index) => (
                <div key={`${index}`} id={`actualite-${index}`}>
                    <Item
                        className="text-uppercase my-3"
                        onClick={() => HanldeCheck(index)}
                        color={checkBoxState[index] ? ecole.couleurPrincipale : '#fff'}
                        textColor={checkBoxState[index] ? '#fff' : '#000'}
                    >
                        <div
                            style={{ cursor: 'pointer' }}
                            className="d-flex align-items-center"
                        >
                            {integrer.titre}
                        </div>
                        {checkBoxState[index] ? (
                            <FaChevronUp
                                style={{ cursor: 'pointer', color: '#fff' }}
                            />
                        ) : (
                            <FaChevronDown
                                style={{
                                    cursor: 'pointer',
                                    color: ecole.couleurPrincipale,
                                }}
                            />
                        )}
                    </Item>
                    <div
                        className={
                            checkBoxState[index]
                                ? 'd-block mb-5 fade-in'
                                : 'mb-5 d-none'
                        }
                    >
                        {integrer.sousTitre && <> <strong>{integrer.sousTitre} </strong> <br /></>}
                        <div>
                          {integrer.corps}
                        </div>
                    </div>
                </div>
            ))}
            </Container>
           
        </>
    )
}
