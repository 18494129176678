import React from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

const TitleDiv = styled.div`
    width: 100%;
    background-color: rgb(0, 141, 54);
`

export const VieUniversitaire = () => {
    return (
        <div className="mb-5 pb-5">
            <TitleDiv className="d-flex justify-content-center align-items-center text-light">
                <span
                    className="body-highlight mt-3 text-uppercase ps-2 mb-3 fs-1"
                    style={{ borderLeft: '10px solid #fff' }}
                >
                    Vie à l'inp-hb
                </span>
            </TitleDiv>
            <Outlet/>
        </div>
    )
}
