import styled from 'styled-components'
import '../../utils/style/index.scss'

const SchoolNavbarWrapper = styled.div`
    background-color: ${(prop) => prop.color};
    display: flex;
    justify-content: center;
    align-items: center;
`
const SchoolName = styled.div`
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;
    @media(max-width:730px){
        flex-direction: column;
        justify-content: center;
    }
`
const SchoolNameText = styled.span`
    font-style: normal;
    font-weight: bold;
    font-size: 2.5em;
    padding-left: 10px;
    border-left: 10px solid #fff;
    text-align: center;
    color: #fff;
`
const MyLogo = styled.img`
    transition: transform 360ms ease-in-out;
    &:hover {
        transform: rotate(360deg);
    }

    
`

export default function HeaderEcoleItems({
    couleurPrincipale,
    logo,
    acronyme,
    nom,
}) {
    return (
        <SchoolNavbarWrapper color={couleurPrincipale}>
            <SchoolName>
                <a
                    href={`/ecoles/${acronyme.toLowerCase()}`}
                >
                    <MyLogo
                        style={{
                            width: '100px',
                            height: '100px',
                            backgroundColor: '#fff',
                            borderRadius: '50%',
                        }}
                        src={logo}
                        alt={acronyme}
                        className="border-0 mb-2 ms-5"
                    />
                </a>
                <div className="w-100 text-center text-uppercase">
                    <SchoolNameText >{nom}</SchoolNameText>
                </div>
            </SchoolName>
        </SchoolNavbarWrapper>
    )
    
}
