import React from 'react'
import { AiOutlineCheckCircle } from 'react-icons/ai';

function SlideToRight() {
  return (
    <>
        <a href='https://inphb-diplome.verifdiploma.com/verifcenter/' target='_blank'>
            <button class="buttonvideo2 slideToRight">
            <AiOutlineCheckCircle size={30} style={{ marginRight: '5px' }} className='verif3' />
                Vérifier un diplôme INP-HB
            </button>
        </a>
    </>
  )
}

export default SlideToRight