import React, { useState } from 'react'
import styled from 'styled-components'
import '../../utils/style/index.scss'
import { Carousel, Container } from 'react-bootstrap'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { PortailInformations } from '../../datas'
import { Transitions } from '../../components/Transitions'

const TitleDiv = styled.div`
    width: 100%;
    background-color: rgb(0, 141, 54);
`
const Item = styled.div`
    padding: 10px;
    background-color: ${(prop) => prop.color};
    color: ${(prop) => prop.textColor};
    display: flex;
    border-bottom: 1px solid #f47920;
    justify-content: space-between;
    align-items: center;
    cursor: pointer !important;
`
const Title = styled.div`
    &::before {
        content: '.';
        font-size: 4em;
        color: #0dba1e;
    }
`

export const SitesEtCampus = () => {
    const [checkBoxState, setcheckBoxState] = useState([
        false,
        false,
        false,
        false,
        false,
        false,
        false,
    ])
    const HanldeCheck = (i) => {
        setcheckBoxState((prevState) =>
            prevState.map((item, idx) => (idx === i ? !item : false))
        )
    }
    return (
        <Transitions>
            <TitleDiv className="d-flex justify-content-center align-items-center text-light mb-5">
                <span
                    className="body-highlight mt-3 text-uppercase ps-2 mb-3 fs-1"
                    style={{ borderLeft: '10px solid #fff' }}
                >
                    Sites et Campus
                </span>
            </TitleDiv>
            <Container>
                {PortailInformations.sites.map((site, index) => (
                    <div
                        key={`sites-${site.titre}-${index}`}
                        id={`sites-${index}`}
                    >
                        <Item
                            className="text-uppercase my-1"
                            onClick={() => HanldeCheck(index)}
                            color={checkBoxState[index] ? '#f47920' : '#fff'}
                            textColor={checkBoxState[index] ? '#fff' : '#000'}
                        >
                            <div style={{ cursor: 'pointer' }}>
                                {site.titre}
                            </div>
                            {checkBoxState[index] ? (
                                <FaChevronUp
                                    style={{ cursor: 'pointer', color: '#fff' }}
                                />
                            ) : (
                                <FaChevronDown
                                    style={{
                                        cursor: 'pointer',
                                        color: '#f47920',
                                    }}
                                />
                            )}
                        </Item>
                        <div
                            className={
                                checkBoxState[index]
                                    ? 'd-block mb-5 fade-in'
                                    : 'mb-5 d-none'
                            }
                        >
                            {site.images && <Carousel className="w-100 my-3">
                                {site.images.map((im, i) => (
                                    <Carousel.Item key={`Sport-${i}`}>
                                        <img
                                            className="d-block w-100"
                                            src={im.image}
                                            alt={site.titre}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel> }
                            
                            <div className="body-text border-0">
                                {site.description}
                            </div>
                            {site.ecoles.map((ecole, index2) => (
                                <Title
                                    to={ecole.lien}
                                    key={`sites-${site.nom}-${index2}`}
                                >
                                    {ecole.nom}
                                </Title>
                            ))}
                        </div>
                    </div>
                ))}
            </Container>
        </Transitions>
    )
}
