import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import styled from 'styled-components'
import { Transitions } from '../../components/Transitions'
import { PortailInformations } from '../../datas'

const TitleDiv = styled.div`
    width: 100%;
    background-color: rgb(0, 141, 54);
`
const Item = styled.div`
    padding: 10px;
    background-color: ${(prop) => prop.color};
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer !important;
`
export const Organisation = () => {
    const [checkBoxState, setcheckBoxState] = useState([false, false, false, false,false, false, false, false, false])
    const HanldeCheck = (i) => {
        setcheckBoxState((prevState) =>
            prevState.map((item, idx) => (idx === i ? !item : false))
        )
    }
    return (
        <Transitions>
            <TitleDiv className="d-flex justify-content-center align-items-center text-light mb-5">
                <span
                    className="body-highlight mt-3 text-uppercase ps-2 mb-3 fs-1"
                    style={{ borderLeft: '10px solid #fff' }}
                >
                    Organisation
                </span>
            </TitleDiv>
            <Container className='mb-5 body-text border-0 mb-2'>
                Le modèle d'administration adopté par l'INP-HB est le fruit de dizaines d'années d'expériences capitalisées au sein des ex grandes écoles dont il est issu. Il traduit la nécessité pour l’Institut d'établir un lien organique fort entre d'une part les structures de production, et d'autre part celles qui ont en charge la formation. Il est illustré par l'organigramme ci-dessous :
            </Container>
            <Container>
                {PortailInformations.organisation.map((histoire, index) => (
                    <div
                        key={`actualites-${histoire.titre}-${index}`}
                        id={`actualites-${index}`}
                    >
                        <Item
                            className="text-light text-uppercase my-1"
                            onClick={() => HanldeCheck(index)}
                            color={
                                checkBoxState[index]
                                    ? '#EE7202'
                                    : '#7b7676'
                            }
                        >
                            <div style={{ cursor: 'pointer' }}>
                                {histoire.titre}
                            </div>
                            {checkBoxState[index] ? (
                                <FaChevronUp style={{ cursor: 'pointer' }} />
                            ) : (
                                <FaChevronDown style={{ cursor: 'pointer' }} />
                            )}
                        </Item>
                        <div
                            className={
                                checkBoxState[index]
                                    ? 'd-block mb-5 fade-in'
                                    : 'mb-5 d-none'
                            }
                        >
                            <div className="body-text border-0">
                                {histoire.description}
                            </div>
                        </div>
                    </div>
                ))}
            </Container>
        </Transitions>
    )
}
