import React from 'react'
import Newmenutop from '../Newmenutop/Newmenutop'

export default function Newschool() {
  return (
    <>
        <Newmenutop />
    </>
  )
}
