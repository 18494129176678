import React, { useState, useContext } from 'react'
import { Container } from 'react-bootstrap'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import styled from 'styled-components'
import Histoire from '../../assets/Portail/historique.png'
import { Transitions } from '../../components/Transitions'
import { MyContext } from '../../utils/context'
import Error from '../../components/Error'
import {Loader} from '../../components/Loader'
import histo from '../../assets/Portail/historique.png'
import Organigram from '../../assets/Portail/organigramme.png'
// import classes from './organigram.module.css'
import  './organigram.css'
import { Tree, TreeNode, TreeNodeProps } from 'react-organizational-chart';
import Organigramme from './MyOrgChart'
import Imgtest from '../../assets/trombi/imgtest.jpg'
import { FaTimes } from 'react-icons/fa';


const FullPageDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleDiv = styled.div`
    width: 100%;
    background-color: rgb(0, 141, 54);
`
const Item = styled.div`
    padding: 10px;
    background-color: ${(prop) => prop.color};
    color: ${(prop) => prop.textColor};
    display: flex;
    border-bottom: 1px solid #f47920;
    justify-content: space-between;
    align-items: center;
    cursor: pointer !important;
`
const Myimg = styled.img`
    width: 100%;
`
export const APropos = () => {
    const [isDivOpen, setIsDivOpen] = useState(false);
    const [isDivOpenDG, setIsDivOpenDG] = useState(false);
    const [isDivOpenDGAPVE, setIsDivOpenDGAPVE] = useState(false);
    const [isDivOpenDGARIT, setIsDivOpenDGARIT] = useState(false);
    const [isDivOpenSG, setIsDivOpenSG] = useState(false);
    const [isDivOpenDF, setIsDivOpenDF] = useState(false);
    const [isDivOpenDPE, setIsDivOpenDPE] = useState(false);
    const [isDivOpenDRH, setIsDivOpenDRH] = useState(false);
    const [isDivOpenDC, setIsDivOpenDC] = useState(false);
    const [isDivOpenACP, setIsDivOpenACP] = useState(false);
    const [isDivOpenCB, setIsDivOpenCB] = useState(false);











    const handleGestion = () => {
        setIsDivOpen(!isDivOpen);
    }
    const handleDG = () => {
        setIsDivOpenDG(!isDivOpenDG);
    }
    const handleDGAPVE = () => {
        setIsDivOpenDGAPVE(!isDivOpenDG);
    }
    const handleDGARIT = () => {
        setIsDivOpenDGARIT(!isDivOpenDGARIT);
    }
    const handleSG = () => {
        setIsDivOpenSG(!isDivOpenSG);
    }
    const handleDF = () => {
        setIsDivOpenDF(!isDivOpenDF);
    }
    const handleDPE = () => {
        setIsDivOpenDPE(!isDivOpenDPE);
    }
    const handleDRH = () => {
        setIsDivOpenDRH(!isDivOpenDRH);
    }
    const handleDC = () => {
        setIsDivOpenDC(!isDivOpenDC);
    }
    const handleACP = () => {
        setIsDivOpenACP(!isDivOpenACP);
    }
    const handleCB = () => {
        setIsDivOpenCB(!isDivOpenCB);
    }
    const [checkBoxState, setcheckBoxState] = useState([
        false,
        false,
        true,
        false,
    ])
    const HanldeCheck = (i) => {
        setcheckBoxState((prevState) =>
            prevState.map((item, idx) => (idx === i ? !item : false))
        )
    }

    const {isLoading, error, infoGen} = useContext(MyContext)

    const datesImportantes = infoGen[0] ? infoGen[0].datesImportantes : null
    const organigramme = infoGen[0] ? infoGen[0].organigramme : null;

    if(error) return <Error/>

    const StyledNode = styled.div`
    padding: 5px;
    border-radius: 8px;
    display: inline-block;
    border: 1px solid red;
    `;

    // Les content div du trombinoscope
    

    return (
        <Transitions>
            <TitleDiv className="d-flex justify-content-center align-items-center text-light mb-5">
                <span
                    className="body-highlight mt-3 text-uppercase ps-2 mb-3 fs-1"
                    style={{ borderLeft: '10px solid #fff' }}
                >
                    À Propos de l'INP-HB
                </span>
            </TitleDiv>
            <Container>
                <div className='mb-2'>
                    <Item
                        className="text-uppercase my-1"
                        onClick={() => HanldeCheck(0)}
                        color={checkBoxState[0] ? '#f47920' : '#fff'}
                        textColor={checkBoxState[0] ? '#fff' : '#000'}
                    >
                        <div
                            style={{ cursor: 'pointer' }}
                            className="text-uppercase"
                        >
                            Historique
                        </div>
                        {checkBoxState[0] ? (
                            <FaChevronUp style={{ cursor: 'pointer', color: "#fff" }} />
                        ) : (
                            <FaChevronDown style={{ cursor: 'pointer', color: "#f47920" }} />
                        )}
                    </Item>
                    <div
                        className={
                            checkBoxState[0] ? 'd-block mb-5 fade-in' : 'mb-5 d-none'
                        }
                    >
                        <div className="body-text border-0">
                            Etablissement Public à caractère administratif sous
                            la tutelle du Ministère de l’Enseignement Supérieure
                            et de la Recherche Scientifique, l’Institut National
                            Polytechnique est une institution unique dans la
                            Sous-région ouest- africaine. Créé par décret 96-678
                            du 04 septembre 1996, l’INP-HB est issu du
                            regroupement de l’Ecole Nationale Supérieure
                            d’Agronomie (ENSA), l’Ecole Nationale Supérieure des
                            Travaux Publics (ENSTP), l’Institut Agricole de
                            Bouaké (IAB) et l’Institut National Supérieure de
                            l’Enseignement Technique (INSET), quatre écoles
                            appelées communément les « Ex Grandes Ecoles de
                            Yamoussoukro ».
                        </div>
                        <div className="d-flex align-items-center justify-content-center">
                            <Myimg
                                src={Histoire}
                                alt=""
                                className="my-3"
                            />
                        </div>
                        <div className="body-text border-0">
                            Grâce à son savoir-faire et son expertise,
                            l’institut a formé des milliers de Managers,
                            ingénieurs et techniciens supérieurs dans les
                            domaines du Commerce, des Tavaux Publics, de
                            l’Agronomie, de l’Industrie, des Mines et de la
                            Géologie, qui font sa fierté en Afrique et à travers
                            le monde. Hormis ses formations de base, l’INP-HB a
                            également mis en œuvre plusieurs programmes
                            professionnalisants (Mastères spécialisés) en vue
                            d’étoffer son offre de formation mais surtout,
                            répondre aux besoins sans cesse croissants des
                            entreprises.
                        </div>
                    </div>
                </div>
                <div>
                    {/* <Item
                        className="text-uppercase my-1 mt-0"
                        onClick={() => HanldeCheck(1)}
                        color={checkBoxState[1] ? '#f47920' : '#fff'}
                        textColor={checkBoxState[1] ? '#fff' : '#000'}
                    >
                        <div
                            style={{ cursor: 'pointer' }}
                            className="text-uppercase"
                        >
                            Dates Importantes
                        </div>
                        {checkBoxState[1] ? (
                            <FaChevronUp style={{ cursor: 'pointer', color: "#fff" }} />
                        ) : (
                            <FaChevronDown style={{ cursor: 'pointer', color: "#f47920" }} />
                        )}
                    </Item> */}
                    <div
                        className={
                            checkBoxState[1] ? 'd-block mb-2 fade-in p-0' : 'mb-2 d-none p-0'
                        }
                    >
                        <div className="d-flex align-items-center justify-content-center mt-0">
                            <Myimg
                                src={histo}
                                alt=""
                                className="mt-0"
                            />
                        </div>
                    </div>
                </div>
                <div className='pb-3'>
                    <Item
                        className="text-uppercase my-1"
                        onClick={() => HanldeCheck(2)}
                        color={checkBoxState[2] ? '#f47920' : '#fff'}
                        textColor={checkBoxState[2] ? '#fff' : '#000'}
                    >
                        <div
                            style={{ cursor: 'pointer' }}
                            className="text-uppercase"
                        >
                            Organigramme
                        </div>
                        {checkBoxState[2] ? (
                            <FaChevronUp style={{ cursor: 'pointer', color: "#fff" }} />
                        ) : (
                            <FaChevronDown style={{ cursor: 'pointer', color: "#f47920" }} />
                        )}
                    </Item>
                    <div
                        className={
                            checkBoxState[2] ? 'd-block mb-5 fade-in' : 'mb-5 d-none'
                        }
                    >
                         <div className="body-text border-0 mb-2">
                            Le modèle d'administration adopté par l'INP-HB est le fruit de
                            dizaines d'années d'expériences capitalisées au sein des ex
                            grandes écoles dont il est issu. Il traduit la nécessité pour
                            l’Institut d'établir un lien organique fort entre d'une part les
                            structures de production, et d'autre part celles qui ont en
                            charge la formation. Il est illustré par l'organigramme
                            ci-dessous :
                        </div>
                        <div className="d-flex align-items-center justify-content-center">
                        <div className='trobconttelphone'>
                            <div className='bouttontrombitel bgbleuunique' onClick={e => alert('Votre appareil est moins de 768px, veuillez utiliser un ordinateur svp.')}>voir le <br/> trombiniscope</div>
                        </div>
                        <div className='trobcont'>
                            <div className='trobcont'>                               
                                <div className='trombi'>
                                    
                                    <div className='trombidivent larger textcap1 bggris' 
                                        // onClick={handleGestion}
                                     >
                                        <div className='trombidiventcont bggris'><img src="https://inphb.ci/trombi/imgtest.jpg" alt='' /><br /> <span>pr arsene toka kobea</span></div>
                                        
                                        <h5 className='teltext'>CONSEIL dE GESTION</h5>
                                    </div>
                                        {isDivOpen && (
                                            <div className='opengestion'>
                                                <button onClick={() => setIsDivOpen(false)}><FaTimes /></button>
                                                <h5>CONSEIL DE GESTION</h5>
                                                
                                            </div>
                                        )}
                                    <div className='ligne1'></div>
                                    <div className='directiong'>
                                        <div className='trombidivent normal bgbleuunique textcap1' onClick={handleDG}>
                                            <div className='trombidiventcont bgbleuunique'><img src="https://inphb.ci/trombi/diabymoussa.jpg" alt='' /><br /> <span>dr moussa a. k. diaby </span></div>
                                            
                                            <h5 className='teltext'>DIRECTION générale</h5>
                                        </div>
                                        {isDivOpenDG && (
                                            <div className='opengestion'>
                                                <button onClick={() => setIsDivOpenDG(false)}><FaTimes /></button>
                                                <div className='letitre'>
                                                    <h5>Direction générale</h5>
                                                </div>
                                                <div className='bggrisclair popupavecsd borad15'>
                                                    <div className='imagedirectur'>
                                                        <img src="https://inphb.ci/trombi/diabymoussa.jpg" alt='' />
                                                    </div>
                                                    <div className='dirtitle'>
                                                        <h5 style={{color: '#7896DB', textTransform:'uppercase'}}>dr moussa a. k. diaby </h5>
                                                        <span style={{color: '#000', textTransform:'uppercase'}}>Directeur Général</span>
                                                    </div>
                                                    {/* bureu du DGA COMMENCE ICI  */}
                                                    {/* <div className='popuppostedirbureau'>
                                                        <div className='popupsdbur bgbleub'>
                                                            <div className='imagechef1'>
                                                                <img src="https://inphb.ci/trombi/gbeyjoris.jpg" alt='' />
                                                                <h6>gbey séhoué joris eveline</h6>
                                                                <h6 style={{color: '#000', fontWeight: '600'}}>Assistante particulière du dg <br/>Cheffe du secrétariat particulier</h6>
                                                            </div>
                                                            <div className='imagechef1'>
                                                                <img src="https://inphb.ci/trombi/siakabakayoko.jpg" alt='' />
                                                                <h6>siaka bakayoko</h6>
                                                                <h6 style={{color: '#000', fontWeight: '600'}}>chargé du protocole et des missions</h6>
                                                            </div>
                                                            <div className='imagechef1'>
                                                                <img src="https://inphb.ci/trombi/yebouayawa.jpg" alt='' />
                                                                <h6>yeboua yawa nina caroline</h6>
                                                                <h6 style={{color: '#000', fontWeight: '600'}}>assistante technique du dg chargée du pilotage du plan <br /> d'orientation stratégique et du suivi du contrat d'objectifs <br/> et de performence</h6>
                                                            </div>
                                                            
                                                        </div>
                                                    </div> */}
                                                        {/* LE SECRETARIAT PARTICULIER DU DG  */}
                                                    <div className='popupsddg bgbleub'>
                                                        <div className='soudirecteursdg' style={{height:'80vh'}}>
                                                            <div>
                                                                {/* <img src="https://inphb.ci/trombi/konekolotcholoma.jpg" alt='' /> */}
                                                                <h5 >secrétariat <br/> particulier <br/> du directeur général</h5>
                                                                {/* <p style={{color: '#000', textTransform:'uppercase', fontWeight:'600', fontSize:'12px', paddingRight:'5px'}}>bureau central de contrôle de gestion et suivi financier des projets : <br /> assistant technique du DG chargé de la coordination</p> */}
                                                            </div>
                                                            
                                                        </div>
                                                        <div className='chefsservices'>
                                                            <div className='services'>
                                                                <div className='imagechefdg'>
                                                                    <img src="https://inphb.ci/trombi/gbeyjoris.jpg" alt='' />
                                                                </div>
                                                                <div className='nomservicedg'>
                                                                    <h5>gbey séhoué joris eveline</h5>
                                                                    <h6 style={{color: '#000', fontWeight: '600'}}>Assistante particulière du dg <br/>Cheffe du secrétariat particulier</h6>
                                                                </div>
                                                            </div>
                                                            <div className='services'>
                                                                <div className='imagechefdg'>
                                                                    <img src="https://inphb.ci/trombi/siakabakayoko.jpg" alt='' />
                                                                </div>
                                                                <div className='nomservicedg'>
                                                                    <h5>siaka bakayoko</h5>
                                                                    <h6 style={{color: '#000', fontWeight: '600'}}>chargé du protocolE et des missions</h6>
                                                                </div>
                                                            </div>
                                                            <div className='lestiresdessousservicesdg'>LES CONSEILLERS TECHNIQUES </div>
                                                            <div className='services'>
                                                                <div className='imagechefdg'>
                                                                    <img src="https://inphb.ci/trombi/avatar.jpg" alt='' />
                                                                </div>
                                                                <div className='nomservicedg'>
                                                                    <h5>toure sara</h5>
                                                                    <h6 style={{color: '#000', fontWeight: '600'}}>conseillère technique chargée <br/>de la reforme organisationnelle</h6>
                                                                </div>
                                                            </div>
                                                            <div className='services'>
                                                                <div className='imagechefdg'>
                                                                    <img src="https://inphb.ci/trombi/avatar.jpg" alt='' />
                                                                </div>
                                                                <div className='nomservicedg'>
                                                                    <h5>konate mohamed</h5>
                                                                    <h6 style={{color: '#000', fontWeight: '600'}}>conseiller technique chargé du développement <br/>de la coopération avec l'europe et le moyen-orient</h6>
                                                                </div>
                                                            </div>
                                                            <div className='services'>
                                                                <div className='imagechefdg'>
                                                                    <img src="https://inphb.ci/trombi/avatar.jpg" alt='' />
                                                                </div>
                                                                <div className='nomservicedg'>
                                                                    <h5>coulibaly massita </h5>
                                                                    <h6 style={{color: '#000', fontWeight: '600'}}>Conseillère technique chargée du développement <br/> de la coopération avec l'amérique</h6>
                                                                </div>
                                                            </div>
                                                            <div className='serviceslast'>
                                                                <div className='imagechefdg'>
                                                                    <img src="https://inphb.ci/trombi/avatar.jpg" alt='' />
                                                                </div>
                                                                <div className='nomservicedg'>
                                                                    <h5>condo nadia </h5>
                                                                    <h6 style={{color: '#000', fontWeight: '600'}}>Conseillère technique chargée du développement <br/> de la coopération avec l'afrique et l'asie</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                     {/* bureu du DGA SE TERMINE ICI  */}
                                                    <hr style={{color:'#fff'}}/>
                                                    <div className='popupcont bgbleub  borad15'>
                                                        <div className='popupimagedirdg'>
                                                            {/* <img src="https://inphb.ci/trombi/adamakone.jpg" alt='' /> */}
                                                            <h5 style={{color: '#02183e', textTransform:'uppercase'}}>secrétariat <br />technique <br/> du directeur général</h5>
                                                            {/* <span style={{color: '#000', textTransform:'uppercase', fontWeight:'600'}}>Directeur de la scolarité <br/> et de la vie etudiante</span> */}
                                                        </div>
                                                        <div className='popuppostedir'>
                                                            <div className='servicesyeb '>
                                                                <div className='imagechefdg'>
                                                                    <img src="https://inphb.ci/trombi/yebouayawa.jpg" alt='' />
                                                                </div>
                                                                <div className='nomservicedg'>
                                                                    <h5>yeboua yawa nina caroline</h5>
                                                                    <h6 style={{color: '#000', fontWeight: '600'}}>assistante technique du dg chargée du pilotage du plan <br /> d'orientation stratégique et du suivi du contrat d'objectifs <br/> et de performence</h6>
                                                                </div>
                                                            </div>
                                                            <div className='lestiresdessousservicesdg'>bureau central de contrôle de gestion<br/> et de suivi financier des projets</div>
                                                            
                                                            <div className='popupsdspe '>
                                                                <div className='soudirecteurs'>
                                                                    <div>
                                                                        <img src="https://inphb.ci/trombi/konekolotcholoma.jpg" alt='' />
                                                                        <h5>kone kolotcholoma</h5>
                                                                        <p style={{color: '#000', textTransform:'uppercase', fontWeight:'600', fontSize:'12px', paddingRight:'5px'}}>assistant technique du DG chargé de la coordination</p>
                                                                    </div>
                                                                </div>
                                                                <div className='chefsservices'>
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/kraarsene.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>kra arsène</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600'}}>Bureau accueil, missions et voyages @ abidjan</p>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/diomandesahi.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>diomande sahi</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600'}}>Bureau accueil, missions et voyages @ yamoussoukro</p>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <div className='serviceslast'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/doumbiainza.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>doumbia inza</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>bureau transport et gestion de la flotte automobile</p>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='lestiresdessousservicesdg'>bureau central de suivi <br/> et evalation des projets bailleurs</div>
                                                            <hr style={{backgroundColor: 'transparent', color: 'transparent'}} />
                                                            <div className='popupsdspe'>
                                                                <div className='soudirecteurs'>
                                                                    <div>
                                                                        <img src="https://inphb.ci/trombi/kouakoungoran.jpg" alt='' />
                                                                        <h5>kouakou n'goran david</h5>
                                                                        <p style={{color: '#000', textTransform:'uppercase', fontWeight:'600', fontSize:'12px'}}>assistant technique du dg chargé de la coordination</p>
                                                                    </div>
                                                                </div>
                                                                <div className='chefsservices'>
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/fossouromain.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>fossou romain</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>Bureau suivi et evaluation</p>
                                                                        </div> 
                                                                    </div>
                                                                    <div className='serviceslast'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/gbossoukoudou.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>gbossou koudou armande</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>consultante chargée de l'impact institutionnel</p>                                                                        
                                                                        </div>
                                                                       
                                                                    </div>
                                                                    {/* <div className='serviceslast'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/ligueleontine.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>LIGUE léontine</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>Chef de service du bureau des etudiants non-nationaux</p>                                                                        </div>
                                                                       
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                            {/* <hr style={{backgroundColor: 'transparent', color: 'transparent'}} /> */}
                                                            {/* <hr style={{backgroundColor: 'transparent', color: 'transparent'}} /> */}
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                        )}
                                        <div className='ligne1xxl'></div>
                                        <div className='sousdg1a'>
                                            <div className='trombidivent2  '></div>
                                            {/* <div className='trombidivfree smallerxl '>CONSEIL ACADEMIQUE</div> */}
                                            <div className='trombidivent2a  '>
                                                <div className='petitrond '></div>
                                            </div>
                                            <div className='trombidivent2b '>
                                                <div className='lignehoriz1'>
                                                    <div className='lignehoriz'></div>
                                                        <div className='lesflottants'>
                                                            <div className='laligneflottante'></div>
                                                            <div className=' lecontenuediv brhalf bgcell textcap' onClick={handleCB}>contrôle <br/>budgétaire </div>
                                                        </div>
                                                        <div className='lesflottants2'>
                                                            <div className='laligneflottante'></div>
                                                            <div className='lecontenuediv brhalf bgcell textcap' onClick={handleACP}>AGENCE <br/>comptable </div>
                                                         </div>
                                                </div>

                                            </div>
                                            {/* <div className='trombidivfree smallerxl'>CB</div> */}

                                        </div>
                                        <div className='ligne1xxla'></div>
                                        <div className='sousdg1'>
                                            <div className='trombidivfree smallerxl bggris brhalf textcap' >conseil académique</div>
                                            {isDivOpenACP && (
                                            <div className='opengestion'>
                                                <button onClick={() => setIsDivOpenACP(false)}><FaTimes /></button>
                                                <div className='letitre2 brgrisunique '>
                                                    <h5 style={{color:'#000'}}>Agence comptable principal</h5>
                                                </div>
                                                <div className='bggrisclair popupavecsd borad15'>
                                                    <div className='imagedirectur'>
                                                        <img src="https://inphb.ci/trombi/kouameboidou.jpg" alt='' />
                                                    </div>
                                                    <div className='dirtitle'>
                                                        <h5 style={{color: '#7896DB', textTransform:'uppercase'}}>boidou franck </h5>
                                                        <span style={{color: '#000', textTransform:'uppercase'}}>agent comptable principal</span>
                                                    </div>
                                                    {/* bureu du DGA COMMENCE ICI  */}
                                                    <div className='popuppostedirbureau'>
                                                        <div className='popupsdbur brgrisunique'>
                                                            <div className='imagechef1'>
                                                                <img src="https://inphb.ci/trombi/konatesoumaila.jpg" alt='' />
                                                                <h6>konate soumaïla </h6>
                                                                <h6 style={{color: '#000', fontWeight: '600'}}>fondé de pouvoir</h6>
                                                            </div>
                                                            <div className='imagechef1'>
                                                                <img src="https://inphb.ci/trombi/kikounblaise.jpg" alt='' />
                                                                <h6>kikoun blaise</h6>
                                                                <h6 style={{color: '#000', fontWeight: '600'}}>Agent comptable secondaire nord <br/></h6>
                                                            </div>
                                                            <div className='imagechef1'>
                                                                <img src="https://inphb.ci/trombi/zanlibi.jpg" alt='' />
                                                                <h6>zanli bi tra</h6>
                                                                <h6 style={{color: '#000', fontWeight: '600'}}>agent comptable secondaire centre </h6>
                                                            </div>
                                                            <div className='imagechef1'>
                                                                <img src="https://inphb.ci/trombi/avatar.jpg" alt='' />
                                                                <h6>n'dri kouadio benoit</h6>
                                                                <h6 style={{color: '#000', fontWeight: '600'}}>agent comptable secondaire abidjan </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                     {/* bureu du DGA SE TERMINE ICI  */}
                                                    <hr style={{color:'#fff'}}/>
                                                    <div className='popupcont  borad15'>
                                                        <div className='popupimagedir'>
                                                            {/* <img src="https://inphb.ci/trombi/adamakone.jpg" alt='' /> */}
                                                            <h5 style={{color: '#7896DB', textTransform:'uppercase'}}>pool agence <br />comptable principale</h5>
                                                            {/* <span style={{color: '#000', textTransform:'uppercase', fontWeight:'600'}}>Directeur de la scolarité <br/> et de la vie etudiante</span> */}
                                                        </div>
                                                        <div className='popuppostedir'>
                                                            <div className='popupsd brgrisunique'>
                                                                <div className='chefsservices'>
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/adouarsene.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>adou arsène</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600'}}>chef de service compte financier</p>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/betieneetraore.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>betie née traore djénéba</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600'}}> cheffe de service dépense</p>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/kouadiokouame.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>kouadio kouame alexandre</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>chef de service comptabilité epn</p>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/neneekone.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>ne née kone fatoumata</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>cheffe de service comptabilité etat</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/nguessankouame.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>n'guessan kouamé Norbert</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>chef de service comptabilité projet</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='serviceslast'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/akrenadre.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>akre nadre audre malika</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>cheffe de service recouvrement</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <hr style={{backgroundColor: 'transparent', color: 'transparent'}} /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                            )}
                                            {/* <div className='trombidivfree smallerxl '>CONSEIL ACADEMIQUE</div> */}
                                            <div className='trombidivent2'><div className='petitrond'></div></div>
                                            <div className='trombidivfree smallerxl bggris brhalf textcap' >Conseil scientifique</div>
                                            {isDivOpenCB && (
                                            <div className='opengestion'>
                                                <button onClick={() => setIsDivOpenCB(false)}><FaTimes /></button>
                                                <div className='letitre2 brgrisunique'>
                                                    <h5 style={{color:'#000'}}>COntrôle budgétaire</h5>
                                                </div>
                                                <div className='bggrisclair popupavecsd borad15'>
                                                    <div className='imagedirecturcb'>
                                                        <img src="https://inphb.ci/trombi/nguessannda.jpg" alt='' />
                                                    </div>
                                                    <div className='dirtitle'>
                                                        <h5 style={{color: '#7896DB', textTransform:'uppercase'}}>n'guessan n'da laurence</h5>
                                                        <span style={{color: '#000', textTransform:'uppercase'}}>contrôleur budgetaire</span>
                                                    </div>
                                                    {/* bureu du DGA COMMENCE ICI  */}
                                                    <div className='popuppostedirbureau'>
                                                        <div className='popupsdbur brgrisunique'>
                                                            <div className='imagechef1'>
                                                                <img src="https://inphb.ci/trombi/avatar.jpg" alt='' />
                                                                <h6>KOUASSI TINA epse bolou bolou </h6>
                                                                <h6 style={{color: '#000', fontWeight: '600'}}>assistante du cb</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='popupsdcb bgcell2 '>
                                                        <div className='soudirecteursdg' style={{height:'20vh'}}>
                                                            <div>
                                                                {/* <img src="https://inphb.ci/trombi/konekolotcholoma.jpg" alt='' /> */}
                                                                <h5 >Pool <br/> contrôle<br/> budgétaire</h5>
                                                                {/* <p style={{color: '#000', textTransform:'uppercase', fontWeight:'600', fontSize:'12px', paddingRight:'5px'}}>bureau central de contrôle de gestion et suivi financier des projets : <br /> assistant technique du DG chargé de la coordination</p> */}
                                                            </div>
                                                            
                                                        </div>
                                                        <div className='chefsservices'>
                                                            <div className='serviceslast'>
                                                                <div className='imagechefdg'>
                                                                    <img src="https://inphb.ci/trombi/traoredrissa.jpg" alt='' />
                                                                </div>
                                                                <div className='nomservicedg'>
                                                                    <h5>traore drissa</h5>
                                                                    <h6 style={{color: '#000', fontWeight: '600'}}>chef de service contrôle</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                     {/* bureu du DGA SE TERMINE ICI  */}
                                                    <hr style={{color:'#fff'}}/>
                                                </div>
                                            </div>
                                            )}
                                            {/* <div className='trombidivfree smallerxl'>CB</div> */}

                                        </div>
                                    <div className='ligne1'></div>
                                    <div className='directorial'>
                                        <div className='ligne3'></div>
                                        <div className='ligne3'></div>
                                        <div className='ligne3'></div>
                                        <div className='ligne3'></div>
                                        {/* <div className='ligne3'></div>
                                        <div className='ligne3'></div>
                                        <div className='ligne3'></div> */}

                                    </div>
                                    <div className='directorialcont'>
                                         <div className='trombidivfree2'>
                                             <div className='trombidiv bgbleuunique textcap' onClick={handleDGAPVE}>Direction Générale Adjointe <br /> de la Pédagogie et de la <br />vie etudiante </div>
                                            <div className='ligne1'></div>
                                            <div className='trombidivsouligne '>
                                                <div className='longueligne' />
                                                
                                            </div>
                                            <div className='lesdirfloats'>
                                                <div className='lesdirections'>
                                                    <div className='laligneflottante'></div>
                                                    <div className='ladirection1 bgbleuunique2 textcap ' onClick={handleDGAPVE}>direction de scolarité ET de <br/>  la vie etudiante</div>
                                                </div>
                                            </div>
                                            <div className='lesdirfloats'>
                                                <div className='lesdirections'>
                                                    <div className='laligneflottante'></div>
                                                    <div className='ladirection2  textcap '>ecoles de formation<br/>initiale et continue</div>
                                                </div>
                                            </div>
                                            <div className='lesdirfloats'>
                                                <div className='lesdirections'>
                                                    <div className='laligneflottante'></div>
                                                    <div className='ladirection2  textcap '>ecoles <br/> doctorales</div>
                                                </div>
                                            </div>
                                            <div className='lesdirfloats'>
                                                <div className='lesdirections'>
                                                    <div className='laligneflottante'></div>
                                                    <div className='ladirection3  textcap '>centres <br/> de formation</div>
                                                </div>
                                            </div>
                                         </div>
                                         
                                         {isDivOpenDGAPVE && (
                                            <div className='opengestion'>
                                                <button onClick={() => setIsDivOpenDGAPVE(false)}><FaTimes /></button>
                                                <div className='letitre'>
                                                    <h5>DGA Pédagogie et vie etudiante</h5>
                                                </div>
                                                <div className='bggrisclair popupavecsd borad15'>
                                                    <div className='imagedirectur'>
                                                        <img src="https://inphb.ci/trombi/loumgeorges.jpg" alt='' />
                                                    </div>
                                                    <div className='dirtitle'>
                                                        <h5 style={{color: '#7896DB', textTransform:'uppercase'}}>Pr  GEORGES LAUSSANE LOUM</h5>
                                                        <span style={{color: '#000', textTransform:'uppercase'}}>Directeur Général Adjoint en charge de la Pédagogie et la Vie etudiante</span>
                                                    </div>
                                                    {/* bureu du DGA COMMENCE ICI  */}
                                                    <div className='popuppostedirbureau'>
                                                        <div className='popupsdbur bgbleub'>
                                                            <div className='imagechef1'>
                                                                <img src="https://inphb.ci/trombi/ouattarrabiba.jpg" alt='' />
                                                                <h6>OUATTARA BIBA</h6>
                                                                <h6 style={{color: '#000', fontWeight: '600'}}>Assistante du DGA-PVE</h6>
                                                            </div>
                                                            <div className='imagechef1'>
                                                                <img src="https://inphb.ci/trombi/kadjotanon.jpg" alt='' />
                                                                <h6>KADJO TANON LAMBERT</h6>
                                                                <h6 style={{color: '#000', fontWeight: '600'}}>Bureau Central des concours <br /> d'entrée à l'INP-HB</h6>
                                                            </div>
                                                            <div className='imagechef1'>
                                                                <img src="https://inphb.ci/trombi/adjoumaniwognin.jpg" alt='' />
                                                                <h6>ADJOUMANI WOGNIN</h6>
                                                                <h6 style={{color: '#000', fontWeight: '600'}}>Bureau genre</h6>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                     {/* bureu du DGA SE TERMINE ICI  */}
                                                    <hr style={{color:'#fff'}}/>
                                                    <div className='popupcont  borad15'>
                                                        <div className='popupimagedir'>
                                                            <img src="https://inphb.ci/trombi/adamakone.jpg" alt='' />
                                                            <h5 style={{color: '#7896DB', textTransform:'uppercase'}}>ADAMA KONE</h5>
                                                            <span style={{color: '#000', textTransform:'uppercase', fontWeight:'600'}}>Directeur de la scolarité <br/> et de la vie etudiante</span>
                                                        </div>
                                                        <div className='popuppostedir'>
                                                            <div className='popupsd bgbleub'>
                                                                <div className='soudirecteurs'>
                                                                    <div>
                                                                        <img src="https://inphb.ci/trombi/yaposeverine.jpg" alt='' />
                                                                        <h5>YAPO SEVERINE CLARISSE</h5>
                                                                        <span style={{color: '#000', textTransform:'uppercase', fontWeight:'600', fontSize:'12px'}}>Sous-Directrice de la scolarité</span>
                                                                    </div>
                                                                </div>
                                                                <div className='chefsservices'>
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/coulibalykaritia.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>COULIBALY KARITIA</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600'}}>Cheffe de service gestion adminstrative <br /> du parcours de l'etudiant</p>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/konanaffoue.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>KONAN AFFOUE INES Epse ATIAPO</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600'}}>Cheffe de service  bourses et mobilités<br /></p>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <div className='serviceslast'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/lohouesmarie.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>LOHOUES MARIE STELLA</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>Cheffe de service Orientation, insertion professionnelle<br />et carrières</p>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr style={{backgroundColor: 'transparent', color: 'transparent'}} />
                                                            <div className='popupsd bgbleub'>
                                                                <div className='soudirecteurs'>
                                                                    <div>
                                                                        <img src="https://inphb.ci/trombi/dossoneedoumbia.jpg" alt='' />
                                                                        <h5>DOSSO Née doumbia aminata</h5>
                                                                        <span style={{color: '#000', textTransform:'uppercase', fontWeight:'600', fontSize:'12px'}}>Sous-Directrice de la vie Etudiante</span>
                                                                    </div>
                                                                </div>
                                                                <div className='chefsservices'>
                                                                    <div className='servicesheb'>
                                                                        <div className='imagechefheb'>
                                                                            <img src="" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5></h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>Cheffe de service restauration et hébergement<br/>cheffe de cité du sud</p>
                                                                            <div className='nomserviceheb'>
                                                                                <div className='nomservicehebimg'>
                                                                                    <img src="https://inphb.ci/trombi/kouassiedouard.jpg" alt='' />
                                                                                </div>
                                                                                <div className='nomservicehebtitre'>
                                                                                    konan kouassi edouard<br/>
                                                                                    <span style={{color:'#000'}}>chef de cité du centre</span>
                                                                                    {/* <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>chef de cité du centre</p> */}
                                                                                </div>
                                                                            </div>
                                                                        </div> 
                                                                    </div>
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/lobryeric.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>LOBRY ERIC</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>Chef de service animation et associations etudiantes</p>                                                                        
                                                                        </div>
                                                                       
                                                                    </div>
                                                                    <div className='serviceslast'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/ligueleontine.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>LIGUE léontine</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>Cheffe de service du bureau des etudiants non-nationaux</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr style={{backgroundColor: 'transparent', color: 'transparent'}} />
                                                            <div className='popupsd bgbleub'>
                                                                <div className='soudirecteurs'>
                                                                    <div>
                                                                        <img src="https://inphb.ci/trombi/safisafieddine.jpg" alt='' />
                                                                        <h5>safi safieddine</h5>
                                                                        <p style={{color: '#000', textTransform:'uppercase', fontWeight:'600', fontSize:'12px'}}>Sous-Directeur de la coordination des enseignements</p>
                                                                    </div>
                                                                </div>
                                                                <div className='chefsservices'>
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/tanokouakou.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>tano kouakou barthélémy</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600'}}>Chef de service coordination des stages <br/> et du développement humain</p>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className='serviceslast'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/kraessi.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>kra essi kouadio francis</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600'}}>Chef de service ingénierie pédagogique <br/> et de l'enseignement numérique</p>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    {/* <div className='serviceslast'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/seprivat.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>se privat</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>Chef de service coordination des langues <br /> et du développement humain</p>
                                                                        </div>
                                                                        
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                        )}
                                         <div className='trombidivfree2'>
                                             <div className='trombidiv textcap bgbleuunique' onClick={handleDGARIT}>Direction Générale Adjointe  <br /> de la Recherche, Innovation <br />et du Technopole</div>
                                            <div className='ligne1'></div>
                                            <div className='trombidivsouligne '>
                                                <div className='longueligne' />
                                            </div>
                                            <div className='lesdirfloats'>
                                                <div className='lesdirections'>
                                                    <div className='laligneflottante'></div>
                                                    <div className='ladirection1 bgbleuunique2 textcap ' onClick={handleDGARIT}>direction du technopôle</div>
                                                </div>
                                            </div>
                                            <div className='lesdirfloats'>
                                                <div className='lesdirections'>
                                                    <div className='laligneflottante'></div>
                                                    <div className='ladirection4  textcap '>direction <br/> umri  STI*</div>
                                                </div>
                                            </div>
                                            <div className='lesdirfloats'>
                                                <div className='lesdirections'>
                                                    <div className='laligneflottante'></div>
                                                    <div className='ladirection4  textcap '>direction <br/> umri  Sapt*</div>
                                                </div>
                                            </div>
                                            <div className='lesdirfloats'>
                                                <div className='lesdirections'>
                                                    <div className='laligneflottante'></div>
                                                    <div className='ladirection4  textcap '>direction <br/> umri  Seg*</div>
                                                </div>
                                            </div>
                                            <div className='lesdirfloats'>
                                                <div className='lesdirections'>
                                                    <div className='laligneflottante'></div>
                                                    <div className='ladirection4  textcap '>direction <br/> umri  msn*</div>
                                                </div>
                                            </div>
                                            {/* <div className='trombidiv bgbleuunique textcap'>DIRECTION du technopôle</div> */}
                                            
                                         </div>
                                         {isDivOpenDGARIT && (
                                            <div className='opengestion'>
                                                <button onClick={() => setIsDivOpenDGARIT(false)}><FaTimes /></button>
                                                <div className='letitre'>
                                                    <h5>DGA RECHERCHE, innovation et technopôle</h5>
                                                </div>
                                                <div className='bggrisclair popupavecsd borad15'>
                                                    <div className='imagedirectur'>
                                                        <img src="https://inphb.ci/trombi/pierreyourougou.jpg" alt='' />
                                                    </div>
                                                    <div className='dirtitle'>
                                                        <h5 style={{color: '#7896DB', textTransform:'uppercase'}}>Pr pierre yourougou</h5>
                                                        <span style={{color: '#000', textTransform:'uppercase'}}>Directeur Général Adjoint en charge de la Recherche, de l'innovation et du technopôle</span>
                                                    </div>
                                                    {/* bureu du DGA COMMENCE ICI  */}
                                                    <div className='popuppostedirbureau'>
                                                        <div className='popupsdbur bgbleub'>
                                                            <div className='imagechef1'>
                                                                <img src="https://inphb.ci/trombi/avatar.jpg" alt='' />
                                                                <h6>GBONGUE DOUAGBEU</h6>
                                                                <h6 style={{color: '#000', fontWeight: '600'}}>Assistante du DGA-RIT</h6>
                                                            </div>
                                                            <div className='imagechef1'>
                                                                <img src="https://inphb.ci/trombi/brouyao.jpg" alt='' />
                                                                <h6>BROU YAO CASIMIR</h6>
                                                                <h6 style={{color: '#000', fontWeight: '600'}}>Bureau Central des Programmes de recherche parteneriale, <br /> de l'information scientifique et des relations avec le cames</h6>
                                                            </div>
                                                            <div className='imagechef1'>
                                                                <img src="https://inphb.ci/trombi/koreguy.jpg" alt='' />
                                                                <h6>kore guy</h6>
                                                                <h6 style={{color: '#000', fontWeight: '600'}}>Bureau central de l'incubateur, de l'economie,<br /> de l'innovarion et la propriété intellectuelle</h6>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                     {/* bureu du DGA SE TERMINE ICI  */}
                                                    <hr style={{color:'#fff'}}/>
                                                    <div className='popupcont  borad15'>
                                                        <div className='popupimagedir'>
                                                            <img src="https://inphb.ci/trombi/sorodoudjo.jpg" alt='' />
                                                            <h5 style={{color: '#7896DB', textTransform:'uppercase'}}>soro doudjo</h5>
                                                            <span style={{color: '#000', textTransform:'uppercase', fontWeight:'600'}}>Directeur du technopôle</span>
                                                        </div>
                                                        <div className='popuppostedir'>
                                                            <div className='popupsd bgbleub'>
                                                                <div className='soudirecteurs' style={{height:'40vh'}}>
                                                                    <div>
                                                                        <img src="https://inphb.ci/trombi/kouassiesaie.jpg" alt='' />
                                                                        <h5>kouassi esaïe kouadio appiah</h5>
                                                                        <p style={{color: '#000', textTransform:'uppercase', fontWeight:'600', fontSize:'12px'}}>Sous-Directreur de l'innovation et du transfert technologique</p>
                                                                    </div>
                                                                </div>
                                                                <div className='chefsservices'>
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/akaffoujean.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>akaffou jean nicaise</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600'}}>Chef de service innovation et transfert <br />de technologie des fablabs tech</p>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/ebouediman.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>ebou ediman théodore anicet</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600'}}>Chef de service  innovation et transfert <br />de technologie des fablabs agritech</p>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/avatar.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>moumouni noellie pétronille</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>Chef de service innovation et transfert <br/> de technologie des fablabs chimie & procédé industriels</p>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/avatar.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>kouakou nanan steve yanick y. v.</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>Chef de service de l'innovation et transfert<br />de technologies des fablabs BIM</p>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <div className='serviceslast'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/ouattarasoumaila1.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>ouattara soumaïla collè</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>Chef de Service de l'Innovation et Transfert <br/>de Technologie des FabLabs fintech </p>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr style={{backgroundColor: 'transparent', color: 'transparent'}} />
                                                            <div className='popupsd bgbleub'>
                                                                <div className='soudirecteurs'>
                                                                    <div>
                                                                        <img src="https://inphb.ci/trombi/amariadangba.jpg" alt='' />
                                                                        <h5>amari adangba félix</h5>
                                                                        <p style={{color: '#000', textTransform:'uppercase', fontWeight:'600', fontSize:'12px'}}>Sous-Directeur aménagement et développement du technopôle</p>
                                                                    </div>
                                                                </div>
                                                                <div className='chefsservices'>
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/assaleyah.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>assale yah monique</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600'}}>Cheffe de service assistance juridique <br/> et administrative du technopôle</p>
                                                                        </div> 
                                                                    </div>
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/dechiangodji.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>dechi angodji evariste</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>Chef de service etudes techniques du technopôle</p>                                                                        
                                                                        </div>
                                                                       
                                                                    </div>
                                                                    <div className='serviceslast'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/sissokoamara.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>sissoko amara</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>Chef de service des activités promotionnelles du technopôle</p>                                                                        </div>
                                                                       
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                         <div className='trombidivfree2'>
                                             <div className='trombidiv textcap bgbleuunique' onClick={handleSG}>Sécrétariat<br />Général</div>
                                            <div className='ligne1'></div>
                                            <div className='trombidivsouligne '>
                                                <div className='longueligne' />
                                            </div>
                                            <div className='lesdirfloats'>
                                                <div className='lesdirections'>
                                                    <div className='laligneflottante'></div>
                                                    <div className='ladirection1 bgbleuunique2 textcap ' onClick={handleSG}>direction des <br/>moyens généraux</div>
                                                </div>
                                            </div>
                                            <div className='lesdirfloats'>
                                                <div className='lesdirections'>
                                                    <div className='laligneflottante'></div>
                                                    <div className='ladirection1 bgbleuunique2 textcap ' onClick={handleSG}>direction  <br/> des systèmes d'information</div>
                                                </div>
                                            </div>
                                            <div className='lesdirfloats'>
                                                <div className='lesdirections'>
                                                    <div className='laligneflottante'></div>
                                                    <div className='ladirection1 bgbleuunique2 textcap ' onClick={handleSG}>direction de la qualité, de la  <br/> santé et de <br/>l'environnement</div>
                                                </div>
                                            </div>
                                         </div>
                                         {isDivOpenSG && (
                                            <div className='opengestion'>
                                                <button onClick={() => setIsDivOpenSG(false)}><FaTimes /></button>
                                                <div className='letitre'>
                                                    <h5>secrétariat général</h5>
                                                </div>
                                                <div className='bggrisclair popupavecsd borad15'>
                                                    <div className='imagedirectur'>
                                                        <img src="https://inphb.ci/trombi/palesie.jpg" alt='' />
                                                    </div>
                                                    <div className='dirtitle'>
                                                        <h5 style={{color: '#7896DB', textTransform:'uppercase'}}>dr palé sié</h5>
                                                        <span style={{color: '#000', textTransform:'uppercase'}}>secrétaire Général </span>
                                                    </div>
                                                    {/* bureu du DGA COMMENCE ICI  */}
                                                    <div className='popuppostedirbureau'>
                                                        <div className='popupsdbur bgbleub'>
                                                            <div className='imagechef1'>
                                                                <img src="https://inphb.ci/trombi/zahorebi.jpg" alt='' />
                                                                <h6>ZAHORE BI GOULE MICHEL</h6>
                                                                <h6 style={{color: '#000', fontWeight: '600'}}>Assistant du secrétaire Général</h6>
                                                            </div>
                                                            <div className='imagechef1'>
                                                                <img src="https://inphb.ci/trombi/avatar.jpg" alt='' />
                                                                <h6>COULIBALY KOMELAN ANGES</h6>
                                                                <h6 style={{color: '#000', fontWeight: '600'}}>Bureau Central de la sureté de fonctionnement </h6>
                                                            </div>
                                                            {/* <div className='imagechef1'>
                                                                <img src="https://inphb.ci/trombi/koreguy.jpg" alt='' />
                                                                <h6>kore guy</h6>
                                                                <h6 style={{color: '#000', fontWeight: '600'}}>Bureau central de l'incubateur, de l'economie<br /> de l'innovarion et la propriété intellectuelle</h6>
                                                            </div> */}
                                                            
                                                        </div>
                                                    </div>
                                                     {/* bureu du DGA SE TERMINE ICI  */}
                                                    <hr style={{color:'#fff'}}/>
                                                    <div className='popupcont  borad15'>
                                                        <div className='popupimagedir'>
                                                            <img src="https://inphb.ci/trombi/siakasorho.jpg" alt='' />
                                                            <h5 style={{color: '#7896DB', textTransform:'uppercase'}}>siaka sorho</h5>
                                                            <span style={{color: '#000', textTransform:'uppercase', fontWeight:'600'}}>Directeur des moyens généraux </span>
                                                        </div>
                                                        <div className='popuppostedir'>
                                                            <div className='popupsd bgbleub'>
                                                                <div className='soudirecteurs'>
                                                                    <div>
                                                                        <img src="https://inphb.ci/trombi/avatar.jpg" alt='' />
                                                                        <h5>konan kouakou charles</h5>
                                                                        <p style={{color: '#000', textTransform:'uppercase', fontWeight:'600', fontSize:'12px'}}>Sous-Directreur patrimoine et maintenance</p>
                                                                    </div>
                                                                </div>
                                                                <div className='chefsservices'>
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/avatar.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>ekra amenan fatou</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600'}}>Cheffe de service gestion du patrimoine</p>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                     <div className='serviceslast'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/betieamidou.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>betie amidou</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600'}}>Chef de service gestion des opérations de maintenance</p>
                                                                        </div>
                                                                        
                                                                    </div> 
                                                                </div>
                                                            </div>
                                                            <hr style={{backgroundColor: 'transparent', color: 'transparent'}} />
                                                            <div className='popupsd bgbleub'>
                                                                <div className='soudirecteurs'>
                                                                    <div>
                                                                        <img src="https://inphb.ci/trombi/siakabakayoko.jpg" alt='' />
                                                                        <h5>siaka bakayoko</h5>
                                                                        <p style={{color: '#000', textTransform:'uppercase', fontWeight:'600', fontSize:'12px'}}>Sous-Directeur des travaux neufs et réhabilitation</p>
                                                                    </div>
                                                                </div>
                                                                <div className='chefsservices'>
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/sangareamadou1.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>sangare amadou</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600'}}>Chef de service des travaux neufs et des stocks</p>
                                                                        </div> 
                                                                    </div>
                                                                    <div className='serviceslast'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/mahanoulai.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>Mahan oulaï sondé anicet</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>Chef de service des réhabilitations</p>                                                                        
                                                                        </div>
                                                                       
                                                                    </div>
{/*                                                                     <div className='serviceslast'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/sissokoamara.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>sissoko amara</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>Chef de service des activités promotionnelles du technopôle</p>                                                                        </div>
                                                                       
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr style={{backgroundColor: 'transparent', color: 'transparent'}} />
                                                    <div className='popupcont  borad15'>
                                                        <div className='popupimagedir'>
                                                            <img src="https://inphb.ci/trombi/tanohtanoh.jpg" alt='' />
                                                            <h5 style={{color: '#7896DB', textTransform:'uppercase'}}>tanoh tanoh lambert</h5>
                                                            <span style={{color: '#000', textTransform:'uppercase', fontWeight:'600'}}>Directeur des systèmes d'information</span>
                                                        </div>
                                                        <div className='popuppostedir'>
                                                            <div className='popupsd bgbleub'>
                                                                <div className='soudirecteurs'>
                                                                    <div>
                                                                        <img src="https://inphb.ci/trombi/touvoliserge.jpg" alt='' />
                                                                        <h5>touvoli serge</h5>
                                                                        <p style={{color: '#000', textTransform:'uppercase', fontWeight:'600', fontSize:'12px'}}>Sous-Directreur digitalisation et sécurité informatique</p>
                                                                    </div>
                                                                </div>
                                                                <div className='chefsservices'>
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/kouameamos.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>kouame amos</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600'}}>Chef de service sécurité des applications <br/> et support aux utilisateurs</p>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                     <div className='serviceslast'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/nguessankoffitheophile.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>N'guessan koffi théophile</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600'}}>Chef de service de la transformation digitale, <br/> de la data et du développement <br/> des outils d'enseignement numérique</p>
                                                                        </div>
                                                                        
                                                                    </div> 
                                                                </div>
                                                            </div>
                                                            <hr style={{backgroundColor: 'transparent', color: 'transparent'}} />
                                                            <div className='popupsd bgbleub'>
                                                                <div className='soudirecteurs'>
                                                                    <div>
                                                                        <img src="https://inphb.ci/trombi/goorebi.jpg" alt='' />
                                                                        <h5>GOORE BI TRA</h5>
                                                                        <p style={{color: '#000', textTransform:'uppercase', fontWeight:'600', fontSize:'12px'}}>Sous-Directeur réseau et maintenance informatique</p>
                                                                    </div>
                                                                </div>
                                                                <div className='chefsservices'>
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/attebisylvain.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>attebi liza sylvain</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600'}}>Chef de service réseau internet, intranet et téléphonie fixe</p>
                                                                        </div> 
                                                                    </div>
                                                                    <div className='serviceslast'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/nguessankoffifranck.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>n'guessan koffi franck</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>Chef de service sécurité des infrastructures, <br/> exploitation et maintenance</p>                                                                        
                                                                        </div>
                                                                       
                                                                    </div>
{/*                                                                     <div className='serviceslast'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/sissokoamara.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>sissoko amara</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>Chef de service des activités promotionnelles du technopôle</p>                                                                        </div>
                                                                       
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr style={{backgroundColor: 'transparent', color: 'transparent'}} />
                                                    <div className='popupcont  borad15'>
                                                        <div className='popupimagedir'>
                                                            <img src="https://inphb.ci/trombi/akakikoffi.jpg" alt='' />
                                                            <h5 style={{color: '#7896DB', textTransform:'uppercase'}}>akaki koffi david</h5>
                                                            <span style={{color: '#000', textTransform:'uppercase', fontWeight:'600'}}>Directeur qualité, santé, sécurité et environnement</span>
                                                        </div>
                                                        <div className='popuppostedir'>
                                                            <div className='popupsd bgbleub'>
                                                                <div className='soudirecteurs'>
                                                                    <div>
                                                                        <img src="https://inphb.ci/trombi/avatar.jpg" alt='' />
                                                                        <h5>kouame kouassi sylvestre</h5>
                                                                        <p style={{color: '#000', textTransform:'uppercase', fontWeight:'600', fontSize:'12px'}}>Sous-Directreur qualité</p>
                                                                    </div>
                                                                </div>
                                                                <div className='chefsservices'>
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/ouragaraissa.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>ouraga raïssa nadège</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600'}}>Cheffe de service des procédures et du contrôle qualité</p>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                     <div className='serviceslast'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/sekaossey.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>seka ossey clovis</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600'}}>Chef de service des accréditations et des certifications</p>
                                                                        </div>
                                                                        
                                                                    </div> 
                                                                </div>
                                                            </div>
                                                            <hr style={{backgroundColor: 'transparent', color: 'transparent'}} />
                                                            <div className='popupsd bgbleub'>
                                                                <div className='soudirecteurs'>
                                                                    <div>
                                                                        <img src="" alt='' />
                                                                        <h5></h5>
                                                                        <p style={{color: '#000', textTransform:'uppercase', fontWeight:'600', fontSize:'12px'}}>Sous-Directeur santé, sécurité et environnement</p>
                                                                    </div>
                                                                </div>
                                                                <div className='chefsservices'>
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/avatar.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>assi marie christelle</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600'}}>Chef de service santé et hygiène de vie </p>
                                                                        </div> 
                                                                    </div>
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/kaunanjulien.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>kaunan julien</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>Chef de service sécurité</p>                                                                        
                                                                        </div>
                                                                       
                                                                    </div>
                                                                    <div className='serviceslast'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/seukambire.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>seu kambire</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>Chef de service devéloppement durable</p>                                                                        
                                                                        </div>
                                                                       
                                                                    </div>
{/*                                                                     <div className='serviceslast'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/sissokoamara.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>sissoko amara</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>Chef de service des activités promotionnelles du technopôle</p>                                                                        </div>
                                                                       
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                                                    {/* direction financiere  */}
                                         {/* <div className='trombidivfree2'>
                                             <div className='trombidiv textcap bgbleuunique' onClick={handleDF}>Direction <br />Financière</div>
                                            <div className='ligne1'></div>
                                            <div className='trombidivsd bgbleuuniquesd textcap'>sous direction planification et budget  </div>
                                            <div className='ligne1'></div>
                                            <div className='trombidivsd bgbleuuniquesd textcap'>sous direction comptabilité</div>
                                         </div> */}
                                         {isDivOpenDF && (
                                            <div className='opengestion'>
                                                <button onClick={() => setIsDivOpenDF(false)}><FaTimes /></button>
                                                <div className='letitre'>
                                                    <h5>Direction financière</h5>
                                                </div>
                                                <div className='bggrisclair popupavecsd borad15'>
                                                    <div className='imagedirectur'>
                                                        <img src="https://inphb.ci/trombi/syllamahamadou1.jpg" alt='' />
                                                    </div>
                                                    <div className='dirtitle'>
                                                        <h5 style={{color: '#7896DB', textTransform:'uppercase'}}>sylla mahamadou</h5>
                                                        <span style={{color: '#000', textTransform:'uppercase'}}>Directeur financier</span>
                                                    </div>
                                                    {/* bureu du DGA COMMENCE ICI  */}
                                                    <div className='popuppostedirbureau'>
                                                        <div className='popupsdbur bgbleub'>
                                                            <div className='imagechef1'>
                                                                <img src="https://inphb.ci/trombi/avatar.jpg" alt='' />
                                                                <h6>kamara haïtiamé kadidjatou</h6>
                                                                <h6 style={{color: '#000', fontWeight: '600'}}>Assistante du DF, responsable du bureau des facturations</h6>
                                                            </div>
                                                            {/* <div className='imagechef1'>
                                                                <img src="https://inphb.ci/trombi/kadjotanon.jpg" alt='' />
                                                                <h6>KADJO TANON LAMBERT</h6>
                                                                <h6 style={{color: '#000', fontWeight: '600'}}>Bureau Central des concours <br /> d'entrée à l'INP-HB</h6>
                                                            </div>
                                                            <div className='imagechef1'>
                                                                <img src="https://inphb.ci/trombi/adjoumaniwognin.jpg" alt='' />
                                                                <h6>ADJOUMANI WOGNIN</h6>
                                                                <h6 style={{color: '#000', fontWeight: '600'}}>Bureau genre</h6>
                                                            </div> */}
                                                            
                                                        </div>
                                                    </div>
                                                     {/* bureu du DGA SE TERMINE ICI  */}
                                                    <hr style={{color:'#fff'}}/>
                                                    <div className='popupcont  borad15'>
                                                        <div className='popupimagedir'>
                                                            {/* <img src="https://inphb.ci/trombi/adamakone.jpg" alt='' /> */}
                                                            <h5 style={{color: '#7896DB', textTransform:'uppercase'}}>pool <br/>direction financière</h5>
                                                            {/* <span style={{color: '#000', textTransform:'uppercase', fontWeight:'600'}}>Directeur de la scolarité <br/> et de la vie etudiante</span> */}
                                                        </div>
                                                        <div className='popuppostedir'>
                                                            <div className='popupsd bgbleub'>
                                                                <div className='soudirecteurs'>
                                                                    <div>
                                                                        <img src="https://inphb.ci/trombi/diomandeloua.jpg" alt='' />
                                                                        <h5>diomandé loua</h5>
                                                                        <span style={{color: '#000', textTransform:'uppercase', fontWeight:'600', fontSize:'12px'}}>Sous-Directeur planification et budget</span>
                                                                    </div>
                                                                </div>
                                                                <div className='chefsservices'>
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/gnatintgoulydehi.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>gnatint goulydehi</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600'}}>Chef de service budget et planification</p>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className='serviceslast'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/konannatacha.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>KONAN natacha</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600'}}>Cheffe de service passation et suivi des marchés</p>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr style={{backgroundColor: 'transparent', color: 'transparent'}} />
                                                            <div className='popupsd bgbleub'>
                                                                <div className='soudirecteurs'>
                                                                    <div>
                                                                        <img src="https://inphb.ci/trombi/adohahou.jpg" alt='' />
                                                                        <h5>adoh ahou françoise</h5>
                                                                        <span style={{color: '#000', textTransform:'uppercase', fontWeight:'600', fontSize:'12px'}}>Sous-Directrice de la comptabilité</span>
                                                                    </div>
                                                                </div>
                                                                <div className='chefsservices'>
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/essohnomel.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>essoh nomel jean pierre v.</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>Chef de service achats</p>
                                                                        </div> 
                                                                    </div>
                                                                    <div className='serviceslast'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/obroumireille.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>obrou mireille</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>Cheffe de service comptabilité</p>                                                                        
                                                                        </div>
                                                                       
                                                                    </div>
                                                                    {/* <div className='serviceslast'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/ligueleontine.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>LIGUE léontine</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>Chef de service du bureau des etudiants non-nationaux</p>                                                                        </div>
                                                                       
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                        )}
                                                                    {/* direction production expertise  */}
                                         {/* <div className='trombidivfree2'>
                                             <div className='trombidiv textcap bgbleuunique' onClick={handleDPE}>DIRECTION <br />PRODUCTION <br /> Expertise</div>
                                            <div className='ligne1'></div>
                                            <div className='trombidivsd bgbleuuniquesd textcap'>sous direction prospection et marketing</div>
                                            <div className='ligne1'></div>
                                            <div className='trombidivsd bgbleuuniquesd textcap'>sous direction prestations de service et expertise</div>
                                            <div className='ligne1'></div>
                                            <div className='trombidivsd bgbleuuniquesd textcap'>sous direction production</div>
                                         </div> */}
                                         {isDivOpenDPE && (
                                            <div className='opengestion'>
                                                <button onClick={() => setIsDivOpenDPE(false)}><FaTimes /></button>
                                                <div className='letitre'>
                                                    <h5>Direction de la production et de l'expertise</h5>
                                                </div>
                                                <div className='bggrisclair popupavecsd borad15'>
                                                    <div className='imagedirectur'>
                                                        <img src="https://inphb.ci/trombi/cissemoctar.jpg" alt='' />
                                                    </div>
                                                    <div className='dirtitle'>
                                                        <h5 style={{color: '#7896DB', textTransform:'uppercase'}}>cisse moctar</h5>
                                                        <span style={{color: '#000', textTransform:'uppercase'}}>Directeur de la production et de l'expertise</span>
                                                    </div>
                                                    {/* bureu du DGA COMMENCE ICI  */}
                                                    <div className='popuppostedirbureau'>
                                                        <div className='popupsdbur bgbleub'>
                                                            <div className='imagechef1'>
                                                                <img src="https://inphb.ci/trombi/coulibalyaminata1.jpg" alt='' />
                                                                <h6>coulibaly aminata</h6>
                                                                <h6 style={{color: '#000', fontWeight: '600'}}>Assistante du DPE</h6>
                                                            </div>
                                                            {/* <div className='imagechef1'>
                                                                <img src="https://inphb.ci/trombi/kadjotanon.jpg" alt='' />
                                                                <h6>KADJO TANON LAMBERT</h6>
                                                                <h6 style={{color: '#000', fontWeight: '600'}}>Bureau Central des concours <br /> d'entrée à l'INP-HB</h6>
                                                            </div>
                                                            <div className='imagechef1'>
                                                                <img src="https://inphb.ci/trombi/adjoumaniwognin.jpg" alt='' />
                                                                <h6>ADJOUMANI WOGNIN</h6>
                                                                <h6 style={{color: '#000', fontWeight: '600'}}>Bureau genre</h6>
                                                            </div> */}
                                                            
                                                        </div>
                                                    </div>
                                                     {/* bureu du DGA SE TERMINE ICI  */}
                                                    <hr style={{color:'#fff'}}/>
                                                    <div className='popupcont  borad15'>
                                                        <div className='popupimagedir'>
                                                            {/* <img src="https://inphb.ci/trombi/adamakone.jpg" alt='' /> */}
                                                            <h5 style={{color: '#7896DB', textTransform:'uppercase'}}>pool direction <br/>production et expertise</h5>
                                                            {/* <span style={{color: '#000', textTransform:'uppercase', fontWeight:'600'}}>Directeur de la scolarité <br/> et de la vie etudiante</span> */}
                                                        </div>
                                                        <div className='popuppostedir'>
                                                            <div className='popupsd bgbleub'>
                                                                <div className='soudirecteurs'>
                                                                    <div>
                                                                        <img src="https://inphb.ci/trombi/avatar.jpg" alt='' />
                                                                        <h5>traore abass</h5>
                                                                        <span style={{color: '#000', textTransform:'uppercase', fontWeight:'600', fontSize:'12px'}}>Sous-Directeur prospection et marketing</span>
                                                                    </div>
                                                                </div>
                                                                <div className='chefsservices'>
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/maningaaline.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>maninga aline</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600'}}>Cheffe de service stratégie, marketing, <br/> commerciale et reprographie</p>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className='serviceslast'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/ouattarasiambou.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>ouattara siambou</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600'}}>Chef de service prospection, acquisitions <br/> et développement des marchés</p>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr style={{backgroundColor: 'transparent', color: 'transparent'}} />
                                                            <div className='popupsd bgbleub'>
                                                                <div className='soudirecteurs'>
                                                                    <div>
                                                                        <img src="https://inphb.ci/trombi/konekouwelton.jpg" alt='' />
                                                                        <h5>kone kouwelton patrick</h5>
                                                                        <p style={{color: '#000', textTransform:'uppercase', fontWeight:'600', fontSize:'12px'}}>Sous-Directeur prestations de services et expertise</p>
                                                                    </div>
                                                                </div>
                                                                <div className='chefsservices'>
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/sorofaltia.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>soro faltia</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>Cheffe de service location de biens et services</p>
                                                                        </div> 
                                                                    </div>
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/thiopeyokoh.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>thio peyokoh roger</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>Chef de service conseil et expertise</p>                                                                        
                                                                        </div>
                                                                       
                                                                    </div>
                                                                    <div className='serviceslast'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/kakoukouassi.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>kakou kouassi enerst</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>Chef de service mesures et analyses</p>                                                                        </div>
                                                                       
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr style={{backgroundColor: 'transparent', color: 'transparent'}} />
                                                            <div className='popupsd bgbleub'>
                                                                <div className='soudirecteurs'>
                                                                    <div>
                                                                        <img src="https://inphb.ci/trombi/seglasessien.jpg" alt='' />
                                                                        <h5>segla sessien komlan adeladjou</h5>
                                                                        <p style={{color: '#000', textTransform:'uppercase', fontWeight:'600', fontSize:'12px'}}>Sous-Directeur production</p>
                                                                    </div>
                                                                </div>
                                                                <div className='chefsservices'>
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/yapiyapo.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>yapi yapo magloire</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>Chef de service gestion des unités de production agricole</p>
                                                                        </div> 
                                                                    </div>
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/avatar.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>kouassi alexis</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>Chef de service gestion des unités de <br />production technologique et industrielle</p>                                                                        
                                                                        </div>
                                                                       
                                                                    </div>
                                                                    <div className='serviceslast'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/konekisselmina.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>kone kisselmina</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>Chef de service gestion des usines ecoles </p>                                                                        </div>
                                                                       
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                        )}
                                                            {/* DIRECTION DES RESSOURCES UMAINES  */}
                                         {/* <div className='trombidivfree2'>
                                             <div className='trombidiv textcap bgbleuunique' onClick={handleDRH}>Direction <br /> des ressources <br />Humaines</div>
                                            <div className='ligne1'></div>
                                            <div className='trombidivsd bgbleuuniquesd textcap'>sous direction personnel</div>
                                            <div className='ligne1'></div>
                                            <div className='trombidivsd bgbleuuniquesd textcap'>sous direction planification, formation et relations sociales</div>
                                         
                                         </div> */}
                                         {isDivOpenDRH && (
                                            <div className='opengestion'>
                                                <button onClick={() => setIsDivOpenDRH(false)}><FaTimes /></button>
                                                <div className='letitre'>
                                                    <h5>Direction des ressources humaines</h5>
                                                </div>
                                                <div className='bggrisclair popupavecsd borad15'>
                                                    <div className='imagedirectur'>
                                                        <img src="https://inphb.ci/trombi/wandannarcisse.jpg" alt='' />
                                                    </div>
                                                    <div className='dirtitle'>
                                                        <h5 style={{color: '#7896DB', textTransform:'uppercase'}}>wandan narcisse</h5>
                                                        <span style={{color: '#000', textTransform:'uppercase'}}>Directeur des ressources humaines</span>
                                                    </div>
                                                    {/* bureu du DGA COMMENCE ICI  */}
                                                    <div className='popuppostedirbureau'>
                                                        <div className='popupsdbur bgbleub'>
                                                            <div className='imagechef1'>
                                                                <img src="https://inphb.ci/trombi/brouaneky.jpg" alt='' />
                                                                <h6>brou aneky eudès</h6>
                                                                <h6 style={{color: '#000', fontWeight: '600'}}>Assistant du DRH</h6>
                                                            </div>
                                                            {/* <div className='imagechef1'>
                                                                <img src="https://inphb.ci/trombi/kadjotanon.jpg" alt='' />
                                                                <h6>KADJO TANON LAMBERT</h6>
                                                                <h6 style={{color: '#000', fontWeight: '600'}}>Bureau Central des concours <br /> d'entrée à l'INP-HB</h6>
                                                            </div>
                                                            <div className='imagechef1'>
                                                                <img src="https://inphb.ci/trombi/adjoumaniwognin.jpg" alt='' />
                                                                <h6>ADJOUMANI WOGNIN</h6>
                                                                <h6 style={{color: '#000', fontWeight: '600'}}>Bureau genre</h6>
                                                            </div> */}
                                                            
                                                        </div>
                                                    </div>
                                                     {/* bureu du DGA SE TERMINE ICI  */}
                                                    <hr style={{color:'#fff'}}/>
                                                    <div className='popupcont  borad15'>
                                                        <div className='popupimagedir'>
                                                            {/* <img src="https://inphb.ci/trombi/adamakone.jpg" alt='' /> */}
                                                            <h5 style={{color: '#7896DB', textTransform:'uppercase'}}>pool direction <br/>des ressources humaines</h5>
                                                            {/* <span style={{color: '#000', textTransform:'uppercase', fontWeight:'600'}}>Directeur de la scolarité <br/> et de la vie etudiante</span> */}
                                                        </div>
                                                        <div className='popuppostedir'>
                                                            <div className='popupsd bgbleub'>
                                                                <div className='soudirecteurs'>
                                                                    <div>
                                                                        <img src="https://inphb.ci/trombi/serisabla.jpg" alt='' />
                                                                        <h5>seri sabla aimée natacha</h5>
                                                                        <span style={{color: '#000', textTransform:'uppercase', fontWeight:'600', fontSize:'12px'}}>Sous-Directrice du personnel</span>
                                                                    </div>
                                                                </div>
                                                                <div className='chefsservices'>
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/kouamenadege.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>kouame nadège ahou rosine</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600'}}>Cheffe de service paie</p>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/callejean.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>calle jean henri</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600'}}>Chef de service gestion des fonctionnaires et <br/> carrière du personnel</p>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <div className='serviceslast'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/kabamamoudou.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>kaba mamoudou</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600'}}>Chef de service gestion des contractuels <br/>et des stagiaires</p>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr style={{backgroundColor: 'transparent', color: 'transparent'}} />
                                                            <div className='popupsd bgbleub'>
                                                                <div className='soudirecteurs'>
                                                                    <div>
                                                                        <img src="https://inphb.ci/trombi/koneneecoulibaly.jpg" alt='' />
                                                                        <h5>kone née coulibaly tiégnehe</h5>
                                                                        <p style={{color: '#000', textTransform:'uppercase', fontWeight:'600', fontSize:'12px'}}>Sous-Directrice de la  plannification,  <br/> formation et relations sociales</p>
                                                                    </div>
                                                                </div>
                                                                <div className='chefsservices'>
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/kouakouemmanuel.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>kouakou emmanuel</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>Chef de service plannification (prévisions et statistiques) <br/>et formation</p>
                                                                        </div> 
                                                                    </div>
                                                                    <div className='serviceslast'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/konanneeyoboue.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>konan née yoboue nina georgette</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>Cheffe de service social, assistance <br/> et préventions</p>                                                                        
                                                                        </div>
                                                                       
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                        )}
                                        <div className='trombidivfree2' >
                                        {/* <div className='petitrond'></div> */}
                                            <div className='trombidivligne'></div>
                                            <div className='ligne1'></div>
                                            <div className='trombidivsouligne '>
                                                <div className='longueligne' />
                                            </div>
                                            <div className='lesdirfloats'>
                                                <div className='lesdirections'>
                                                    <div className='laligneflottante'></div>
                                                    <div className='ladirection1 bgbleuunique2 textcap ' onClick={handleDF}>direction<br/> financière</div>
                                                </div>
                                            </div>
                                            <div className='lesdirfloats'>
                                                <div className='lesdirections'>
                                                    <div className='laligneflottante'></div>
                                                    <div className='ladirection1 bgbleuunique2 textcap ' onClick={handleDPE}>direction de la<br/> production et de l'expertise</div>
                                                </div>
                                            </div>
                                            <div className='lesdirfloats'>
                                                <div className='lesdirections'>
                                                    <div className='laligneflottante'></div>
                                                    <div className='ladirection1 bgbleuunique2 textcap ' onClick={handleDRH}>direction des <br/> ressources humaines</div>
                                                </div>
                                            </div>
                                            <div className='lesdirfloats'>
                                                <div className='lesdirections'>
                                                    <div className='laligneflottante'></div>
                                                    <div className='ladirection1 bgbleuunique2 textcapdir ' onClick={handleDC}>direction de la<br/> communication, des relations extérieures <br/>et des affaires juridiques</div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                                            {/* DIRECTION DE LA COMMUNICATION  */}
                                         {/* <div className='trombidivfree2'>
                                             <div className='trombidiv bgbleuunique textcap' onClick={handleDC}>Direction Communication,<br />Relations extérieurs<br />et affaires juridiques</div>
                                            <div className='ligne1'></div>
                                            <div className='trombidivsd bgbleuuniquesd textcap'>sous direction communication</div>
                                            <div className='ligne1'></div>
                                            <div className='trombidivsd bgbleuuniquesd textcap'>sous direction relations extérieures et affaires juridiques</div>
                                         </div> */}
                                         {isDivOpenDC && (
                                            <div className='opengestion'>
                                                <button onClick={() => setIsDivOpenDC(false)}><FaTimes /></button>
                                                <div className='letitre'>
                                                    <h5>Direction communication, relations extérieures<br /> et affaires juridiques</h5>
                                                </div>
                                                <div className='bggrisclair popupavecsd borad15'>
                                                    <div className='imagedirectur'>
                                                        <img src="https://inphb.ci/trombi/ngoranzita.jpg" alt='' />
                                                    </div>
                                                    <div className='dirtitle'>
                                                        <h5 style={{color: '#7896DB', textTransform:'uppercase'}}>n'goran zita epse aw</h5>
                                                        <span style={{color: '#000', textTransform:'uppercase'}}>Directrice communication, relations extérieures et affaires juridiques</span>
                                                    </div>
                                                    {/* bureu du DGA COMMENCE ICI  */}
                                                    <div className='popuppostedirbureau'>
                                                        <div className='popupsdbur bgbleub'>
                                                            <div className='imagechef1'>
                                                                <img src="https://inphb.ci/trombi/tovorprisca.jpg" alt='' />
                                                                <h6>TOVOR PRISCA</h6>
                                                                <h6 style={{color: '#000', fontWeight: '600'}}>Attachée de communication, de l'évènementiel <br/>et des relations publiques</h6>
                                                            </div>
                                                            {/* <div className='imagechef1'>
                                                                <img src="https://inphb.ci/trombi/kadjotanon.jpg" alt='' />
                                                                <h6>KADJO TANON LAMBERT</h6>
                                                                <h6 style={{color: '#000', fontWeight: '600'}}>Bureau Central des concours <br /> d'entrée à l'INP-HB</h6>
                                                            </div>
                                                            <div className='imagechef1'>
                                                                <img src="https://inphb.ci/trombi/adjoumaniwognin.jpg" alt='' />
                                                                <h6>ADJOUMANI WOGNIN</h6>
                                                                <h6 style={{color: '#000', fontWeight: '600'}}>Bureau genre</h6>
                                                            </div> */}
                                                            
                                                        </div>
                                                    </div>
                                                     {/* bureu du DGA SE TERMINE ICI  */}
                                                    <hr style={{color:'#fff'}}/>
                                                    <div className='popupcont  borad15'>
                                                        <div className='popupimagedir'>
                                                            {/* <img src="https://inphb.ci/trombi/adamakone.jpg" alt='' /> */}
                                                            <h5 style={{color: '#7896DB', textTransform:'uppercase'}}>pool direction communication, <br/> relations extérieures et affaires juridiques</h5>
                                                            {/* <span style={{color: '#000', textTransform:'uppercase', fontWeight:'600'}}>Directeur de la scolarité <br/> et de la vie etudiante</span> */}
                                                        </div>
                                                        <div className='popuppostedir'>
                                                            <div className='popupsd bgbleub'>
                                                                <div className='soudirecteurs'>
                                                                    <div>
                                                                        <img src="https://inphb.ci/trombi/damoaka.jpg" alt='' />
                                                                        <h5>damo aka toussaint</h5>
                                                                        <p style={{color: '#000', textTransform:'uppercase', fontWeight:'600', fontSize:'12px'}}>Sous-Directeur communication <br/> et documentation</p>
                                                                    </div>
                                                                </div>
                                                                <div className='chefsservices'>
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/konanjoel.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>konan joel</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600'}}>Chef de service rédaction et  <br/> communication digitale</p>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/abledjirita.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>ablédji tohourihonon rita</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600'}}>Cheffe de service production multimédia</p>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <div className='serviceslast'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/koffigrace.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>koffi grace</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600'}}>Cheffe de service documentation, archivages</p>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr style={{backgroundColor: 'transparent', color: 'transparent'}} />
                                                            <div className='popupsd bgbleub'>
                                                                <div className='soudirecteurs'>
                                                                    <div>
                                                                        <img src="https://inphb.ci/trombi/sidibemoustapha.jpg" alt='' />
                                                                        <h5>sidibe moustapha</h5>
                                                                        <p style={{color: '#000', textTransform:'uppercase', fontWeight:'600', fontSize:'12px'}}>Sous-Directeur relations extérieures et <br/>affaires juridiques</p>
                                                                    </div>
                                                                </div>
                                                                <div className='chefsservices'>
                                                                    <div className='services'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/traoreaboubacar.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>traore aboubacar</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>Chef de service relations extérieures et <br/> suivi des partenariats</p>
                                                                        </div> 
                                                                    </div>
                                                                    <div className='serviceslast'>
                                                                        <div className='imagechef'>
                                                                            <img src="https://inphb.ci/trombi/avatar.jpg" alt='' />
                                                                        </div>
                                                                        <div className='nomservice'>
                                                                            <h5>seri gabrielle anne</h5>
                                                                            <p style={{color: '#000', textTransform:'uppercase', fontSize:'10px', fontWeight:'600', lineHeight:''}}>Cheffe de service affaires juridiques</p>                                                                        
                                                                        </div>
                                                                       
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>
                                        )}
                                        
                                        
                                    </div>
                                                                
                                        {/* <div className='trombidiv '>DIRECTION générale</div>
                                        <div className='trombidiv'>DIRECTION générale</div> */}
                                    </div>
                                    
                                </div>
                                <div className='lessignifications'>
                                    <div>
                                        <div className='lessigles'>
                                            <span style={{fontWeight:'800'}}>*UMRI : </span>Unité Mixte de Recherche et d'Innovation
                                            <br/>
                                            <span style={{fontWeight:'800'}}>*STI : </span> Sciences et Technologies de l'Ingénieur
                                            <br/>
                                            <span style={{fontWeight:'800'}}>*SAPT : </span> Sciences Agronomiques et Procédés de Transformation
                                            <br/>
                                            <span style={{fontWeight:'800'}}>*SEG : </span> Sciences Economiques et de Gestion 
                                            <br/>
                                            <span style={{fontWeight:'800'}}>*MSN : </span>Mathématiques et Sciences du Numérique
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <Organigramme /> */}
                            
                            </div>
           
                            {/* <Myimg
                                src={Organigram}
                                alt=""
                                className="my-3"
                            /> */}

                            
                        </div>
                        
                    </div>
                    
                </div>
                
            </Container>
        </Transitions>
    )
}
