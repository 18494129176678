import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import '../../utils/style/index.scss'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import styled from 'styled-components'
import Mission from '../../assets/Portail/MISSIONS.png'
import Vision from '../../assets/Portail/VISION.png'
import Valeur from '../../assets/Portail/VALEURS.png'
import { Transitions } from '../../components/Transitions'

const TitleDiv = styled.div`
    width: 100%;
    background-color: rgb(0, 141, 54);
`
const Item = styled.div`
    padding: 10px;
    background-color: ${(prop) => prop.color};
    color: ${(prop) => prop.textColor};
    display: flex;
    border-bottom: 1px solid #f47920;
    justify-content: space-between;
    align-items: center;
    cursor: pointer !important;
`
const Myimg = styled.img`
    width: 100%;
`
export const Missions = () => {
    const [checkBoxState, setcheckBoxState] = useState([
        false,
        true,
        false,
    ])
    const HanldeCheck = (i) => {
        setcheckBoxState((prevState) =>
            prevState.map((item, idx) => (idx === i ? !item : false))
        )
    }
    return (
        <Transitions>
            <TitleDiv className="d-flex justify-content-center align-items-center text-light mb-5">
                <span
                    className="body-highlight mt-3 text-uppercase ps-2 mb-3 fs-1"
                    style={{ borderLeft: '10px solid #fff' }}
                >
                    Vision, mission, valeurs
                </span>
            </TitleDiv>
            <Container>
            <div>
                    <Item
                        className="text-uppercase my-1"
                        onClick={() => HanldeCheck(1)}
                        color={checkBoxState[1] ? '#f47920' : '#fff'}
                        textColor={checkBoxState[1] ? '#fff' : '#000'}
                    >
                        <div
                            style={{ cursor: 'pointer' }}
                            className="text-uppercase"
                        >
                            Notre vision
                        </div>
                        {checkBoxState[1] ? (
                            <FaChevronUp
                                style={{ cursor: 'pointer', color: '#fff' }}
                            />
                        ) : (
                            <FaChevronDown
                                style={{ cursor: 'pointer', color: '#f47920' }}
                            />
                        )}
                    </Item>
                    <div
                        className={
                            checkBoxState[1] ? 'd-block mb-5 fade-in' : 'mb-5 d-none'
                        }
                    >
                        <div className="d-flex align-items-center justify-content-center">
                            <Myimg src={Vision} alt="" className="my-3" />
                        </div>
                        <div className="body-text border-0">
                            Faire de l'INP-HB : « le fleuron de la formation des
                            Ingénieurs et Techniciens Supérieurs en Afrique et
                            exporter la qualité de son système dans les pays de
                            la sous-région ». <br /> Cela implique :
                            <div className="vert">
                                Le maintien d’un environnement sain de travail,
                                propice à des études supérieures de haut niveau
                                ;{' '}
                            </div>
                            <div className="vert">
                                Le développement de la culture de l’excellence
                                depuis les concours jusqu'à l’obtention des
                                diplômes ;{' '}
                            </div>
                            <div className="vert">
                                L’extension de l’offre de formation et
                                l’augmentation de la capacité d’accueil de
                                l’Institut pour fournir à la Côte d'Ivoire et à
                                l’Afrique, des Ingénieurs et Techniciens
                                Supérieurs de qualité dans tous les secteurs
                                clés de l’économie ;{' '}
                            </div>
                            <div className="vert">
                                Le développement de partenariats stratégiques en
                                Afrique et à l'étranger avec des établissements
                                supérieurs de haute renommée et des sociétés
                                savantes partout dans le monde ;{' '}
                            </div>
                            <div className="vert">
                                La mise en œuvre de partenariats avec les
                                entreprises aussi bien pour les stages de
                                production que pour l’expertise ;{' '}
                            </div>
                            <div className="vert">
                                L’excellence de la recherche appliquée aux
                                besoins de l’industrie afin de faire de
                                l’INP-HB, le hub de l’innovation en Afrique ;{' '}
                            </div>
                            <div className="vert">
                                Le suivi des étudiants dans les prestigieuses
                                écoles à l’International tout en mobilisant le
                                réseau et le soutien des alumni de l’INP-HB.{' '}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-2">
                    <Item
                        className="text-uppercase my-1"
                        onClick={() => HanldeCheck(0)}
                        color={checkBoxState[0] ? '#f47920' : '#fff'}
                        textColor={checkBoxState[0] ? '#fff' : '#000'}
                    >
                        <div
                            style={{ cursor: 'pointer' }}
                            className="text-uppercase"
                        >
                            Nos missions
                        </div>
                        {checkBoxState[0] ? (
                            <FaChevronUp
                                style={{ cursor: 'pointer', color: '#fff' }}
                            />
                        ) : (
                            <FaChevronDown
                                style={{ cursor: 'pointer', color: '#f47920' }}
                            />
                        )}
                    </Item>
                    <div
                        className={
                            checkBoxState[0] ? 'd-block mb-5 fade-in' : 'mb-5 d-none'
                        }
                    >
                        <div className="d-flex align-items-center justify-content-center">
                            <Myimg src={Mission} alt="" className="my-3" />
                        </div>
                        <div className="body-text border-0">
                            De par ses activités de formation, de recherche, de
                            conseil et d’expertise dans les secteurs primaire,
                            secondaire et tertiaire, l’Institut œuvre à combler
                            les attentes des entreprises et à améliorer les
                            conditions de vie des populations. <br /> L’Institut
                            s’inscrit progressivement comme un vecteur
                            incontournable de l’intégration sous régionale et
                            africaine. Ses missions se résument en trois points
                            :
                            <div className="vert">
                                {' '}
                                <strong>La formation</strong> : Formation initiale et continue à
                                travers des formations diplômantes et
                                qualifiantes (recyclage, perfectionnement) des
                                techniciens supérieurs, des ingénieurs des
                                techniques et des ingénieurs de conception dans
                                les domaines de l'industrie, du commerce, de
                                l'administration, du génie civil, des mines et
                                de la géologie.{' '}
                            </div>
                            <div className="vert">
                                <strong>L’innovation</strong> : Recherche – développement.{' '}
                            </div>
                            <div className="vert">
                                <strong>La Transformation</strong> : Expertise, Assistance et
                                production au profit des entreprises et
                                administrations.{' '}
                            </div>
                        </div>
                    </div>
                </div>
                
                <div>
                    <Item
                        className="text-uppercase my-1"
                        onClick={() => HanldeCheck(2)}
                        color={checkBoxState[2] ? '#f47920' : '#fff'}
                        textColor={checkBoxState[2] ? '#fff' : '#000'}
                    >
                        <div
                            style={{ cursor: 'pointer' }}
                            className="text-uppercase"
                        >
                            Nos valeurs
                        </div>
                        {checkBoxState[2] ? (
                            <FaChevronUp
                                style={{ cursor: 'pointer', color: '#fff' }}
                            />
                        ) : (
                            <FaChevronDown
                                style={{ cursor: 'pointer', color: '#f47920' }}
                            />
                        )}
                    </Item>
                    <div
                        className={
                            checkBoxState[2] ? 'd-block mb-5 fade-in' : 'mb-5 d-none'
                        }
                    >
                        <div className="d-flex align-items-center justify-content-center">
                            <Myimg src={Valeur} alt="" className="my-3" />
                        </div>
                        <div className="body-text border-0">
                            <div className="vert">
                                <strong> L’excellence de la formation et de la re¬cherche</strong><br/>
                                L’INP-HB, par la qualité du recrutement de ses
                                élèves et de ses enseignants a toujours été un
                                centre d’excellence dans la sous-région avec
                                pour mission essentielle de fournir au continent
                                des cadres de haut niveau pouvant exercer dans
                                divers secteurs d’activité.{' '}
                            </div>
                            <div className="vert">
                                <strong> L’innovation au service de la population </strong> <br/>
                                L’INP-HB affirme sa présence citoyenne à travers
                                des actions d’innovation, de valorisation et de
                                transfert technologique en vue d’améliorer les
                                conditions de vie et de travail des populations
                                et de faciliter l’insertion professionnelle des
                                jeunes.{' '}
                            </div>
                            <div className="vert">
                                <strong> Une communauté forte et solidaire </strong> <br/> Au sein de sa
                                communauté, l’INP-HB favorise une synergie dans
                                laquelle chacun des membres trouve son avantage
                                à être un acteur dans une institution phare, à
                                laquelle il s’identifie et dont il partage les
                                orientations stratégiques.{' '}
                            </div>
                            <div className="vert">
                                <strong> Le professionnalisme de l’administration </strong> <br/> La
                                force de l’organisation de l’Institut réside en
                                partie, dans les capacités de l’administration à
                                se renouveler pour s’adapter à son contexte en
                                vue de répondre aux besoins des enseignants, des
                                élèves et des partenaires externes.{' '}
                            </div>
                            <div className="vert">
                                <strong>
                                Une politique d’ouverture et de Partenariat
                                institutionnel
                                </strong><br/>
                                 De nombreux accords de
                                coopération avec des centres de formation et de
                                recherche mondialement reconnus contribuent à
                                améliorer efficacement la qualité de la
                                formation dispensée et à réaliser la mission de
                                veille technologique.<br/>
                                Le recrutement d’élèves issus d’autres pays
                                africains traduit le caractère panafricaniste de
                                l’Institut et réaffirme sa vocation régionale.{' '}
                            </div>
                            <div className="vert">
                                <strong> Le respect de l’environnement</strong> <br/> L’INP-HB s’inscrit
                                résolument dans le cadre du développement
                                durable de la ville de Yamoussoukro, capitales
                                politique de la Côte d’Ivoire. Son objectif est
                                de créer un environnement de travail sain et
                                agréable, propice à l’épanouissement de ses
                                personnels et des élèves.{' '}
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </Transitions>
    )
}
