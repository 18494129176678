import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
import { useContext, useState, useRef } from 'react'
import { MyContext } from '../../utils/context'
import { France, Anglais, Arabe } from '../../datas'
import { AiOutlineClose } from 'react-icons/ai'
import logo from '../../assets/INP-HB.png'
import { useEffect } from 'react'
import { Transitions } from '../../components/Transitions'
import { motion } from 'framer-motion'
import ReactCounter from "../../components/ReactCounter/ReactCounter"
import SideMenuTop from '../../components/SideMenuTop/SideMenuTop'
import './personnel.css'
import SlideToRight from '../../components/SlideToRight/SlideToRight'
import { AiOutlineCheckCircle } from 'react-icons/ai';
import Biblio from '../../assets/Placeholder/biblio.png'
import Actualites from '../../assets/Placeholder/actualites.png'
import Webtv from '../../assets/Placeholder/webtv.png'
import { Link } from 'react-router-dom'
import Modal from 'react-modal';
import YouTube from 'react-youtube';
import Docto from '../../assets/Placeholder/docto.jpg'
import classes from './popup.module.css'
import Cocan from '../../assets/Placeholder/logocan.png'
import Cocanb from '../../assets/Placeholder/balloncan.png'
import { differenceInDays } from 'date-fns';
import { AiOutlineBell } from 'react-icons/ai';
import classes2 from '../Newplaceholder/newplace.module.css'




function importAll(r) {
    let images = {}
    r.keys().map((item, index) => (images[item.replace('./', '')] = r(item)))
    return images
}

const placeholder = importAll(
    require.context('../../assets/Placeholder', false, /\.(png|jpe?g|svg|mp4)$/)
)

const PlaceholderWrapper = styled(Container)`
    background-image: url(${placeholder['PlaceholderBg.jpg']});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 96vh;
    padding: 50px;
    padding-top: 8%;
    padding-bottom: 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
    @media (max-width: 768px) {
        height: auto !important;
    }
    @media (max-width: 540px) {
        height: 100vh !important;
    }
    @media (max-width: 415px) {
        padding: 20px;
    }
    @media (max-width: 381px) {
        height: auto !important;
        background-image: url(${placeholder['phoneBG.jpg']});
    }
`
const InformationSection = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 13vh;
    width: 58%;
    border-radius: 45px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: auto;
    margin-bottom: 15px;
    @media (max-width: 900px) {
        height: 9vh;
    }
    @media (max-width: 600px) {
        height: 7vh;
    }
    @media (max-width: 480px) {
        width: 100%;
        height: 12vh;
    }
`

const InformationAnnonce = styled.img`
    width: 100%;
    cursor: pointer;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-bottom: 15px;
    transition: transform 350ms ease-in-out;
    &:hover {
        transform: scale(1.08);
    }
    @media (max-width: 540px) {
        display: none;
    }
`
const InformationAnnonceMD = styled.img`
    display: none;
    transition: transform 350ms ease-in-out;
    &:hover {
        transform: scale(1.08);
    }
    @media (max-width: 540px) {
        display: block;
        width: 100%;
        cursor: pointer;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        margin-bottom: 15px;
    }
    @media (max-width: 415px) {
        display: none;
    }
`
const InformationAnnonceSM = styled.img`
    display: none;
    transition: transform 350ms ease-in-out;
    &:hover {
        transform: scale(1.08);
    }
    @media (max-width: 415px) {
        display: block;
        width: 100%;
        cursor: pointer;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        margin-bottom: 15px;
    }
`
const PlaceholderItem = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    @media (max-width: 376px) {
        flex-basis: 100% !important;
    }
    @media (max-width: 376px) {
        padding: 3px;
    }
`
const PlaceholderItemButton = styled.a`
                                                    ${'' /* les modif ici  */}
    width: 100%;
    margin-top: 16px;
    text-align: center;
    border-radius: 25px;
    background-color: #fff;
    backdrop-filter: blur(5px);
    color: #008d36;
    &:hover {
        /* background-color: #fff;*/
        color: #008d36;
        text-shadow: 0px 0px 8px #008d36;
        box-shadow: 0px 0px 8px #008d36;
    }
    @media (max-width: 1200px) {
        font-size: 0.75em;
    }
    @media (max-width: 992px) {
        font-size: 0.65em;
    }
`

const PlaceholderImageWrapper = styled.a`
    transition: transform 450ms;
    &:hover {
        transform: scale(1.05);
    }
`
const PlaceholderImage = styled.img`
    width: 100%;
    cursor: pointer;
    border-radius: 20px;
`
const PlaceholderImageText = styled.div`
    padding: 0.3em !important;
    z-index: 3;
    font-size: 0.85em;
    background-color: #00000078;
    border-radius: 30px;
    border: 2px solid #fff;
    margin: auto;
    @media (max-width: 1150px) {
        font-size: 0.5em;
    }
    @media (max-width: 385px) {
        font-size: 0.9em;
    }
    @media (max-width: 330px) {
        font-size: 0.5em;
    }
    @media (max-width: 230px) {
        font-size: 0.3em;
    }
`
const Footer = styled.footer`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    color: #fff;
    height: 4.2vh;
    padding: 10px;
    @media (max-width: 335px) {
        font-size: 0.85em;
        text-align: center;
    }
`
const PopupWrapper = styled.div`
    display: none;
    position: fixed;
    z-index: 20;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(3px);
`
const PlaceholderRow = styled(Row)`
    @media (max-width: 376px) {
        padding: 30px;
    }
    @media (max-width: 330px) {
        padding: 15px;
    }
    @media (max-width: 230px) {
        padding: 10px;
    }
`

const Popup = styled(motion.div)`
    width: 60%;
    @media (max-width: 800px) {
        width: 70%;
    }
    @media (max-width: 600px) {
        width: 75%;
    }
    @media (max-width: 400px) {
        width: 80%;
    }
    @media (max-width: 300px) {
        width: 90%;
    }
`
const Close = styled.div`
    display: flex;
    justify-content: flex-end;
    background-color: rgba(255, 255, 255, 0.7);
`
const CloseButton = styled.button`
    border: 0px;
    cursor: pointer;
`
const RotatingImage = styled.img`
animation: rotation 2s infinite linear;
@keyframes rotation {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(1turn);
  }
}
`;

function Placeholder() {
    const popup = useRef()
    const video = useRef()
    const [annonce, setAnnonce] = useState(true)
    const [chooseLang, setChooseLang] = useState(false)
    const { lang, addLang } = useContext(MyContext)
    let language, lang1, lang2
    switch (lang) {
        case 0:
            language = France
            lang1 = 1
            lang2 = 2
            break
        case 1:
            language = Anglais
            lang1 = 0
            lang2 = 2
            break
        case 2:
            language = Arabe
            lang1 = 0
            lang2 = 1
            break
        default:
            language = France
            lang1 = 1
            lang2 = 2
            break
    }
    const closeVideo = () => {
        popup.current.style.display = 'none'
        video.current.src = ''
    }
    window.addEventListener('load', function () {
        this.setTimeout(function open() {
            try {
                popup.current.style.display = 'flex'
            } catch (error) {}
        }, 2000)
    })
    useEffect(() => {
        document.addEventListener('click', HandleClickOutside, true)
    }, [])
    const refOne = useRef(null)
    const HandleClickOutside = (e) => {
        try {
            if (!refOne.current.contains(e.target)) {
                setChooseLang(false)
            }
        } catch (error) {}
    }



    // Modal de la video
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const openModal = () => {
        setModalIsOpen(true);
    }
    const closeModal = () => {
        setModalIsOpen(false);
    }

    useEffect(() => {
        openModal();
    }, []);

    // Modal de l'image
    const [isModalOpen, setIsModalOpen] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsModalOpen(true);
        }, 5000);
        return () => clearTimeout(timer); // nettoyez le timer si le composant est démonté avant que le délai ne soit écoulé
    }, []);

    const startDate = new Date(); // Date actuelle
    const endDate = new Date(2024, 0, 13); // 0 est janvier
    const remainingDays = differenceInDays(endDate, startDate);

    const BellIcon = styled(AiOutlineBell)`
    
    color: #ff8601;
    position: absolute;
    ${'' /* background: red ; */}
    index: 999;
    borderRadius: 25px;
    fontWeight: 800;
    top: 0%;
    left: 50%;
    transform: translateX(-50%);
    animation: blink 1s linear infinite;
    @keyframes blink {
        0% { opacity: 1; }
        50% { opacity: 0; }
        100% { opacity: 1; }
    }
`;

// To create a mascot juggling a ball and moving around the page, you can add the following code snippet:

    return (
        <> 
         
            <PlaceholderWrapper fluid>

                {/* Début du popup  */}
                    <Modal
                            isOpen={isModalOpen}
                            onRequestClose={() => setIsModalOpen(false)}
                            contentLabel="My Modal"
                            className={classes.popup_image}
                    >
                        <button onClick={() => setIsModalOpen(false)} className={classes.closemodal}>X</button>
                        <div><img src={Docto} alt="CREFSEM INPHB" /></div>
                        <button onClick={() => {setIsModalOpen(false); window.open('https://recrutements.concours.inphb.app', '_blank')}} className={classes.lienmodal}>S'INSCRIRE</button>
                        {/* <button onClick={() => {setIsModalOpen(false); window.open('https://inphb.ci/mastercrefsem2024.pdf', '_blank')}} className={classes.lienmodal}>MASTER</button> */}

                    </Modal>

                    {/* Fin du popup  */}
                <Transitions>
                    <Container fluid>
                        {annonce ? (
                            // <a href="/concours/ingenieur" style={{cursor:"pointer"}} target="_blank" rel='noreferrer'>
                            //     <InformationSection annonce={annonce} />
                            // </a>
                            <Row className="d-flex align-items-center justify-content-center">
                                {/* Début  Ajout du boutton verif diploma  */}
                                <Col
                                
                                    xxl={12}

                                >
                                    <div className="verif">
                                        <a href='https://inphb-diplome.verifdiploma.com/verifcenter/'>
                                        <div className="bouttonverif">
                                        <AiOutlineCheckCircle size={20} style={{ marginRight: '5px' }} />
                                            Vérifier un diplôme INP-HB
                                        </div>
                                                                                </a>
                                    </div>
                                </Col>

                                {/* Fin ajout du boutton verfi diploma  */}
                                <Col
                                    xxl={8}
                                    style={{
                                        position: annonce ? 'relative' : null,
                                    }}
                                >
                                    {/* <BellIcon size={35}  /> */}
                                    <a href="https://ingenieurs.concours.inphb.app/" 
                                        target={'_blank'} 
                                        rel="noreferrer"
                                    >
                                        <InformationAnnonce
                                            src={placeholder['annonce.jpg']}
                                        />
                                        <InformationAnnonceMD
                                            src={placeholder['annonceMD.jpg']}
                                        />
                                        <InformationAnnonceSM
                                            src={placeholder['annonceSM.jpg']}
                                        />
                                    </a>
                                </Col>
                            </Row>
                        ) : (
                            <InformationSection annonce={annonce}>
                                <img
                                    className="logo"
                                    style={{ height: '70%', width: 'auto' }}
                                    src={logo}
                                    alt="logo-inphb"
                                />
                                {/* <div className="ms-3" ref={refOne}>
                                    <LanguageSlected
                                        onClick={() => setLanguage(lang)}
                                        className="fw-bold"
                                    >
                                        <div className="cursor-pointer">
                                            {langue[lang].title}
                                        </div>
                                    </LanguageSlected>
                                    <ChooseLanguage
                                        open={chooseLang}
                                        onMouseLeave={() => setChooseLang(false)}
                                        className="p-1 fade-in"
                                    >
                                        <Language
                                            onClick={() => setLanguage(lang1)}
                                            className="fw-bold"
                                        >
                                            <div className="cursor-pointer">
                                                {langue[lang1].title}
                                            </div>
                                        </Language>
                                        <Language
                                            onClick={() => setLanguage(lang2)}
                                            className="fw-bold"
                                        >
                                            <div className="cursor-pointer">
                                                {langue[lang2].title}
                                            </div>
                                        </Language>
                                    </ChooseLanguage>
                                </div> */}
                            </InformationSection>
                        )}


                        <section className='sectionbiblio'>
                            <div className='bibliotheque'>
                                <Link to="https://actualites.inphb.ci" target='_blank'>
                                    <img src={Actualites} alt='' /> 
                                    <h5>actualités  </h5>
                                </Link>
                            </div>
                            {/* <div className='lacan'>
                                <div className='imagecan'><img src={Cocan} alt='' /> <RotatingImage src={Cocanb} /></div>
                                <div className='decomptecan'> Jour J - {remainingDays}</div>
                            </div> */}
                            <div className='bibliotheque'>
                                <Link to="https://bibliotheque.inphb.ci" target='_blank'>
                                    <img src={Biblio} alt='' />
                                    <h5>Bibliothèque  </h5>
                                </Link>
                            </div>

                            <div className='bibliotheque'>
                                <Link to="Erasmus" 
                                // target='_blank'
                                >
                                    <img src={Webtv} alt='' /> 
                                    <h5>ERASMUS</h5>
                                </Link>
                            </div>
                        </section>
                        
                        <PlaceholderRow>
                            <PlaceholderItem
                                xxl={3}
                                xl={3}
                                lg={3}
                                md={3}
                                sm={6}
                                xs={6}
                                className="right-to-left"
                            >
                                <PlaceholderImageWrapper
                                    href="portail"
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    <PlaceholderImage
                                        src={placeholder['portail.png']}
                                    />
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    >
                                        <PlaceholderImageText className="text-white text-center text-uppercase fw-bold">
                                            {language[0].accesportail}
                                        </PlaceholderImageText>
                                    </div>
                                </PlaceholderImageWrapper>
                                <PlaceholderItemButton
                                    className="mybtn"
                                    href="portail"
                                >
                                    {language[0].accesportail}
                                </PlaceholderItemButton>
                            </PlaceholderItem>
                            <PlaceholderItem
                                xxl={3}
                                xl={3}
                                lg={3}
                                md={3}
                                sm={6}
                                xs={6}
                                className="bottom-to-top"
                            >
                                <PlaceholderImageWrapper
                                    href="ecoles"
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    <PlaceholderImage
                                        src={placeholder['ecoles.png']}
                                    />
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    >
                                        <PlaceholderImageText className="text-white text-center text-uppercase fw-bold">
                                            {language[0].visite}
                                        </PlaceholderImageText>
                                    </div>
                                </PlaceholderImageWrapper>

                                <PlaceholderItemButton
                                    className="mybtn"
                                    href="ecoles"
                                >
                                    {language[0].visite}
                                </PlaceholderItemButton>
                            </PlaceholderItem>
                            <PlaceholderItem
                                xxl={3}
                                xl={3}
                                lg={3}
                                md={3}
                                sm={6}
                                xs={6}
                                className="top-to-bottom"
                            >
                                <PlaceholderImageWrapper
                                    href="concours"
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    <PlaceholderImage
                                        src={placeholder['concours.png']}
                                    />
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    >
                                        <PlaceholderImageText className="text-white text-center text-uppercase fw-bold">
                                            {language[0].voir}
                                        </PlaceholderImageText>
                                    </div>
                                </PlaceholderImageWrapper>
                                <PlaceholderItemButton
                                    className="mybtn"
                                    href="concours"
                                >
                                    {language[0].voir}
                                </PlaceholderItemButton>
                            </PlaceholderItem>
                            <PlaceholderItem
                                xxl={3}
                                xl={3}
                                lg={3}
                                md={3}
                                sm={6}
                                xs={6}
                                className="left-to-right"
                            >
                                <PlaceholderImageWrapper
                                    href="recherche"
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    <PlaceholderImage
                                        src={placeholder['recherche.png']}
                                    />
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    >
                                        <PlaceholderImageText className="text-white text-center text-uppercase fw-bold">
                                            {language[0].research}
                                        </PlaceholderImageText>
                                    </div>
                                </PlaceholderImageWrapper>
                                <PlaceholderItemButton
                                    className="mybtn"
                                    href="recherche"
                                >
                                    {language[0].research}
                                </PlaceholderItemButton>
                            </PlaceholderItem>
                        </PlaceholderRow>
                        <ReactCounter />
                        
                    </Container>
                    
                </Transitions>
                <SideMenuTop />
                <SlideToRight /> 
            </PlaceholderWrapper>
            {/* <div>Okay</div> */}
            
            <Footer>INP-HB Digital Copyright &copy; {new Date().toLocaleString('default', { month: 'long' }).toUpperCase()} {new Date().getFullYear()}</Footer>
            
        </>
    )
}

export default Placeholder
