import React from 'react'
import { Container } from 'react-bootstrap'
import styled from 'styled-components'
import Diplome from '../../assets/Portail/diplome.jpg'
import { Transitions } from '../../components/Transitions'
import '../../utils/style/index.scss'

const TitleDiv = styled.div`
    width: 100%;
    background-color: rgb(0, 141, 54);
`
const Myimg = styled.img`
    width: 100%;
`
export const NosDiplomes = () => {
    return (
        <Transitions>
            <TitleDiv className="d-flex justify-content-center align-items-center text-light mb-5">
                <span
                    className="body-highlight mt-3 text-uppercase ps-2 mb-3 fs-1"
                    style={{ borderLeft: '10px solid #fff' }}
                >
                    Nos diplômés emblématiques
                </span>
            </TitleDiv>
            <Container>
                <div className="d-flex align-items-center justify-content-center">
                    <Myimg src={Diplome} alt="" className="my-3" />
                </div>
                <div className="body-text border-0">
                    Nos diplômés ont une longue tradition d’excellence.
                    Représentés dans tous les secteurs d’activités à travers le
                    monde, ils sont notre fierté. De par leur professionnalisme,
                    l’Institut s’est bâti de fil en aiguille, une réputation
                    solide. Au nombre d’eux figurent des membres du Gouvernement
                    ivoirien (09 ministres), des Présidents Directeurs Généraux, des Directeurs Généraux,
                    des entrepreneurs…{' '}
                </div>
            </Container>
        </Transitions>
    )
}
