import { createGlobalStyle } from 'styled-components'
import colors from '../colors/index'
import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const Loader = styled.div`
  padding: 10px;
  border: 6px solid ${colors.primary};
  border-bottom-color: transparent;
  border-radius: 22px;
  animation: ${rotate} 1s infinite linear;
  height: 0;
  width: 0;
`

const StyledGlobalStyle = createGlobalStyle`
    * {
      font-family: 'Roboto', 'Segoe Ui', sans-serif;
    }
    body {
        width: 100%;
        margin: 0px;
        background-color: #fff;
        overflow-x: hidden;
        scroll-behavior: smooth;
    }
`

function GlobalStyle() {
    return <StyledGlobalStyle />
}

export default GlobalStyle
