import React from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Pagination } from 'swiper'
import { Card } from 'react-bootstrap'

function importAll(r) {
    let images = {}
    r.keys().map((item, index) => {
        images[item.replace('./', '')] = r(item)
    })
    return images
}

const portail = importAll(
    require.context('../../assets/Portail', false, /\.(png|jpe?g|svg)$/)
)

const appelscandidatures = importAll(
    require.context('../../assets/Portail/appelscandidatures', false, /\.(png|jpe?g|svg)$/)
)

const MySwiper  = styled(SwiperSlide)`
    height: auto !important;
    background-color: transparent;
    /* @media(max-width:1200px) {
        height: 27rem !important;
    }
    @media(max-width:992px) {
        height: 24rem !important;
    } */
`
const MyImage = styled.img`
    /* width: 100% !important; */
    object-fit: contain;
`


const appels = [

    // {
    //     lien: 'https://mbauqam.inphb.ci/',
    //     picture: appelscandidatures['mbauqam.jpg'],
    // },
    
    // {
    //     lien: 'https://efficacite-energetique.inphb.ci/',
    //     picture: appelscandidatures['eef.jpg'],
    // },

]

function AppelACandidature() {
    return (
        <Swiper
            slidesPerView={2}
            spaceBetween={10}
            pagination={{
                clickable: true,
            }}

            breakpoints={{
                "@0.00": {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                "@0.75": {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                "@1.00": {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                "@1.50": {
                  slidesPerView: 2,
                  spaceBetween: 50,
                },
              }}
            modules={[Pagination]}
            className="mySwiper w-100 px-3"
        >
            {appels.map((appel, index) => (
                <MySwiper key={`appel-${index}`} className="p-2" >
                    <Card
                        key={`${appel.name}-${index}`}
                        className="p-0 m-0"
                        style={{
                            width: '100%',
                            height: '100%',
                            border: '0px',
                            borderRadius: 30,
                            overflow:'hidden',
                            boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}}
                            
                    >
                        <a href={appel.lien} target={"_blank"} rel="noreferrer"><MyImage variant="top" src={appel.picture}/></a>
                    </Card>
                </MySwiper>
            ))}
        </Swiper>
        
    )
    
}

export default AppelACandidature
