import { useContext, useState, useRef, useEffect } from 'react'
import classes from './newplace.module.css'
import Slider from 'react-slick';
import ReactPlayer from 'react-player';
// import './newplace.module.css'
import Slider1 from './Slider'
import ReactCounter from "../../components/ReactCounter/ReactCounter"
import styled from 'styled-components'
import SlideToRight from '../../components/SlideToRight/SlideToRight'
import { FaBook, FaRegUserCircle, FaRegLightbulb, FaBars, FaTimes, FaRegUser, FaBed, FaSchool, FaChalkboardTeacher, FaCar, FaHotel } from 'react-icons/fa'
import { FcDepartment, FcAndroidOs, FcNews, FcDiploma1 } from "react-icons/fc";
import { BsNodePlusFill } from "react-icons/bs";
import { SiMicrosoftoutlook } from "react-icons/si";
// import { BiSolidSchool } from "react-icons/bi";
import Playlogo from '../../assets/Placeholder/playstore.png'
import FooterPortailConcours from '../../components/FooterPortailConcours'
import { Link } from 'react-router-dom';
import Newmenutop from '../Newmenutop/Newmenutop';
import axios from 'axios';
import ModalFiliere from './ModalFiliere'; // Importez le nouveau composant modal





function importAll(r) {
    let images = {}
    r.keys().map((item, index) => (images[item.replace('./', '')] = r(item)))
    return images
}



const placeholder = importAll(
    require.context('../../assets/Placeholder',  false, /\.(png|jpe?g|svg|mp4)$/)
)

const Slider2 = importAll(
    require.context('../../assets/Placeholder/Slider', false, /\.(png|jpe?g|svg|mp4)$/)
)
const Footer = styled.footer`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    color: #fff;
    height: 4.2vh;
    padding: 10px;
    @media (max-width: 335px) {
        font-size: 0.85em;
        text-align: center;
    }
`


export default function NewPlaceholder() {

    const Mobilitydownload= () =>{
        alert('Vous allez être redirigé vers le lien sécurisé de téléchargement.')
        window.open('https://expo.dev/accounts/magicienrci/projects/inphbmobility/builds/a52accfb-3b2b-476f-ab9f-14d0d6fc58c4', '_blank')
    }

    const videos = [
        { id: 1, url: 'https://youtu.be/TR2PzDWRmi0', title: 'Video Title 1', description: 'Description of Video 1' },
        { id: 2, url: 'https://youtu.be/YChPMeCM6k0', title: 'Video Title 2', description: 'Description of Video 2' },
        // { id: 3, url: 'https://example.com/video3.mp4', title: 'Video Title 3', description: 'Description of Video 3' }
    ];
    const settings = {
        swipe: true,
        dots: true, // Show dot indicators
        infinite: false, // Disable infinite looping
        speed: 500, // Transition speed
        slidesToShow: videos.length, // Show all videos for larger screens
        slidesToScroll: 1, // Scroll one slide at a time
        responsive: [
            {
                breakpoint: 767, // Applies to screens smaller than 768px
                settings: {
                    slidesToShow: 1, // Show one slide at a time on smaller screens
                    slidesToScroll: 1,
                    infinite: true, // Enable infinite looping for smooth carousel experience
                    dots: false // Show navigation dots
                }
            }
        ]
    };

    const [isMenuOpen, setIsMenuOpen] = useState(false); // State for mobile menu
    const [counterValue, setCounterValue] = useState(0);
    const [counterValueRooms, setCounterValueRooms] = useState(0);
    const [counterValueSchools, setCounterValueSchools] = useState(0);
    const [counterValueTeachers, setCounterValueTeachers] = useState(0);
    const [counterValueDepart, setCounterValueDepart] = useState(0);





    const handleToggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const [shrink, setShrink] = useState(false);

    const handleScroll = () => {
        const currentScrollPos = window.scrollY;
        const shrinkOn = 100; // Définissez la position de défilement à partir de laquelle les changements sont appliqués
        if (currentScrollPos > shrinkOn) {
            setShrink(true);
        } else {
            setShrink(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [])
    //Pour le décompte des élèves 
    useEffect(() => {
        let interval;
        if (counterValue < 4137) {
            interval = setInterval(() => {
                setCounterValue(prevCount => (prevCount + 40 > 4137 ? 4137 : prevCount + 40)); // Change 40 to adjust the speed
            }, 70); // Change 20 to adjust the speed
        } else if (counterValue === 4137) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [counterValue]);

    //Pour les chambres

    useEffect(() => {
        let interval;
        if (counterValueRooms < 3008) {
            interval = setInterval(() => {
                setCounterValueRooms(prevCount => (prevCount + 40 > 3008 ? 3008 : prevCount + 40)); // Change 40 to adjust the speed
            }, 70); // Change 20 to adjust the speed
        } else if (counterValueRooms === 3008) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [counterValueRooms]);

    // Pour les écoles

    useEffect(() => {
        let interval;
        if (counterValueSchools < 11) {
            interval = setInterval(() => {
                setCounterValueSchools(prevCount => (prevCount + 40 > 11 ? 11 : prevCount + 40)); // Change 40 to adjust the speed
            }, 300); // Change 20 to adjust the speed
        } else if (counterValueSchools === 3008) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [counterValueSchools]);

    // Pour les enseignants
    useEffect(() => {
        let interval;
        if (counterValueTeachers < 340) {
            interval = setInterval(() => {
                setCounterValueTeachers(prevCount => (prevCount + 40 > 340 ? 340 : prevCount + 40)); // Change 40 to adjust the speed
            }, 100); // Change 20 to adjust the speed
        } else if (counterValueTeachers === 3008) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [counterValueTeachers]);

    //Pour les départements
    useEffect(() => {
        let interval;
        if (counterValueDepart < 22) {
            interval = setInterval(() => {
                setCounterValueDepart(prevCount => (prevCount + 40 > 22 ? 22 : prevCount + 22)); // Change 40 to adjust the speed
            }, 300); // Change 20 to adjust the speed
        } else if (counterValueDepart === 22) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [counterValueDepart]);

//Pour les filières 
    const [filieres, setFilieres] = useState([]);
    const [displayFilieres, setDisplayFilieres] = useState([]);
    const [selectedFiliere, setSelectedFiliere] = useState(null); // État pour la filière sélectionnée
    const [isModalOpen, setIsModalOpen] = useState(false); // État pour contrôler l'ouverture du modal
    const [error, setError] = useState(null);
    useEffect(() => {
        console.log('Fetching data from API...');
        axios.get('https://www.adminsite.inphb.app/serverlim6.php')
            .then(response => {
                console.log('Data fetched successfully:', response.data);
                setFilieres(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the data!', error);
                setError(error);
            });
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 767) {
                setDisplayFilieres(filieres.slice(0, 6)); // 6 résultats pour les téléphones
            }   else if (window.innerWidth >= 768 && window.innerWidth <= 1024) {
                setDisplayFilieres(filieres.slice(0, 9)); // 6 résultats pour les téléphones
            }  else  {
                setDisplayFilieres(filieres.slice(0, 10)); // 12 résultats pour les grands écrans
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Appeler la fonction pour définir initialement l'état correct

        return () => window.removeEventListener('resize', handleResize);
    }, [filieres]);

    const openModal = (filiere) => {
        setSelectedFiliere(filiere);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedFiliere(null);
    };

    //Pour Destination
    const [destinationsportail, setDestinationsportail] = useState([]);
    const [destinationsecoles, setDestinationsecoles] = useState([]);
    const [destinationsconcours, setDestinationsconcours] = useState([]);
    const [destinationsrecherche, setDestinationsrecherche] = useState([]);



    useEffect(() => {
        console.log('Fetching data from API...');
        axios.get('https://www.adminsite.inphb.app/destination_portail.php')
            .then(response => {
                console.log('Data fetched successfully:', response.data);
                setDestinationsportail(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the data!', error);
                setError(error);
            });
    }, []);

    useEffect(() => {
        console.log('Fetching data from API...');
        axios.get('https://www.adminsite.inphb.app/destination_ecoles.php')
            .then(response => {
                console.log('Data fetched successfully:', response.data);
                setDestinationsecoles(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the data!', error);
                setError(error);
            });
    }, []);
    useEffect(() => {
        console.log('Fetching data from API...');
        axios.get('https://www.adminsite.inphb.app/destination_concours.php')
            .then(response => {
                console.log('Data fetched successfully:', response.data);
                setDestinationsconcours(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the data!', error);
                setError(error);
            });
    }, []);
    useEffect(() => {
        console.log('Fetching data from API...');
        axios.get('https://www.adminsite.inphb.app/destination_recherche.php')
            .then(response => {
                console.log('Data fetched successfully:', response.data);
                setDestinationsrecherche(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the data!', error);
                setError(error);
            });
    }, []);
    

      
  return (
    <>
        <div className={classes.container}>
            <Newmenutop />
            <div className={classes.slider}>
                <Slider1 />
            </div>
            <div className={classes.destination}>CHOISISSEZ VOTRE DESTINATION </div>
            <div className={classes.iconcontainer}>
                <div className={classes.iconspresets}>
                    {destinationsportail.map(destination => (
                    <div key={destination.id} className={classes.portail}  onClick={() => window.open('/portail', '_self')}>
                        <img src={`https://adminsite.inphb.app/public/destinationimages/${destination.image}`} alt={destination.title}  onClick={() => window.open('/portail', '_self')} />
                        <div className={classes.portailtext}  onClick={() => window.open('/portail', '_self')}>{destination.texte}</div>
                    </div>
                    ))}
                    {destinationsecoles.map(destination => (
                    <div key={destination.id} className={classes.ecoles}  onClick={() => window.open('/ecoles', '_self')}>
                        <img src={`https://adminsite.inphb.app/public/destinationimages/${destination.image}`} alt={destination.title}  onClick={() => window.open('/portail', '_self')} />
                        <div className={classes.portailtext}  onClick={() => window.open('/ecoles', '_self')}>{destination.texte}</div>
                    </div>
                    ))}
                    {destinationsconcours.map(destination => (
                    <div key={destination.id} className={classes.concours}  onClick={() => window.open('/concours', '_self')}>
                        <img src={`https://adminsite.inphb.app/public/destinationimages/${destination.image}`} alt={destination.title}  onClick={() => window.open('/portail', '_self')} />
                        <div className={classes.portailtext}  onClick={() => window.open('/concours', '_self')}>{destination.texte}</div>
                    </div>
                    ))}
                    {destinationsrecherche.map(destination => (
                    <div key={destination.id} className={classes.concours}  onClick={() => window.open('/recherche', '_self')}>
                        <img src={`https://adminsite.inphb.app/public/destinationimages/${destination.image}`} alt={destination.title}  onClick={() => window.open('/portail', '_self')} />
                        <div className={classes.portailtext}  onClick={() => window.open('/recherche', '_self')}>{destination.texte}</div>
                    </div>
                    ))}

                    {/* <div className={classes.ecoles} onClick={() => window.open('/ecoles', '_self')}>
                        <img src={placeholder['ecoles.png']} alt='INPHB' onClick={() => window.open('/ecoles', '_self')} />
                        <div className={classes.portailtext} onClick={() => window.open('/ecoles', '_self')}>visiter NOS ECOLES</div>
                    </div>
                    <div className={classes.concours} onClick={() => window.open('/concours', '_self')}>
                        <img src={placeholder['concours.png']} alt='INPHB' onClick={() => window.open('/concours', '_self')} />
                        <div className={classes.portailtext} onClick={() => window.open('/concours', '_self')}>voir NOS CONCOURS</div>
                    </div>
                    <div className={classes.innovation} onClick={() => window.open('/recherche', '_self')}>
                        <img src={placeholder['recherche.png']} alt='INPHB' onClick={() => window.open('/recherche', '_self')} />
                        <div className={classes.portailtext} onClick={() => window.open('/recherche', '_self')}>recherche</div>
                    </div>     */}
                </div>
            </div>
        </div>

        {/* COURS ET FILIERES  */}
        
            <div className={classes.courses}>
                {/* {filieres.map(filiere => (
                    <> */}
                    <div className={classes.coursesleft}>
                        <div className={classes.courseslefttitle} > 
                            <span>Nos Formations </span>
                            {/* <button> voir toutes nos formations </button> */}
                        </div>
                        <div className={classes.lesfilieres}>
                        {displayFilieres.map(filiere => (
                            <div className={classes.lafiliere1} key={filiere.id} onClick={() => openModal(filiere)}>
                                <div className={classes.lafilieretitle}>{filiere.filiere}</div>
                                <div className={classes.lafiliereecole}><span style={{color: '#00ff62', fontWeight: 'bolder'}}>Entité :</span> <br /> {filiere.ecole}</div>
                                <div className={classes.lafilierecycle}>Cycle : {filiere.codeCycle}</div>
                            </div>
                        ))}
                        <div className={classes.courseslefttitle} > 
                            <button onClick={() => window.open('/courses')}> ... voir plus ... </button>
                        </div>

                        </div>
                    </div>
                    {/* <div></div> */}
                {/* </>
                ))} */}
            </div>
            <ModalFiliere
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                filiere={selectedFiliere}
            />
        
        <div className={classes.afterslide}>
            {/* <h2>Avant-premières</h2> */}
            <div className={classes.lesvideos}>
                <Slider {...settings}>
                    {videos.map(video => (
                        <div key={video.id} className={classes.unevideo}>
                            {/* <h3>{video.title}</h3> */}
                            <ReactPlayer url={video.url} controls width='100%' height='100%' />
                            {/* <p>{video.description}</p> */}
                        </div>
                    ))}
                </Slider>
            </div>
            <div className={classes.application}>
                <span>Télécharger INP-HB MOBILITY</span>
                {/* <hr/> */}
                <img src={placeholder['playstore.png']}alt='INPHB MOBILITY' onClick={Mobilitydownload} />
                <p style={{fontSize: '40px', color: '#fff', cursor:'pointer'}} onClick={Mobilitydownload}><FcAndroidOs /></p>
            </div>
        </div>
        <ReactCounter />
        {/* <SlideToRight  />  */}
        {/* <Footer>INP-HB Digital Copyright &copy; {new Date().toLocaleString('default', { month: 'long' }).toUpperCase()} {new Date().getFullYear()}</Footer> */}
        <FooterPortailConcours />
    </>
  )
}
