import React from 'react'
import styled from 'styled-components'
import { Container } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { Transitions } from '../../components/Transitions'
import { Loader } from "../../components/Loader"
import { PortailAccueil } from '../PortailAccueil'

const TitleDiv = styled.div`
    width: 100%;
    background-color: rgb(0, 141, 54);
`
const Item = styled.div`
    padding: 10px;
    background-color: ${(prop) => prop.color};
    color: ${(prop) => prop.textColor};
    display: flex;
    border-bottom: 1px solid #008D36;
    justify-content: space-between;
    align-items: center;
    cursor: pointer !important;
`
const Myimg = styled.img`
    width: 100%;
`
export const Actualites = () => {
    const [checkBoxState, setcheckBoxState] = useState([true, true, true, true, true, true])
    const HanldeCheck = (i) => {
        setcheckBoxState((prevState) =>
            prevState.map((item, idx) => (idx === i ? !item : false))
        )
    }

    const [isLoading, setLoading] = useState(false)
    const [actuData, setActu] = useState([])

    useEffect(() => {
        setLoading(true)
        fetch("https://inphb-api.herokuapp.com/api/portail/actus")
            .then(response => response.json())
            .then(({ actus }) => {
                setActu(actus)
                setLoading(false)
            })
    }, [])
    

    return (
        <Transitions>
            <TitleDiv className="d-flex justify-content-center align-items-center text-light">
                <span
                    className="body-highlight mt-3 text-uppercase ps-2 mb-3 fs-1"
                    style={{ borderLeft: '10px solid #fff' }}
                >
                    Actualités
                </span>
            </TitleDiv>
            {isLoading ? (
                <div className='d-flex justify-content-center align-items-center w-100'><Loader /></div>
            ) : (
                <Container className="mt-5">
                    {actuData.map((actualite, index) => (
                        <div
                            key={`${index}`}
                            id={`actualite-${index}`}
                        >
                            <Item
                                className="text-uppercase my-3"
                                onClick={() => HanldeCheck(index)}
                                color={checkBoxState[index] ? '#008D36' : '#fff'}
                                textColor={checkBoxState[index] ? '#fff' : '#000'}
                            >
                                <div style={{ cursor: 'pointer' }}>
                                    {actualite.titre}
                                </div>
                                {checkBoxState[index] ? (
                                    <FaChevronUp
                                        style={{ cursor: 'pointer', color: '#fff' }}
                                    />
                                ) : (
                                    <FaChevronDown
                                        style={{
                                            cursor: 'pointer',
                                            color: '#008D36',
                                        }}
                                    />
                                )}
                            </Item>
                            <div
                                className={
                                    checkBoxState[index]
                                        ? 'd-block mb-5 fade-in'
                                        : 'mb-5 d-none'
                                }
                            >
                                <p className="body-highlight fs-4 text-center fw-bold" >
                                    {actualite.titre}
                                </p>
                                <div className="d-flex align-items-center justify-content-center">
                                    <Myimg
                                        src={actualite.image} //`https://inphb-api.herokuapp.com/${actualite.photo.bucket}/${actualite.photo.key}`
                                        alt={actualite.titre}
                                        className="mb-3"
                                    />
                                </div>

                                <div dangerouslySetInnerHTML={{ __html: actualite.recit }} className="mt-5 body-text border-0" />
                                {/*     {actualite.recit}
                                </p> */}
                            </div>
                        </div>
                    ))}
                </Container>
            )}
        </Transitions>
    )
}