import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Newmenutop from '../Newmenutop/Newmenutop';
import ModalFiliereecole from '../Newplaceholder/ModalFiliereecole';
import FooterPortailConcours from '../../components/FooterPortailConcours';
import { Transitions } from '../../components/Transitions';
import classes from '../Newplaceholder/newplace.module.css';
import './ecolesnew.css';

const Topimage = styled.img`
  width: 100%;
  object-fit: contain;
  cursor: pointer;
  border-radius: 20px;
  border: 5px solid ${(props) => props.color};
  transition: transform 450ms;
  &:hover {
    transform: scale(1.03);
  }
`;

const MyContainer = styled(Container)``;
const StyledImWrapper = styled.a`
  text-decoration: none;
  cursor: pointer;
  border: 1px solid ${(props) => props.color};
  width: 100%;
  background-color: ${(props) => props.color};
  border-radius: 20px;
  margin-top: 10px;
  display: flex;
  padding: 5px !important;
  align-items: center;
  justify-content: flex-center;
  transition: 1s;
`;

const StyledWrapper = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  @media (max-width: 376px) {
    flex-basis: 100% !important;
  }
`;

const MyImg = styled.img`
  width: 25%;
  margin: 0px !important;
  margin-right: 10px !important;
  cursor: pointer;
  background-color: #fff;
  transition: transform 350ms ease-in-out;
  &:hover {
    transform: rotate(360deg);
  }
`;

const MyText = styled.div`
  font-size: 0.85em;
  @media (max-width: 1200px) {
    font-size: 0.75em;
  }
  @media (max-width: 768px) {
    font-size: 0.65em;
  }
`;

const MyRow = styled(Row)`
  @media (max-width: 376px) {
    padding: 30px;
  }
  @media (max-width: 330px) {
    padding: 15px;
  }
  @media (max-width: 230px) {
    padding: 10px;
  }
`;

function EcolesAccueil() {
  const [isLoading, setLoading] = useState(false);
  const [ecolesData, setEcoles] = useState([]);
  const [selectedFiliere, setSelectedFiliere] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filieres, setFilieres] = useState([]);
  const [filteredFilieres, setFilteredFilieres] = useState([]);
  const [displayFilieres, setDisplayFilieres] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCycles, setSelectedCycles] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(false);
    fetch("")
      .then(response => response.json())
      .then((ecolesData) => {
        setEcoles(ecolesData);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  useEffect(() => {
    console.log('Fetching data from API...');
    axios.get('https://www.adminsite.inphb.app/server.php')
      .then(response => {
        console.log('Data fetched successfully:', response.data);
        setFilieres(response.data);
        setFilteredFilieres(response.data);
        setDisplayFilieres(response.data.slice(0, 15));
      })
      .catch(error => {
        console.error('There was an error fetching the data!', error);
        setError(error);
      });
  }, []);

  useEffect(() => {
    const results = filieres.filter(filiere =>
      (filiere.filiere.toLowerCase().includes(searchTerm.toLowerCase()) ||
        filiere.codeFiliere.toLowerCase().includes(searchTerm.toLowerCase()) ||
        filiere.ecole.toLowerCase().includes(searchTerm.toLowerCase()) ||
        filiere.codeEcole.toLowerCase().includes(searchTerm.toLowerCase()) ||
        filiere.specialite.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (selectedCycles.length === 0 || selectedCycles.includes(filiere.codeCycle))
    );
    setFilteredFilieres(results);
    setDisplayFilieres(results.slice(0, 15));
  }, [searchTerm, selectedCycles, filieres]);

  const move = useRef([]);
  const moveText = useRef([]);
  const moveButton = (i, link) => {
    move.current[i].style.transform =
      'translateX(calc(280%)) rotate(360deg)';
    setTimeout(function open() {
      moveText.current[i].style.opacity = '0';
    }, 230);
    setTimeout(function open() {
      if (link === "edp.inphb.ci") return window.open(`https://${link}`);
      window.location.assign(`${link}`);
    }, 400);
  };

  const openModal = (filiere) => {
    setSelectedFiliere(filiere);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedFiliere(null);
  };

  return (
    <>
      <div className={classes.container}>
        <Newmenutop />
        <Transitions>
          <div className='thenewschoolshow'>
            <div className='newshowtitle'>
              <span>N</span>
              <h1>os 11 écoles supérieures</h1>
            </div>
            <div className='schoolsandcourses'>
              <div className='newschoolsshow'>
                <div className='schoolshow'>
                  <Link to='/ecoles/cpge' target='_blank'>
                    <div className='schoolshowtitle color1'>
                      ÉCOLE PRÉPARATOIRE AUX GRANDES ÉCOLES
                    </div>
                    <div className='schoolshowimg'>
                      <img src="https://adminsite.inphb.app/Imagessiteprincipal/ecoles/backepge.jpg" alt='CPGE INP-HB' />
                    </div>
                  </Link>
                </div>
                <div className='schoolshow'>
                  <Link to='/ecoles/esa' target='_blank'>
                    <div className='schoolshowtitle color2'>
                      ÉCOLE supérieure d'agronomie
                    </div>
                    <div className='schoolshowimg'>
                      <img src="https://adminsite.inphb.app/Imagessiteprincipal/ecoles/backesa.jpg" alt='ESA INP-HB' />
                    </div>
                  </Link>
                </div>
                <div className='schoolshow'>
                  <Link to='/ecoles/escae' target='_blank'>
                    <div className='schoolshowtitle color3'>
                      ÉCOLE supérieure de commerce
                      et d'administration des entreprises
                    </div>
                    <div className='schoolshowimg'>
                      <img src="https://adminsite.inphb.app/Imagessiteprincipal/ecoles/backescae.jpg" alt='ESCAE INP-HB' />
                    </div>
                  </Link>
                </div>
                <div className='schoolshow'>
                  <Link to='https://efspc.inphb.ci' target='_blank'>
                    <div className='schoolshowtitle color4'>
                      école supérieure de formation
                      et perfectionnement des cadres
                    </div>
                    <div className='schoolshowimg'>
                      <img src="https://adminsite.inphb.app/Imagessiteprincipal/ecoles/backefscpc.jpg" alt='EFSPC INP-HB' />
                    </div>
                  </Link>
                </div>
                <div className='schoolshow'>
                  <Link to='/ecoles/esi' target='_blank'>
                    <div className='schoolshowtitle color5'>
                      école supérieure d'industrie
                    </div>
                    <div className='schoolshowimg'>
                      <img src="https://adminsite.inphb.app/Imagessiteprincipal/ecoles/backesi.jpg" alt='EFSPC INP-HB' />
                    </div>
                  </Link>
                </div>
                <div className='schoolshow'>
                  <Link to='/ecoles/esmg' target='_blank'>
                    <div className='schoolshowtitle color6'>
                      école supérieure des mines et  de géologie
                    </div>
                    <div className='schoolshowimg'>
                      <img src="https://adminsite.inphb.app/Imagessiteprincipal/ecoles/backesmg.jpg" alt='ESMG INP-HB' />
                    </div>
                  </Link>
                </div>
                <div className='schoolshow'>
                  <Link to='https://estp.inphb.ci' target='_blank'>
                    <div className='schoolshowtitle color7'>
                      école supérieure des travaux  publics
                    </div>
                    <div className='schoolshowimg'>
                      <img src="https://adminsite.inphb.app/Imagessiteprincipal/ecoles/backestp.jpg" alt='ESTP INP-HB' />
                    </div>
                  </Link>
                </div>
                <div className='schoolshow'>
                  <Link to='https://escpe.inphb.ci' target='_blank'>
                    <div className='schoolshowtitle color8'>
                      école supérieure de chimie pétrole et de l'énergie
                    </div>
                    <div className='schoolshowimg'>
                      <img src="https://adminsite.inphb.app/Imagessiteprincipal/ecoles/backescpe.jpg" alt='ESCPE INP-HB' />
                    </div>
                  </Link>
                </div>
                <div className='schoolshow'>
                  <Link to='https://edp.inphb.ci' target='_blank'>
                    <div className='schoolshowtitle color9'>
                      école doctorale polytechnique
                    </div>
                    <div className='schoolshowimg'>
                      <img src="https://adminsite.inphb.app/Imagessiteprincipal/ecoles/backedp.jpg" alt='EDP INP-HB' />
                    </div>
                  </Link>
                </div>
                <div className='schoolshow'>
                  <Link to='#' target='_self'>
                    <div className='schoolshowtitle color10'>
                      E S A S
                    </div>
                    <div className='schoolshowimg'>
                      <img src="https://adminsite.inphb.app/Imagessiteprincipal/ecoles/backesas.jpg" alt='ESAS INP-HB' />
                    </div>
                  </Link>
                </div>
                <div className='schoolshow'>
                  <Link to='#' target='_self'>
                    <div className='schoolshowtitle color11'>
                      E D P S A P T
                    </div>
                    <div className='schoolshowimg'>
                      <img src="https://adminsite.inphb.app/Imagessiteprincipal/ecoles/backedpsapt.jpg" alt='EDPSAPT INP-HB' />
                    </div>
                  </Link>
                </div>
              </div>

                {/* POUR LES FORMATION  */}

              <div className='newscourseshow'>
                <div className='newscourseshowsearch'>
                  <input
                    type="text"
                    placeholder="Rechercher une formation..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className={classes.searchInput}
                  />
                </div>
                <div className='newscourseshowtitle'>Nos Formations</div>
                <div className='newscourseshowfiilieres'>
                  {displayFilieres.length > 0 ? (
                    displayFilieres.map(filiere => (
                      <div className='newscourseshowfiiliere' onClick={() => openModal(filiere)}>
                        <div className='newscourseshowfiiliereschool'>entité : {filiere.codeEcole}</div>
                        <div className='newscourseshowfiilierename'>{filiere.filiere}</div>
                      </div>
                    ))
                  ) : (
                    <div className={classes.noResults}>Aucun résultat trouvé</div>
                  )}
                </div>
              </div>
            </div>
            <div>Les centres</div>
            <ModalFiliereecole
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              filiere={selectedFiliere}
            />
          </div>
          <FooterPortailConcours />
        </Transitions>
      </div>
    </>
  );
}

export default EcolesAccueil;
