import styled from 'styled-components'
import '../../utils/style/index.scss'
import HeaderConcours from '../../components/HeaderConcours'
import FooterPortailConcours from '../../components/FooterPortailConcours'
import { Outlet } from 'react-router-dom'
import HomeFlot from '../../components/HomeFlot'
import { ChangeLanguage } from '../../components/ChangeLanguage'


const ConcoursWrapper = styled.div`
    overflow: hidden;
`
function Concours() {
    return (
        <ConcoursWrapper>
            <HeaderConcours />
            <HomeFlot />
            <ChangeLanguage/>
            <Outlet />
            <FooterPortailConcours />
        </ConcoursWrapper>
    )
}

export default Concours
