import styled from 'styled-components'
import HeaderPortail from '../../components/HeaderPortail'
import FooterPortailConcours from '../../components/FooterPortailConcours'
import { Outlet } from 'react-router-dom'
import HomeFlot from '../../components/HomeFlot'
import { ChangeLanguage } from '../../components/ChangeLanguage'
import classes from '../Newplaceholder/newplace.module.css'
import Newmenutop from '../Newmenutop/Newmenutop'

const PortailWrapper = styled.div`
    overflow: hidden;
`
function Portail() {
    return (
        <>
            <div className={classes.container}>
                <Newmenutop />
                <PortailWrapper>
                    <HeaderPortail />
                    <HomeFlot />
                    <ChangeLanguage />
                    <Outlet/>
                    <FooterPortailConcours />
                </PortailWrapper>
            </div>
        </>
    )
}

export default Portail
