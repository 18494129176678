import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { FaMailBulk, FaPhone } from 'react-icons/fa'
import Travailler from '../../assets/Portail/secondbg.jpg'
import styled from 'styled-components'
import { FcBusinesswoman } from 'react-icons/fc'
import { Transitions } from '../../components/Transitions'

const MyCard = styled(Card)`
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    @media (max-width: 590px) {
        font-size: 0.9em;
    }
`
const MyCol = styled(Col)`
    @media (max-width: 475px) {
        flex-basis: 80% !important;
        margin-bottom: 10px;
    }
    @media (max-width: 330px) {
        flex-basis: 90% !important;
    }
`
const Title = styled.div`
    &::before {
        content: '.';
        font-size: 4em;
        color: #0DBA1E;
    }
`
const TitleDiv = styled.div`
width: 100%;
background-color: rgb(0, 141, 54);
`
export const TravaillerIci = () => {
    return (
        <Transitions>
            <TitleDiv className="d-flex justify-content-center align-items-center text-light mb-5">
                <span
                    className="body-highlight mt-3 text-uppercase ps-2 mb-3 fs-1"
                    style={{ borderLeft: '10px solid #fff' }}
                >
                    Travailler à l'inp-hb
                </span>
            </TitleDiv>
            <Container className="mt-5 mb-2">
                <img
                    className="mb-3"
                    src={Travailler}
                    alt=""
                    style={{ width: '100%' }}
                />
                <div className="p-3 text-start body-text border-0 m-0">
                    <Title>Postes administratifs et techniques (appels internes)</Title>
                    <Title>Postes administratifs et techniques (appels externes)</Title>
                    <Title>Postes académiques (Enseignants fonctionnaires et vacataires)</Title>
                    <Title>Postes Scientifiques</Title>
                    <Title>Postes pour élèves diplômés de l’INP-HB</Title>
                </div>
                <p className='fw-bold body-text border-0'>Les candidatures spontanées à un emploi ou stage à l’INP-HB pour des postes administratifs et techniques peuvent être adressées à la Direction des Ressources humLes candidatures spontanées à un emploi ou stage à l’INP-HB pour des postes administratifs et techniques peuvent être adressées à la Direction des Ressources humaines.</p>
            </Container>
            <Row className="d-flex flex-wrap align-items-center justify-content-center mt-5 mb-5">
                <p className="text-center fw-bold text-uppercase">
                    Direction des Ressources Humaines
                </p>
                <MyCol xxl={4} xl={5} lg={6} md={8} sm={10} xs={12}>
                    <MyCard
                        className="d-flex flex-row align-items-center justify-content-evenly p-2"
                        style={{
                            width: '100%',
                            height: '100%',
                            border: '0px',
                        }}
                    >
                        <div>
                        <div
                                style={{
                                    borderRadius: '50%',
                                    backgroundColor: '#E6E6E6',
                                }}
                                className="p-2"
                            >
                                <FcBusinesswoman style={{ fontSize: '4.5em' }} />
                            </div>
                            <p className="mb-3 mt-2 text-center">Mme Koné</p>
                        </div>
                        <div className="d-flex flex-column align-items-start justify-content-center ms-2">
                            <div className="text-start">
                                <FaPhone style={{ color: '#0DBA1E' }} /> (00225)
                                08518979 / 08078218 / 77233278
                            </div>
                            <div className="mt-3 text-start">
                                <FaMailBulk style={{ color: '#0DBA1E' }} />{' '}
                                ressourceshumaines@inphb.ci
                            </div>
                        </div>
                    </MyCard>
                </MyCol>
            </Row>
        </Transitions>
    )
}
