import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { FaPhone } from 'react-icons/fa'
import { FcBusinessman, FcBusinesswoman } from 'react-icons/fc'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { Transitions } from '../../components/Transitions'

const MyCard = styled(Card)`
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    @media (max-width: 590px) {
        font-size: 0.9em;
    }
`
const MyCol = styled(Col)`
    @media (max-width: 475px) {
        flex-basis: 80% !important;
        margin-bottom: 10px;
    }
    @media (max-width: 330px) {
        flex-basis: 90% !important;
    }
`
export const BureauDesEtudiants = () => {
    return (
        <Transitions>
            <div className="text-dark text-start ms-4 my-2 d-flex">
               <NavLink to='/'>Vie à l'INP-HB </NavLink> | Bureau Des Elèves 
            </div>
            <Container>
                <div
                    style={{ backgroundColor: '#E6E6E6' }}
                    className="p-3 py-4 text-justify text-center fs-3 body-text border-0"
                >
                    Présentation des activités du Bureau Des Elèves
                </div>
                <div className="mb-5 mt-5">
                    <p className="body-text border-0 mb-2">
                    Créé sous l’impulsion de Dr Moussa Diaby, Directeur Général de l’Institut, 
                    le « Bureau Des Elèves de l’INP-HB (BDE INP-HB) est une association d’élèves de 
                    l’Institut National Polytechnique Félix Houphouët-Boigny ayant pour dénomination 
                    « Bureau Des Elèves de l’INP-HB » ayant pour objectif de favoriser la pratique d’activités socioéducatives 
                    en vue d’accroitre la connaissance générale des élèves et faciliter leur intégration socio-professionnelle. 
                    Le BDE INP-HB n’est pas une association à vocation syndicale, religieuse et politique. Le BDE a diverses missions :
                    </p>
                    <p
                        className="body-text border-0 mt-3"
                        style={{ color: '#0DBA1E' }}
                    >
                        {' '}
                        Il accueille et accompagne les nouveaux venus
                    </p>
                    <p className="body-text border-0 mb-2">
                    Les membres du BDE sont aussi chargés de l’accueil des nouveaux élèves sur le campus universitaire. 
                    Ils œuvrent à leur insertion et à leur épanouissement. Le contact entre eux s’établit en général à 
                    l’occasion des journées carrières ou journées portes ouvertes.
                    Tout au long de l’année, le BDE conseille et accompagne les étudiants lorsqu’ils ont des questions 
                    sur les associations de l’école ou sur les activités extra-scolaires par exemple.
                    <br/>
                    </p>
                    <p
                        className="body-text border-0 mt-3"
                        style={{ color: '#0DBA1E' }}
                    >
                        {' '}
                        Il participe à l’animation du campus
                    </p>
                    <p className="body-text border-0 mb-2">
                    Le BDE est responsable de la vie étudiante de l’Ecole. A cet effet il organise en collaboration avec certaines entités de l’Institut des activités qui permettent aux élèves de se divertir mais également d’acquérir des connaissances nouvelles. Au nombre de ces activités figurent des tournois sportifs, des soirées dansantes, des journées culturelles, des conférences thématiques, des journées portes ouvertes aux entreprises. Pour la réussite de ces différents évènements qui rythment l’année universitaire, le BDE est parfois amené à démarcher et à rechercher des sponsors.
                    </p>
                    <p
                        className="body-text border-0 mt-3"
                        style={{ color: '#0DBA1E' }}
                    >
                        {' '}
                        Siège
                    </p>
                    <p className="body-text border-0 mb-2">
                        Le siège du BDE INP-HB est situé à Yamoussoukro, à
                        l’INP-HB Centre. Il peut être transféré par décision du
                        Comité Extrascolaire sur tout autre site de l’INP-HB.
                    </p>
                    <p
                        className="body-text border-0 mt-3"
                        style={{ color: '#0DBA1E' }}
                    >
                        {' '}
                        Durée
                    </p>
                    <p className="body-text border-0 mb-2">
                        La durée du BDE INP-HB est illimitée à compter de la
                        date de création. Il peut être mis fin à ses activités
                        par la Direction Générale sur proposition du Comité
                        Extrascolaire.
                    </p>
                </div>
                <p className="mt-5 text-start fw-bold text-uppercase">
                    LISTE DES MEMBRES DU BUREAU PRINCIPAL
                </p>
                <Row className="d-flex flex-wrap align-items-center justify-content-center mb-3">
                    <MyCol
                        xxl={3}
                        xl={3}
                        lg={4}
                        md={5}
                        sm={6}
                        xs={6}
                        className="mt-5"
                    >
                        <MyCard
                            className="d-flex flex-column align-items-center justify-content-center p-2"
                            style={{
                                width: '100%',
                                height: '100%',
                                border: '0px',
                            }}
                        >
                            <div
                                style={{
                                    borderRadius: '50%',
                                    backgroundColor: '#E6E6E6',
                                }}
                                className="p-2"
                            >
                                <FcBusinesswoman style={{ fontSize: '4.5em' }} />
                            </div>
                            <p className="mb-3 mt-2 text-center">
                                Mlle TAPE NASSOU ODETTE-OPHIR
                            </p>
                            <div className="mb-3 text-center">
                                <FaPhone style={{ color: '#0DBA1E' }} /> (00225)
                                    07 78 70 18 08
                            </div>
                            <div className="mb-5 text-center fw-bold">
                                Présidente
                            </div>
                        </MyCard>
                    </MyCol>
                    <MyCol
                        xxl={3}
                        xl={3}
                        lg={4}
                        md={5}
                        sm={6}
                        xs={6}
                        className="mt-5"
                    >
                        <MyCard
                            className="d-flex flex-column align-items-center justify-content-center p-2"
                            style={{
                                width: '100%',
                                height: '100%',
                                border: '0px',
                            }}
                        >
                            <div
                                style={{
                                    borderRadius: '50%',
                                    backgroundColor: '#E6E6E6',
                                }}
                                className="p-2"
                            >
                                <FcBusinessman style={{ fontSize: '4.5em' }} />
                            </div>
                            <p className="mb-3 mt-2 text-center">
                                M EBY ALEX WILFRIED
                            </p>
                            <div className="mb-3 text-center">
                                <FaPhone style={{ color: '#0DBA1E' }} /> (00225)
                                     07 49 28 30 07
                            </div>
                            <div className="mb-5 text-center fw-bold">
                                Vice-Président
                            </div>
                        </MyCard>
                    </MyCol>
                    <MyCol
                        xxl={3}
                        xl={3}
                        lg={4}
                        md={5}
                        sm={6}
                        xs={6}
                        className="mt-5"
                    >
                        <MyCard
                            className="d-flex flex-column align-items-center justify-content-center p-2"
                            style={{
                                width: '100%',
                                height: '100%',
                                border: '0px',
                            }}
                        >
                            <div
                                style={{
                                    borderRadius: '50%',
                                    backgroundColor: '#E6E6E6',
                                }}
                                className="p-2"
                            >
                                <FcBusinessman
                                    style={{ fontSize: '4.5em' }}
                                />
                            </div>
                            <p className="mb-3 mt-2 text-center">
                                M N'DRI NAVIE KOFFI D.
                            </p>
                            <div className="mb-3 text-center">
                                <FaPhone style={{ color: '#089BD9' }} /> (00225)
                                07 57 96 34 50
                            </div>
                            <div className="mb-5 text-center fw-bold">
                                Secrétaire Général
                            </div>
                        </MyCard>
                    </MyCol>
                    <MyCol
                        xxl={3}
                        xl={3}
                        lg={4}
                        md={5}
                        sm={6}
                        xs={6}
                        className="mt-5"
                    >
                        <MyCard
                            className="d-flex flex-column align-items-center justify-content-center p-2"
                            style={{
                                width: '100%',
                                height: '100%',
                                border: '0px',
                            }}
                        >
                            <div
                                style={{
                                    borderRadius: '50%',
                                    backgroundColor: '#E6E6E6',
                                }}
                                className="p-2"
                            >
                                <FcBusinessman
                                    style={{ fontSize: '4.5em' }}
                                />
                            </div>
                            <p className="mb-3 mt-2 text-center">
                                M ASSE KOUASSI JUNIOR
                            </p>
                            <div className="mb-3 text-center">
                                <FaPhone style={{ color: '#089BD9' }} /> (00225)
                                01 40 71 39 76
                            </div>
                            <div className="mb-5 text-center fw-bold">
                                Secrétaire Général Adjoint
                            </div>
                        </MyCard>
                    </MyCol>
                    <MyCol
                        xxl={3}
                        xl={3}
                        lg={4}
                        md={5}
                        sm={6}
                        xs={6}
                        className="mt-5"
                    >
                        <MyCard
                            className="d-flex flex-column align-items-center justify-content-center p-2"
                            style={{
                                width: '100%',
                                height: '100%',
                                border: '0px',
                            }}
                        >
                            <div
                                style={{
                                    borderRadius: '50%',
                                    backgroundColor: '#E6E6E6',
                                }}
                                className="p-2"
                            >
                                <FcBusinessman
                                    style={{ fontSize: '4.5em' }}
                                />
                            </div>
                            <p className="mb-3 mt-2 text-center">
                                M N'GUESSAN LIANE CAMILLE
                            </p>
                            <div className="mb-3 text-center">
                                <FaPhone style={{ color: '#089BD9' }} /> (00225)
                                +225 01 73 14 40 40
                            </div>
                            <div className="mb-5 text-center fw-bold">
                                Secrétaire à l'Organisation
                            </div>
                        </MyCard>
                    </MyCol>
                    <MyCol
                        xxl={3}
                        xl={3}
                        lg={4}
                        md={5}
                        sm={6}
                        xs={6}
                        className="mt-5"
                    >
                        <MyCard
                            className="d-flex flex-column align-items-center justify-content-center p-2"
                            style={{
                                width: '100%',
                                height: '100%',
                                border: '0px',
                            }}
                        >
                            <div
                                style={{
                                    borderRadius: '50%',
                                    backgroundColor: '#E6E6E6',
                                }}
                                className="p-2"
                            >
                                <FcBusinessman
                                    style={{ fontSize: '4.5em' }}
                                />
                            </div>
                            <p className="mb-3 mt-2 text-center">
                                M ELIAM YVES HERMAS ETTIEN
                            </p>
                            <div className="mb-3 text-center">
                                <FaPhone style={{ color: '#089BD9' }} /> (00225)
                                05 54 65 57 82
                            </div>
                            <div className="mb-5 text-center fw-bold">
                                Secrétaire à l'Organisation adjoint
                            </div>
                        </MyCard>
                    </MyCol>
                    <MyCol
                        xxl={3}
                        xl={3}
                        lg={4}
                        md={5}
                        sm={6}
                        xs={6}
                        className="mt-5"
                    >
                        <MyCard
                            className="d-flex flex-column align-items-center justify-content-center p-2"
                            style={{
                                width: '100%',
                                height: '100%',
                                border: '0px',
                            }}
                        >
                            <div
                                style={{
                                    borderRadius: '50%',
                                    backgroundColor: '#E6E6E6',
                                }}
                                className="p-2"
                            >
                                <FcBusinesswoman
                                    style={{ fontSize: '4.5em' }}
                                />
                            </div>
                            <p className="mb-3 mt-2 text-center">
                                Mlle CISSOUMA KARIDJATOU
                            </p>
                            <div className="mb-3 text-center">
                                <FaPhone style={{ color: '#089BD9' }} /> (00225)
                                01 51 13 37 91
                            </div>
                            <div className="mb-5 text-center fw-bold">
                                Trésorière Générale
                            </div>
                        </MyCard>
                    </MyCol>
                    <MyCol
                        xxl={3}
                        xl={3}
                        lg={4}
                        md={5}
                        sm={6}
                        xs={6}
                        className="mt-5"
                    >
                        <MyCard
                            className="d-flex flex-column align-items-center justify-content-center p-2"
                            style={{
                                width: '100%',
                                height: '100%',
                                border: '0px',
                            }}
                        >
                            <div
                                style={{
                                    borderRadius: '50%',
                                    backgroundColor: '#E6E6E6',
                                }}
                                className="p-2"
                            >
                                <FcBusinessman
                                    style={{ fontSize: '4.5em' }}
                                />
                            </div>
                            <p className="mb-3 mt-2 text-center">
                                M TAPE LOROUGNON EPHRAHIM
                            </p>
                            <div className="mb-3 text-center">
                                <FaPhone style={{ color: '#089BD9' }} /> (00225)
                                +225 07 78 70 17 46
                            </div>
                            <div className="mb-5 text-center fw-bold">
                                Trésorier Général Adjoint
                            </div>
                        </MyCard>
                    </MyCol>
                </Row>
            </Container>
        </Transitions>
    )
}
