import styled from 'styled-components'
import { NavLink, useParams } from 'react-router-dom'
import '../../utils/style/index.scss'
import { Container } from 'react-bootstrap'
import { useState } from 'react'
import { Transitions } from '../../components/Transitions'

// const DepartementWrapper = styled.div`
//     overflow: hidden;
//     background: #fff;
// `
const DepartementMenuWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
    width: 100%;
    background-color: rgba(0, 141, 54, 0.75) !important;
`
const DepartementMenuItems = styled.div`
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin-left: 5%;
    @media (max-width: 580px) {
        width: 95%;
    }
    @media (max-width: 493px) {
        width: 92%;
    }
    @media (max-width: 341px) {
        width: 95%;
    }
`
const DepartementMenuItem = styled.div`
    background-color: ${(prop) => prop.color};
    color: ${(prop) => prop.textColor};
    border-radius: 30px;
    padding: 0.7em;
    font-size: 0.8em;
    cursor: pointer;
    width: 10em;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    transition: all 2ms ease-in-out;
    text-decoration: none;
    &:hover {
        color: #fff;
        background-color: #008D36;
    }
    @media (max-width: 493px) {
        padding: 0.6em;
        font-size: 0.7em;
        width: 9em;
    }
    @media (max-width: 420px) {
        padding: 0.4em;
        font-size: 0.6em;
        width: 9em;
    }
    @media (max-width: 375px) {
        padding: 0.4em;
        font-size: 0.6em;
        width: 8em;
        border-radius: 25px;
    }
`
const Myimg = styled.img`
    width: 100%;
`

const Departements = {
    agriculture_et_ressources_animales: {
        nom: 'Agriculture et Resources Animales',
        menus: [
            {
                nom: 'Présentation',
                contenu: {
                    text: (
                        <>
                            Le Département de Formation et de Recherches
                            Agriculture et Ressources Animales (DFR-ARA)
                            regroupe les ressources humaines des Sciences de
                            l’agronomie et Productions Végétales, de la
                            phytopathologie et Biologie Végétale, de la zoologie
                            Agricole et Entomologie et de la zootechnie et
                            Productions Animales. Le DFR ARA est localisé sur le
                            site de l'INP-HB Nord). <br /> <br />
                            Les missions du DFR-ARA sont la Formation, la
                            Recherche et l'Expertise en matière d’Agriculture
                            précisément dans les domaines des productions
                            végétales et animales ainsi que les facteurs
                            environnementaux de natures scientifique, technique,
                            humaine, sociale, économique, qui président à son
                            existence et conditionnent son évolution. Pour
                            réaliser avec efficacité ces missions, le DFR-ARA
                            dispose de 35 enseignants-chercheurs dont 2 Maîtres
                            de Conférences, 6 Maîtres- Assistants, 3 Chargés de
                            Recherche et 17 Assistants. Ce personnel enseignant
                            dispense des cours dans 3 écoles de l’INP-HB :<br />{' '}
                            <br />
                            <strong> Ecole Supérieure d’Agronomie (ESA)</strong>
                            <strong>
                                Classes préparatoires biologie (CPGE)
                            </strong>
                            <strong>
                                École de formation continue et de
                                perfectionnement des cadres (EFCPC)
                            </strong>
                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Organisation',
                contenu: {
                    text: (
                        <>
                            Le DFR-ARA fonctionne selon un organigramme dont les
                            articulations principales sont trois Sections
                            (Ingénierie Pédagogique ; Recherche, Coopération et
                            Information Scientifique et Technologique ;
                            Production, Projets et Expertise) et quatre
                            laboratoires (Agronomie et Productions Végétales ;
                            Phytopathologie et Biologie Végétale ; Zoologie
                            Agricole et Entomologie ; Zootechnie et Productions
                            Animales).
                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Recherche',
                contenu: {
                    text: (
                        <>
                            Les laboratoires, au sein du DFR-ARA, donnent un
                            appui à la formation, par des travaux pratiques et
                            travaux de fin d’études d’étudiants. Ils servent de
                            cadre d’activités de recherches qui abordent de
                            nombreux thèmes dont les principaux sont indiqués
                            ci-dessous :
                            <strong>
                                {' '}
                                Laboratoire de Phytopathologie et Biologie
                                Végétale(LPBV)
                            </strong>{' '}
                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Thèmes',
                contenu: {
                    text: (
                        <>
                            <div className="vert">
                                pourriture brune des cabosses de cacaoyers en
                                Côte d’Ivoire ;
                            </div>
                            <div className="vert">
                                fusariose vasculaire du cotonnier en Côte
                                d’Ivoire ;
                            </div>

                            <div className="vert">
                                facteurs de dégradation de la qualité des
                                produits destinés à l’exportation (fèves de
                                cacao, banane dessert, papaye, mangue,
                                ananas..);
                            </div>
                            <div className="vert">
                                Microbiologie et écologie des sols (diversité
                                des champignons dans différents types
                                d’utilisation des sols en Côte-d’Ivoire) ;
                            </div>
                            <div className="vert">
                                viroses des plantes cultivées en Côte d’Ivoire ;
                            </div>
                            <div className="vert">
                                tests d’efficacité de produits fongicides en
                                Côte d’Ivoire ;
                            </div>
                            <div className="vert">
                                effets d’extraits de plantes, comme alternative
                                à la lutte chimique contre les maladies des
                                cultures ;
                            </div>
                            <div className="vert">
                                tests d’efficacité d’herbicides en Côte d’Ivoire
                                ;
                            </div>
                            <div className="vert">
                                étude des adventices des principales cultures
                                d’exportation.
                            </div>
                        </>
                    ),
                    image: '',
                },
            },
        ],
    },
    batiment_et_urbanisme: {
        nom: 'BATIMENT ET URBANISME',
        menus: [
            {
                nom: 'Présentation',
                contenu: {
                    text: (
                        <>
                            Le Département de Formation et de Recherche Bâtiment et Urbanisme (DFR-BU), à l’instar des autres départements de l’INP-HB, est issu du décret N° 96 – 678 du 4 septembre 1996 portant création de L’Institut National Polytechnique Félix HOUPHOUET-BOIGNY (INP-HB) déterminant ses attributions, son organisation et son fonctionnement.
                            Le Département « Bâtiment et Urbanisme » est une structure d’enseignement et de recherche, de production ou de service organisée autour des champs disciplinaires couvrant le secteur professionnel du bâtiment, de l’urbanisme, de l’aménagement ou de disciplines connexes.
                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Missions',
                contenu: {
                    text: (
                        <>
                            <strong>Missions de formation</strong> <br />

                            Nos missions de formations s’inscrivent, en cycle Technicien Supérieur, d’Ingénieur des Techniques ou en cycle Ingénieur de Conception, dans le domaine des Sciences et Technologies. Elles permettent aux étudiants des Ecoles bénéficiaires de nos interventions, d’acquérir un parcours cohérent autour du génie civil, du génie urbain et de l’environnement, parcours à vocation professionnelle (entreprises, collectivités territoriales, bureaux d’études, …) et bientôt de recherche avec le système LMD.
                            <br />
                            <br />

                            <strong>Missions de recherche</strong> <br />

                            Depuis 1997, nos missions de recherche centrées sur les matériaux de construction tel que le Projet « PELMACO » (Promotion des Entreprises Locales de Matériaux de Construction) se poursuivent de façon diversifiée. La Tuile en Mortier Vibré (TMV), produite au Laboratoire de Matériaux jusqu’à une certaine époque récente, en est un exemple parmi tant d’autres.
                            De nouvelles thématiques autour de la gestion urbaine ou de la création urbaine, de l’aménagement durable, de l’habitat seront explorées pour appuyer le développement de nos collectivités territoriales (villages, mairies, régions, districts, etc.).
                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: "Domaines d'intervention",
                contenu: {
                    text: (
                        <>
                            <strong>Etudes</strong><br />

                            <div className='vert'>Etudes architecturales</div>
                            <div className='vert'>Etudes Techniques</div>
                            <div className='vert'>Etudes d’urbanisme</div>
                            <div className='vert'>Etude de faisabilité</div>
                            <div className='vert'>Laboratoires de matériaux (essai sur les matériaux de construction)</div>
                            <div className='vert'>Laboratoire physique de physique du bâtiment</div>
                            <br />
                            <div className='vert'> Assistance Technique</div>
                            <div className='vert'> Conduite de projet</div>
                            <div className='vert'> Partenariat d’études de projet</div>
                            <div className='vert'> Elaboration du dépouillement d’appels d’offres</div>
                            <div className='vert'> Direction et contrôle des travaux</div>
                            <div className='vert'> Réalisation de maquettes</div>
                            <div className='vert'> Planification et gestion de l’entretien de patrimoine</div>
                            <div className='vert'>Gestion des équipements urbains</div>
                            <div className='vert'>Gestion de l’environnement et du cadre de vie</div>
                            <div className='vert'> Gestion des ordures ménagères</div>
                            <div className='vert'> Gestion d’activités promotionnelles</div>
                            <br />
                            <div className='vert'>Expertise et Production</div>
                            <div className='vert'>Evaluations immobilières et foncières</div>
                            <div className='vert'>Promotion immobilière</div>
                            <div className='vert'>Analyse des coûts et charges d’Exploitation immeubles</div>
                            <div className='vert'>Montage coordination d’opérations en réhabilitation</div>
                            <div className='vert'>Gestion de syndic</div>
                            <div className='vert'>Préfabrication légère de Matériaux de construction ou parties d’ouvrages (tuiles, corps        etc.)</div>
                            <div className='vert'>Préfabrication légère d’Eléments d’aménagement d’espaces (Pavés, dalettes, etc.)</div>

                        </>
                    ),
                    image: '',
                },
            },
        ],
    },
    finance_comptabilite_droit: {
        nom: 'FINANCE – COMPTABILITE - DROIT (DFR-FCD)',
        menus: [
            {
                nom: 'Présentation',
                contenu: {
                    text: (
                        <>
                            Le Département de Formation et de Recherche Finance-Comptabilité, localisé géographiquement sur le site de l’INPHB-Centre, anime plus de 50% des cours dispensés à l’Ecole Supérieure de Commerce et d’Administration des Entreprises (ESCAE) et intervient dans une proportion moindre dans les6 autres écoles de l’Institut National Polytechnique Félix Houphouët- Boigny de Yamoussoukro.22 enseignants et 47 vacataires favorisent le renforcement des liens avec le monde économique. La rigueur académique et la pertinence pratique des enseignements dans des domaines aussi variés que la comptabilité, le contrôle de gestion, la fiscalité, le droit et les assurances ont permis à l’INP-HB d’insérer plusieurs promotions d’étudiants dans le tissu socio professionnel national et international depuis bientôt 15 ans.

                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Missions',
                contenu: {
                    text: (
                        <>
                            Les missions assignés au Département de Formation et de Recherche FINANCE – COMPTABILITE – DROIT (DFR-FCD) sont trois (3) ordres : <br />
                            <div className='vert'>Formation (cours, séminaires… encadrements en cycles DUT et en cycles INGENIEUR) ;</div>
                            <div className='vert'>Recherche ;</div>
                            <div className='vert'>Production</div>
                        </>
                    ),
                    image: '',
                },
            },
        ],
    },
    eaux_foret_et_environnement: {
        nom: 'EAUX, FORÊT ET ENVIRONNEMENT(DFR-FOREN)',
        menus: [
            {
                nom: 'Présentation',
                contenu: {
                    text: (
                        <>
                            Le Département de Formation et de Recherche Eaux, Forêts et Environnement (DFR-FOREN) est l’un des 14 départements que compte l'Institut National Polytechnique Houphouët-Boigny (INP-HB). Il est localisé sur le site de l’INP-NORD, il est dirigé par un
                            <br />
                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Organisation',
                contenu: {
                    text: (
                        <>
                        <div className='vert'>Pour mener à bien ses missions, le personnel du département est regroupé au sein des organes suivants :</div>
                        <div className='vert'>Le Conseil Département, organe suprême de décision. Il délibère sur toutes les questions        relatives au fonctionnement et à la vie du département ;</div>
                        <div className='vert'>La cellule pédagogique dont le rôle est de procéder à la répartition et au suivi des cours        dispensés dans les différentes écoles, d’organiser les stages et les soutenances de projets        de fin d’études, de s’assurer de la qualité des enseignements dispensés.</div>
                        <div className='vert'>la cellule recherche et développement, dont le rôle est de définir la politique de recherche et        de coordonner les activités de recherche des différentes équipes de recherche;</div>
                        <div className='vert'>d’une cellule projet dont le rôle est d’organiser les activités de production, de rechercher des     partenariats de production avec les industriels, de suivre l’exécution des projets, de rechercher        des financements pour la recherche</div>
                        <div className='vert'>d’un service social, dont le rôle est de contribuer à la résolution des problèmes liés aux cas        sociaux, de gérer la mutuelle du département, d’organiser les activités liées aux loisirs et à        l’entraide                            <br /></div>
                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Missions',
                contenu: {
                    text: (
                        <>
                            Le département FOREN a trois principales missions, à savoir, la formation, la recherche et l’expertise. <br />
                            <strong>Formation</strong>
                            le DFR-FOREN est impliqué dans la formation initiale des ingénieurs des eaux et forêts (IEF) et des ingénieurs des techniques des eaux et forêts (ITEF). Les champs d’intervention des enseignements sont: la pédologie, la climatologie, la foresterie, l’environnement, la faune, la pisciculture et les ressources en eau. Le DFR-FOREN assure également la formation continue des agents de l’État dans le domaine de la foresterie, de l’environnement et de la pédologie.
                            <br />
                            <br />
                            <strong>Formation</strong>
                            le DFR mène des activités de recherches dans les différents champs disciplinaires et les domaines de compétence de son personnel enseignants : foresterie, agroforesterie, environnement, pédologie, bioclimatologie et production halieutique.
                            <br />
                            <br />
                            <strong>Expertise et Conseil</strong>
                            cette mission se développe dans un cadre de collaborations avec des partenaires extérieurs (exploitants forestiers, industriels du bois, administration et entreprises publiques et organisations internationales). Dans le cadre de cette expertise, le département mène les activités suivantes :
                            <div className='vert'>les activités d’analyse de sol et de végétaux</div>
                            <div className='vert'>les activités de production de cartes thématiques (carte de végétation, carte des sols)</div>
                            <div className='vert'>Les activités de production d’alevins et de poissons de manière intensive</div>
                            <div className='vert'>les activités de production et de conservation de semences de bonne qualité et des plants        (pépinières) d’espèces forestières</div>
                            <div className='vert'>Analyse et modélisation de la dynamique forestière.</div>
                        </>

                    ),
                    image: '',
                },
            },
        ],
    },
    genie_chimique_et_agroalimentaire: {
        nom: 'GENIE CHIMIQUE ET AGROALIMENTAIRE',
        menus: [
            {
                nom: 'Présentation',
                contenu: {
                    text: (
                        <>
                            Le Département de Formation et de Recherche Génie Chimique et Agroalimentaire (DFR-GCAA) fait partie des 14 départements que compte l’Institut National Polytechnique Houphouët-Boigny (INP-HB) de Yamoussoukro. Il regroupe les ressources humaines des disciplines du génie chimiques et du génie alimentaire. Il est localisé sur les trois sites (Sud, Centre, Nord) de l’INP-HB.
                            Sur le site Sud (ex-ENSTP), le DFR-GCAA dispose d’un bâtiment abritant deux salles où ont lieu, les travaux pratiques (TP) de chimie générale, destinés aux élèves des classes préparatoires aux concours d’entrée dans les écoles d’ingénieurs.<br />{' '}
                            <br />
                            </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Organisation',
                contenu: {
                    text: (
                        <>
                           Pour mener à bien ses missions le département dispose des organes suivants:
                        <div className='vert'>Conseil Département, organe suprême de décision. Il délibère sur toutes les questions relatives au fonctionnement et à la vie du département ;</div> 
                        <div className='vert'>Service finances et achats, dont le rôle est de gérer les ressources financières, de l’exécution et l’engagement des achats, d’établir les factures etc.;</div> 
                        <div className='vert'>Service social, dont le rôle est de contribuer à la résolution des problèmes liés aux cas sociaux, de gérer la mutuelle du département, d’organiser les activités liées aux loisirs et à l’entraide ;</div> 
                        <div className='vert'>Cellule recherche et développement, dont le rôle est de définir la politique de recherche et de coopération internationale, de coordonner les activités de recherche des différentes unités de recherche, de rechercher des financements pour la recherche ;</div> 
                        <div className='vert'>Cellule pédagogique dont le rôle est de procéder à la répartition et au suivi des cours dispensés dans les différentes écoles, d’organiser les stages et les soutenances de projets de fin d’études, de s’assurer de la qualité des enseignements dispensés ;</div> 
                        <div className='vert'>Cellule production dont le rôle est d’organiser les activités de production, de rechercher des partenariats de production avec les industriels, de suivre l’exécution des projets.</div> 
                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Missions',
                contenu: {
                    text: (
                        <>
                            Les missions assignées au département sont de trois ordres :
                        <div className='vert'>Formation</div> 
                        <div className='vert'>Recherche</div> 
                        <div className='vert'>Production</div> 
                            Pour réaliser avec efficacité ces missions, le DFR-GCAA dispose de 30 enseignants- chercheurs dont 2 professeurs Titulaires, 7 Maîtres de Conférences, 4 Maîtres Assistants, 17 Assistants. Ce personnel enseignant dispense des cours dans les différentes écoles de l’INP-HB que sont :
                        <div className='vert'>L’École supérieure d’Industrie (ESI)</div> 
                        <div className='vert'>L’Ecole Supérieure d’Agronomie (ESA)</div> 
                        <div className='vert'>L’Ecole Supérieure de Commerce et d’Administration des Entreprises (ESCAE)</div> 
                        <div className='vert'>L’Ecole Supérieure des Travaux Publics (ESTP)</div> 
                        <div className='vert'>L’École Supérieures des Mines et Géologie (ESMG)</div> 
                        <div className='vert'>L’École de formation continue et de perfectionnement des cadres (EFCPC)</div> 
                        <div className='vert'>L’Ecole Doctorale Polytechnique (EDP)</div> 
                        <div className='vert'>L’École Préparatoire (CPGE)</div> 
                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Production',
                contenu: {
                    text: (
                        <>
                            Le DFR-GCAA dispose d’un personnel dont les compétences couvrent l’ensemble du domaine de la chimie et l’agroalimentaire. C’est un cas en unique en Côte d’Ivoire où il y a une aussi grande concentration de compétences. Ce département a acquis au fil des années un savoir-faire en matière d’analyse et traitement des eaux, aussi bien naturelles qu’industrielles.
                            Plusieurs conventions existent entre le Département et des entreprises de la place pour leur faire bénéficier de cette expertise.
                            Dans le domaine du diagnostic et de l’optimisation des procédés industriels, une équipe compétente apporte son concours aux entreprises de la place. Toute entreprise possédant des données accumulées sur une longue période, peut nous faire appel en vue de leur exploitation. Nous fournissons des modèles valides qui affranchissent de l’expérimentation nécessaire lorsque les paramètres du procédé viennent à être modifiés pour une raison ou une autre.
                            Lorsque des machines industrielles pour des raisons d’usures naturelles ne permettent plus d’avoir les rendements et la qualité recherchée, nous pouvons par l’utilisation des outils de la MSP faire un diagnostic et proposer des solutions durables.
                            Dans le domaine du perfectionnement des cadres, le département dispose de compétences requises pour la formation qualifiante dans le domaine des outils statistique, du génie chimique et des techniques d’analyse chimique (CPG, UV, HPLC, etc.). Des formations spécifiques (à la carte) au profit de petit personnel de laboratoire peuvent également être fournies. Ces formations peuvent se faire au sein de l’entreprise demandeus ou dans les locaux de l’INP-HB.
                            Un soutien peut encore être apporté aux établissements privés ne disposant pas de laboratoires pour la réalisation de travaux pratiques dans l cadre de formation de BTS et d’Ingénieurs.
                        </>
                    ),
                    image: '',
                },
            },
        ],
    },
    gestion_commerce_et_economie_appliquee: {
        nom: 'GESTION COMMERCE ET ECONOMIE APPLIQUEE',
        menus: [
            {
                nom: 'Présentation',
                contenu: {
                    text: (
                        <>
                            Le Département de Formation et de Recherche Gestion Commerce et Économie Appliquée (DFR-GCEA) fait partie des 15 Départements que compte l’INSTITUT NATIONAL POLYTECHNIQUE HOUPHOUET-BOIGNY (INP-HB) de YAMOUSSOUKRO.
                            Le DFR-GCEA est né en 1982 de la fusion des Départements Gestion Commerce et Économie Appliquée de l’ex INSET. Il regroupe des compétences complexes en Agroéconomie, en Économie Générale et en Gestion.
                            <br />
                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Organisation',
                contenu: {
                    text: (
                        <>
                            Le DFR-GCEA fonctionne avec 30 enseignants chercheurs dont 14 en section Agro économie 6 en section Gestion et 10 en section économie. Le Département fonctionne sur 3 sites : l’INPHB-Nord, l’INPHB-Centre et l’INPHB-Sud.
                            Animales).
                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Missions',
                contenu: {
                    text: (
                        <>
                            Les missions assignées au DFR-GCEA sont de 3 ordres :
                            <div className='vert'>Formation (cours, séminaires, encadrements en cycles DUT et en cycles Ingénieurs)</div> 
                            <div className='vert'>Recherche</div> 
                            <div className='vert'>Production</div> 
                        </>
                    ),
                    image: '',
                },
            },
        ],
    },
    genie_electrique_et_electronique: {
        nom: 'GENIE ÉLECTRIQUE ET ÉLECTRONIQUE',
        menus: [
            {
                nom: 'Présentation',
                contenu: {
                    text: (
                        <>
                            Doté d’une équipe pédagogique et d’une équipe administrative et technique expérimentées et performantes, le Département de Formation et de Recherche Génie Électrique et Électronique (DFR GEE) se présente comme l’un des principaux départements de l’INPHB.
                            Fort des compétences professionnelles de ses étudiants techniciens et ingénieurs formés acquièrent de solides connaissances scientifiques et techniques et un savoir-faire immédiatement opérationnel.
                            Le Département regroupe 48 enseignants-chercheurs et 8 agents techniques et administratifs sur les sites Centre et Sud de l’INPHB Yamoussoukro.
                            Ses domaines de formation de production et de recherche sont : la physique et l’instrumentation, l’électronique et le traitement des signaux, l’électrotechnique et les installations électriques industrielles, les télécommunications et réseaux, l’automatique et l’informatique industrielle.
                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Organisation',
                contenu: {
                    text: (
                        <>
                            Pour atteindre ses objectifs pédagogiques le DFR-GEE est organisé en 4 cellules qui sont chargées d’organiser et d’animer les activités pédagogiques :
                            <div className='vert'>La Cellule Pédagogique Électronique (9 enseignants)</div> 
                            <div className='vert'>La Cellule Pédagogique Électrotechnique (19 enseignants)</div> 
                            <div className='vert'>La Cellule Pédagogique Télécommunications (7 enseignants)</div> 
                            <div className='vert'>La Cellule Pédagogique Physique (13 enseignants)</div> 
                            <div className='vert'>Le département dispose d’une trentaine de salles de classes et d’une vingtaine de laboratoires et de salles de Travaux Pratiques.</div> 
                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Missions',
                contenu: {
                    text: (
                        <>
                            Le DFR GEE a pour missions essentielles de :
                            <div className='vert'>Former et perfectionner des ingénieurs et des techniciens pour l’industrie</div> 
                            <div className='vert'>Aider les entreprises sous forme d’expertise, de conseil, et d’étud</div> 
                            <div className='vert'>Développer la recherche appliquée et fondamentale</div> 
                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Pédagogie',
                contenu: {
                    text: (
                        <>
                            Les enseignants du DFR GEE participent à la formation initiale et continue des Ingénieurs et des Techniciens supérieurs des écoles de l’INPHB :
                            <div className='vert'>l’Ecole Supérieure d’Industrie (ESI) : 63,2%</div> 
                            <div className='vert'>l’École de Formation Continue et de Perfectionnement des Cadres (EFCPC) : 8%</div> 
                            <div className='vert'>l’Ecole Supérieure de Mines et de Géologie (ESMG) :1,5%</div> 
                            <div className='vert'>l’Ecole Supérieure des Travaux Publics (ESTP) : 0,05%</div> 
                            <div className='vert'>l’Ecole Supérieure d’agronomie (ESA) :0,25%$</div> 
                            <div className='vert'>l’École Préparatoire (EP) :27%</div> 
                            En moyenne, ce sont près de 18.000 heures de cours, travaux dirigés et travaux pratiques, et environ 4.000 heures d’encadrement de projets internes et de projets en industrie sont réalisés par les enseignants du DFR GEE.
                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Recherche',
                contenu: {
                    text: (
                        <>
                            Les enseignants-chercheurs du DFR GEE développent leurs activités de recherche dans des Unités de Recherche regroupées au sein du Laboratoire d’Électronique et d’Électricité Appliquées (LEEA) qui est l’un des sept laboratoires de l’INPHB.
                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Entreprise',
                contenu: {
                    text: (
                        <>
                        Le DFR GEE entretient une étroite collaboration avec les entreprises. Une Cellule Chargée des Relations Extérieures été récemment instaurée pour servir d’interface entre le département et les anciens étudiants mais également entre le département et les entreprises. <br />
                        A ce titre, le personnel qualifié et expérimenté du département se tient à la disposition de toute entreprise pour des travaux d’étude et de réalisations industrielles. <br /><br />

                        Le DFR GEE offre plusieurs atouts dans ce domaine. Outre les salles et laboratoires de Travaux Pratiques, il dispose de :
                        <div className='vert'>Un laboratoire de circuits imprimé pour la réalisation de cartes électroniques,</div>
                        <div className='vert'>Un atelier de bobinage de transformateurs et de moteurs électriques</div>
                        <div className='vert'>Un atelier de maintenance et de réalisation d’équipements et d’armoires électriques</div>
                        <div className='vert'>Un atelier de maintenance électronique (postes téléviseurs et radios, vidéo, …) et informatique (ordinateurs PC et portables).</div>
                        <div className='vert'>D’autre part, la collaboration avec les entreprises se matérialise par des actions telles que :</div>
                        <div className='vert'>La participation de responsables d’entreprises au Conseil de département,</div>
                        <div className='vert'>L’existence d’un partenariat avec certaines entreprises pour l’équipement des laboratoires du département et pour des travaux communs sur des projets industriels spécifiques,</div>
                        <div className='vert'>La synergie au niveau de l’encadrement des étudiants préparant leurs Projets de Fin d’Étude en entreprise,</div>
                        <div className='vert'>L’implication d’industriels en activités aux enseignements dispensés par le département,</div>
                        <div className='vert'>La formation continue visant le recyclage, la mise à niveau ou le perfectionnement du personnel technique en activité dans les entreprises.</div>
                        </>
                    ),
                    image: '',
                },
            },
            
        ],
    },
    genie_mecanique_et_energetique: {
        nom: 'GENIE MÉCANIQUE ET ÉNERGÉTIQUE',
        menus: [
            {
                nom: 'Présentation',
                contenu: {
                    text: (
                        <>
                            Le Département Génie Mécanique et Énergétique se localise sur le site de l’INP-HB Centre et regroupe les sections de la Mécanique et de l’Énergétique. <br />
                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Organisation',
                contenu: {
                    text: (
                        <>
                            La section Mécanique regroupe toutes les ressources humaines et matérielles spécifiques à la mécanique : Les ateliers de production Mécanique, de Métaux en feuilles et le Bureau d’Étude et Infographie, Les laboratoires d’Automatismes Industriels et de Matériaux et Structures.
                            <br /><br />
                            La section Énergétique regroupe les ressources humaines et matérielles spécifiques à l’Énergétique : Les ateliers de Moteurs et Garage, d’hydraulique et d’aérodynamique, Les laboratoires de Froid et Thermique, d’Énergies Nouvelles et Renouvelables.
                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Missions',
                contenu: {
                    text: (
                        <>
                            <strong>Pédagogie</strong><br />
                            Les enseignants du département sont constitués en unité pédagogique. Ils assurent les enseignements, évaluent les élèves, effectuent le suivi des Projets de Fin d’Étude (PFE) et participent aux jurys de soutenance. <br />
                            L’unité pédagogique a en charge l’harmonisation des enseignements, de l’analyse du programme fourni par les écoles, de définir les travaux pratiques et projets internes, organisation des visites d’usine ou d’entreprises…
                                <br /><br />
                            <strong>Recherche</strong>
                            La recherche est animée par une cellule Recherche et Développement composée des laboratoires. Les activités de recherche du Département sont coordonnées par un responsable de cellule, chargé des missions recherche, de l’étude des coopérations et des partenariats, des recherches de financements en collaboration avec les responsables de laboratoire,
                            <div className='vert'>Il anime des séances de travail avec les différents chercheurs ;</div> 
                            <div className='vert'>Il active les conventions en cours ;</div> 
                            <div className='vert'>Il veille à la rédaction des projets et rapports de recherche ;</div> 
                            <div className='vert'>Il centralise les projets et rapports de recherche ;</div> 
                            <div className='vert'>Il assure la publication des travaux de recherche en collaboration avec les chercheurs et le conseil scientifique de l’Institut.</div> 
                                <br /><br />
                            <strong>Production</strong>
                            L’activité de production est animée par une cellule Production. Pour ses activités, la cellule production s’appuie sur les ateliers et laboratoires du Département. La production regroupe les activités : d’Expertise, de Conception, de Fabrication, de Formation Continue, d’Assistance aux Entreprises, de recherche de travaux neufs. <br />
                            Les produits fabriqués sont regroupés dans une salle d’exposition contient les prototypes ou modèles de produits fabriqués ou inventés dans le département, de même que les produits de la recherche.

                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Travaux Réalisés',
                contenu: {
                    text: (
                        <>
                            <strong>Entreprises</strong> <br />
                            <div className='vert'>FDFP Formation des artisans et des maîtres artisans aux techniques de froid ménager,de climatisation automobile, de moteurs thermiques et de construction métallique</div> 
                            <div className='vert'>IVOIRE COTON Analyses métallurgiques d’un arbre de train d’égreneuse de coton</div> 
                            <div className='vert'>LCCI Analyse métallurgique sur pièces diverses</div> 
                            <div className='vert'>SCB Analyse métallurgique sur câble haubanage</div> 
                            <div className='vert'>HOTEL PRESIDENT Remise en état d’une poulie en aluminium</div> 
                            <div className='vert'>Reprise d’arbre de turbine et montage de l’ensemble institut</div> 
                            <div className='vert'>BASILIQUE YAMOUSSOUKRO Réalisation des gilles de soufflage et de reprise d’air de climatisation</div> 
                            <div className='vert'>Réalisation d’un échafaudage modulaire de 15 m de hauts</div> 
                            <div className='vert'>DISTRICT DE YAMOUSSOUKRO Étude et faisabilité des abris de bus</div> 
                            <div className='vert'>DIRECTION DE L’EAU Réalisation de pièces de rechange pour les pompes d’hydraulique villageoise</div> 
                                <br /><br />
                            <strong>Particuliers</strong><br />
                            <div className='vert'>Rodage de culasse pour une Renault 5</div> 
                            <div className='vert'>Réalisation de grilles de clôture</div> 
                            <div className='vert'>Fabrication de vis pour presse d’Attiéké et assemblage des pièces</div> 
                            <div className='vert'>Gravure sur cuillers, fourchettes et couteaux à pain</div> 
                            <div className='vert'>Rectification de roues à cachet 12 dents, d’axes et de bagues</div> 
                            <div className='vert'>Réalisation de pièces pour machine d’imprimerie</div> 
                            <div className='vert'>Réalisation de presse-jus par fonderie</div> 
                        </>
                    ),
                    image: '',
                },
            },
        ],
    },
    genie_rural_et_sciences_geographiques: {
        nom: 'GENIE RURAL ET SCIENCES GEOGRAPHIQUES',
        menus: [
            {
                nom: 'Présentation',
                contenu: {
                    text: (
                        <>
                            Le Département Génie Mécanique et Énergétique se localise sur le site de l’INP-HB Centre et regroupe les sections de la Mécanique et de l’Énergétique. <br />
                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Organisation',
                contenu: {
                    text: (
                        <>
                            La section Mécanique regroupe toutes les ressources humaines et matérielles spécifiques à la mécanique : Les ateliers de production Mécanique, de Métaux en feuilles et le Bureau d’Étude et Infographie, Les laboratoires d’Automatismes Industriels et de Matériaux et Structures.
                            <br /><br />
                            La section Énergétique regroupe les ressources humaines et matérielles spécifiques à l’Énergétique : Les ateliers de Moteurs et Garage, d’hydraulique et d’aérodynamique, Les laboratoires de Froid et Thermique, d’Énergies Nouvelles et Renouvelables.
                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Missions',
                contenu: {
                    text: (
                        <>
                            <strong>Pédagogie</strong><br />
                            Les enseignants du département sont constitués en unité pédagogique. Ils assurent les enseignements, évaluent les élèves, effectuent le suivi des Projets de Fin d’Étude (PFE) et participent aux jurys de soutenance. <br />
                            L’unité pédagogique a en charge l’harmonisation des enseignements, de l’analyse du programme fourni par les écoles, de définir les travaux pratiques et projets internes, organisation des visites d’usine ou d’entreprises…
                                <br /><br />
                            <strong>Recherche</strong>
                            La recherche est animée par une cellule Recherche et Développement composée des laboratoires. Les activités de recherche du Département sont coordonnées par un responsable de cellule, chargé des missions recherche, de l’étude des coopérations et des partenariats, des recherches de financements en collaboration avec les responsables de laboratoire,
                            <div className='vert'>Il anime des séances de travail avec les différents chercheurs ;</div> 
                            <div className='vert'>Il active les conventions en cours ;</div> 
                            <div className='vert'>Il veille à la rédaction des projets et rapports de recherche ;</div> 
                            <div className='vert'>Il centralise les projets et rapports de recherche ;</div> 
                            <div className='vert'>Il assure la publication des travaux de recherche en collaboration avec les chercheurs et le conseil scientifique de l’Institut.</div> 
                                <br /><br />
                            <strong>Production</strong>
                            L’activité de production est animée par une cellule Production. Pour ses activités, la cellule production s’appuie sur les ateliers et laboratoires du Département. La production regroupe les activités : d’Expertise, de Conception, de Fabrication, de Formation Continue, d’Assistance aux Entreprises, de recherche de travaux neufs. <br />
                            Les produits fabriqués sont regroupés dans une salle d’exposition contient les prototypes ou modèles de produits fabriqués ou inventés dans le département, de même que les produits de la recherche.

                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Travaux Réalisés',
                contenu: {
                    text: (
                        <>
                            <strong>Entreprises</strong> <br />
                            <div className='vert'>FDFP Formation des artisans et des maîtres artisans aux techniques de froid ménager,de climatisation automobile, de moteurs thermiques et de construction métallique</div> 
                            <div className='vert'>IVOIRE COTON Analyses métallurgiques d’un arbre de train d’égreneuse de coton</div> 
                            <div className='vert'>LCCI Analyse métallurgique sur pièces diverses</div> 
                            <div className='vert'>SCB Analyse métallurgique sur câble haubanage</div> 
                            <div className='vert'>HOTEL PRESIDENT Remise en état d’une poulie en aluminium</div> 
                            <div className='vert'>Reprise d’arbre de turbine et montage de l’ensemble institut</div> 
                            <div className='vert'>BASILIQUE YAMOUSSOUKRO Réalisation des gilles de soufflage et de reprise d’air de climatisation</div> 
                            <div className='vert'>Réalisation d’un échafaudage modulaire de 15 m de hauts</div> 
                            <div className='vert'>DISTRICT DE YAMOUSSOUKRO Étude et faisabilité des abris de bus</div> 
                            <div className='vert'>DIRECTION DE L’EAU Réalisation de pièces de rechange pour les pompes d’hydraulique villageoise</div> 
                                <br /><br />
                            <strong>Particuliers</strong><br />
                            <div className='vert'>Rodage de culasse pour une Renault 5</div> 
                            <div className='vert'>Réalisation de grilles de clôture</div> 
                            <div className='vert'>Fabrication de vis pour presse d’Attiéké et assemblage des pièces</div> 
                            <div className='vert'>Gravure sur cuillers, fourchettes et couteaux à pain</div> 
                            <div className='vert'>Rectification de roues à cachet 12 dents, d’axes et de bagues</div> 
                            <div className='vert'>Réalisation de pièces pour machine d’imprimerie</div> 
                            <div className='vert'>Réalisation de presse-jus par fonderie</div> 
                        </>
                    ),
                    image: '',
                },
            },
        ],
    },
    infrastructure_et_transports: {
        nom: 'INFRASTRUCTURES ET TRANSPORT',
        menus: [
            {
                nom: 'Présentation',
                contenu: {
                    text: (
                        <>
                Le Département de Formation et de Recherche Infrastructures et Transports (DFR -IT) fait partie des 14 Départements que compte l’Institut National Polytechnique Félix Houphouët BOIGNY (INP-HB) de Yamoussoukro.
                <br /> Il regroupe des ressources humaines dans le domaine des Travaux Publics et du Génie Civil, des Transports et de la Logistique, de l’Hydraulique et du Génie Rural.
                <br /> Le DFR-IT est installé sur le site de l’INP-Sud (EX.ENSTP) et dispose de3 Laboratoires où se déroulent les travaux pratiques des étudiants de l’ESTP et de l’ESMG. Ces laboratoires sont mis à contribution pour la production en apportant un appui aux entreprises, aux maîtres d’œuvres et d’ouvrages des projets du BTP.
</>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Organisation',
                contenu: {
                    text: (
                        <>
                            <div className='vert'>1 conseil de département</div> 
                            <div className='vert'>3 sections : Routes et Transports, Hydraulique et Environnement, administration</div> 
                            <div className='vert'>3Laboratoires : Le labo Mécanique des sols - Le Labo de RDM - Le Labo d’Hydraulique</div> 

                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Missions',
                contenu: {
                    text: (
                        <>
                            La mission dévolue au département IT se résume en trois points, comme indiqué par le décret de création de l’INP-PHB sont: La formation, La recherche, La production.
                            <br /> Pour accomplir sa mission avec efficacité, le D.I.T compte sur 21 Enseignant-chercheurs au grade d’assistant, dont 06 Docteurs, 02 Doctorants et 14 Ingénieurs de conception dans le domaine du génie civil, génie rural, de l’environnement et des transports.
                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Chantiers de recherche',
                contenu: {
                    text: (
                        <>
                            <div className='vert'>«La relance de la recherche routière pour la mise en des normes ivoiriennes»</div> 
                            <div className='vert'>«Les perspectives de développement du système portuaire ivoirien»</div> 
                            <div className='vert'>«La reforme de l’entretien routier»</div> 
                            <div className='vert'>«Diagnostic du transport aérien»</div> 
                            <div className='vert'>«Le low-cost en Afrique de l’Ouest, mythe ou réalité?»</div> 
                            <div className='vert'>«Impacts des travaux réalisés par les concessionnaires de réseaux sur le coût d’entretien de la voirie urbaine»</div> 
                            <div className='vert'>«La relance des transports fluviaux et lagunaires»</div> 
                            <div className='vert'>«Révision du catalogue de dimensionnement des chaussées ivoiriennes LBTP»</div> 
                        </>
                    ),
                    image: '',
                },
            },
        ],
    },
    langues_et_sciences_humaines: {
        nom: 'LANGUES ET SCIENCES HUMAINES',
        menus: [
            {
                nom: 'Présentation',
                contenu: {
                    text: (
                        <>
                            Le département Langues et Sciences Humaines (DFR-LSH) localisé à l’INP-HB-Centre, est une structure d’enseignement et de recherche organisée autour de disciplines voisines. Il regroupe les enseignants et enseignants chercheurs d’une même discipline ou de disciplines voisines ainsi que les équipements pédagogiques, les laboratoires liés à ces disciplines.
                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Organisation',
                contenu: {
                    text: (
                        <>
                            Le DFR-LSH est composé de 3 sections formations :
                            <div className='vert'>Sciences humaines</div> 
                            <div className='vert'>Éducation physique et sportive</div> 
                            <div className='vert'>Anglais</div> 
                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Missions',
                contenu: {
                    text: (
                        <>
                            Le DFR-LSH effectue des enseignements pour le compte des écoles de l’INP-HB, assure des activités de recherche, de production d’assistance aux entreprises.
                        </>
                    ),
                    image: '',
                },
            },
            
        ],
    },
    mathematiques_et_informatique: {
        nom: 'MATHEMATIQUES ET INFORMATIQUE',
        menus: [
            {
                nom: 'Présentation',
                contenu: {
                    text: (
                        <>
                            Le Département Mathématiques et Informatique en abrégé DMI a été créé par le décret n° 96-678 du 4 septembre 1996 portant création de l’Institut National Polytechnique Félix HOUPHOUET-BOIGNY (INP-HB) et déterminant les attributions, l’organisation et le fonctionnement de cet Institut.

                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Organisation',
                contenu: {
                    text: (
                        <>
                            Le département est scindé en 2 sections : Une section informatique et une section mathématique. Chacune des sections est vue comme un tout composé d’ateliers pédagogiques et de recherche, d’enseignements de la même spécialité. Le département est composé d’Équipes Pédagogique et de Recherche (EPR) constitués d’enseignants œuvrant collectivement sur des questions de pédagogie et de Recherche. Les EPR sont regroupées en une unité logique appelée Laboratoire de Recherche en Informatique et Mathématiques Appliquées (LARIMA).
                            <br /> La structuration se fonde sur les spécialités qu’on retrouve dans le Département, à savoir l’informatique et les mathématiques. D’où la création de la section informatique et la section mathématique. Chacune des sections est vue comme un tout composé d’ateliers pédagogiques et de recherche, d’enseignements de la même spécialité. Il est institué des Equipes Pédagogique et de Recherche (EPR) qui sont constituées librement par les enseignants qui acceptent de travailler collectivement sur des questions de pédagogie et de Recherche. Les EPR sont regroupées en une unité logique appelée Laboratoire de Recherche en Informatique et Mathématiques Appliquées (LARIMA).

                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Missions',
                contenu: {
                    text: (
                        <>
                            Le Département Mathématiques et Informatique regroupe les enseignants et les chercheurs en Mathématiques, informatique ou en toute autre discipline voisine ainsi que les équipements pédagogiques, les ateliers et les laboratoires liés à ces disciplines.
                            <br /> Parallèlement à cette mission de formation, le département mène des actions de recherche et de production.
                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Compétences',
                contenu: {
                    text: (
                        <>
                            Les compétences du département touchent plusieurs domaines de formation :
                            <div className='vert'>Les Nouvelles Technologies de l’Information et de la Communication (NTIC)</div> 
                            <div className='vert'>Les techniques informatiques et mathématiques de base</div> 
                            <div className='vert'>Les outils de développement</div> 
                            <div className='vert'>Les outils bureautiques</div> 
                            <div className='vert'>Les logiciels mathématiques et statistiques</div> 
                            <div className='vert'>L’étude, l’assistance et conseil, l’audit</div> 

                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Recherche',
                contenu: {
                    text: (
                        <>
                            La recherche au sein du département est assurée par le Laboratoire de Recherche en Informatique et Mathématiques Appliquées (LARIMA).
                            <br /><br />
                            <strong>Axes de recherche en Informatique</strong> <br />
                            <div className='vert'>Architecture distribuée, Architecture parallèle, Calcul scientifique</div> 
                            <div className='vert'>Réseaux, Génie logiciel et programmation</div> 
                            <div className='vert'>Intelligence Artificielle, Base de Données, Interface Homme-machine</div> 
                            <br /><br />
                            <strong>Axes de recherche en Mathématique</strong><br />
                            <div className='vert'>Statistiques et Probabilités</div> 
                            <div className='vert'>Groupe de Lie et théorie des représentations</div> 
                            <div className='vert'>Mécanique des fluides</div> 
                            <div className='vert'>Systèmes dynamiques</div> 
                            <div className='vert'>Biométrie</div> 
                        </>
                    ),
                    image: '',
                },
            },
        ],
    },
    sciences_administratives: {
        nom: 'SCIENCES ADMINISTRATIVES',
        menus: [
            {
                nom: 'Présentation',
                contenu: {
                    text: (
                        <>
                            Le DFR-SA qui fait partie des unités de formation de l’INP-HB a ses bureaux à l’INP-HB centre, ensemble central, porte 306. Selon le Décret n°96-678 du 4 septembre 1996 portant création de l’Institut National Polytechnique Félix HOUPHOUET-BOIGNY et déterminant les attributions, l’organisation et le fonctionnement de cet Institut, les activités du Département concernent :
                            <div className='vert'>la formation des Etudiants des écoles ayant besoin des compétences de ses Enseignants ;</div> 
                            <div className='vert'>la conception de programmes d’enseignement, la définition des contenus des cours en fonction des objectifs globaux préalablement fixés par les Directeurs d’écoles ;</div> 
                            <div className='vert'>la formation continue et le perfectionnement au service d’entreprises ou organismes publics ou parapublics désireux d’entreprendre des actions de formation au profit de leur personnel ;</div> 
                            <div className='vert'>la recherche à l’instar de tous les autres Départements à qui cette mission est par principe assignée.</div> 
                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Organisation',
                contenu: {
                    text: (
                        <>
                            Le Département regroupe des Professeurs pédagogiquement qualifiés expérimentés, spécialisés dans l’enseignement des disciplines liées aux Techniques Administratives. A ce jour, le Personnel Enseignant est constitué de huit 8 enseignants permanents. En plus de leur formation de base, 3 ont acquis une ingénierie en gestion des Ressources Humaines qui leur permet d’assurer des cours dans ce domaine.
                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Missions',
                contenu: {
                    text: (
                        <>
                            Contribuer à la formation des élites d’hier, d’aujourd’hui et de demain telle est la vocation première des enseignants du Département Sciences Administratives (DFR-SA) qui participe à la formation de plusieurs promotions d’Ingénieurs dans le domaine du tertiaire.
                            <br /> L’enseignement dispensé par DFR-SA offre à l’apprenant des aptitudes qui lui permettent d’exercer avec efficacité aussi bien dans le privé que dans le public une fonction administrative. Ainsi, les écrits professionnels, l’organisation administrative d’un service, la présentation de documents aux normes administratives, commerciales et académiques avec l’utilisation de matériels relevant des NTIC, le renforcement de capacité des personnels administratifs et techniques de toute entreprise, est notre affaire.
                            <br /> Le Département dispose d’un programme alléchant et adapté pour une meilleure formation des étudiants et des auditeurs libres (travailleurs du secteur privé et du public et autres).

                        </>
                    ),
                    image: '',
                },
            },
        ],
    },
    science_de_la_terre_et_des_ressources_minieres: {
        nom: 'SCIENCES DE LA TERRE ET DES RESSOURCES MINIERES',
        menus: [
            {
                nom: 'Présentation',
                contenu: {
                    text: (
                        <>
                            Le Département Sciences de la Terre et des Ressources Minières (STeRMi) fait partie des quatorze (14) Départements que compte l’Institut National Polytechnique Houphouët Boigny (INP-HB) de Yamoussoukro. Il a été crée, à l’instar des autres Départements, par le Décret n° 96-672 du 4 septembre 1996 portant création et organisation dudit Institut.
                            <br /> Le Département STeRMi est situé sur le site de l’INP-HB Sud au cloître d’études n°2 (figure 2) qui abrite la Direction dudit département
                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Missions',
                contenu: {
                    text: (
                        <>
                            Les missions assignées au DFR- STeRMi sont la formation, la recherche et la production. Le département participe également à la formation des élèves techniciens supérieurs et ingénieurs de l’Ecole Supérieure des Mines et de Géologie (ESMG) et de l’Ecole Supérieure des Travaux Publics (ESTP), des élèves ingénieurs de l’Ecole Supérieure d’Industrie (ESI) et de l’Ecole Supérieure d’Agronomie (ESA), des élèves de première et deuxième années des classes préparatoires de l’Ecole Préparatoire (EP) et des auditeurs techniciens supérieurs et ingénieurs de l’Ecole de Formation Continue et de Perfectionnement des Cadres (EFCPC).
                            <br /><br />
                            Le DFR- STeRMi est situé sur le site de l’INP-HB Sud au cloître d’études n°2 (figure 2) qui abrite la Direction dudit département (figure 5), sept (7) bureaux d’enseignants et deux (2) salles-machines (figures 3 et 4) équipées d’un total de quinze (15) ordinateurs sur lesquels sont installés le logiciel SURPAC pour les travaux pratiques de conception de fosses et d’évaluation de gisements. En outre, il dispose d’un laboratoire de Géologie et de Géochimie, où se tiennent les travaux pratiques de pétrographie, minéralogie et de métallogénie, d’un atelier de confession de lames minces et d’un magasin pour le stockage du matériel de travaux pratiques de terrain.
                            <br /><br />
                            Pour mener à bien ces missions, le Département STeRMi dispose de 15 enseignants-chercheurs dont cinq (5) docteurs et dix (10) ingénieurs.
                            <br /> Les domaines de compétence sont variés et couvrent la Géologie, la Géophysique, les Mines, les hydrocarbures, l’Eau, l’Environnement, la Télédétection et les Systèmes d’Informations Géographiques (SIG).
                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Recherche',
                contenu: {
                    text: (
                        <>
                            Les activités de recherche sont menées activement au sein du département se font au niveau du laboratoire Génie Civil, Géosciences et Sciences Géographiques.
                            <br /> Les axes de recherche sont multiples. Nous pouvons citer entre autres :
                            <div className='vert'>Analyse des impacts des changements climatiques actuels et futurs sur les ressources        en eau ;</div> 
                            <div className='vert'>Modélisation hydrologique et hydrogéologique ;</div> 
                            <div className='vert'>Caractérisation hydrodynamique des aquifères de Côte d’Ivoire ;</div> 
                            <div className='vert'>Caractérisation hydrogéochimique des aquifères de Côte d’Ivoire ;</div> 
                            <div className='vert'>Caractérisation pétrographique et stratigraphique des réservoirs pétroliers ;</div> 
                            <div className='vert'>Amélioration de la production des gisements à réservoirs non consolidés ;</div> 
                            <div className='vert'>Amélioration des systèmes de récupération des gisements pétroliers ivoiriens ;</div> 
                            <div className='vert'>Caractérisation sédimentologique des bassins sédimentaires;</div> 
                            <div className='vert'>Modélisation et évaluation des réservoirs;</div> 
                            <div className='vert'>Application de la Télédétection à la Caractérisation géologique et pédologique;</div> 
                            <div className='vert'>Modélisation géostatistique des réservoirs pétroliers et hydrogéologiques ;</div> 
                            <div className='vert'>Modélisation géostatistique des gisements miniers ;</div> 
                            <div className='vert'>Application de la géophysique aux géologique, minier, pétrolier et hydrogéologique ;</div> 
                            <div className='vert'>Cartographie géologique ;</div> 
                            <div className='vert'>Analyse structurale et pétrographique ;</div> 
                            <div className='vert'>Prospection et exploitation minière.</div> 
                        </>
                    ),
                    image: '',
                },
            },
            {
                nom: 'Thèmes',
                contenu: {
                    text: (
                        <>
                            <div className="vert">
                                pourriture brune des cabosses de cacaoyers en
                                Côte d’Ivoire ;
                            </div>
                            <div className="vert">
                                fusariose vasculaire du cotonnier en Côte
                                d’Ivoire ;
                            </div>

                            <div className="vert">
                                facteurs de dégradation de la qualité des
                                produits destinés à l’exportation (fèves de
                                cacao, banane dessert, papaye, mangue,
                                ananas..);
                            </div>
                            <div className="vert">
                                Microbiologie et écologie des sols (diversité
                                des champignons dans différents types
                                d’utilisation des sols en Côte-d’Ivoire) ;
                            </div>
                            <div className="vert">
                                viroses des plantes cultivées en Côte d’Ivoire ;
                            </div>
                            <div className="vert">
                                tests d’efficacité de produits fongicides en
                                Côte d’Ivoire ;
                            </div>
                            <div className="vert">
                                effets d’extraits de plantes, comme alternative
                                à la lutte chimique contre les maladies des
                                cultures ;
                            </div>
                            <div className="vert">
                                tests d’efficacité d’herbicides en Côte d’Ivoire
                                ;
                            </div>
                            <div className="vert">
                                étude des adventices des principales cultures
                                d’exportation.
                            </div>
                        </>
                    ),
                    image: '',
                },
            },
        ],
    },
}

function Departement() {
    const [checkBoxState, setcheckBoxState] = useState([
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
    ])
    const [isActuallyOpen, setisActuallyOpen] = useState(0)
    const HanldeCheck = (i) => {
        setcheckBoxState((prevState) =>
            prevState.map((item, idx) => (idx === i ? !item : false))
        )
        setisActuallyOpen(i)
    }
    const { departementName } = useParams()
    const monDepartement = Departements[`${departementName}`]
    return monDepartement ? (
        <Transitions>
            <div className="text-dark text-start ms-4 my-2 d-flex">
                <NavLink to="/portail/departements" className="me-1">Département</NavLink> | {monDepartement.nom}
            </div>
            <DepartementMenuWrapper>
                <DepartementMenuItems>
                    {monDepartement.menus.map((menu, index) => (
                        <DepartementMenuItem
                            key={`departements-${menu.nom}-${index}`}
                            onClick={() => HanldeCheck(index)}
                            color={checkBoxState[index] ? '#008D36' : '#fff'}
                            textColor={
                                checkBoxState[index] ? '#fff' : '#008D36'
                            }
                            className="text-uppercase py-4 text-center m-1"
                        >
                            {menu.nom}
                        </DepartementMenuItem>
                    ))}
                </DepartementMenuItems>
            </DepartementMenuWrapper>
            <Container
                className={
                    checkBoxState[isActuallyOpen]
                        ? 'd-flex flex-column mt-5'
                        : 'mt-5 d-none'
                }
            >
                <div className="d-flex align-items-center justify-content-center">
                    <Myimg
                        src={monDepartement.menus[isActuallyOpen].contenu.image}
                    />
                </div>
                <div className="body-text border-0">
                    {' '}
                    {monDepartement.menus[isActuallyOpen].contenu.text}
                </div>
            </Container>
        </Transitions>
    ) : (
        window.location.replace('/erreur')
    )
}

export default Departement
