import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { Transitions } from '../../components/Transitions'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { EpreuvesConcours } from '../../datas'

const Item = styled.div`
    padding: 10px;
    background-color: ${(prop) => prop.color};
    color: ${(prop) => prop.textColor};
    display: flex;
    border-bottom: 1px solid ${(prop) => prop.textColor};
    justify-content: space-between;
    align-items: center;
    cursor: pointer !important;
`
const Title = styled.div`
    &::before {
        content: '.';
        font-size: 4em;
        color: ${(prop) => prop.color};
    }
`
const Contenu = styled.div`
    border-left: 5px solid ${(prop) => prop.color};
    padding-left: 10px;
    margin-left: 30px;
`


export const CetteEpreuve = () => {
    const { epreuve } = useParams()
    const Epreuve = EpreuvesConcours[epreuve]
    const [checkBoxState, setcheckBoxState] = useState([
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
    ])
    const HanldeCheck = (i) => {
        setcheckBoxState((prevState) =>
            prevState.map((item, idx) => (idx === i ? !item : false))
        )
    }

    return (
        <Transitions>
            <Container>
                <div className="mb-2">
                    <Item className="text-uppercase my-2 mb-4 text-dark">
                        <div
                            style={{ cursor: 'pointer' }}
                            className="text-uppercase fs-1"
                        >
                            {epreuve}
                        </div>
                    </Item>
                    <Row>
                        {Epreuve.map((ep, index) => (
                            <div key={`Epreuve--${index}`}>
                                <Item
                                    className="text-uppercase my-1"
                                    onClick={() => HanldeCheck(index)}
                                    color={
                                        checkBoxState[index]
                                            ? '#008D36'
                                            : '#FFF'
                                    }
                                    textColor={
                                        checkBoxState[index]
                                            ? '#fff'
                                            : '#008D36'
                                    }
                                >
                                    <div style={{ cursor: 'pointer' }}>
                                        {ep.titre}
                                    </div>
                                    {checkBoxState[index] ? (
                                        <FaChevronUp
                                            style={{ cursor: 'pointer' }}
                                        />
                                    ) : (
                                        <FaChevronDown
                                            style={{ cursor: 'pointer' }}
                                        />
                                    )}
                                </Item>
                                <div
                                    className={
                                        checkBoxState[index]
                                            ? 'd-block mb-5 fade-in'
                                            : 'mb-5 d-none'
                                    }
                                >
                                    {ep.sousTitre.map((vol, index) => (
                                        <div
                                            className="text-start body-text border-0  my-3 "
                                            key={`volume-${index}`}
                                        >
                                            <span style={{ color: '#008D36' }}>
                                                {vol.enTete}
                                            </span>
                                            {vol.contenu && (
                                                <Contenu color={'#008D36'}>
                                                    <div className="my-3">
                                                        {vol.contenu.map(
                                                            (don, ind) => (
                                                                <Title
                                                                    className=""
                                                                    color={
                                                                        '#008D36'
                                                                    }
                                                                    key={ind}
                                                                >
                                                                    {don} {ind < (vol.contenu.length -1) && ";"}
                                                                </Title>
                                                            )
                                                        )}
                                                    </div>
                                                </Contenu>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </Row>
                </div>
            </Container>
        </Transitions>
    )
}
