import React from 'react'
import * as ReactDOMClient from 'react-dom/client'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import {
    BrowserRouter as Router,
} from 'react-router-dom'
import GlobalStyle from './utils/style/index.jsx'
import { MyContextProvider } from './utils/context'
import FlotButton from './components/FlotButton/index.jsx'
// import NewFloat from './components/FlotButton/NewFloat.jsx'
import Moya from './components/Moya/index.jsx'
import { AnimatedRoutes } from './components/AnimatedRoutes/index.jsx'
import LiveChat from 'react-livechat'

const container = document.getElementById('root')
const root = ReactDOMClient.createRoot(container)

root.render(
    <React.StrictMode>
        <Router>
            <MyContextProvider>
                <GlobalStyle />
                <FlotButton />
                {/* <NewFloat /> */}
                <LiveChat license={15005835}/>
                 {/* <Moya />  */}
                <AnimatedRoutes />
            </MyContextProvider>
        </Router>
    </React.StrictMode>
)
