import React from 'react'
import classes from '../Newplaceholder/newplace.module.css'
import Newmenutop from '../Newmenutop/Newmenutop';



function Newconcours() {
  return (
    <>
        <div className={classes.container}>
            <Newmenutop />
            <div className={classes.titreformation}>
                <h1>Formation initiale</h1>
            </div>


        </div>
    </>
  )
}

export default Newconcours