import React, { useEffect, useState } from 'react';
import axios from 'axios';
import FooterPortailConcours from '../../components/FooterPortailConcours';
import classes from '../Newplaceholder/newplace.module.css';
import Newmenutop from '../Newmenutop/Newmenutop';
import { FaSchool, FaTimes } from "react-icons/fa";
import { TbFileCertificate } from "react-icons/tb";
import HomeFlot from '../../components/HomeFlot';

function Newcourses() {
  const [filieres, setFilieres] = useState([]);
  const [filteredFilieres, setFilteredFilieres] = useState([]); // Ajout d'un état pour les filières filtrées
  const [searchTerm, setSearchTerm] = useState(''); // État pour le terme de recherche
  const [selectedCycles, setSelectedCycles] = useState([]); // État pour les cycles sélectionnés
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log('Fetching data from API...');
    axios.get('https://www.adminsite.inphb.app/server.php')
      .then(response => {
        console.log('Data fetched successfully:', response.data);
        setFilieres(response.data);
        setFilteredFilieres(response.data); // Initialiser les filières filtrées avec toutes les filières
      })
      .catch(error => {
        console.error('There was an error fetching the data!', error);
        setError(error);
      });
  }, []);

  useEffect(() => {
    const results = filieres.filter(filiere =>
      (filiere.filiere.toLowerCase().includes(searchTerm.toLowerCase()) ||
      filiere.codeFiliere.toLowerCase().includes(searchTerm.toLowerCase()) ||
      filiere.ecole.toLowerCase().includes(searchTerm.toLowerCase()) ||
      filiere.codeEcole.toLowerCase().includes(searchTerm.toLowerCase()) ||

      filiere.specialite.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (selectedCycles.length === 0 || selectedCycles.includes(filiere.codeCycle))
    );
    setFilteredFilieres(results);
  }, [searchTerm, selectedCycles, filieres]);

  const handleCycleChange = (event) => {
    const value = event.target.value;
    setSelectedCycles(prevSelectedCycles =>
      prevSelectedCycles.includes(value)
        ? prevSelectedCycles.filter(codeCycle => codeCycle !== value)
        : [...prevSelectedCycles, value]
    );
  };

  const clearSearch = () => {
    setSearchTerm('');
  };

  return (
    <>
    <HomeFlot />
      <div className={classes.container}>
        <Newmenutop />
      </div>
      <div className={classes.courses}>
        <div className={classes.searchbar}>
          <div className={classes.labarre}>
            <input
              type="text"
              placeholder="Rechercher une formation / filière ..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className={classes.searchInput}
            />
            {searchTerm && <FaTimes className={classes.clearIcon} onClick={clearSearch} />}
          </div>

          <div className={classes.checkboxContainer}>
            <label>
              <input
                type="checkbox"
                value="PREPA"
                onChange={handleCycleChange}
                checked={selectedCycles.includes('PREPA')}
              />
              Prepa
            </label>
            <label>
              <input
                type="checkbox"
                value="TS"
                onChange={handleCycleChange}
                checked={selectedCycles.includes('TS')}
              />
              TS
            </label>
            <label>
              <input
                type="checkbox"
                value="LICENCE"
                onChange={handleCycleChange}
                checked={selectedCycles.includes('LICENCE')}
              />
              Licence
            </label>
            <label>
              <input
                type="checkbox"
                value="MASTER"
                onChange={handleCycleChange}
                checked={selectedCycles.includes('MASTER')}
              />
              Master
            </label>
            <label>
              <input
                type="checkbox"
                value="ING"
                onChange={handleCycleChange}
                checked={selectedCycles.includes('ING')}
              />
              Ingénieur
            </label>
            <label>
              <input
                type="checkbox"
                value="DOCTORAT"
                onChange={handleCycleChange}
                checked={selectedCycles.includes('DOCTORAT')}
              />
              Doctorat
            </label>
          </div>
        </div>
        <div className={classes.coursesleft}>
          <div className={classes.courseslefttitle}>
            <span>Formations & filières</span>
            {/* <button> voir toutes nos formations </button> */}
          </div>
          <div className={classes.lesfilieres}>
            {filteredFilieres.length > 0 ? (
              filteredFilieres.map(filiere => (
                <div className={classes.lafiliere} key={filiere.id}>
                  <div className={classes.lafilieretitle}>{filiere.filiere}</div>
                  <div className={classes.lafiliereecole}>
                    <span style={{ color: '#00ff62', fontWeight: 'bolder' }}>école :</span> <br /> {filiere.ecole}
                  </div>
                  <div className={classes.lafilierespecialite}><span>Spécialité : </span> {filiere.specialite}</div>
                  <div className={classes.lafiliereniveau}><span>Niveau : </span> <TbFileCertificate style={{ color: '#fff', fontSize: '15px' }} /> {filiere.niveau}</div>
                  <div className={classes.lafilierecycle}>
                    <span style={{ color: 'white' }}>Accès : <FaSchool /> {filiere.acces}</span> <br />
                    Cycle : {filiere.codeCycle}
                  </div>
                  {/* <div className={classes.lafilierecode}>
                    <span>Code Filiere : </span> {filiere.codeFiliere}
                  </div> */}
                </div>
              ))
            ) : (
              <div className={classes.noResults}>Aucun résultat trouvé</div>
            )}
          </div>
        </div>
      </div>
      <FooterPortailConcours />
    </>
  );
}

export default Newcourses;
