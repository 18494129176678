import React from 'react'
import { Carousel, Col, Container, Row } from 'react-bootstrap'
import '../../utils/style/index.scss'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { Transitions } from '../../components/Transitions'

function importAll(r) {
    let images = {}
    r.keys().map((item, index) => (images[item.replace('./', '')] = r(item)))
    return images
}
const portail = importAll(
    require.context('../../assets/Portail', false, /\.(png|jpe?g|svg)$/)
)

const MyP = styled.p`
    margin: 0px !important;
    padding: 0px !important;
    &::before {
        content: '.';
        font-size: 4em;
        color: #0dba1e;
    }
`
const Studios = styled.div`
    background-color: #089bd9;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: #fff;
`
const StyledImWrapper = styled.div`
    text-decoration: none;
    cursor: pointer;
    width: 100%;
    border-radius: 20px;
    margin-top: 10px;
    display: flex;
    padding: 5px !important;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    justify-content: flex-center;
    transition: 1s;
`
const MyImg = styled.img`
    width: 30%;
    margin: 0px !important;
    margin-right: 10px !important;
    cursor: pointer;
    background-color: #fff;
    transition: transform 350ms ease-in-out;
`
const MyTexts = styled.div`
    border-left: 3px solid #f47920;
    padding-left: 10px;
`
const MyText = styled.div`
    font-size: 0.95em;
    @media (max-width: 1200px) {
        font-size: 0.75em;
    }
    @media (max-width: 768px) {
        font-size: 0.65em;
    }
`
const MyCarousel = styled(Carousel)`
    width: 50%;
    @media(max-width: 1200px){
        width: 60%;
    }
    @media(max-width: 993px){
        width: 70%;
    }
    @media(max-width: 701px){
        width: 80%;
    }
    @media(max-width: 501px){
        width: 90%;
    }
    @media(max-width: 401px){
        width: 100%;
    }
`

const associations = [
    {
        nom: 'Bureau Des Etudiants',
        alt: 'BDE',
        image: portail['logoBDE.png'],
        president: "M. Emmanuel N'CHO",
    },
    {
        nom: 'Amicale ENSA',
        alt: 'ENSA',
        image: portail['logoAA.jpg'],
        president: 'Mlle Olivia ESSO',
    },
    {
        nom: 'JE Petroleum Engineers',
        alt: 'AIC-Mines',
        image: portail['logoJE.png'],
        president: 'M. Sotigui TRAORE',
    },
]
export const VieAssociative = () => {
    return (
        <Transitions>
            <div className="text-dark text-start ms-4 my-2 d-flex">
               <NavLink to='/portail/vie_universitaire' className="me-2">Vie à l'INP-HB </NavLink> | Vie Associative à l'INP-HB
            </div>
            <Container>
                <div
                    style={{ backgroundColor: '#E6E6E6' }}
                    className="p-3 py-4 text-justify text-center fs-3 body-text border-0 text-capitalize"
                >
                    Vie associative à l'INP-HB
                </div>
                <Container className="my-4 align-items-center justify-content-center d-flex flex-column">
                    <MyCarousel>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={portail['vieAssociativeAccueil.jpg']}
                                alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={portail['association1.jpeg']}
                                alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={portail['association2.jpeg']}
                                alt="First slide"
                            />
                        </Carousel.Item>
                    </MyCarousel>
                </Container>
                <div className="text-justify mt-3 mb-5 body-text border-0">
                    La vie sur les campus et en dehors de l'INP-HB rend
                    parfaitement compte du dynamisme de l'institut, de son souci
                    constant de rester à l'écoute de son environnement et de
                    participer de manière effective à la construction d'une
                    société tout à la fois moderne mais qui ne renonce pas pour
                    autant aux vertus de la tradition africaine authentique. Les
                    associations d'élèves de l'INP-HB visent principalement à:
                    <MyP>
                        Développer l'esprit d'initiative, le sens des
                        responsabilités, la passion d'entreprendre des futurs
                        cadres et leurs aptitudes à animer une équipe et à
                        acquérir une expérience réelle d'organisation, et les
                        habituer au pluralisme des opinions.
                    </MyP>
                    <MyP>
                        Animer les campus universitaires, assurer la promotion
                        des structures de l'INP-HB, contribuant ainsi à tisser
                        des liens multiformes entre l'institut et la société
                        dans son ensemble et singulièrement les entreprises dont
                        ces associations constituent des interfaces efficaces
                        (Associations regroupant diplômés et élèves en formation
                        d'une École ou d'une spécialité).
                    </MyP>
                    <MyP>
                        Défendre les intérêts matériels, moraux, culturels et
                        académiques des étudiants, préserver ou défendre
                        l'éthique et l'image de marque de chaque École ou d'une
                        spécialité, développer le sentiment d'appartenance à une
                        même famille propre aux Grandes Écoles. Relayer au
                        niveau de l'École les actions de développement et de
                        solidarité initiées par les organisations régionales,
                        religieuses ou culturelles. Voir la liste des
                        associations d’élèves.
                    </MyP>
                </div>
            </Container>
            <Studios className="px-5 py-5 mb-5">
                <div>
                    <div className="text-center body-highlight fs-3 mt-0">
                        Vous êtes élève et souhaitez créer une association, une
                        activité récréative et sportive ?
                    </div>
                    <div className="text-justify mt-3 body-text border-0">
                        Le service accueil et animation vous soutient dans
                        l’encadrement technique et organisationnel de vos
                        activités. Informez nous de l’organisation de vos
                        manifestations en adressant une demande au Secrétariat
                        Général dans un délai minimum de 30 jours. Pour touts
                        informations, contactez le Service Accueil et Animation.
                    </div>
                </div>
                <a href="mailto:yao.brou@inphb.ci" className="mybtn mt-5">
                    Nous Contacter
                </a>
            </Studios>
            <Container>
                <div
                    style={{ backgroundColor: '#E6E6E6' }}
                    className="p-3 py-4 text-justify text-center fs-3 body-text border-0 text-capitalize mb-5"
                >
                    Nos associations
                </div>
                <Row className="d-flex flex-wrap align-items-center justify-content-center">
                    {associations.map((association, index) => (
                        <Col
                            xxl={4}
                            xl={4}
                            lg={4}
                            md={6}
                            sm={6}
                            className="mb-3"
                            key={`Association-${index}`}
                        >
                            <StyledImWrapper>
                                <MyImg
                                    alt={association.alt}
                                    className="ms-3"
                                    src={association.image}
                                />
                                <MyTexts>
                                    <MyText className="text-dark fw-bold">
                                        {association.nom}
                                    </MyText>
                                    <MyText className="text-dark fw-bold mt-2">
                                        {association.president}
                                    </MyText>
                                </MyTexts>
                            </StyledImWrapper>
                        </Col>
                    ))}
                </Row>
            </Container>
        </Transitions>
    )
}
