import styled from 'styled-components'
import { NavbarBrand } from 'react-bootstrap'
import '../../utils/style/index.scss'
import HamburgerMenu from '../HamburgerMenu'
import logo from "../../assets/INP-HB.png"

const SchoolNavbar = styled.div`
    background-color: #008D36;
    position: absolute;
    top: 5%;
    width: 100%;
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
    @media (max-width: 750px) {
        top: 0px;
        position: fixed;
        justify-content: flex-start;
    }
`
const NavItem = styled.a`
    text-decoration: none;
    font-size: 1em;
    text-align: center;
    cursor: pointer;
    @media (max-width: 750px) {
        display: none;
    }
`
export default function HeaderEcole() {
    const EcoleItems = [
        {
            text: 'FabLabs',
            link: '#',
        },
        {
            text: 'Incubateur',
            link: '#',
        },
        {
            text: 'Prod & exp',
            link: '/production_expertise',
        },
        {
            text: 'Technopole',
            link: '#',
        },
    ]
    return (
        <SchoolNavbar data-aos="fade-up" color="#008D36">
            <HamburgerMenu itemcolor="#008D36" showWidth= '750px' burgercolor="#fff" items= {EcoleItems} Size="50px"/>
            <NavItem className="fw-bold text-light me-5" href="">FABLABS</NavItem>
            <NavItem className="fw-bold text-light me-5" href="">INCUBATEUR</NavItem>
            <NavbarBrand>
                <img
                    style={{
                        width: '100px',
                        height: '100px',
                        backgroundColor: '#fff',
                        borderRadius: '50%',
                        border: '0px',
                    }}
                    className="me-5"
                    src={logo}
                    alt="ESMG"
                    
                />
                
            </NavbarBrand>
            <NavItem className="fw-bold text-light me-5" href="production_expertise">PROD & EXP</NavItem>
            <NavItem className="fw-bold text-light me-5" href="">TECHNOPOLE</NavItem>
        </SchoolNavbar>
    )
}
