import React, { useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import '../../utils/style/index.scss'
import { FaChevronDown, FaChevronUp, FaMailBulk, FaPhone } from 'react-icons/fa'
import styled from 'styled-components'
import { Transitions } from '../../components/Transitions'
import { FcBusinesswoman } from 'react-icons/fc'
import classes from './genre.module.css'
import ReactPlayer from 'react-player/youtube'


const TitleDiv = styled.div`
    width: 100%;
    background-color: rgb(0, 141, 54);
`

const Item = styled.div`
    padding: 10px;
    background-color: ${(prop) => prop.color};
    color: ${(prop) => prop.textColor};
    display: flex;
    border-bottom: 1px solid #f47920;
    justify-content: space-between;
    align-items: center;
    cursor: pointer !important;
`
const MyCard = styled(Card)`
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    @media (max-width: 590px) {
        font-size: 0.9em;
    }
`
const MyCol = styled(Col)`
    @media (max-width: 475px) {
        flex-basis: 80% !important;
        margin-bottom: 10px;
    }
    @media (max-width: 330px) {
        flex-basis: 90% !important;
    }
`
export const BureauGenre = () => {
    const [checkBoxState, setcheckBoxState] = useState([
        false,
        true,
        false,
    ])
    const HanldeCheck = (i) => {
        setcheckBoxState((prevState) =>
            prevState.map((item, idx) => (idx === i ? !item : false))
        )
    }

const [youtubeID] = useState('15a3CcPhFH8')
const [youtubeID2] = useState('MKICuruknSs')


    return (
        <Transitions>
            
            <TitleDiv className="d-flex justify-content-center align-items-center text-light mb-5">
                <span
                    className="body-highlight mt-3 text-uppercase ps-2 mb-3 fs-1"
                    style={{ borderLeft: '10px solid #fff' }}
                >
                    Bureau genre
                </span>
            </TitleDiv>
                <div className={classes.titregenre}>
                    Le Bureau Genre de l’INP-HB, une plateforme inclusive de valorisation des compétences 
                    de l’ensemble de la communauté.
                </div>
            <Container>
                <div className={classes.genre_video_container}>
                    <div className={classes.annoncetitre}>Vidéos récentes</div>
                    <hr />
                    <div className={classes.lignevideo}>
                        <div className={classes.videoordi}><ReactPlayer url='https://youtu.be/15a3CcPhFH8' /></div>
                        <div className={classes.videoordi}><ReactPlayer url='https://www.youtube.com/watch?v=MKICuruknSs' /></div>
                        <div className={classes.videotel}>
                            <iframe 
                                title='INP-HB'
                                sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                                src={`https://youtube.com/embed/${youtubeID}?autoplay=0`}
                                allowFullScreen
                                >
                            </iframe>
                            <iframe 
                                title='INP-HB'
                                sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                                src={`https://youtube.com/embed/${youtubeID2}?autoplay=0`}
                                allowFullScreen
                                >
                            </iframe>
                        </div>
                    </div>
                </div>
                <div>
                    <Item
                        className="text-uppercase my-1"
                        onClick={() => HanldeCheck(1)}
                        color={checkBoxState[1] ? '#f47920' : '#fff'}
                        textColor={checkBoxState[1] ? '#fff' : '#000'}
                    >
                        <div
                            style={{ cursor: 'pointer' }}
                            className="text-uppercase"
                        >
                            INP-HB : Une priorité à l’égalité du genre 
                        </div>
                        {checkBoxState[1] ? (
                            <FaChevronUp
                                style={{ cursor: 'pointer', color: '#fff' }}
                            />
                        ) : (
                            <FaChevronDown
                                style={{ cursor: 'pointer', color: '#f47920' }}
                            />
                        )}
                    </Item>
                    <div
                        className={
                            checkBoxState[1] ? 'd-block mb-5 fade-in mt-3' : 'mb-5 d-none'
                        }
                    >       
                        <div className="body-text border-0">
                            À l’échelle mondiale, l'égalité des genres est devenue une priorité pour toutes les nations. L’Agenda 2030 des Nations unies à travers son Objectif pour le Développement Durable (ODD 5) a préconisé l'égalité des sexes d’ici 2030. L’INP-HB qui joue un rôle important dans la formation de nombreux cadres et techniciens ivoiriens et africains ne saurait rester en marge. Dans ce contexte, il s’est doté d’un Bureau Genre depuis le 1er janvier 2022.  Celui-ci a pour cible toute la communauté INP-HB (personnels administratifs et techniques, personnels enseignants, vacataires, prestataires, contractuels et partenaires).
                        </div>
                    </div>
                </div>
                <div className="mb-2">
                    <Item
                        className="text-uppercase my-1"
                        onClick={() => HanldeCheck(0)}
                        color={checkBoxState[0] ? '#f47920' : '#fff'}
                        textColor={checkBoxState[0] ? '#fff' : '#000'}
                    >
                        <div
                            style={{ cursor: 'pointer' }}
                            className="text-uppercase"
                        >
                            Missions du Bureau Genre
                        </div>
                        {checkBoxState[0] ? (
                            <FaChevronUp
                                style={{ cursor: 'pointer', color: '#fff' }}
                            />
                        ) : (
                            <FaChevronDown
                                style={{ cursor: 'pointer', color: '#f47920' }}
                            />
                        )}
                    </Item>
                    <div
                        className={
                            checkBoxState[0] ? 'd-block mb-5 fade-in mt-3' : 'mb-5 d-none'
                        }
                    >
                        <div className="body-text border-0">
                            Le Bureau Genre a pour missions de : <br /> 
                            <div className="vert">
                            Veiller à l’intégration de la dimension genre dans toutes les activités de l’INP-HB et à sa prise en compte dans les planifications, programmes et budgets de l’institut. 
                            </div>
                            <div className="vert">
                            Promouvoir l'égalité des sexes et réduire la violence en engageant toute la communauté INP-HB dans une réflexion critique et une introspection afin de mettre à la disposition de la nation des cadres socialement stable.
                            </div>
                        </div>
                    </div>
                </div>
                
                <div>
                    <Item
                        className="text-uppercase my-1"
                        onClick={() => HanldeCheck(2)}
                        color={checkBoxState[2] ? '#f47920' : '#fff'}
                        textColor={checkBoxState[2] ? '#fff' : '#000'}
                    >
                        <div
                            style={{ cursor: 'pointer' }}
                            className="text-uppercase"
                        >
                            Activités principales
                        </div>
                        {checkBoxState[2] ? (
                            <FaChevronUp
                                style={{ cursor: 'pointer', color: '#fff' }}
                            />
                        ) : (
                            <FaChevronDown
                                style={{ cursor: 'pointer', color: '#f47920' }}
                            />
                        )}
                    </Item>
                    <div
                        className={
                            checkBoxState[2] ? 'd-block mb-5 fade-in' : 'mb-5 d-none'
                        }
                    >
                        <div className="body-text border-0">
                        Plusieurs activités sont menées par le bureau Genre. Celles-ci englobent :  <br />
                            <div className="vert">
                                <strong> La sensibilisation contre toutes formes de violence basées sur le genre.  </strong> <br/>
                                {/* La sensibilisation passe par la promotion du numéro vert de l’INP-HB (07 59 91 91 91) pour une sensibilisation des personnels et des élèves sur toutes les formes de violences basées sur le genre (harcèlement, mariage forcé) afin de leur faire prendre conscience des conséquences négatives sur les victimes.  L’objectif étant de tendre vers « Zéro cas d’échec dû aux harcèlements ».  */}
                            </div>
                            <div className="vert">
                                <strong> Le panier d’enseignant </strong> <br/> La
                                Ce projet vise à aider tous les élèves rencontrant des difficultés pédagogiques. Ces derniers seront accompagnés de manière bénévole par un pool d’enseignants (toutes matières confondues).
                            </div>
                            <div className="vert">
                                <strong> Le Gender tour</strong> <br/> 
                                Le Gender tour consiste à sillonner les collèges et lycées du pays pour faire la promotion des filières scientifiques et technologiques auprès des jeunes filles dans le but de les réconcilier aux sciences. L’objectif de cette activité est d’augmenter le taux de filles dans les filières scientifiques et technologiques. La visite dans ces différents établissements sera sanctionnée par la sélection des meilleures filles qui bénéficieront d’un séjour académique et culturel sur le campus de l’INP-HB pendant les vacances scolaires.
                                Dans ce contexte, 44 jeunes filles de la classe de la terminale C issues de 19 établissements de 9 régions du pays, encadrées par le Bureau Genre ont participé à un camp d’excellence en août 2022.  
                          </div>
                            <div className="vert">
                                <strong> Le Gender leadership féminin</strong> <br/>
                                Cette activité vise à regrouper les filles de 1ère année en association dans le but de leur permettre de bénéficier de formation en leadership féminin. L’idée est de créer dans 5 ans, le réseau 22. Au sein de ce réseau, un effectif de 30% d’hommes est souhaitable. Des formations en développement personnel leur seront prodiguées. A cet effet, une tutrice sera affectée à chaque promotion par école ; développant ainsi le système de mentorat. 
                            </div>
                            <div className="vert">
                                <strong> Etudiant (e), un jour</strong><br/>
                                « Permettre à un(e) collégien(ne) de se mettre dans la peau d’un polytechnicien.ne en passant une journée sur le campus de l’INP-HB » de susciter son envie d’intégrer le campus est l’objectif visé par ce projet. 
                            </div>
                        </div>
                    </div>
                </div>
                <Row className="d-flex flex-wrap align-items-center justify-content-center mt-5">
                <p className="text-center fw-bold text-uppercase">
                    Responsable du Bureau Genre
                </p>
                <MyCol xxl={4} xl={5} lg={6} md={8} sm={10} xs={12}>
                    <MyCard
                        className="d-flex flex-row align-items-center justify-content-evenly p-2 mb-5"
                        style={{
                            width: '100%',
                            height: '100%',
                            border: '0px',
                        }}
                    >
                        <div>
                            <div
                                style={{
                                    borderRadius: '50%',
                                    backgroundColor: '#E6E6E6',
                                }}
                                className="p-2 w-75 d-flex align-items-center justify-content-center"
                            >
                                <FcBusinesswoman style={{ fontSize: '4em' }} />
                            </div>
                            <p className="mb-3 mt-2 text-center">
                                Mme ADJOUMANI
                            </p>
                        </div>
                        <div className="d-flex flex-column align-items-start justify-content-center ms-2">
                            <div className="text-start">
                                <FaPhone style={{ color: '#0DBA1E' }} /> (00225)
                                07 07 97 25 52
                            </div>
                            <div className="mt-3 text-start">
                                <FaMailBulk style={{ color: '#0DBA1E' }} />{' '}
                                service.genre@inphb.ci
                            </div>
                        </div>
                    </MyCard>
                </MyCol>
            </Row>
            </Container>
        </Transitions>
    )
}
