import { createContext, useEffect, useState } from 'react'
import { PortailInformations } from '../../datas'
import { Actualites } from '../../pages/Actualites'
import { PortailAccueil } from '../../pages/PortailAccueil'
import photodg from '../../assets/Portail/dg.png'

export const MyContext = createContext()
export const MyContextProvider = ({ children }) => {
    const langue = ['france', 'anglais', 'arabe']
    const [lang, setLang] = useState(0)
    const addLang = (number) => {
        setLang(number)
    }
    
    // const element1 = document.querySelector(
    //     '#localize-widget-langs a:first-child'
    // )
    // if (element1) {
    //     element1.innerHTML = 'AR'
    // }
    // const elements = document.querySelectorAll('#localize-widget-langs a')
    // if (elements.length > 1) {
    //     // elements[1].innerHTML = 'EN'
    //     // elements[2].innerHTML = 'CH'
    //     elements[2].style.display = 'none'
    // }
    // const element = document.querySelector('#localize-widget')
    // if (element) {
    //     element.classList.remove('localize-right-top')
    //     element.classList.add('localize-left-bottom')
    // }
    // const active = document.querySelectorAll('#localize-active-lang')
    // if (active[0 ]) {
    //     active[0].innerHTML = 'FR'
    // } 

    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [infoGen, setInfo] = useState({})
    const [actuData, setActu] = useState({})
    const [eventData, setEvent] = useState({})
    // const [motDuDG, setMot] = useState('')
    const [photoDg, setPhoto] = useState('')
    // const photoDg = PortailInformations.imageDirecteur
    const motDuDG = PortailInformations.motDuDirecteur
    // const actuData = PortailInformations.actualites
    
    

    useEffect(() => {
        async function fetchData() {
            try {
                // setLoading(false)
                // const infoResponse = await fetch(PortailInformations)
                // const { infos } = await infoResponse.json()
                // setInfo(infos)
                // setMot(infos[0].motDuDG)
                // setPhoto(photoDuDG)
                // const actuData = PortailInformations
                // setActu(actuData)
                // const actuResponse = await fetch('https://inphb-api.herokuapp.com/api/portail/actus')
                // const { actus } = await actuResponse.json()
                // setActu(actus)
                // const eventResponse = await fetch('https://inphb-api.herokuapp.com/api/portail/events')
                // const { events } = await eventResponse.json()
                // setEvent(events)
                
                
                
             
                
            } catch (error) {
                setError(true)
                // console.log('=====error=====', error)
            } finally {
                setLoading(false)
            }
        }
            
        fetchData()
    }, [])

    return (
        <>
        <MyContext.Provider
            value={{
                langue,
                lang,
                addLang,
                isLoading,
                error,
                infoGen,
                actuData,
                eventData,
                motDuDG,
                photoDg,
            }}
        >
        
            {children}
        </MyContext.Provider>
       
    </>
    )
}