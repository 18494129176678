import React from 'react'
import styled from 'styled-components'
import { Col, Container, Row } from 'react-bootstrap'
import { useState } from 'react'
import {
    FaChevronDown,
    FaChevronUp,
    FaUserFriends,
    FaUserGraduate,
} from 'react-icons/fa'
import { ImBooks } from 'react-icons/im'
import { BsFillFilePersonFill } from 'react-icons/bs'
import { RiBuildingFill } from 'react-icons/ri'
import { Transitions } from '../../components/Transitions'
import { VictoryPie, VictoryLegend } from 'victory'

const TitleDiv = styled.div`
    width: 100%;
    background-color: rgb(0, 141, 54);
`
const Item = styled.div`
    padding: 10px;
    background-color: ${(prop) => prop.color};
    color: ${(prop) => prop.textColor};
    display: flex;
    border-bottom: 1px solid #f47920;
    justify-content: space-between;
    align-items: center;
    cursor: pointer !important;
`
const MyRow = styled(Row)`
    width: 100%;
    padding-left: 25%;
    @media(max-width:1025px){
        padding-left: 20%;
    }
    @media(max-width:600px){
        padding-left: 15%;
    }
    @media(max-width:490px){
        padding-left: 10%;
    }
    @media(max-width:450px){
        padding-left: 0%;
    }
`
const data = [
    {
        categorie: 'CPGE',
        nombre: 829,
    },
    {
        categorie: 'EDP',
        nombre: 24,
    },
    {
        categorie: 'EFCPC',
        nombre: 1176,
    },
    {
        categorie: 'ESA',
        nombre: 317,
    },
    {
        categorie: 'ESI',
        nombre: 264,
    },
    {
        categorie: 'ESMG',
        nombre: 207,
    },
    {
        categorie: 'ESCAE',
        nombre: 329,
    },
    {
        categorie: 'ESTP',
        nombre: 300,
    },
]

const statistiques = [
    {
        titre: 'Élèves et diplomés',
        logo: <FaUserGraduate />,
        stats: [
            {
                categorie: 'Élèves en formation initiale',
                nombre: '3300',
            },
            {
                categorie: 'De filles',
                nombre: '28.75%',
            },
            {
                categorie: 'Élèves en formation continue',
                nombre: '1176',
            },
            {
                categorie: 'Diplômés/an',
                nombre: '700',
            },
        ],
    },
    {
        titre: 'Formations et Recherche',
        logo: <ImBooks />,
        stats: [
            {
                categorie: 'Grandes écoles de formation initiale',
                nombre: '09',
            },
            {
                categorie: 'Filières de formation',
                nombre: '52',
            },
            {
                categorie: 'Départements de Formation et de Recherche',
                nombre: '14',
            },
            {
                categorie: "Unités Mixtes de Recherche et d'Innovation",
                nombre: '08',
            },
        ],
    },
    {
        titre: 'Moyens Humains',
        logo: <BsFillFilePersonFill />,
        stats: [
            {
                categorie: 'Enseignants',
                nombre: '728',
            },
            {
                categorie: 'Membres du personnel administratif',
                nombre: '201',
            },
            {
                categorie: 'Membres du personnel technique',
                nombre: '120',
            },
        ],
    },
    {
        titre: "Partenariats et Entreprises",
        logo: <FaUserFriends />,
        stats: [
            {
                categorie: 'Entreprises partenaires',
                nombre: '+ 50',
            },
            {
                categorie: 'Établissements partenaires à travers le monde',
                nombre: '+ 30',
            },
        ],
    },
    {
        titre: 'Infrastructures',
        logo: <RiBuildingFill />,
        stats: [
            {
                categorie: 'Ha de superficie générale',
                nombre: '+ 1000',
            },
            {
                categorie: 'Ha de superficie mise en valeur',
                nombre: '400',
            },
            {
                categorie: 'Résidences universitaires',
                nombre: '32',
            },
            {
                categorie: 'Chambres individuelles',
                nombre: '3008',
            },
        ],
    },
]
export const Statistiques = () => {
    const [checkBoxState, setcheckBoxState] = useState([
        false,
        false,
        false,
        false,
        false,
        false,
    ])
    const HanldeCheck = (i) => {
        setcheckBoxState((prevState) =>
            prevState.map((item, idx) => (idx === i ? !item : false))
        )
    }
    const settings = {
        xxl: 6,
        xl: 6,
        lg: 6,
        md: 12,
        sm: 12,
        xs: 12,
    }

    return (
        <Transitions>
            <TitleDiv className="d-flex justify-content-center align-items-center text-light">
                <span
                    className="body-highlight mt-3 text-uppercase ps-2 mb-3 fs-1"
                    style={{ borderLeft: '10px solid #fff' }}
                >
                    Statistiques et ressources
                </span>
            </TitleDiv>
            <Container className="my-5">
                {statistiques.map((statistique, index) => (
                    <div key={`${index}`} id={`actualite-${index}`}>
                        <Item
                            className="text-uppercase my-3"
                            onClick={() => HanldeCheck(index)}
                            color={checkBoxState[index] ? '#f47920' : '#fff'}
                            textColor={checkBoxState[index] ? '#fff' : '#000'}
                        >
                            <div
                                style={{ cursor: 'pointer' }}
                                className="d-flex align-items-center"
                            >
                                <span className="mx-2 fs-4">
                                    {statistique.logo}
                                </span>
                                {statistique.titre}
                            </div>
                            {checkBoxState[index] ? (
                                <FaChevronUp
                                    style={{ cursor: 'pointer', color: '#fff' }}
                                />
                            ) : (
                                <FaChevronDown
                                    style={{
                                        cursor: 'pointer',
                                        color: '#f47920',
                                    }}
                                />
                            )}
                        </Item>
                        <div
                            className={
                                checkBoxState[index]
                                    ? 'd-block mb-5 fade-in'
                                    : 'mb-5 d-none'
                            }
                        >
                            <Row>
                                {statistique.stats.map((stat, i) => (
                                    <Col
                                        className="p-3"
                                        key={`statistique-${i}`}
                                        {...settings}
                                    >
                                        <div
                                            className="w-100 h-100 p-5 d-flex flex-column align-items-center justify-content-center"
                                            style={{
                                                backgroundColor: '#F1F1F1',
                                                borderRadius: '30px',
                                            }}
                                        >
                                            {i % 2 === 0 ? (
                                                <>
                                                    <span
                                                        className="fs-2 text-center fw-bold"
                                                        style={{
                                                            color: '#008D36',
                                                        }}
                                                    >
                                                        {stat.nombre}
                                                    </span>
                                                    <span className="text-center">
                                                        {stat.categorie}
                                                    </span>
                                                </>
                                            ) : (
                                                <>
                                                    <span
                                                        className="fs-2 text-center fw-bold"
                                                        style={{
                                                            color: '#079edb',
                                                        }}
                                                    >
                                                        {stat.nombre}
                                                    </span>
                                                    <span className="text-center">
                                                        {stat.categorie}
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </div>
                ))}
                <div key={`${5}`} id={`actualite-${5}`}>
                    <Item
                        className="text-uppercase my-3"
                        onClick={() => HanldeCheck(5)}
                        color={checkBoxState[5] ? '#f47920' : '#fff'}
                        textColor={checkBoxState[5] ? '#fff' : '#000'}
                    >
                        <div
                            style={{ cursor: 'pointer' }}
                            className="d-flex align-items-center"
                        >
                            <span className="mx-2 fs-4">
                                <FaUserGraduate />
                            </span>
                            Effectif par école
                        </div>
                        {checkBoxState[5] ? (
                            <FaChevronUp
                                style={{ cursor: 'pointer', color: '#fff' }}
                            />
                        ) : (
                            <FaChevronDown
                                style={{
                                    cursor: 'pointer',
                                    color: '#f47920',
                                }}
                            />
                        )}
                    </Item>
                    <div
                        className={
                            checkBoxState[5]
                                ? 'd-flex mb-5 fade-in align-items-center justify-content-center'
                                : 'mb-5 d-none'
                        }
                    >
                        <MyRow className="d-flex align-items-center justify-content-center border mb-5">
                            <Col>
                                <VictoryPie
                                    data={data}
                                    // padding={{ top: 50, bottom: 60 }}
                                    colorScale={[
                                        '#096678',
                                        '#f7a600',
                                        '#bf0538',
                                        '#00a651',
                                        '#74294b',
                                        '#cc580a',
                                        '#234d9d',
                                        '#612c85',
                                    ]}
                                    padAngle={1}
                                    innerRadius={100}
                                    x="nombre"
                                    y="nombre"
                                /></Col>
                            <Col>
                                <VictoryLegend
                                    x={1}
                                    y={10}
                                    orientation="vertical"
                                    width={400}
                                    gutter={20}
                                    style={{ border: { stroke: 'black' }, }}
                                    colorScale={[
                                        '#096678',
                                        '#f7a600',
                                        '#bf0538',
                                        '#00a651',
                                        '#74294b',
                                        '#cc580a',
                                        '#234d9d',
                                        '#612c85',
                                    ]}
                                    data={[
                                        {
                                            name: 'CPGE',
                                        },
                                        {
                                            name: 'EDP',
                                        },
                                        {
                                            name: 'EFCPC',
                                        },
                                        {
                                            name: 'ESA',
                                        },
                                        {
                                            name: 'ESI',
                                        },
                                        {
                                            name: 'ESMG',
                                        },
                                        {
                                            name: 'ESCAE',
                                        },
                                        {
                                            name: 'ESTP',
                                        },
                                    ]}
                                />
                            </Col>
                        </MyRow>
                    </div>
                </div>
            </Container>
        </Transitions>
    )
}
