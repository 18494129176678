import styled from 'styled-components'
import '../../utils/style/index.scss'
import { useEffect, useState } from 'react'
import { TbMessageCircle2 } from 'react-icons/tb'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { FiSend } from 'react-icons/fi'
import { Form, InputGroup } from 'react-bootstrap'
import moya from '../../assets/Portail/Moya.png'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { motion } from 'framer-motion'
import { FcBusinessman } from 'react-icons/fc'

const HamburgerMenuWrapper = styled.div`
    position: fixed;
    z-index: 11;
    bottom: 170px;
    right: 20px;
    background-color: #fff;
    border-radius: 30px;
    max-height: 31.75rem;
    max-width: 23rem;
    overflow: auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-top: 10px solid #079edb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`
const TextStart = styled.p`
    font-size: 0.6em !important;
`
const TextEnd = styled.p`
    font-size: 0.7em !important;
`
const MessagesWrapper = styled.div`
    width: 100%;
`
const Message = styled.div`
    background-color: #e6e6e6;
    padding: 15px;
    border-radius: 15px;
    font-size: 0.75em;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`
const OpenIcon = styled(motion.div)`
    position: fixed;
    z-index: 25;
    bottom: 50px;
    right: 20px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    color: #fff;
    background-color: #079edb;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5em;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 50%;
`
const CloseIcon = styled.div`
    position: fixed;
    z-index: 25;
    bottom: 100px;
    right: 20px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    color: #fff;
    background-color: #079edb;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 50%;
`

function Moya() {
    const [isOpen, setisOpen] = useState(false)
    const [response, setResponse] = useState('')
    const [moyaResponse, setMoyaResponse] = useState('Coucou')
    const [messages, setMessages] = useState([
        {
            message: ['Salut !', 'Comment puis-je vous aider ?'],
            expediteur: 'Moya',
        },
        {
            message: ['Salut Moya !'],
            expediteur: 'Client',
        },
    ])
    const updateMessagesMoya = (text) => {
        setMessages([
            ...messages,
            {
                message: [text],
                expediteur: 'Client',
            },
            {
                message: [moyaResponse],
                expediteur: 'Moya',
            },
        ])
        ;(text.includes('ecoles') || text.includes('ecole')) &&
        setTimeout(function open() {
            window.location.assign('/ecoles')
        }, 5000)
            
    }

    const updateMessagesClient = (mes) => {
        mes.includes('ecoles') || mes.includes('ecole')
            ? setMoyaResponse('Vous serez redirigé vers la page des Écoles')
            : setMoyaResponse('Okay ! Compris')
        updateMessagesMoya(mes)
        setResponse('')
    }

    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])
    return isOpen ? (
        <>
            <HamburgerMenuWrapper data-aos="fade-up" className="px-3 pb-2 pt-2">
                <TextStart className="text-muted text-center mb-3 mt-3">
                    Moya from INP-HB Digital<br />
                    Nous sommes ici pour vous !
                </TextStart>
                {messages.map((mes, index) =>
                    mes.expediteur === 'Moya' ? (
                        <MessagesWrapper
                            className="mb-1 d-flex justify-content-start align-items-start"
                            key={`Messages-Moya${index}`}
                        >
                            <img
                                src={moya}
                                alt="MOYA"
                                width={40}
                                style={{
                                    borderRadius: '50%',
                                    backgroundColor: '#E6E6E6',
                                    boxShadow:
                                        'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                                    padding: 10,
                                    marginRight: 5,
                                    marginTop: 10,
                                }}
                            />
                            <div>
                                {mes.message.map((cesMes, index) => (
                                    <Message
                                        className="my-1 w-100 flex-wrap"
                                        key={`Message-Moya${index}`}
                                    >
                                        {cesMes}
                                    </Message>
                                ))}
                            </div>
                        </MessagesWrapper>
                    ) : (
                        <MessagesWrapper
                            className="mb-1 d-flex justify-content-end align-items-center"
                            key={`Messages-Client${index}`}
                        >
                            <div>
                                {mes.message.map((cesMes, index) => (
                                    <Message
                                        className="my-1 w-100 flex-wrap"
                                        key={`Messages-Client${index}`}
                                    >
                                        {cesMes}
                                    </Message>
                                ))}
                            </div>{' '}
                            <div
                                style={{
                                    borderRadius: '50%',
                                    backgroundColor: '#E6E6E6',
                                }}
                                className="p-2 ms-2"
                            >
                                <FcBusinessman style={{ fontSize: '1.5em' }} />
                            </div>
                        </MessagesWrapper>
                    )
                )}

                <InputGroup className="mt-3">
                    <Form.Control
                        type="text"
                        className="mb-2"
                        placeholder="Reply to Moya"
                        onChange={(e) => setResponse(e.target.value)}
                        value={response}
                        style={{
                            borderRadius: '20px 0px 0px 20px',
                            fontSize: '.98em',
                        }}
                    />
                    <InputGroup.Text
                        className="mb-2"
                        style={{
                            borderRadius: '0px 20px 20px 0px',
                            fontSize: '.98em',
                            cursor: 'pointer',
                        }}
                    >
                        <FiSend
                            onClick={() =>
                                response && updateMessagesClient(response)
                            }
                        />
                    </InputGroup.Text>
                </InputGroup>
                <TextEnd className="text-muted"> Chat ✨ avec Moya</TextEnd>
            </HamburgerMenuWrapper>
            <CloseIcon onClick={() => setisOpen(!isOpen)} className="mt-5">
                <AiOutlineCloseCircle
                    style={{ width: '50px', height: '50px' }}
                />
            </CloseIcon>
        </>
    ) : (
        <OpenIcon onClick={() => setisOpen(!isOpen)} title="Moya" drag>
            <TbMessageCircle2 style={{ width: '50px', height: '50px' }} />
        </OpenIcon>
    )
}

export default Moya
