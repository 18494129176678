import React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'

const Wrapper = styled(motion.div)`
    overflow: hidden;
`
const Mytransition = {
    hidden: {
        opacity: 0,
        x: '100vw', 
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: { duration: 1.5 },
    },
    exit: {
        opacity: 0,
        x: '-100vw',
        transition: {ease: 'easeInOut', duration: 1}
    },
}

export const Transitions = ({ children }) => {
    return (
        <Wrapper
            variants={Mytransition}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            {children}
        </Wrapper>
    )
}
