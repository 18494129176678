import React from 'react'
import { Container } from 'react-bootstrap'
import '../../utils/style/index.scss'
import Construction from '../../assets/PageEnConstruction.jpg'
import styled from 'styled-components'

const Title = styled.h1`
    font-size: 2em;
    @media(max-width: 453px){
      font-size: 1.5em;
    }
`
const Image = styled.img`
    width: 65%;
    @media(max-width: 1200px){
      width: 75%;
    }
    @media(max-width: 1015px){
      width: 85%;
    }
    @media(max-width: 879px){
      width: 90%;
    }
    @media(max-width: 645px){
      width: 100%;
    }
`

export const PageEnConstruction = () => {
    return (
        <Container>
            <Title className="title-text fw-bold text-uppercase text-center mb-5 mt-5">
                {' '}
                Page En Construction{' '}
            </Title>
            <div className="d-flex align-items-center justify-content-center">
                <Image
                    src={Construction}
                    alt=""
                    style={{ borderRadius: '50%' }}
                />
            </div>
        </Container>
    )
}
