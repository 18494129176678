import React from 'react'
import { Container } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

const LinkWrapper = styled(Container)`
    overflow: auto;
    @media (max-width: 365px) {
        padding-left: 30%;
    }
    @media (max-width: 288px) {
        padding-left: 50%;
    }
    @media (max-width: 248px) {
        padding-left: 70%;
    }
    & >a {
        transition: transform 250ms ease-in-out;
        &:hover {
            transform: scale(1.09) !important;
            font-weight: bold !important;
        }
        @media (max-width: 433px) {
            margin: 5px !important;
        }
        @media (max-width: 403px) {
            font-size: 9px !important;
        }
    }
`

const SchoolNavbarWrapper = styled.div`
    background-color: ${(prop) => prop.color};
    display: flex;
    justify-content: center;
    align-items: center;
`
const SchoolName = styled.div`
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;
    @media (max-width: 730px) {
        flex-direction: column;
        justify-content: center;
    }
`
const SchoolNameText = styled.span`
    font-style: normal;
    font-weight: bold;
    font-size: 2.5em;
    padding-left: 10px;
    border-left: 10px solid #fff;
    text-align: center;
    color: #fff;
`

export const Epreuves = () => {
    const navLinkStyles = ({ isActive }) => {
        return {
            fontWeight: isActive ? 'bold' : 'normal',
            textDecoration: 'none',
            color: isActive ? '#fff' : '#008D36',
            background: isActive ? '#008D36': '#fff',
            padding: '10px 20px',
            fontSize: '12px',
            letterSpacing: '2px',
            borderRadius: '5px',
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        }
    }
    return (
        <>
            <SchoolNavbarWrapper color={'#008D36'} className="mb-3">
                <SchoolName>
                    <div className="w-100 text-center text-uppercase">
                        <SchoolNameText>
                            Épreuves
                        </SchoolNameText>
                    </div>
                </SchoolName>
            </SchoolNavbarWrapper>
            <LinkWrapper className="d-flex justify-content-center align-items-center py-3">
                <NavLink
                    to="a2gp"
                    className={'text-uppercase mx-2'}
                    style={navLinkStyles}
                >
                    a2gp
                </NavLink>
                <NavLink
                    to="stic"
                    className={'text-uppercase mx-2'}
                    style={navLinkStyles}
                >
                    stic
                </NavLink>
                <NavLink
                    to="gic"
                    className={'text-uppercase mx-2'}
                    style={navLinkStyles}
                >
                    gic
                </NavLink>
                <NavLink
                    to="cae"
                    className={'text-uppercase mx-2'}
                    style={navLinkStyles}
                >
                    cae
                </NavLink>
                {/* <NavLink
                    to="ita"
                    className={'text-uppercase mx-2'}
                    style={navLinkStyles}
                >
                    ita
                </NavLink> */}
            </LinkWrapper>
            <Outlet />
        </>
    )
}
