import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import styled from 'styled-components'
import Histoire from '../../assets/Portail/historique.png'
import { Transitions } from '../../components/Transitions'
import { PortailInformations } from '../../datas'

const TitleDiv = styled.div`
    width: 100%;
    background-color: rgb(0, 141, 54);
`
const Item = styled.div`
    padding: 10px;
    background-color: ${(prop) => prop.color};
    color: ${(prop) => prop.textColor};
    display: flex;
    border-bottom: 1px solid #f47920;
    justify-content: space-between;
    align-items: center;
    cursor: pointer !important;
`
export const Historique = () => {
    const [checkBoxState, setcheckBoxState] = useState([false, false, false, false,false, false, false, false, false])
    const HanldeCheck = (i) => {
        setcheckBoxState((prevState) =>
            prevState.map((item, idx) => (idx === i ? !item : false))
        )
    }
    return (
        <Transitions>
            <TitleDiv className="d-flex justify-content-center align-items-center text-light mb-5">
                <span
                    className="body-highlight mt-3 text-uppercase ps-2 mb-3 fs-1"
                    style={{ borderLeft: '10px solid #fff' }}
                >
                    Historique
                </span>
            </TitleDiv>
            <Container className='mb-5'>
                <div className='body-text border-0 mb-2'>
                    L’Institut National Polytechnique Félix HOUPHOUËT-BOIGNY
                    (INP-HB) a été crée par décret 96-678 du 04 Septembre 1996,
                    de la restructuration de l’Ecole Nationale Supérieure
                    d’Agronomie(ENSA), l’Ecole Nationale Supérieure des Travaux
                    Publics ( ENSTP), l’Institut Agricole de Bouaké (IAB) et de
                    l’Institut National Supérieure de l’Enseignement Technique (
                    INSET). Quatre établissements que l’on désigne communément
                    sous le vocable de Grandes Écoles de Yamoussoukro. <br /><br /> La
                    création de l’INP-HB répond à plusieurs objectifs : <br /><br /> La
                    réduction des coûts structurels et une allocation plus
                    pertinente des ressources disponibles ; L'harmonisation des
                    politiques de formation ; L'amélioration de la qualité des
                    enseignements et le rapprochement de la formation et
                    l'emploi.
                </div>
                <img src={Histoire} alt="" style={{width:"100%"}}/>
            </Container>
            <Container>
                {PortailInformations.histoires.map((histoire, index) => (
                    <div
                        key={`actualites-${histoire.titre}-${index}`}
                        id={`actualites-${index}`}
                    >
                        <Item
                            className="text-uppercase my-1"
                            onClick={() => HanldeCheck(index)}
                            color={checkBoxState[index] ? '#f47920' : '#fff'}
                            textColor={checkBoxState[index] ? '#fff' : '#000'}
                        >
                            <div style={{ cursor: 'pointer' }}>
                                {histoire.titre}
                            </div>
                            {checkBoxState[index] ? (
                                <FaChevronUp style={{ cursor: 'pointer', color: "#fff" }} />
                            ) : (
                                <FaChevronDown style={{ cursor: 'pointer', color: "#f47920" }} />
                            )}
                        </Item>
                        <div
                            className={
                                checkBoxState[index]
                                    ? 'd-block mb-5 fade-in my-3'
                                    : 'mb-5 d-none'
                            }
                        >
                            <div className="body-text border-0">
                                {histoire.description}
                            </div>
                        </div>
                    </div>
                ))}
            </Container>
        </Transitions>
    )
}
