import React from 'react'
import "./loader.scss"

export const Loader = () => {
    return (
        <div className="loader">
            <div className="loader_item loader-item--1"></div>
            <div className="loader_item loader-item--2"></div>
            <div className="loader_item loader-item--3"></div>
            <div className="loader_item loader-item--4"></div>
            <div className="loader_item loader-item--5"></div>
        </div>
    )
}
