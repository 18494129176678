import styled from 'styled-components'
import { Link, NavLink, Outlet, useParams } from 'react-router-dom'
import '../../utils/style/index.scss'
import { concours } from '../../datas'
import { Container } from 'react-bootstrap'

// const BachelierWrapper = styled.div`
//     overflow: hidden;
//     background: #ffffff;
// `

const LinkWrapper = styled(Container)`
    border-top: 2px solid #008d36;
    border-bottom: 2px solid #008d36;
    overflow: auto;
    @media (max-width: 613px) {
        padding-left: 20%;
    }
    @media (max-width: 455px) {
        padding-left: 30%;
    }
    @media (max-width: 344px) {
        padding-left: 70%;
    }
    @media (max-width: 264px) {
        padding-left: 100%;
    }
    & > a {
        transition: transform 250ms ease-in-out;
        &:hover {
            transform: scale(1.09) !important;
            font-weight: bold !important;
        }
        @media (max-width: 523px) {
            font-size: 12px !important;
            margin: 5px !important;
        }
        @media (max-width: 422px) {
            font-size: 9px !important;
        }
    }
`
// const MyNav = styled.nav`
//     background-color: #d9d9d9;
//     display: flex;
//     padding: 10px;
//     justify-content: space-evenly;
//     align-items: center;
//     font-size: 1em;
//     @media (max-width: 400px) {
//         flex-wrap: nowrap !important;
//         overflow-x: auto !important;
//         width: 100%;
//         -webkit-overflow-scrolling: touch;
//         &::-webkit-scrollbar {
//             display: none;
//         }
//     }
//     @media (max-width: 315px) {
//         padding-left: 25%;
//     }
//     & > a {
//         transition: all 350ms ease-in-out;
//         &:hover {
//             transform: scale(1.1);
//             font-weight: bold !important;
//         }
//     }
// `

function CeConcours() {
    const { contestName } = useParams()
    const monConcours = concours[`${contestName}`]
    const navLinkStyles = ({ isActive }) => {
        return {
            fontWeight: isActive ? 'bold' : 'normal',
            textDecoration: 'none',
            color: isActive ? '#fff' : '#027c30',
            background: isActive ? '#027c30' : '#fff',
            padding: '10px 20px',
            fontSize: '16px',
            letterSpacing: '2px',
            borderRadius: '5px',
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        }
    }
    return monConcours ? (
        <>
            <p className="text-dark text-start ms-4 my-2">
                <Link to="/concours" className="me-1">
                    CONCOURS{' '}
                </Link>{' '}
                | {monConcours.title}
            </p>
            <LinkWrapper
                className="text-uppercase d-flex justify-content-center align-items-center py-4"
                fluid
            >
                <NavLink
                    style={navLinkStyles}
                    to=""
                    end
                    className=" menu-items"
                >
                    Accueil
                </NavLink>
                <NavLink
                    style={navLinkStyles}
                    to="presentation"
                    className=" menu-items mx-3"
                >
                    Présentation
                </NavLink>
                {monConcours.title === 'ingenieur' && (
                    <NavLink
                        style={navLinkStyles}
                        to="epreuves"
                        className=" menu-items mx-3"
                    >
                        Épreuves
                    </NavLink>
                )}
                <NavLink
                    style={navLinkStyles}
                    to={
                        monConcours.title === 'bachelier'
                            ? 'sujets_concours_bachelier'
                            : 'sujets_concours_ing'
                    }
                    className=" menu-items ms-2"
                >
                    Sujets
                </NavLink>
            </LinkWrapper>
            <Outlet />
        </>
    ) : (
        window.location.replace('/erreur')
    )
}

export default CeConcours
