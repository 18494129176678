import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { FaBed, FaMailBulk, FaPhone } from 'react-icons/fa'
import Chambre1 from '../../assets/Portail/chambre1.jpg'
import Chambre2 from '../../assets/Portail/chambre2.jpg'
import Chambre3 from '../../assets/Portail/chambre3.jpg'
import Chambre4 from '../../assets/Portail/chambre4.jpg'
import styled from 'styled-components'
import { FcBusinesswoman } from 'react-icons/fc'
import { NavLink } from 'react-router-dom'
import { Transitions } from '../../components/Transitions'

const Studios = styled.div`
    background-color: #089bd9;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
`
const MyCard = styled(Card)`
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    @media (max-width: 590px) {
        font-size: 0.9em;
    }
`
const MyCol = styled(Col)`
    @media (max-width: 475px) {
        flex-basis: 80% !important;
        margin-bottom: 10px;
    }
    @media (max-width: 330px) {
        flex-basis: 90% !important;
    }
`
const Circle = styled.div`
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    color: #000;
    padding: 25px;
    margin-bottom: 10px;
`
const BottomCircle = styled.div`
    border: 1px solid #ffa656;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #ffa656;
    width: 100%;
    padding: 5px;
`
export const Hebergement = () => {
    return (
        <Transitions>
            <div className="text-dark text-start ms-4 my-2 d-flex">
               <NavLink to='/portail/vie_universitaire' className="me-2">Vie à l'INP-HB </NavLink> | Hébergement
            </div>
            <div
                style={{ backgroundColor: '#E6E6E6' }}
                className="p-3 py-4 text-justify text-center fs-3 body-text border-0"
            >
                Hébergement
            </div>
            <Studios>
                <div className="d-flex flex-column align-items-center justify-content-around mt-3 fs-3">
                    <FaBed style={{ fontSize: '50px' }} />
                    <div>3008 STUDIOS</div>
                    <span className="fs-5">répartis comme suit</span>
                </div>
                <div className="d-flex align-items-center justify-content-around mt-3 fs-4 mb-3">
                    <div className="d-flex flex-column align-items-center justify-content-center me-5">
                        <Circle>1504</Circle>
                        <BottomCircle>SUD</BottomCircle>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <Circle>1504</Circle>
                        <BottomCircle>CENTRE</BottomCircle>
                    </div>
                </div>
            </Studios>
            <div
                style={{ backgroundColor: '#E6E6E6' }}
                className="p-3 text-center"
            >
                Tous les élèves admis au concours ont droit d’office à un studio
                respectant les critères de commodité. Les résidences de l’INP-HB
                sont subventionnées à 80% par l’État pour les élèves nationaux.
            </div>
            <Row>
                <Col className="p-0 pe-1">
                    <img src={Chambre1} alt="" style={{ width: '100%' }} />
                </Col>
                <Col className="p-0 pe-1">
                    <img src={Chambre2} alt="" style={{ width: '100%' }} />
                </Col>
                <Col className="p-0 pe-1">
                    <img src={Chambre3} alt="" style={{ width: '100%' }} />
                </Col>
                <Col className="p-0">
                    <img src={Chambre4} alt="" style={{ width: '100%' }} />
                </Col>
            </Row>
            <div
                style={{ backgroundColor: '#E6E6E6' }}
                className="p-3 px-5 text-justify"
            >
                <strong>
                    INP-HB... Un cadre agréable et propice aux études:
                </strong>{' '}
                <br />
                <p>
                    Un logement confortable, chaleureux sur les cités INP-Sud et
                    INP-Centre. Studio privé avec douche intégrée entièrement
                    meublé (un lit, une ARMOIRE, un bureau d’étude). <br />
                    Le logement inclut l’eau chaude, l’électricité, l’accès à
                    Internet. <br /> Une belle option pour
                    des études en toute tranquillité.
                </p>
            </div>
            <p className="mt-5 text-center fw-bold text-uppercase">
                responsable hébergement
            </p>
            <Row className="d-flex flex-wrap align-items-center justify-content-center">
                {/* <MyCol xxl={3} xl={3} lg={4} md={5} sm={6} xs={6}>
                    <MyCard
                        className="d-flex flex-column align-items-center justify-content-center p-2 mb-5"
                        style={{
                            width: '100%',
                            height: '100%',
                            border: '0px',
                        }}
                    >
                        <div
                                style={{
                                    borderRadius: '50%',
                                    backgroundColor: '#E6E6E6',
                                }}
                                className="p-2"
                            >
                                <FcBusinesswoman
                                    style={{ fontSize: '4.5em' }}
                                />
                            </div>
                        <p className="mb-3 mt-2 text-center">
                            Mme COULIBALY
                        </p>
                        <div className="mb-3 text-center">
                            <FaPhone style={{ color: '#0DBA1E' }} /> (00225)
                            0777372111
                        </div>
                        <div className="mb-5 text-center">
                            <FaMailBulk style={{ color: '#0DBA1E' }} />{' '}
                            hebergementcentre@inphb.ci
                        </div>
                        <p className="text-uppercase text-center fw-bold">
                            site centre
                        </p>
                    </MyCard>
                </MyCol> */}
                <MyCol xxl={3} xl={3} lg={4} md={5} sm={6} xs={6}>
                    <MyCard
                        className="d-flex flex-column align-items-center justify-content-center p-2 mb-5"
                        style={{
                            width: '100%',
                            height: '100%',
                            border: '0px',
                        }}
                    >
                        <div
                                style={{
                                    borderRadius: '50%',
                                    backgroundColor: '#E6E6E6',
                                }}
                                className="p-2"
                            >
                                <FcBusinesswoman
                                    style={{ fontSize: '4.5em' }}
                                />
                            </div>
                        <p className="mb-3 mt-2 text-center">
                            Mme LARISSA
                        </p>
                        <div className="mb-3 text-center">
                            <FaPhone style={{ color: '#089BD9' }} /> (00225)
                            0708078208
                        </div>
                        <div className="mb-5 text-center">
                            <FaMailBulk style={{ color: '#089BD9' }} />{' '}
                            larissa.kouadio@inphb.ci
                        </div>
                        <p className="text-uppercase text-center fw-bold">
                            site Sud/Centre
                        </p>
                    </MyCard>
                </MyCol>
            </Row>
        </Transitions>
    )
}
