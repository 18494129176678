import React from 'react'
import { Card, Col, Container } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { Transitions } from '../../components/Transitions'

function importAll(r) {
    let images = {}
    r.keys().map((item, index) => (images[item.replace('./', '')] = r(item)))
    return images
}

const portail = importAll(
    require.context('../../assets/Portail', false, /\.(png|jpe?g|svg)$/)
)

const FiliereWrapper = styled.div`
    width: 100%;
    flex-wrap: wrap;
`
const FiliereCard = styled(Col)`
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    @media (max-width: 440px) {
        flex-basis: 100% !important;
    }
`
const vies = [
    {
        nom: 'Hébergement',
        image: portail['hebergement.jpg'],
        link: 'hebergement',
    },
    {
        nom: 'Restauration',
        image: portail['restauration.jpg'],
        link: 'restauration',
    },
    {
        nom: 'Vie Associative',
        image: portail['vieAssociative.jpg'],
        link: 'vie_associative',
    },
    {
        nom: 'Activités Sportives',
        image: portail['activitesSportives.jpg'],
        link: 'activites_sportives',
    },
    {
        nom: 'Assistance Sociale et Santé',
        image: portail['santeB.jpg'],
        link: 'assistance_sociale_et_sante',
    },
    {
        nom: 'Bureau Des Étudiants INP-HB',
        image: portail['BDE.jpg'],
        link: 'bureau_des_eleves',
    },
]

export const VieUniversitaireAccueil = () => {
    return (
        <Transitions>
            <Container>
            <FiliereWrapper className=" d-flex align-items-center justify-content-center p-3">
                {vies.map((vie, index) => (
                    <FiliereCard
                        key={`${vie.nom}-${index}`}
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={6}
                        sm={6}
                        xs={6}
                    >
                        <NavLink className="text-dark" to={vie.link}>
                            {' '}
                            <Card
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    border: '0px',
                                    boxShadow:
                                        'rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
                                }}
                            >
                                <Card.Img variant="top" src={vie.image} />
                                <Card.Body>
                                    <p className="text-center">{vie.nom}</p>
                                </Card.Body>
                            </Card>
                        </NavLink>
                    </FiliereCard>
                ))}
            </FiliereWrapper>
        </Container>
        </Transitions>
        
    )
}
