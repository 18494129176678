import React from 'react'
import { Carousel, Container } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import MeilleursEleves from '../../components/MeilleursEleves'
import NosTerrains from '../../components/NosTerrains'
import { Transitions } from '../../components/Transitions'

function importAll(r) {
    let images = {}
    r.keys().map((item, index) => (images[item.replace('./', '')] = r(item)))
    return images
}

const portail = importAll(
    require.context('../../assets/Portail', false, /\.(png|jpe?g|svg)$/)
)

const NosMeilleursJoueurs = styled(Container)`
    width: 100%;
    background-color: #008D36;
    padding-bottom: 70px;
`
const CaptionText = styled.p`
    font-size: 1em !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`
const MyCaption = styled(Carousel.Caption)`
    font-size: 1em !important;
    @media (max-width: 550px) {
        font-size: 0.55em !important;
    }
    @media (max-width: 385px) {
        font-size: 0.45em !important;
    }
    @media (max-width: 305px) {
        font-size: 0.35em !important;
    }
`
const MyCarousel = styled(Carousel)`
    width: 100%;
`
const Equipes = [
    {
        nom: "Basket-ball",
        image: portail["basket.jpg"],
    },
    {
        nom: "Football",
        image: portail["football.jpg"],
    },
    {
        nom: "Handball",
        image: portail["handball.jpg"],
    },
    {
        nom: "Judo",
        image: portail["JUDO.jpg"],
    },
     {
        nom: "Natation",
        image: portail["natation.jpg"],
    },
     {
        nom: "Volleyball",
        image: portail["volleyball.jpg"],
    },
]
const MeilleursJoueurs = [
    {
        name: 'Traoré Maïmouna',
        picture: portail['sportMeilleurBasket.jpg'],
        filiere: 'MVP Basket-Ball Feminin',
    },
    {
        name: 'Koffi Ezechiel',
        picture: portail['sportMeilleurVolleyG.jpg'],
        filiere: 'MVP Volleyball',
    },
    {
        name: 'Brindou Yann',
        picture: portail['sportMeilleurVolley.jpg'],
        filiere: 'Meilleur Attaquant Volleyball',
    },
    {
        name: 'Dosso Magnama',
        picture: portail['sportMeilleurVolleyF.jpg'],
        filiere: 'MVP Volleyball Feminin',
    },
    {
        name: 'Konate Tidiane',
        picture: portail['tidiane.jpg'],
        filiere: 'MVP Finale Basketball',
    },
]

export const ActivitesSportives = () => {

    return (
        <Transitions>
            <div className="text-dark text-start ms-4 my-2 d-flex">
               <NavLink to='/portail/vie_universitaire' className="me-2"> Vie à l'INP-HB </NavLink> | Activités Sportives
            </div>
            <Container className="my-4 align-items-center justify-content-center d-flex flex-column">
                <div
                    style={{ backgroundColor: '#E6E6E6' }}
                    className="p-3 py-4 text-justify text-center fs-3 body-text border-0 text-capitalize w-100"
                >
                    Activités sportives
                </div>
                <MyCarousel>
                    {Equipes.map((equipe, index)=>(
                        <Carousel.Item key={`Sport-${index}`}>
                            <img
                                className="d-block w-100"
                                src={equipe.image}
                                alt="First slide"
                            />
                            <MyCaption>
                                <CaptionText
                                    style={{ fontSize: '.65em !important' }}
                                >
                                    {equipe.nom}
                                </CaptionText>
                            </MyCaption>
                        </Carousel.Item>
                    ))}
                </MyCarousel>
                <div className="body-text border-0 mt-5">
                    Le sport occupe une place de choix dans la formation des
                    élèves de l'INP-HB. Au regard du bien-être qu'il procure,
                    l'Institut s'est doté de plusieurs installations sportives
                    pour permettre à ses élèves de pratiquer aussi bien les
                    sports individuels que collectifs. Au-delà des cours
                    d'Education Physique et Sportive, l'Institut a initié le
                    championnat inter-écoles depuis plusieurs années. Cette
                    compétition sportive qui oppose les différentes écoles
                    (ESCAE, ESI, ESA, ESMG, ESTP et les CPGE) de l'INP-HB vise à
                    créer un brassage entre les étudiants. L'idée est de faire
                    disparaitre progressivement les barrières de toute sorte
                    donnant ainsi lieu à la construction d'un véritable « esprit
                    INP ». Il est axé sur des sports collectifs (basket-ball,
                    handball, football et volleyball) et individuels
                    (athlétisme, natation, judo, teqball).
                    <br />
                    <br /> Au-delà du jeu, cette activité sportive permet en
                    outre de cultiver chez les étudiants, des valeurs qui les
                    aideront non seulement à vivre en communauté mais aussi à
                    manager plus tard des équipes. Il s'agit entre autres de :
                    l'efficacité, la compétitivité, la solidarité et la
                    performance.
                </div>
            </Container>
            <NosMeilleursJoueurs
                    className="pb-3 pt-5 mb-2"
                    fluid
                    data-aos="fade-up"
                >
                    <Container>
                        <p className="body-highlight text-center text-light text-uppercase fw-bold pt-0 mt-0">
                            meilleurs joueurs
                        </p>
                        <MeilleursEleves eleves={MeilleursJoueurs} />
                    </Container>
                </NosMeilleursJoueurs>
            <Container>
                <div className="text-center text-uppercase fs-2 my-3">
                    Nos différentes aires de jeu
                </div>
                <NosTerrains />
            </Container>
        </Transitions>
    )
}
