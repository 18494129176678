import React from 'react';
import Modal from 'react-modal';
import classes from './newplace.module.css';
import { FaSchool } from "react-icons/fa";

Modal.setAppElement('#root'); // Assurez-vous que ceci pointe vers l'élément racine de votre application

const ModalFiliere = ({ isOpen, onRequestClose, filiere }) => {
  if (!filiere) return null;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Détails de la filière"
      className={classes.modalContent}
      overlayClassName={classes.modalOverlay}
    >
        <div className={classes.modalinside}>
            <h2>{filiere.filiere}</h2>
            <hr />
            <p><strong>École :</strong> {filiere.ecole} <strong>({filiere.codeEcole})</strong></p>
            <p><strong>Niveau :</strong> {filiere.niveau}</p>
            <p><strong>Cycle :</strong> {filiere.codeCycle}</p>
            <p><strong>Spécialité :</strong> {filiere.specialite}</p>
            <p><strong>Accès : </strong> <FaSchool /> <span>{filiere.acces}</span> </p>

            <hr />
            <div className={classes.courseslefttitle} > 
                <button onClick={onRequestClose}>Fermer</button>
            </div>
            
        </div>
      
    </Modal>
  );
};

export default ModalFiliere;
