import React, { useState, useEffect, useRef } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './sliding.css';
import Slider from 'react-slick';

function Sliding() {
    const [slides, setSlides] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderRef = useRef(null);

    useEffect(() => {
        // Fetch slides from API
        fetch('https://www.adminsite.inphb.app/get_slides.php')
            .then(response => response.json())
            .then(data => setSlides(data))
            .catch(error => console.error('Error fetching slides:', error));
    }, []);

    const next = () => {
        sliderRef.current.slickNext();
    };
  
    const prev = () => {
        sliderRef.current.slickPrev();
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 5000,
        fade: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe: true,
        arrows: true,
        afterChange: (index) => {
            console.log("Slide Changed to: ", index);
            setCurrentSlide(index);
        }
    };

    return (
        <>
            {/* <button onClick={prev}>Previous</button> */}
            <Slider ref={sliderRef} className='slider_container' {...settings}>
                {slides.map((slide, index) => (
                    <div className='slider_slides' key={index}>
                        <img src={`https://adminsite.inphb.app/public/mainsliderimages/${slide.image}`} alt={slide.alt} />
                        <div className={`slider_text1 ${currentSlide === index ? 'active' : ''}`}>
                            {slide.button1Text && (
                                <button className='button1' onClick={() => window.open(slide.button1Link)}>{slide.button1Text}</button>
                            )}
                            <br /><br />
                            {slide.button2Text && (
                                <button className='button2' onClick={() => window.open(slide.button2Link)}>{slide.button2Text}</button>
                            )}
                        </div>
                    </div>
                ))}
            </Slider>
            {/* <button onClick={next}>Next</button> */}
        </>
    );
}

export default Sliding;
