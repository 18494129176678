import React from 'react'
import Menu_Bpe from './Menu_Bpe'
import Topimage from './topimagebpe.jpg'
import classes from './Menubpe.module.css'
import HomeFlot from '../components/HomeFlot'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

function Production_Expertise() {


  return (
    <>
        <HomeFlot />
        <Menu_Bpe />
        <div className={classes.top_image}> <img src={Topimage}/> </div>
        <div className={classes.titrehome} >Bureau de production et expertise</div>
        <div className={classes.textehome}>
            <p>
            Le Bureau de Production et d'Expertise (BPE) de l'INP-HB aspire à devenir 
            une structure de liaison essentielle entre le monde académique et le 
            secteur privé. 
            <br />
            Dans le cadre de la nouvelle politique de l'enseignement 
            supérieur en Côte d'Ivoire, le BPE se positionne comme un catalyseur de 
            collaboration, 
            <br />
            favorisant des partenariats stratégiques entre l'INP-HB et 
            les entreprises privées.
            <br /><br />
            En tant que service rattaché au centre d'excellence qu’est l’INP-HB, 
            le BPE vise à promouvoir l'innovation et la productivité, tout en 
            contribuant de manière durable à l'économie...
           
            </p>
            <div className={classes.leboutonvoirplus}>
                <button style={{fontWeight:' 800'}} className='btn btn-success'>Voir plus</button>
            </div>
        </div>
        <div >
            
        </div>
    </>
  )
}

export default Production_Expertise