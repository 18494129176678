import styled from 'styled-components'
import { Card } from 'react-bootstrap'
import '../../utils/style/index.scss'
import Slider from 'react-slick'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'

const Arrow = styled.div`
    font-size: 30px;
    padding: 5px 15px;
    display: flex;
    width: 50px;
    position: absolute;
    color: #fff;
    top: 200px;
    z-index: 100;
    cursor: pointer;
    right: ${(props) => props.posR};
    left: ${(props) => props.posL};
`
const MySlider = styled.div`
    width: 70%;
    margin: auto;
    @media(max-width:1400px){
        width: 80%;
    }
    @media(max-width:1200px){
        width: 100%;
    }
    @media(max-width:1010px){
        width: 70%;
        padding: 20px;
    }
    @media(max-width: 992px){
        width: 100%;
    }
    @media(max-width: 770px){
        width: 60% !important;
    }
    @media(max-width: 560px){
        width: 80% !important;
    }
    @media(max-width: 425px){
        width: 100% !important;
    }
`
const Slide = styled.div`
    width: 70%;  
`
function SampleNextArrow({ onClick }) {
    return (
        <Arrow onClick={onClick} posR="-50px" posL="auto">
            <FaChevronRight />
        </Arrow>
    )
}

function SamplePrevArrow({ onClick }) {
    return (
        <Arrow onClick={onClick} posL="-50px" posR="auto">
            <FaChevronLeft />
        </Arrow>
    )
}

function MeilleursEleves({eleves}) {
    let settings_1 = {
        className: 'mb-5',
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        initialSlide: 0,
        responsive: [
            {
              breakpoint: 1010,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
                dots: false
              }
            },
            {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  initialSlide: 1
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
          ]
    }
    return (
        <MySlider>
            <Slider {...settings_1}>
                {eleves.map((eleve, index) => (
                    <Slide key={`meilleus-${index}`}>
                        <Card
                            style={{
                                width: '18rem',
                                border: '0px',
                            }}
                            key={`${eleve.name}-${index}`}
                        >
                            <Card.Img
                                variant="top"
                                src={eleve.picture}
                                style={{
                                    height:'16rem'
                                }}
                            />
                            <Card.Body>
                                <Card.Title>{eleve.name}</Card.Title>
                                <Card.Text className='mb-1 fw-bold fs-5'>{eleve.moyenne}</Card.Text>
                                <Card.Text>{eleve.filiere}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Slide>
                ))}
            </Slider>
        </MySlider>
    )
}

export default MeilleursEleves
