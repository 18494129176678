import React from 'react'
import { useContext } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import Partenaires from '../../components/Partenaires'
import { Transitions } from '../../components/Transitions'
import '../../utils/style/index.scss'
import { MyContext } from '../../utils/context'
import Error from '../../components/Error'
import {Loader} from '../../components/Loader'
import DOMPurify from 'dompurify'

function importAll(r) {
    let images = {}
    r.keys().map((item, index) => images[item.replace('./', '')] = r(item))
    return images
}

const portail = importAll(
    require.context('../../assets/Portail', false, /\.(png|jpe?g|svg)$/)
)

const TitleDiv = styled.div`
    width: 100%;
    background-color: rgb(0, 141, 54);
`
const Myimg = styled.img`
    width: 100%;
`
const Nospartenaires = [
    {
        image: portail['x.jpg'],
        alt: 'X Polytechnique',
        lien: 'https://www.polytechnique.edu/',
    },
    {
        image: portail['estp.jpg'],
        alt: 'ESTP Paris',
        lien: 'https://www.estp.fr/',
    },
    {
        image: portail['hec.jpg'],
        alt: 'HEC Paris',
        lien: 'https://www.hec.edu/fr',
    },
    {
        image: portail['centraleLyon.jpg'],
        alt: 'Centrale Lyon',
        lien: 'https://www.ec-lyon.fr/',
    },
    {
        image: portail['ottawa.jpg'],
        alt: "Université d'Ottawa",
        lien: 'https://www2.uottawa.ca/fr',
    },
    {
        image: portail['supAgro.svg'],
        alt: 'MontPellier SupAgro',
        lien: 'https://www.institut-agro-montpellier.fr/',
    },
    {
        image: portail['dauphine.jpg'],
        alt: 'Dauphine Tunis',
        lien: 'https://www.tunis.dauphine.fr/',
    },
]
export const BoursesInternationales = () => {

    const {isLoading, error, infoGen} = useContext(MyContext)

    const boursePhoto = infoGen[0] ? infoGen[0].bourseInterImage : null
    const bourseTexte = infoGen[0] ? infoGen[0].bourseInterTexte : null
    const partenaireTexte = infoGen[0] ? infoGen[0].partenaireAcademiqueTexte : null
    const partenairePhoto = infoGen[0] ? infoGen[0].partenaireAcademiqueImage : null

    if(error) return <Error/>

    return (
        <Transitions>
            {isLoading ? (
                <div className='d-flex justify-content-center align-items-center w-100'><Loader/></div>
            ) : (
                <>
                    <TitleDiv className="d-flex justify-content-center align-items-center text-light mb-5">
                <span
                    className="body-highlight mt-3 text-uppercase ps-2 mb-3 fs-1"
                    style={{ borderLeft: '10px solid #fff' }}
                >
                    Bourses à l'international
                </span>
            </TitleDiv>
            {infoGen[0] ? (
               <>
                 <Container>
                <div className="d-flex align-items-center justify-content-center">
                    <Myimg src={`https://inphb-api.herokuapp.com/${boursePhoto.bucket}/${boursePhoto.key}`} 
                    alt="" className="my-3" />
                </div>
                <div className="body-text border-0 my-3" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(bourseTexte)}}/>
            </Container>
            <Container className='align-items-center justify-content-center'>
                <div
                    style={{ backgroundColor: '#E6E6E6' }}
                    className="p-3 py-4 text-justify text-center fs-3 body-text border-0 text-capitalize"
                >
                    Nos partenaires académiques
                </div>
                <Row className='d-flex align-items-center justify-content-center w-100'>
                    <Col xxl={3} xl={4} lg={5} md={6} sm={12} className="m-0">
                        <img
                            src={`https://inphb-api.herokuapp.com/${partenairePhoto.bucket}/${partenairePhoto.key}`}
                            alt="partenaires"
                            className='m-0'
                            style={{ width: '100%' }}
                        />
                    </Col>
                    <Col
                        xxl={8} xl={8} lg={7} md={6} sm={12}
                        style={{ backgroundColor: '#008d36b6' }}
                        className="text-light py-3 px-3 text-justify m-0 align-items-center justify-content-center"
                    >
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(partenaireTexte)}}/>
                    </Col>
                </Row>
            </Container>
            <Container className='my-5'>
                <Partenaires partenaires={Nospartenaires}/>
            </Container>
               </>
            ) : (null)}
                </>
            )}
        </Transitions>
    )
}