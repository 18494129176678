import React from 'react'
import styled from 'styled-components'

const SchoolNavbarWrapper = styled.div`
    background-color: ${(prop) => prop.color};
    display: flex;
    justify-content: center;
    align-items: center;
`
const SchoolName = styled.div`
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;
    @media (max-width: 730px) {
        flex-direction: column;
        justify-content: center;
    }
`
const SchoolNameText = styled.span`
    font-style: normal;
    font-weight: bold;
    font-size: 2.5em;
    padding-left: 10px;
    border-left: 10px solid #fff;
    text-align: center;
    color: #fff;
`
function HeaderConcours() {
    return (
        <>
            <SchoolNavbarWrapper color={'#008D36'} className="mb-3">
                <SchoolName>
                    <div className="w-100 text-center text-uppercase">
                        <SchoolNameText>
                            Concours
                        </SchoolNameText>
                    </div>
                </SchoolName>
            </SchoolNavbarWrapper>
        </>
    )
}

export default HeaderConcours
