import styled from 'styled-components'
import { Carousel, Col, Container, NavbarBrand, Row } from 'react-bootstrap'
import '../../utils/style/index.scss'
import HeaderRecherche from '../../components/HeaderRecherche'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'
import HomeFlot from '../../components/HomeFlot'
import { ChangeLanguage } from '../../components/ChangeLanguage'
import FooterPortailConcours from '../../components/FooterPortailConcours'
import { Transitions } from '../../components/Transitions'
import { NavLink } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';


function importAll(r) {
    let images = {}
    r.keys().map((item, index) => images[item.replace('./', '')] = r(item))
    return images
}

const recherche = importAll(
    require.context('../../assets/Recherche', false, /\.(png|jpe?g|svg)$/)
)

// const RechercheContainer = styled.div`
//     overflow: hidden;
// `
const RechercheWrapper = styled(Container)`
    padding: 0px;
    margin: 0px;
    background-image: url(${recherche['bg.jpg']});
    background-position: 10% 10%;
    background-repeat: no-repeat;
    background-size: cover;
`
const Text = styled.p`
    @media (max-width: 600px) {
        font-size: 0.75em !important;
    }
    @media (max-width: 515px) {
        font-size: 0.7em !important;
    }
`
const TextTitle = styled.p`
    font-size: 3.5em;
    @media (max-width: 600px) {
        font-size: 1em !important;
    }
`

const InnovationWrapper = styled(Container)`
    color: #000;
    padding-top: 50px;
    padding-bottom: 100px;
`
const InnovationCol = styled(Col)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
const ConcoursPosterText = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 55px;
    justify-content: center;
    align-items: center;
    padding: 0px;
    align-items: space-around;
    margin: 0px;
    background: rgba(152, 189, 60, 0.75);
`
const ConcoursPoster = styled.div`
    padding: 0px !important;
    margin: 0px;
    width: 100% !important;
    background-image: ${recherche['bg.jpg']};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 750px) {
        margin-top: 110px;
    }
    @media (max-width: 515px) {
        margin-top: 130px;
    }
    @media (max-width: 385px) {
        margin-top: 140px;
    }
    @media (max-width: 305px) {
        margin-top: 145px;
    }
`
const CaptionTitle = styled.h3`
font-size:.75em !important;
`
const CaptionText = styled.p`
font-size:.65em !important;
`
const MyCaption = styled(Carousel.Caption)`
font-size:.65em !important; 
@media(max-width: 550px){
    font-size:.55em !important; 
}
@media (max-width: 385px) {
    font-size:.45em !important; 
}
@media (max-width: 305px) {
    font-size:.35em !important; 
}


`

function Recherche() {
    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])
    return (
        <Transitions>
            <RechercheWrapper fluid>
                <HomeFlot />
                <ChangeLanguage />
                <HeaderRecherche />
            </RechercheWrapper>
            <ConcoursPoster>
                <ConcoursPosterText >
                    <Carousel className='w-100'>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={recherche['1.jpg']}
                                alt="First slide"
                            />
                            <MyCaption>
                                <CaptionTitle style={{fontSize:".75em !important"}}>Recherche & Innovation</CaptionTitle>
                                <CaptionText style={{fontSize:".65em !important"}}>
                                    Création de solution hydroalcoolique 
                                </CaptionText>
                            </MyCaption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={recherche['2.jpg']}
                                alt="Second slide"
                            />

                            <MyCaption>
                                <CaptionTitle style={{fontSize:".75em !important"}}>Recherche & Innovation</CaptionTitle>
                                <CaptionText style={{fontSize:".65em !important"}}>
                                    Mise en place d'un système de création d'énergie à partir de plants de patates
                                </CaptionText>
                            </MyCaption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src={recherche['3.jpg']}
                                alt="Third slide"
                            />

                            <MyCaption>
                                <CaptionTitle style={{fontSize:".75em !important"}}>Recherche & Innovation</CaptionTitle>
                                <CaptionText style={{fontSize:".65em !important"}}>
                                    Fabrication de gaz à partir de bouses de cochons
                                </CaptionText>
                            </MyCaption>
                        </Carousel.Item>
                    </Carousel>
                </ConcoursPosterText>
            </ConcoursPoster>
            <InnovationWrapper fluid>
                <TextTitle
                    className="text-center text-uppercase mb-0"
                    data-aos="fade-up"
                >
                    thèses et publications
                </TextTitle>
                <Text className="text-center body-text border-0 mb-3" data-aos="fade-up">
                    Découvrez les différents sujets de nos ingénieurs et doctorants.
                </Text>
                <Row style={{ width: '100%', margin: 'auto' }}
                
                xxl={4}
                                xl={4}
                                lg={4}
                                md={4}
                                sm={2}
                                xs={2}
                className="right-to-left"
                fluid
                >
                    <InnovationCol  data-aos="fade-left">
                        <NavLink activeClassName="is-active"
                            to="https://www.edp.inphb.ci/ecoleDocPolytech/publication"
                            style={isActive => ({
                                color: isActive ? "black" : "blue",
                                alignContent: "center",
                                textAlign: "center",
                                borderRadius: "10px",
                                border: "1px solid green",
                                padding: "10px",
                               
                            })}
                            >
                                <NavbarBrand>
                                    <img
                                        style={{ width: '140px', height: '120px' }}
                                        src={recherche['edpthese.png']}
                                        alt="Recherche"
                                    />
                                </NavbarBrand>
                                <TextTitle className="fw-bold mt-3 mb-2 text-uppercase fs-5"
                                
                                >
                                    Thèses école Doctorale
                                </TextTitle>
                        </NavLink>
                    </InnovationCol>
                    <InnovationCol data-aos="fade-up">
                    <NavLink activeClassName="is-active"
                        to="https://cea-valopro.inphb.ci/publication"
                        style={isActive => ({
                            color: isActive ? "black" : "blue",
                            alignContent: "center",
                            textAlign: "center",
                            hover: "background-color: lightblue",
                            borderRadius: "10px",
                            border: "1px solid green",
                            padding: "10px",
                          })}
                        >
                            <NavbarBrand>
                                <img
                                    style={{
                                        width: '140px',
                                        height: '120px',
                                        
                                    }}
                                    src={recherche['ceavalopro.png']}
                                    alt="Innovation"
                                />
                            </NavbarBrand>
                            <TextTitle className="fw-bold mt-3 mb-2 text-uppercase fs-5">
                                pulications CEA-VALOPRO
                            </TextTitle>
                        </NavLink>
                    </InnovationCol>
                    <InnovationCol className="mt-2" data-aos="fade-right">
                        <NavLink activeClassName="is-active"
                        to="https://cea-mem.inphb.ci/publications-de-recherches.php"
                        style={isActive => ({
                            color: isActive ? "black" : "blue",
                            alignContent: "center",
                            textAlign: "center",
                            hover: "background-color: lightblue",
                            borderRadius: "10px",
                                border: "1px solid green",
                                padding: "10px",
                          })}
                        >
                            <NavbarBrand>
                                <img
                                    style={{ width: '140px', height: '120px' }}
                                    src={recherche['ceamem.png']}
                                    alt="Réalisations"
                                />
                            </NavbarBrand>
                            <TextTitle className="fw-bold mt-3 mb-2 text-uppercase fs-5">
                                publications CEA-mem
                            </TextTitle>
                        </NavLink>
                    </InnovationCol>
                    <InnovationCol className="mt-2" data-aos="fade-right">
                        <NavLink  activeClassName="is-active" 
                        to="https://www.researchgate.net/institution/Institut-National-Polytechnique-Felix-Houphouet-Boigny" 
                        style={isActive => ({
                            color: isActive ? "black" : "blue",
                            alignContent: "center",
                            textAlign: "center",
                            hover: "background-color: lightblue",
                            borderRadius: "10px",
                                border: "1px solid green",
                                padding: "10px",
                          })}
                        exact>
                            <NavbarBrand>
                        
                                <img
                                style={{ width: '140px', height: '120px' }}
                                src={recherche['pubscientifique.png']}
                                alt="Réalisations"
                             />
                        
                            </NavbarBrand>
                            <TextTitle className="fw-bold mt-3 mb-2 text-uppercase fs-5">
                                Publications Scientifiques
                            </TextTitle>
                        </NavLink>
                        
                    </InnovationCol>
                </Row>
            </InnovationWrapper>
            <hr />
            <row className="d-block w-70 " data-aos="fade-right">

                <TextTitle className="fw-bold mt-2 mb-1 text-uppercase fs-5"
                    style={{ textAlign: "center" }}
                >
                                STatistique des résultats de fin d'année des diplômés. [ 2021 - 2022 ]
                </TextTitle>
            
                <Accordion defaultActiveKey={['0']} alwaysOpen>
                    
                        <Accordion.Item eventKey="0" >
                            <Accordion.Header>ECOLE SUPERIEURE D'AGRONOMIE</Accordion.Header>
                                <Accordion.Body>
                                    <TextTitle className="fw-bold mt-2 mb-1 text-uppercase fs-6">
                                        Ingénieur des techniques agricoles
                                    </TextTitle>
                                    <table
                                        style={{ width: '100%',
                                        borderCollapse: 'collapse',
                                        border: '2px solid black',
                                        padding: '5px',
                                
                                    }}
                                    
                                    >
                                        
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td  style={{border: '2px solid black', width:'25%', fontSize:'10px', textAlign:'center'}}>IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'10px' , textAlign:'center'}}>NON IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>CUMUL</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>TAUX</td>
                                        </tr>
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td style={{border: '2px solid black' , fontSize:'10px'}}>
                                                {/* ici pour ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 22 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  8</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 30</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le Non ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 0 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  0</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 0</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le cumul */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 22 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  8</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 30</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le taux */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 73,3 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  26,7</p>
                                                {/* <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 30</p> */}
                                            </td>
                                        </tr>
                                    </table>
                                    <br />
                                        {/* Debut ingénieur agronome */}

                                    <TextTitle className="fw-bold mt-2 mb-1 text-uppercase fs-6">
                                        Ingénieur Agronome
                                    </TextTitle>
                                    <table
                                        style={{ width: '100%',
                                        borderCollapse: 'collapse',
                                        border: '2px solid black',
                                        padding: '5px',
                                
                                    }}
                                    
                                    >
                                        
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td  style={{border: '2px solid black', width:'25%', fontSize:'10px', textAlign:'center'}}>IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'10px' , textAlign:'center'}}>NON IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>CUMUL</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>TAUX</td>
                                        </tr>
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td style={{border: '2px solid black' , fontSize:'10px'}}>
                                                {/* ici pour ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 33 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  15</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 48</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le Non ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 0 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  1</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 1</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le cumul */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 33 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  16</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 49</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le taux */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 67,3 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  32,7</p>
                                                {/* <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 30</p> */}
                                            </td>
                                        </tr>
                                    </table>

                                    <br />

                                     {/* Debut ingénieur agronome */}

                                    <TextTitle className="fw-bold mt-2 mb-1 text-uppercase fs-6">
                                        technicien supérieur en agronomie
                                    </TextTitle>
                                    <table
                                        style={{ width: '100%',
                                        borderCollapse: 'collapse',
                                        border: '2px solid black',
                                        padding: '5px',
                                
                                    }}
                                    
                                    >
                                        
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td  style={{border: '2px solid black', width:'25%', fontSize:'10px', textAlign:'center'}}>IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'10px' , textAlign:'center'}}>NON IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>CUMUL</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>TAUX</td>
                                        </tr>
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td style={{border: '2px solid black' , fontSize:'10px'}}>
                                                {/* ici pour ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 20 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  19</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 39</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le Non ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 1 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  0</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 1</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le cumul */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 21 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  19</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 40</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le taux */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 52,5 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  48,5</p>
                                                {/* <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 30</p> */}
                                            </td>
                                        </tr>
                                    </table>
                                </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>ECOLE SUPERIEURE DE COMMERCE ET ADMINISTRATION DES ENTREPRISES</Accordion.Header>
                                <Accordion.Body>
                                <TextTitle className="fw-bold mt-2 mb-1 text-uppercase fs-6">
                                        TS COMMERCE ET ADMINISTRATION DES ENTREPRISES
                                    </TextTitle>
                                    <table
                                        style={{ width: '100%',
                                        borderCollapse: 'collapse',
                                        border: '2px solid black',
                                        padding: '5px',
                                
                                    }}
                                    
                                    >
                                        
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td  style={{border: '2px solid black', width:'25%', fontSize:'10px', textAlign:'center'}}>IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'10px' , textAlign:'center'}}>NON IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>CUMUL</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>TAUX</td>
                                        </tr>
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td style={{border: '2px solid black' , fontSize:'10px'}}>
                                                {/* ici pour ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 20 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  20</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 40</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le Non ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 0 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  1</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 1</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le cumul */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 20 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  21</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 41</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le taux */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 48,8 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  51,2</p>
                                                {/* <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 30</p> */}
                                            </td>
                                        </tr>
                                    </table>
                                    <br />
                                        {/* Debut TS FINANCES ET COMPTABILITE */}

                                    <TextTitle className="fw-bold mt-2 mb-1 text-uppercase fs-6">
                                        TS FINANCES ET comptabilité
                                    </TextTitle>
                                    <table
                                        style={{ width: '100%',
                                        borderCollapse: 'collapse',
                                        border: '2px solid black',
                                        padding: '5px',
                                
                                    }}
                                    
                                    >
                                        
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td  style={{border: '2px solid black', width:'25%', fontSize:'10px', textAlign:'center'}}>IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'10px' , textAlign:'center'}}>NON IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>CUMUL</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>TAUX</td>
                                        </tr>
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td style={{border: '2px solid black' , fontSize:'10px'}}>
                                                {/* ici pour ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 27 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  11</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 38</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le Non ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 0 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  0</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 0</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le cumul */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 27 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  11</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 38</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le taux */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 71,1 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  28,9</p>
                                                {/* <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 30</p> */}
                                            </td>
                                        </tr>
                                    </table>

                                    <br />
                                        {/* Debut INGENIEUR ESCA */}

                                    <TextTitle className="fw-bold mt-2 mb-1 text-uppercase fs-6">
                                        INGENIEUR ESCA
                                    </TextTitle>
                                    <table
                                        style={{ width: '100%',
                                        borderCollapse: 'collapse',
                                        border: '2px solid black',
                                        padding: '5px',
                                
                                    }}
                                    
                                    >
                                        
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td  style={{border: '2px solid black', width:'25%', fontSize:'10px', textAlign:'center'}}>IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'10px' , textAlign:'center'}}>NON IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>CUMUL</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>TAUX</td>
                                        </tr>
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td style={{border: '2px solid black' , fontSize:'10px'}}>
                                                {/* ici pour ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 15 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  6</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 21</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le Non ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 1 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  1</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 2</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le cumul */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 16 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  7</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 23</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le taux */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 69,6 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  30,4</p>
                                                {/* <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 30</p> */}
                                            </td>
                                        </tr>
                                    </table>
                                    <br />
                                    {/* Debut ETUDES COMPTABLES SUPERIEURES */}

                                    <TextTitle className="fw-bold mt-2 mb-1 text-uppercase fs-6">
                                        études COMPTABLES SUPéRIEURES
                                    </TextTitle>
                                    <table
                                        style={{ width: '100%',
                                        borderCollapse: 'collapse',
                                        border: '2px solid black',
                                        padding: '5px',
                                
                                    }}
                                    
                                    >
                                        
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td  style={{border: '2px solid black', width:'25%', fontSize:'10px', textAlign:'center'}}>IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'10px' , textAlign:'center'}}>NON IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>CUMUL</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>TAUX</td>
                                        </tr>
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td style={{border: '2px solid black' , fontSize:'10px'}}>
                                                {/* ici pour ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 14 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  6</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 20</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le Non ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 1 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  1</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 2</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le cumul */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 15 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  7</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 22</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le taux */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 68,2 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  31,8</p>
                                                {/* <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 30</p> */}
                                            </td>
                                        </tr>
                                    </table>

                                    <br />
                                        {/* Debut HAUTES ETUDES EN ASSURANCE */}

                                    <TextTitle className="fw-bold mt-2 mb-1 text-uppercase fs-6">
                                        HAUTES études EN ASSURANCE
                                    </TextTitle>
                                    <table
                                        style={{ width: '100%',
                                        borderCollapse: 'collapse',
                                        border: '2px solid black',
                                        padding: '5px',
                                
                                    }}
                                    
                                    >
                                        
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td  style={{border: '2px solid black', width:'25%', fontSize:'10px', textAlign:'center'}}>IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'10px' , textAlign:'center'}}>NON IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>CUMUL</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>TAUX</td>
                                        </tr>
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td style={{border: '2px solid black' , fontSize:'10px'}}>
                                                {/* ici pour ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 5 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  4</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 9</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le Non ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 0 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  0</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 0</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le cumul */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 5 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  4</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 9</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le taux */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 55,6 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  44,4</p>
                                                {/* <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 30</p> */}
                                            </td>
                                        </tr>
                                    </table>

                                    <br />
                                        {/* Debut INGENIEUR EN LOGISTIQUE ET TRANSPORT */}

                                    <TextTitle className="fw-bold mt-2 mb-1 text-uppercase fs-6">
                                        INGéNIEUR EN LOGISTIQUE ET TRANSPORT
                                    </TextTitle>
                                    <table
                                        style={{ width: '100%',
                                        borderCollapse: 'collapse',
                                        border: '2px solid black',
                                        padding: '5px',
                                
                                    }}
                                    
                                    >
                                        
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td  style={{border: '2px solid black', width:'25%', fontSize:'10px', textAlign:'center'}}>IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'10px' , textAlign:'center'}}>NON IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>CUMUL</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>TAUX</td>
                                        </tr>
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td style={{border: '2px solid black' , fontSize:'10px'}}>
                                                {/* ici pour ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 13 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  10</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 23</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le Non ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 4 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  0</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 4</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le cumul */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 17 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  10</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 27</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le taux */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 63 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  37</p>
                                                {/* <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 30</p> */}
                                            </td>
                                        </tr>
                                    </table>
                                    

                                </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>ECOLE SUPERIEURE D'INDUSTRIE</Accordion.Header>
                                <Accordion.Body>
                                <TextTitle className="fw-bold mt-2 mb-1 text-uppercase fs-6">
                                        TS SCIENCES ET TECHNOLOGIES DE L'INFORMATION ET DE LA COMMUNICATION
                                    </TextTitle>
                                    <table
                                        style={{ width: '100%',
                                        borderCollapse: 'collapse',
                                        border: '2px solid black',
                                        padding: '5px',
                                
                                    }}
                                    
                                    >
                                        
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td  style={{border: '2px solid black', width:'25%', fontSize:'10px', textAlign:'center'}}>IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'10px' , textAlign:'center'}}>NON IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>CUMUL</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>TAUX</td>
                                        </tr>
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td style={{border: '2px solid black' , fontSize:'10px'}}>
                                                {/* ici pour ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 31 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  8</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 39</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le Non ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 2 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  2</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 4</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le cumul */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 33 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  10</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 43</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le taux */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 76,7 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  23,3</p>
                                                {/* <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 30</p> */}
                                            </td>
                                        </tr>
                                    </table>
                                    <br />
                                        {/* Debut TS SCIENCES ET TECHNOLOGIES DU GENIE INDUSTRIEL */}

                                    <TextTitle className="fw-bold mt-2 mb-1 text-uppercase fs-6">
                                      TS SCIENCES ET TECHNOLOGIES DU GENIE INDUSTRIEL
                                    </TextTitle>
                                    <table
                                        style={{ width: '100%',
                                        borderCollapse: 'collapse',
                                        border: '2px solid black',
                                        padding: '5px',
                                
                                    }}
                                    
                                    >
                                        
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td  style={{border: '2px solid black', width:'25%', fontSize:'10px', textAlign:'center'}}>IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'10px' , textAlign:'center'}}>NON IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>CUMUL</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>TAUX</td>
                                        </tr>
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td style={{border: '2px solid black' , fontSize:'10px'}}>
                                                {/* ici pour ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 50 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  11</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 61</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le Non ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 2 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  0</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 2</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le cumul */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 52 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  11</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 63</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le taux */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 82,5 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  17,5</p>
                                                {/* <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 30</p> */}
                                            </td>
                                        </tr>
                                    </table>

                                    <br />
                                     {/* Debut TS SCIENCES ET TECHNOLOGIES DU GENIE DES PROCEDES */}

                                     <TextTitle className="fw-bold mt-2 mb-1 text-uppercase fs-6">
                                      TS SCIENCES ET TECHNOLOGIES DU GENIE DES PROCEDES
                                    </TextTitle>
                                    <table
                                        style={{ width: '100%',
                                        borderCollapse: 'collapse',
                                        border: '2px solid black',
                                        padding: '5px',
                                
                                    }}
                                    
                                    >
                                        
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td  style={{border: '2px solid black', width:'25%', fontSize:'10px', textAlign:'center'}}>IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'10px' , textAlign:'center'}}>NON IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>CUMUL</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>TAUX</td>
                                        </tr>
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td style={{border: '2px solid black' , fontSize:'10px'}}>
                                                {/* ici pour ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 17 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  10</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 27</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le Non ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 2 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  0</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 2</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le cumul */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 19 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  10</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 29</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le taux */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 65,5 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  34,5</p>
                                                {/* <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 30</p> */}
                                            </td>
                                        </tr>
                                    </table>

                                    <br />
                                     {/* Debut INGENIEUR SCIENCES ET TECHNOLOGIES DE L'INFORMATION ET DE LA COMMUNICATION */}

                                     <TextTitle className="fw-bold mt-2 mb-1 text-uppercase fs-6">
                                     INGéNIEUR SCIENCES ET TECHNOLOGIES DE L'INFORMATION ET DE LA COMMUNICATION
                                    </TextTitle>
                                    <table
                                        style={{ width: '100%',
                                        borderCollapse: 'collapse',
                                        border: '2px solid black',
                                        padding: '5px',
                                
                                    }}
                                    
                                    >
                                        
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td  style={{border: '2px solid black', width:'25%', fontSize:'10px', textAlign:'center'}}>IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'10px' , textAlign:'center'}}>NON IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>CUMUL</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>TAUX</td>
                                        </tr>
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td style={{border: '2px solid black' , fontSize:'10px'}}>
                                                {/* ici pour ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 30 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  8</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 38</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le Non ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 1 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  0</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 1</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le cumul */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 31 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  8</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 39</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le taux */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 79,5 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  20,5</p>
                                                {/* <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 30</p> */}
                                            </td>
                                        </tr>
                                    </table>

                                    <br />
                                     {/* Debut INGENIEUR SCIENCES ET TECHNOLOGIES DU GENIE DES PROCEDES */}

                                     <TextTitle className="fw-bold mt-2 mb-1 text-uppercase fs-6">
                                     INGéNIEUR SCIENCES ET TECHNOLOGIES DU GENIE DES PROCEDES
                                    </TextTitle>
                                    <table
                                        style={{ width: '100%',
                                        borderCollapse: 'collapse',
                                        border: '2px solid black',
                                        padding: '5px',
                                
                                    }}
                                    
                                    >
                                        
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td  style={{border: '2px solid black', width:'25%', fontSize:'10px', textAlign:'center'}}>IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'10px' , textAlign:'center'}}>NON IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>CUMUL</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>TAUX</td>
                                        </tr>
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td style={{border: '2px solid black' , fontSize:'10px'}}>
                                                {/* ici pour ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 17 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  2</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 19</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le Non ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 0 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  0</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 0</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le cumul */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 17 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  2</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 19</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le taux */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 89,5 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  10,5</p>
                                                {/* <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 30</p> */}
                                            </td>
                                        </tr>
                                    </table>
                                </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>ECOLE SUPERIEURE DES MINES ET DE GEOLOGIE</Accordion.Header>
                                <Accordion.Body>
                                <TextTitle className="fw-bold mt-2 mb-1 text-uppercase fs-6">
                                        TECHNICIEN SUPérieur EN MINES ET GéOLOGIE
                                    </TextTitle>
                                    <table
                                        style={{ width: '100%',
                                        borderCollapse: 'collapse',
                                        border: '2px solid black',
                                        padding: '5px',
                                
                                    }}
                                    
                                    >
                                        
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td  style={{border: '2px solid black', width:'25%', fontSize:'10px', textAlign:'center'}}>IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'10px' , textAlign:'center'}}>NON IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>CUMUL</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>TAUX</td>
                                        </tr>
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td style={{border: '2px solid black' , fontSize:'10px'}}>
                                                {/* ici pour ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 17 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  7</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 24</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le Non ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 2 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  0</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 2</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le cumul */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 19 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  7</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 26</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le taux */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 73,1 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  26,9</p>
                                                {/* <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 30</p> */}
                                            </td>
                                        </tr>
                                    </table>
                                    <br />
                                        {/* Debut INGENIEUR DES MINES */}

                                    <TextTitle className="fw-bold mt-2 mb-1 text-uppercase fs-6">
                                    INGéNIEUR DES MINES 
                                    </TextTitle>
                                    <table
                                        style={{ width: '100%',
                                        borderCollapse: 'collapse',
                                        border: '2px solid black',
                                        padding: '5px',
                                
                                    }}
                                    
                                    >
                                        
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td  style={{border: '2px solid black', width:'25%', fontSize:'10px', textAlign:'center'}}>IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'10px' , textAlign:'center'}}>NON IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>CUMUL</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>TAUX</td>
                                        </tr>
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td style={{border: '2px solid black' , fontSize:'10px'}}>
                                                {/* ici pour ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 11 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  6</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 17</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le Non ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 3 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  3</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 6</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le cumul */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 14 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  9</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 23</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le taux */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 60,9 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  39,1</p>
                                                {/* <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 30</p> */}
                                            </td>
                                        </tr>
                                    </table>

                                    <br />
                                     {/* Debut INGENIEUR EXPLOITATION ET TRAITEMENT DES EAUX */}

                                     <TextTitle className="fw-bold mt-2 mb-1 text-uppercase fs-6">
                                     INGéNIEUR EXPLOITATION ET TRAITEMENT DES EAUX
                                    </TextTitle>
                                    <table
                                        style={{ width: '100%',
                                        borderCollapse: 'collapse',
                                        border: '2px solid black',
                                        padding: '5px',
                                
                                    }}
                                    
                                    >
                                        
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td  style={{border: '2px solid black', width:'25%', fontSize:'10px', textAlign:'center'}}>IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'10px' , textAlign:'center'}}>NON IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>CUMUL</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>TAUX</td>
                                        </tr>
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td style={{border: '2px solid black' , fontSize:'10px'}}>
                                                {/* ici pour ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 6 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  4</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 10</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le Non ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 3 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  1</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 4</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le cumul */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 9 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  5</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 14</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le taux */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 64,3 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  35,7</p>
                                                {/* <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 30</p> */}
                                            </td>
                                        </tr>
                                    </table>

                                    <br />
                                     {/* Debut INGENIEUR PETROLE */}

                                     <TextTitle className="fw-bold mt-2 mb-1 text-uppercase fs-6">
                                     INGéNIEUR PETROLE
                                    </TextTitle>
                                    <table
                                        style={{ width: '100%',
                                        borderCollapse: 'collapse',
                                        border: '2px solid black',
                                        padding: '5px',
                                
                                    }}
                                    
                                    >
                                        
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td  style={{border: '2px solid black', width:'25%', fontSize:'10px', textAlign:'center'}}>IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'10px' , textAlign:'center'}}>NON IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>CUMUL</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>TAUX</td>
                                        </tr>
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td style={{border: '2px solid black' , fontSize:'10px'}}>
                                                {/* ici pour ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 7 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  1</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 8</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le Non ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 0 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  0</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 0</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le cumul */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 7 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  1</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 8</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le taux */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 87,5 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  12,5</p>
                                                {/* <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 30</p> */}
                                            </td>
                                        </tr>
                                    </table>

                                    <br />
                                     {/* Debut MASTER RESSSOURCES EN EAUX ET RISQUES ENVIRONNEMENTAUX */}

                                     <TextTitle className="fw-bold mt-2 mb-1 text-uppercase fs-6">
                                     MASTER RESSSOURCES EN EAUX ET RISQUES ENVIRONNEMENTAUX
                                    </TextTitle>
                                    <table
                                        style={{ width: '100%',
                                        borderCollapse: 'collapse',
                                        border: '2px solid black',
                                        padding: '5px',
                                
                                    }}
                                    
                                    >
                                        
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td  style={{border: '2px solid black', width:'25%', fontSize:'10px', textAlign:'center'}}>IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'10px' , textAlign:'center'}}>NON IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>CUMUL</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>TAUX</td>
                                        </tr>
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td style={{border: '2px solid black' , fontSize:'10px'}}>
                                                {/* ici pour ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 12 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  2</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 14</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le Non ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 0 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  0</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 0</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le cumul */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 12 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  2</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 14</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le taux */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 85,7 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  14,3</p>
                                                {/* <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 30</p> */}
                                            </td>
                                        </tr>
                                    </table>
                                </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4">
                            <Accordion.Header>ECOLE SUPERIEURE DES TRAVAUX PUBLICS</Accordion.Header>
                                <Accordion.Body>
                                <TextTitle className="fw-bold mt-2 mb-1 text-uppercase fs-6">
                                        TECHNICIEN SUPérieur DE L'ESTP
                                    </TextTitle>
                                    <table
                                        style={{ width: '100%',
                                        borderCollapse: 'collapse',
                                        border: '2px solid black',
                                        padding: '5px',
                                
                                    }}
                                    
                                    >
                                        
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td  style={{border: '2px solid black', width:'25%', fontSize:'10px', textAlign:'center'}}>IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'10px' , textAlign:'center'}}>NON IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>CUMUL</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>TAUX</td>
                                        </tr>
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td style={{border: '2px solid black' , fontSize:'10px'}}>
                                                {/* ici pour ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 53 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  24</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 77</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le Non ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 7 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  1</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 8</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le cumul */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 60 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  25</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 85</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le taux */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 70,6 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  29,4</p>
                                                {/* <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 30</p> */}
                                            </td>
                                        </tr>
                                    </table>
                                    <br />
                                        {/* Debut INGENIEUR GENIE CIVIL */}

                                    <TextTitle className="fw-bold mt-2 mb-1 text-uppercase fs-6">
                                    INGéNIEUR GENIE CIVIL
                                    </TextTitle>
                                    <table
                                        style={{ width: '100%',
                                        borderCollapse: 'collapse',
                                        border: '2px solid black',
                                        padding: '5px',
                                
                                    }}
                                    
                                    >
                                        
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td  style={{border: '2px solid black', width:'25%', fontSize:'10px', textAlign:'center'}}>IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'10px' , textAlign:'center'}}>NON IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>CUMUL</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>TAUX</td>
                                        </tr>
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td style={{border: '2px solid black' , fontSize:'10px'}}>
                                                {/* ici pour ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 25 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  5</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 30</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le Non ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 1 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  2</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 3</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le cumul */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 26</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  7</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 33</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le taux */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 78,8 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  21,2</p>
                                                {/* <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 30</p> */}
                                            </td>
                                        </tr>
                                    </table>

                                    <br />
                                     {/* Debut  INGENIEUR GENIE CIVIL GEOMETRE-TOPOGRAPHE */}

                                     <TextTitle className="fw-bold mt-2 mb-1 text-uppercase fs-6">
                                     INGENIEUR GENIE CIVIL GEOMETRE-TOPOGRAPHE
                                    </TextTitle>
                                    <table
                                        style={{ width: '100%',
                                        borderCollapse: 'collapse',
                                        border: '2px solid black',
                                        padding: '5px',
                                
                                    }}
                                    
                                    >
                                        
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td  style={{border: '2px solid black', width:'25%', fontSize:'10px', textAlign:'center'}}>IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'10px' , textAlign:'center'}}>NON IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>CUMUL</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>TAUX</td>
                                        </tr>
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td style={{border: '2px solid black' , fontSize:'10px'}}>
                                                {/* ici pour ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 4 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  0</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 4</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le Non ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 0</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  0</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 0</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le cumul */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 4 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  0</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 4</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le taux */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 100 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  0</p>
                                                {/* <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 30</p> */}
                                            </td>
                                        </tr>
                                    </table>

                                    <br />
                                     {/* Debut MASTER PROFESSIONNEL EN MANAGEMENT DES GRANDS PROJETS DE CONSTRUCTION */}

                                     <TextTitle className="fw-bold mt-2 mb-1 text-uppercase fs-6">
                                     MASTER PROFESSIONNEL EN MANAGEMENT DES GRANDS PROJETS DE CONSTRUCTION
                                    </TextTitle>
                                    <table
                                        style={{ width: '100%',
                                        borderCollapse: 'collapse',
                                        border: '2px solid black',
                                        padding: '5px',
                                
                                    }}
                                    
                                    >
                                        
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td  style={{border: '2px solid black', width:'25%', fontSize:'10px', textAlign:'center'}}>IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'10px' , textAlign:'center'}}>NON IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>CUMUL</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>TAUX</td>
                                        </tr>
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td style={{border: '2px solid black' , fontSize:'10px'}}>
                                                {/* ici pour ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 12 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  4</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 16</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le Non ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 7 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  0</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 7</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le cumul */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 19 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  4</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 23</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le taux */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 82,6 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  17,4</p>
                                                {/* <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 30</p> */}
                                            </td>
                                        </tr>
                                    </table>
                                </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="5">
                            <Accordion.Header>CENTRE REGIONAL DE FORMATION SUPERIEURE EN METROLOGIE</Accordion.Header>
                                <Accordion.Body>
                                <TextTitle className="fw-bold mt-2 mb-1 text-uppercase fs-6">
                                        LICENCE PROFESIONNELE EN METROLOGIE   
                                    </TextTitle>
                                    <table
                                        style={{ width: '100%',
                                        borderCollapse: 'collapse',
                                        border: '2px solid black',
                                        padding: '5px',
                                
                                    }}
                                    
                                    >
                                        
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td  style={{border: '2px solid black', width:'25%', fontSize:'10px', textAlign:'center'}}>IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'10px' , textAlign:'center'}}>NON IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>CUMUL</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>TAUX</td>
                                        </tr>
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td style={{border: '2px solid black' , fontSize:'10px'}}>
                                                {/* ici pour ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 6 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  1</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 7</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le Non ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 1 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  0</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 1</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le cumul */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 7 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  1</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 8</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le taux */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 87,5 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  12,5</p>
                                                {/* <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 30</p> */}
                                            </td>
                                        </tr>
                                    </table>
                                    <br />
                                        {/* Debut MASTER PROFESSSIONNEL EN METROLOGIE */}

                                    <TextTitle className="fw-bold mt-2 mb-1 text-uppercase fs-6">
                                       MASTER PROFESSSIONNEL EN METROLOGIE
                                    </TextTitle>
                                    <table
                                        style={{ width: '100%',
                                        borderCollapse: 'collapse',
                                        border: '2px solid black',
                                        padding: '5px',
                                
                                    }}
                                    
                                    >
                                        
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td  style={{border: '2px solid black', width:'25%', fontSize:'10px', textAlign:'center'}}>IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'10px' , textAlign:'center'}}>NON IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>CUMUL</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>TAUX</td>
                                        </tr>
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td style={{border: '2px solid black' , fontSize:'10px'}}>
                                                {/* ici pour ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 4 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  1</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 5</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le Non ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 0 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  0</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 0</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le cumul */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 4</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  1</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 5</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le taux */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 80 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  20</p>
                                                {/* <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 30</p> */}
                                            </td>
                                        </tr>
                                    </table>

                                    <br />
                                     {/* Debut  MASTER EN INSTRUMENTATION, METROLOGIE ET QUALITE */}

                                     <TextTitle className="fw-bold mt-2 mb-1 text-uppercase fs-6">
                                       MASTER EN INSTRUMENTATION, METROLOGIE ET QUALITE
                                    </TextTitle>
                                    <table
                                        style={{ width: '100%',
                                        borderCollapse: 'collapse',
                                        border: '2px solid black',
                                        padding: '5px',
                                
                                    }}
                                    
                                    >
                                        
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td  style={{border: '2px solid black', width:'25%', fontSize:'10px', textAlign:'center'}}>IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'10px' , textAlign:'center'}}>NON IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>CUMUL</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>TAUX</td>
                                        </tr>
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td style={{border: '2px solid black' , fontSize:'10px'}}>
                                                {/* ici pour ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 2</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  1</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 3</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le Non ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 3</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  0</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 3</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le cumul */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 5 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  1</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 6</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le taux */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes :  83,3</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  16,7</p>
                                                {/* <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 30</p> */}
                                            </td>
                                        </tr>
                                    </table>

                                  
                                </Accordion.Body>
                        </Accordion.Item>

                         
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>ECOLE SUPERIEURE DU PETROLE ET DE L'ENERGIE</Accordion.Header>
                                <Accordion.Body>
                                <TextTitle className="fw-bold mt-2 mb-1 text-uppercase fs-6">
                                        MASTER PROFESSIONNEL EN ECONOMIE ET TECHNIQUES DE L'AVAL PéTROLIER(ETAVP)
                                    </TextTitle>
                                    <table
                                        style={{ width: '100%',
                                        borderCollapse: 'collapse',
                                        border: '2px solid black',
                                        padding: '5px',
                                
                                    }}
                                    
                                    >
                                        
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td  style={{border: '2px solid black', width:'25%', fontSize:'10px', textAlign:'center'}}>IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'10px' , textAlign:'center'}}>NON IVOIRIENS</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>CUMUL</td>
                                            <td style={{border: '2px solid black', width:'25%', fontSize:'11px', textAlign:'center'}}>TAUX</td>
                                        </tr>
                                        <tr style={{border: '2px solid black', borderBottom:'2px solid black', width:'100%'}}>
                                            <td style={{border: '2px solid black' , fontSize:'10px'}}>
                                                {/* ici pour ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 2</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  0</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 2</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le Non ivoirien */}
                                                <p></p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 0 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  0</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 0</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le cumul */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 2 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  0</p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 2</p>
                                            </td>
                                            <td style={{border: '2px solid black', width:'12%', fontSize:'10px'}}>
                                                {/* ici pour le taux */}
                                                <p></p> 
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Hommes : 100 </p>
                                                <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Femmes  :  0</p>
                                                {/* <p className='fw-bold mt-2 mb-1 text-uppercase fs-8'>Total : 30</p> */}
                                            </td>
                                        </tr>
                                    </table>
                                   
                                  
                                </Accordion.Body>
                        </Accordion.Item>



                </Accordion>
            </row>
            <FooterPortailConcours />
        </Transitions>
    )
}

export default Recherche
