import styled from 'styled-components'
import { Col, Row } from 'react-bootstrap'
import INPHB from '../../assets/INP-HB.png'
import Aos from 'aos'
import 'aos/dist/aos.css'
import '../../utils/style/index.scss'
import Modal from 'react-bootstrap/Modal'
import {
    FaEnvelope,
    FaFacebook,
    FaLinkedin,
    FaTwitter,
    FaWindows,
    FaYoutube,
} from 'react-icons/fa'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { TbArrowBigTop } from 'react-icons/tb'
import { FiSend } from 'react-icons/fi'
import { useState } from 'react'
import { motion } from 'framer-motion'
import ReglementIn from '../../assets/Portail/Reglementresidences.pdf'
import ReglementCad from '../../assets/Portail/Reglementcadrescolarite.pdf'
import LogoOfficiel  from '../../assets/Portail/logoINP.png'
import Actu from '../../assets/Portail/actumai2023.pdf'
import Politique from "../../assets/Portail/politiquequaliteinphb.pdf"
import Statut from '../../assets/Portail/nouveaustatut.pdf'

const FooterPortailConcoursWrapper = styled.div`
    margin-top: 50px !important;
`
const NewsletterWrapper = styled.div`
    margin-top: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    z-index: 2;
    left: 0px;
    margin-top: -6%;
    @media (max-width: 916px) {
        margin-top: -10%;
    }
    @media (max-width: 648px) {
        margin-top: -15%;
    }
    @media (max-width: 421px) {
        margin-top: -30%;
    }
    @media (max-width: 350px) {
        margin-top: -40%;
    }
`
const NewsletterDiv = styled.div`
    padding: 15px;
    border-radius: 30px;
    background-color: #008d36;
    @media (max-width: 421px) {
        display: flex !important;
        flex-direction: column;
    }
`
const Newsletter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
const NewsletterReceive = styled.p`
    @media (max-width: 550px) {
        display: none;
    }
`
const MonFormulaire = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media (max-width: 420px) {
        margin-right: 5px !important;
    }
`
const Erreur = styled.div`
    color: red;
    @media (max-width: 445px) {
        font-size: 0.8em;
    }
`
const MyInput = styled.textarea`
    /* border-radius: 20px 0px 0px 20px; */
    font-size: 0.98em;
    color: #fff !important;
    background-color: transparent;
    outline: none !important;
    border: 0;
    overflow: hidden;
    resize: none;
    width: 100%;
    border-bottom: 3px solid ${(prop) => prop.color};
    padding: 0.5em;
    &::placeholder {
        color: #fff;
        opacity: .8;
    }
`
const MyButton = styled.button`
    /* border-radius: 0px 20px 20px 0px; */
    font-size: 0.98em;
    border: 0;
    padding: 0.5em;
    width: 100%;
    transition: all 200ms ease-in-out;
    &:hover {
        transform: scale(1.04);
    }
`

const Footer = styled.footer`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #303030;
    color: #fff;
    padding: 20px;
    padding-top: 5%;
    margin-top: 18%;
    @media (max-width: 690px) {
        padding-top: 15%;
    }
    @media(max-width: 421px) {
        margin-top: 50%;
    }
`
const MyRow = styled(Row)`
    margin-top: 3%;
    width: 100%;
    @media (max-width: 1100px) {
        margin-top: 5%;
    }
    @media (max-width: 786px) {
        margin-top: 10%;
    }
    @media (max-width: 610px) {
        margin-top: 15%;
    }
    @media (max-width: 425px) {
        margin-top: 20%;
    }
    @media (max-width: 310px) {
        margin-top: 30%;
    }
`
const MyCol = styled(Col)`
    padding: 10px;
    font-size: 1.2em;
    & > div {
        & > a {
            color: #fff;
        }
    }
    @media (max-width: 1016px) {
        font-size: auto;
    }
    @media (max-width: 380px) {
        flex-basis: 100% !important;
        display: flex;
        flex-direction: column;
        align-items: center !important;
        justify-content: center !important;
    }
`
const FirstDiv = styled.div`
    @media (max-width: 315px) {
        flex-direction: column !important;
    }
`
const GoTop = styled.div`
    position: fixed;
    z-index: 9;
    bottom: 50px;
    left: 20px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    background-color: #fff;
    color: #079edb;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5em;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 50%;
    transition: all 1s ease-in-out;
    @media(max-width: 600px) {
        width: 40px;
        height: 40px;
    }
    /* &:hover {
        color: #079edb;
        background-color: #fff;
    } */
`

function FooterPortailConcours() {
    const [mail, setMail] = useState('')
    const [errorMail, setErrorMail] = useState('')
    const [show, setShow] = useState(false)
    const [prenom, setPreNom] = useState('')
    const mailRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

    const addToNewsletter = () => {
        setErrorMail('')
        setShow(true)
        let parts = mail.split('.')
        setPreNom(parts[0])
    }
    const mailVerification = () => {
        mail === ''
            ? setErrorMail('Veuillez entrer un mail')
            : !mailRegex.test(mail) ? setErrorMail('Entrer un mail correct')  : setErrorMail('') 
    }
    const mailAccess = () => {
        mailVerification()
        mailRegex.test(mail) && addToNewsletter()
    }

    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])

    const variants = {
        initial: { x: 0 },
        animate: {
            x: [-15, 15, -15, 15, -15, 15, 0],
            transition: {
                type: 'spring',
                stiffness: 120,
            },
        },
    }

    return (
        <FooterPortailConcoursWrapper>
            <NewsletterWrapper className="p-3" data-aos="fade-down">
                <NewsletterDiv className="d-flex justify-content-center align-items-center">
                    <Newsletter className="text-light me-3">
                        <FaEnvelope className="fs-2" />
                        <p className="fw-bold text-light text-center">
                            Souscrire à notre Newsletter
                        </p>
                        <NewsletterReceive className="text-center">
                            et recevoir toute l'actualité liée à notre Institut
                        </NewsletterReceive>
                    </Newsletter>
                    <div>
                        <MonFormulaire className="me-3">
                            <motion.div
                                variants={variants}
                                initial="initial"
                                animate={errorMail ? 'animate' : 'initial'}

                            >
                                <MyInput
                                    type="email"
                                    tabIndex={0}
                                    rows="1"
                                    className="mb-1"
                                    style={{ wordWrap: 'break-word' }}
                                    placeholder="newsletter@inphb.ci"
                                    color={errorMail ? 'red' : '#fff'}
                                    onBlur={mailVerification}
                                    onChange={(e) => setMail(e.target.value)}
                                    value={mail}
                                />
                            </motion.div>

                            {errorMail && (
                                <Erreur className="mb-1">{errorMail}</Erreur>
                            )}
                            <MyButton
                                type="submit"
                                className="mb-1 mt-1"
                                onClick={mailAccess}
                            >
                                Souscrire <FiSend />
                            </MyButton>
                        </MonFormulaire>
                    </div>
                </NewsletterDiv>
            </NewsletterWrapper>
            <Modal
                size="md"
                show={show}
                onHide={() => setShow(false) & setMail('')}
                aria-labelledby="add-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title
                        id="add-modal"
                        className="d-flex align-items-center"
                    >
                        <img src={INPHB} alt="logo INP-HB" width={'10%'} />
                        <span className="ms-3 fw-bold">Newslatter INP-HB</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        Vous avez bien été ajouté à la Newsletter de l'INP-HB.
                    </span>
                    <p>
                        Merci,{' '}
                        <span className="text-capitalize">{prenom} !!</span>{' '}
                    </p>
                </Modal.Body>
            </Modal>
            <Footer>
                <MyRow className="d-flex flex-wrap justify-content-center align-items-center mb-3">
                    <MyCol xxl={4} xl={4} lg={5} md={6} sm={8} xs={12}>
                        <FirstDiv className="d-flex mb-2 justify-content-start align-items-center">
                            <NavLink
                                to="/portail"
                                style={{ width: '' }}
                                // className="me-3"
                            >
                                {/* <img
                                    style={{ width: '80%' }}
                                    src={INPHB}
                                    alt="INP-HB"
                                /> */}
                            </NavLink>
                            <div>
                                <p>
                                    Infoline : +225 27 30 64 66 61 | 05 01 80 00 19
                                    <br /> Email : <a href='mailto:secretariatdg@inphb.ci' className='text-light'>secretariatdg@inphb.ci</a> 
                                    <br /> Service en ligne :{' '}
                                    <a href="/" className='text-light'>www.inphb.ci</a>
                                </p>
                            </div>
                        </FirstDiv>
                        <div>
                            <FaWindows /> <FaTwitter /> <FaYoutube />{' '}
                            <FaLinkedin /> <FaFacebook />
                        </div>
                        <div>
                            <p>
                                INP-HB <br /> 1093 Yamoussoukro <br /> Côte
                                d'Ivoire <br />{' '}
                            </p>
                        </div>
                    </MyCol>
                    <MyCol
                        className="d-flex align-items-end justify-content-start"
                        xxl={2}
                        xl={2}
                        lg={3}
                        md={3}
                        sm={4}
                        xs={6}
                    >
                        <div>
                            <p className="fw-bold" style={{color:'green'}}>Présentation</p>
                            <a href="/portail/historique"> Histoire de l'Institut</a>
                        </div>
                    </MyCol>
                    <MyCol xl={2} lg={3} md={3} sm={6} xs={6}>
                        <div>
                            <p className="fw-bold" style={{color:'green'}}>Formation</p>
                            <a href="/ecoles"> Écoles</a>
                            <br />
                            <NavLink
                                to="/portail/centres"
                                className="text-light"
                            >
                                {' '}
                                Centres{' '}
                            </NavLink>
                            <br />
                            <NavLink
                                to="/portail/departements"
                                className="text-light"
                            >
                                {' '}
                                Départements{' '}
                            </NavLink>
                        </div>
                    </MyCol>
                    <MyCol xl={2} lg={2} md={4} sm={6} xs={6}>
                        <div>
                            <p className="fw-bold" style={{color:'green'}}>Concours</p>
                            <a href="/concours/bachelier"> Bachelier</a>
                            <br />
                            <a href="/concours/ingenieur"> Ingénieur </a> <br />
                            <a href="/portail/bourses_internationales"> Internationaux</a>
                        </div>
                    </MyCol>
                    <MyCol xl={2} lg={2} md={4} sm={6} xs={6}>
                        <div>
                            <p className="fw-bold" style={{color:'green'}}>Ressources</p>
                            <p>
                                <ul>
                                    {/* <a href={Actu} download alt="Actu INP-HB" style={{color: "#079edb !important"}}>Actu INP-HB Mai 2023</a> <br /> */}
                                    <li><a href={Statut} download style={{color: "#079edb !important"}}>Nouveau Statut</a></li>
                                    <li><a href={Politique} download style={{color: "#079edb !important"}}>Politique qualité</a></li>
                                    <li><a href={LogoOfficiel} download style={{color: "#079edb !important"}}>Logo</a><br /></li>
                                    <li><a href={ReglementIn} download alt="Règlement intérieur des résidences" style={{color: "#079edb !important"}}>Règlement intérieur des résidences</a></li>
                                    <li><a href={ReglementCad} download alt="Règlement cadre scolarité" style={{color: "#079edb !important"}}>Règlement cadre scolarité</a></li>
                                    <li><a href={"https://youtu.be/JWZ6B9cdiHM"} alt="Film Institutionnel" target={"_blank"} rel="noreferrer" style={{color: "#079edb !important"}}>Film institutionnel</a> </li>{' '}
                                </ul>
                            </p>
                        </div>
                    </MyCol>
                </MyRow>
                {/* <div style={{ cursor: 'pointer' }}>
                    <MyGrLocation className="fs-3" /> Nous retrouver
                </div> */}
                <div className="d-flex align-items-center justify-content-center text-center mt-3">
                    INP-HB Digital Copyright &copy; {new Date().toLocaleString('default', { month: 'long' }).toUpperCase()} {new Date().getFullYear()}
                </div>
            </Footer>
            <GoTop
                onClick={() => {
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth',
                    })
                }}
                data-aos="fade-right"
                title="Aller plus haut"
            >
                <TbArrowBigTop style={{ width: '50px', height: '50px' }} />
            </GoTop>
        </FooterPortailConcoursWrapper>
    )
}

export default FooterPortailConcours
