import React, { useState, useContext } from 'react'
import { Container } from 'react-bootstrap'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import styled from 'styled-components'
import GrandProjet from '../../assets/Portail/grandProjet.jpg'
import ProjetInternational from '../../assets/Portail/projetsInternationaux.jpg'
import { Transitions } from '../../components/Transitions'
import { MyContext } from '../../utils/context'
import Error from '../../components/Error'
import { Loader } from '../../components/Loader'
import DOMPurify from 'dompurify'

const TitleDiv = styled.div`
    width: 100%;
    background-color: rgb(0, 141, 54);
`
const Item = styled.div`
    padding: 10px;
    background-color: ${(prop) => prop.color};
    color: ${(prop) => prop.textColor};
    display: flex;
    border-bottom: 1px solid #f47920;
    justify-content: space-between;
    align-items: center;
    cursor: pointer !important;
`
const Myimg = styled.img`
    width: 100%;
`
export const GrandsProjets = () => {
    const [checkBoxState, setcheckBoxState] = useState([
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
    ])
    const HanldeCheck = (i) => {
        setcheckBoxState((prevState) =>
            prevState.map((item, idx) => (idx === i ? !item : false))
        )
    }

    const { isLoading, error, infoGen } = useContext(MyContext)

    const projetsInter = infoGen[0] ? infoGen[0].projetsInter : null
    const grandProjets = infoGen[0] ? infoGen[0].grandProjets : null

    if (error) return <Error />

    return (
        <Transitions>
            <TitleDiv className="d-flex justify-content-center align-items-center text-light mb-5">
                    <span
                        className="body-highlight mt-3 text-uppercase ps-2 mb-3 fs-1"
                        style={{ borderLeft: '10px solid #fff' }}
                    >
                        Grands Projets
                    </span>
            </TitleDiv>
            <div className="d-flex align-items-center justify-content-center">
                    <Myimg
                        src={ProjetInternational}
                        alt=""
                        className="my-3"
                />
                </div>
            <Container className="mb-5 body-text border-0 mb-2">
                
                
                <div
                                    style={{
                                        backgroundColor: 'rgb(0, 141, 54)',
                                    }}
                                    className="p-3 py-4 text-justify text-center fs-3 body-text border-0 text-capitalize text-light"
                                >
                                    Projets internationaux
                </div>

            </Container>

        </Transitions>
    )
}
