import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Transitions } from '../../components/Transitions'
import { sujets } from '../../datas'

function importAll(r) {
    let images = {}
    r.keys().map((item, index) => (images[item.replace('./', '')] = r(item)))
    return images
}

const epreuves = importAll(
    require.context(
        '../../assets/Epreuves',
        false,
        /\.(png|jpe?g|svg|mp4|rar)$/
    )
)
const MyCol = styled(Col)`
    flex-basis: calc(100% / 5);
    cursor: pointer;
    @media (max-width: 1200px) {
        flex-basis: auto;
    }
`
const Item = styled.div`
    padding: 10px;
    background-color: ${(prop) => prop.color};
    color: ${(prop) => prop.textColor};
    display: flex;
    border-bottom: 1px solid ${(prop) => prop.textColor};
    justify-content: space-between;
    align-items: center;
    cursor: pointer !important;
`
const MySpan = styled.span`
    @media(max-width: 430px) {
        font-size: .75em !important;
    }
    @media(max-width: 277px) {
        font-size: .65em !important;
    }
`

export const CeSujet = () => {
    const { annee } = useParams()
    const settings = {
        xxl: 2,
        xl: 3,
        lg: 3,
        md: 4,
        sm: 4,
        xs: 6,
    }

    return (
        <Transitions>
            <Container>
                <Item className="text-uppercase my-2 mb-4 text-dark">
                <div
                    style={{ cursor: 'pointer' }}
                    className="text-uppercase fs-1"
                >
                    {annee}
                </div>
            </Item>
            <Container>
                <div className="mb-2">
                    <Row>
                        {sujets[annee].map(
                            (sujet, index) => (
                                <MyCol
                                    className="p-2 text-danger fs-5 d-flex flex-column align-items-center justify-content-center"
                                    key={index}
                                    {...settings}
                                >
                                    <a
                                        href={sujet.fichier}
                                        style={{ textDecoration: 'none' }}
                                        alt={`${sujet.nom}`}
                                        className="text-center d-flex align-items-center justify-content-center flex-column"
                                        download
                                    >
                                        <img
                                            src={epreuves['pdf.png']}
                                            alt=""
                                            style={{
                                                width: '80%',
                                                aspectRation: 3 / 2,
                                                objectFit: 'contain',
                                            }}
                                            className="mb-2"
                                        />
                                        <MySpan className='text-center'>{sujet.nom}</MySpan>
                                    </a>
                                </MyCol>
                            )
                        )}
                    </Row>
                </div>
            </Container>
            </Container>
            
        </Transitions>
    )
}
