import { useEffect, useState } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { GiHamburgerMenu } from 'react-icons/gi'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import styled from 'styled-components'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { NavLink } from 'react-router-dom'
import { MenuItems } from '../HeaderPortail'

const HamburgerMenuWrapper = styled.div`
    position: absolute;
    padding: 2em;
    width: 100%;
    right: 0;
    top: 0;
    z-index: 100;
    display: none;
    background-color: rgba(0, 0, 0, .6);
    backdrop-filter: blur(8px);
    webkit-backdrop-filter: blur(8px);
    @media (max-width: 1090px) {
        display: flex;
    }
`
const Mya = styled.div`
padding-bottom: 0.6em !important;
cursor: pointer;
border-bottom: 0.1em solid rgba(33, 20, 8, 0.2);
`
const MyNavLink = styled(NavLink)`
padding-bottom: 0.6em !important;
    cursor: pointer;
    border-bottom: 0.1em solid rgba(33, 20, 8, 0.2);
`

const HamburgerMenuItems = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 5%;
    @media (max-width: 580px) {
        width: 95%;
    }
    @media (max-width: 493px) {
        width: 92%;
    }
    @media (max-width: 341px) {
        width: 95%;
    }
`
const HamburgerMenuItem = styled.div`
    color: #fff !important;
    margin-left: 1em;
    font-size: 0.8em;
    text-decoration: none;
    display: flex; 
    justify-content: space-between;
    width: 100%;
    padding-bottom: 0.6em !important;
    cursor: pointer;
    border-bottom: 0.1em solid rgba(33, 20, 8, 0.2);
    &:hover {
        color: rgb(33, 20, 8);
        border-color: rgb(33, 20, 8);
    }
`
const OpenIcon = styled(GiHamburgerMenu)`
    display: none;
    font-size: 30px;
    position: absolute;
    right: 0;
    top: 10px;
    cursor: pointer;
    color: #000;
    @media (max-width: 1090px) {
        display: flex;
    }
`
const CloseIcon = styled(AiOutlineCloseCircle)`
    font-size: 30px;
    cursor: pointer;
    color: #fff;
    position: absolute;
    right: 0;
    top: 10px;
    @media (max-width: 515px) {
        margin-right: 8px !important;
    }
`

export const HamburgerMenuPortail = () => {
    const [isOpen, setisOpen] = useState(false)
    useEffect(() => {
        Aos.init({ duration: 1000 })
    }, [])
    const [isLinkOpen, setisLinkOpen] = useState([
        false,
        false,
        false,
        false,
        false,
        false,
    ])
    const [isZoneOpen, setisZoneOpen] = useState([
        true,
        true,
        true,
        true,
        true,
        true,
    ])
    const HandleCheck = (i) => {
        setisLinkOpen((prevState) =>
            prevState.map((item, idx) => (idx === i ? !item : false))
        )
        setisZoneOpen((prevState) =>
            prevState.map((item, idx) => (idx === i ? true : !item))
        )
    }
    return isOpen ? (
        <HamburgerMenuWrapper data-aos="fade-down">
            <HamburgerMenuItems>
                {MenuItems.map((item, index) => (
                    <div
                        onClick={() => HandleCheck(index)}
                        key={`${index}`}
                        className={isZoneOpen[index] ? 'd-block' : 'd-none'}
                    >
                        <HamburgerMenuItem
                            key={`${item.titre}-${index}`}
                            className="text-uppercase py-4 text-start m-1"
                        >
                            <span>{item.titre}</span>
                            {isLinkOpen[index] ? (
                                <FaChevronUp style={{ cursor: 'pointer' }} />
                            ) : (
                                <FaChevronDown style={{ cursor: 'pointer' }} />
                            )}
                        </HamburgerMenuItem>
                        <div
                            className={isLinkOpen[index] ? 'd-block d-flex flex-column ms-5' : 'd-none'} style={{fontSize:".75em"}}
                        >
                            {item.items.map((sousLien, index) =>
                                sousLien.as ? (
                                    <Mya
                                        href={sousLien.lien}
                                        className="text-uppercase ms-3 my-3"
                                        key={`sousLien-${index}`} 
                                        style={{color:"#f47920"}}                                   >
                                        {sousLien.nom}
                                    </Mya>
                                ) : (
                                    <MyNavLink
                                        to={sousLien.lien}
                                        className="text-uppercase ms-3 my-3"
                                        key={`sousLien-${index}`}
                                        style={{color:"#f47920"}} 
                                        onClick={() => setisOpen(false)}
                                    >
                                        {sousLien.nom}
                                    </MyNavLink>
                                )
                            )}
                        </div>
                    </div>
                ))}
                <HamburgerMenuItem className="text-uppercase py-4 text-start m-1">
                    <NavLink to="relation_entreprise" className="text-light" onClick={() => setisOpen(false)}>
                        Relations Entreprises
                    </NavLink>
                </HamburgerMenuItem>
                <CloseIcon
                    onClick={() => setisOpen(!isOpen)}
                    className="me-3"
                />
            </HamburgerMenuItems>
        </HamburgerMenuWrapper>
    ) : (
        <OpenIcon onClick={() => setisOpen(!isOpen)} className="mt-3 me-3" />
    )
}
