import React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import Placeholder from '../../pages/Placeholder'
import Error from '../../components/Error'
import Concours from '../../pages/Concours'
import CeConcours from '../../pages/CeConcours'
import Ecoles from '../../pages/Ecoles'
import Ecole from '../../pages/Ecole'
import Portail from '../../pages/Portail'
import Recherche from '../../pages/Recherche'
import { CeConcoursAccueil } from '../../pages/CeConcoursAccueil/index.jsx'
import { Presentation } from '../../pages/Presentation/index.jsx'
import { Sujets } from '../../pages/Sujets/index.jsx'
import { PresentationAccueil } from '../../pages/PresentationAccueil/index.jsx'
import { PortailAccueil } from '../../pages/PortailAccueil/index.jsx'
import { VieUniversitaire } from '../../components/VieUniversitaire/index.jsx'
import { VieUniversitaireAccueil } from '../../pages/VieUniversitaireAccueil/index.jsx'
import { Hebergement } from '../../pages/Hebergement/index.jsx'
import { VieAssociative } from '../../pages/VieAssociative/index.jsx'
import EcolesAccueil from '../../pages/EcolesAccueil/index.jsx'
import ConcoursAccueil from '../../pages/ConcoursAccueil/index.jsx'
import { Sante } from '../../pages/Sante/index.jsx'
import { Restauration } from '../../pages/Restauration/index.jsx'
import { BureauDesEtudiants } from '../../pages/BureauDesEtudiants/index.jsx'
import { Actualites } from '../../pages/Actualites/index.jsx'
import { MotDuDirecteur } from '../../pages/MotDuDirecteur/index.jsx'
import { Historique } from '../../pages/Historique/index.jsx'
import { Organisation } from '../../pages/Organisation/index.jsx'
import { SitesEtCampus } from '../../pages/SitesEtCampus/index.jsx'
import { GrandsProjets } from '../../pages/GrandsProjets/index.jsx'
import { TravaillerIci } from '../../pages/TravaillerIci/index.jsx'
import { PageEnConstruction } from '../../components/PageEnConstruction/index.jsx'
import { APropos } from '../../pages/APropos/index.jsx'
import { Missions } from '../../pages/Missions/index.jsx'
import { NosDiplomes } from '../../pages/NosDiplomes/index.jsx'
import { VenirEtudierIci } from '../../pages/VenirEtudierIci/index.jsx'
import { BoursesInternationales } from '../../pages/BoursesInternationales/index.jsx'
import { RelationEntreprise } from '../../pages/RelationEntreprise/index.jsx'
import { Departements } from '../../pages/Departements/index.jsx'
import { DepartementsAccueil } from '../../pages/DepartementAccueil/index.jsx'
import Departement from '../../pages/Departement/index.jsx'
import { Centres } from '../../pages/Centres/index.jsx'
import { ActivitesSportives } from '../../pages/ActivitesSportives/index.jsx'
import { AnimatePresence } from 'framer-motion'
import { EpreuvesAccueil } from '../../pages/EpreuvesAccueil'
import { CetteEpreuve } from '../../pages/CetteEpreuve'
import { Statistiques } from '../../pages/Statistiques'
import EcoleAccueil from '../../pages/EcoleAccueil'
import { CommentIntegrer } from '../../pages/CommentIntegrer'
import { BureauGenre } from '../../pages/BureauGenre'
import { CycleEtFiliere } from '../../pages/CycleEtFiliere'
import { Epreuves } from '../../pages/Epreuves'
import { SujetsAccueil } from '../../pages/SujetsAccueil'
import { CeSujet } from '../../pages/CeSujet'
import Bpe from '../../pages/Productionexpertise/index'
import Techno from '../Technopole/Techno'
import Eramus from '../Erasmus/erasmus.jsx'
import NewPlaceholder from '../../pages/Newplaceholder/NewPlaceholder.jsx'
import Newschool from '../../pages/Newschools/Newschool.jsx'
import Newconcours from '../../pages/Newconcours/Newconcours.jsx'
import Newcourses from '../../pages/Newcourses/Newcourses.jsx'

export const AnimatedRoutes = () => {
    const location = useLocation()

    return (
        <>
        <AnimatePresence mode="wait">
            <Routes key={location.pathname} location={location}>
                {/* <Route exact path="/" element={<Placeholder />} /> */}
                <Route path="portail" element={<Portail />}>
                    <Route index element={<PortailAccueil />} />
                    <Route path="actualites" element={<Actualites />} />
                    <Route path="bureau_genre" element={<BureauGenre />}/>
                    <Route path="statistiques_et_ressources" element={<Statistiques />} />
                    <Route
                        path="mot_du_directeur"
                        element={<MotDuDirecteur />}
                    />
                    <Route path="historique" element={<Historique />} />
                    <Route path="centres" element={<Centres />} />
                    <Route path="a_propos" element={<APropos />} />
                    <Route
                        path="missions_vision_valeurs"
                        element={<Missions />}
                    />
                    <Route
                        path="diplomes_emblematiques"
                        element={<NosDiplomes />}
                    />
                    <Route
                        path="venir_etudier_ici"
                        element={<VenirEtudierIci />}
                    />
                    <Route
                        path="bourses_internationales"
                        element={<BoursesInternationales />}
                    />
                    <Route
                        path="relation_entreprise"
                        element={<RelationEntreprise />}
                    />
                    <Route path="organisation" element={<Organisation />} />
                    <Route path="sites_et_campus" element={<SitesEtCampus />} />
                    <Route path="grands_projets" element={<GrandsProjets />} />
                    <Route path="travailler_ici" element={<TravaillerIci />} />
                    <Route path="departements" element={<Departements />}>
                        <Route index element={<DepartementsAccueil />} />
                        <Route
                            path=":departementName"
                            element={<Departement />}
                        />
                    </Route>
                    <Route
                        path="vie_universitaire"
                        element={<VieUniversitaire />}
                    >
                        <Route index element={<VieUniversitaireAccueil />} />
                        <Route path="hebergement" element={<Hebergement />} />
                        <Route
                            path="vie_associative"
                            element={<VieAssociative />}
                        />
                        <Route
                            path="assistance_sociale_et_sante"
                            element={<Sante />}
                        />
                        <Route
                            path="activites_sportives"
                            element={<ActivitesSportives />}
                        />
                        <Route path="restauration" element={<Restauration />} />
                        <Route
                            path="bureau_des_eleves"
                            element={<BureauDesEtudiants />}
                        />
                    </Route>
                </Route>
                <Route
                    path="page_en_construction"
                    element={<PageEnConstruction />}
                />
                <Route path="recherche" element={<Recherche />} />
                <Route path="ecoles" element={<Ecoles />}>
                    <Route index element={<EcolesAccueil />} />
                    <Route path=":schoolName" element={<Ecole />}>
                        <Route index element={<EcoleAccueil />} />
                        <Route path="comment_integrer_notre_ecole" element={<CommentIntegrer />} />
                        <Route path="cycles_et_filieres" element={<CycleEtFiliere />} />
                    </Route>
                </Route>
                <Route path="concours" element={<Concours />}>
                    <Route index element={<ConcoursAccueil />} />
                    <Route path=":contestName" element={<CeConcours />}>
                        <Route index element={<CeConcoursAccueil />} />
                        <Route
                            path="presentation"
                            element={<Presentation />}
                        >
                            <Route
                                index
                                element={<PresentationAccueil />}
                            />
                        </Route>
                        <Route
                            path="sujets_concours_bachelier"
                            element={<Sujets />}>
                                <Route
                                index
                                element={<SujetsAccueil />}
                            />
                            <Route path=":annee" element={<CeSujet />} />
                        </Route>
                        <Route
                            path="sujets_concours_ing"
                            element={<Sujets />}>
                                <Route
                                index
                                element={<SujetsAccueil />}
                            />
                            <Route path=":annee" element={<CeSujet />} />
                        </Route>
                        
                        <Route
                            path="epreuves"
                            element={<Epreuves />}
                        >
                            <Route
                                index
                                element={<EpreuvesAccueil />}
                            />
                            <Route path=":epreuve" element={<CetteEpreuve />} />
                        </Route>
                        
                    </Route>
                    
                </Route>
                <Route path="*" element={<Error />} />
                
                {/* Tous les autres liens s'ajoutent içi  */}
                <Route path="production_expertise" element={<Bpe />} />
                <Route path="Technopole" element={<Techno />} />
                <Route path="Erasmus" element={<Eramus />} />
                <Route path="/" element={<NewPlaceholder />} />
                <Route path="/toutesnosecoles" element={<Newschool />} />
                <Route path="/admission" element={<Newconcours />} />
                <Route path="/courses" element={<Newcourses />} />



                


                
            </Routes>
            
        </AnimatePresence>
        
        </>
        //c'et ici tous les liens
    )
}
