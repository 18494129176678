import React from 'react'
import styled from 'styled-components'
import { Container } from 'react-bootstrap'
import '../../utils/style/index.scss'
import { useLocation } from 'react-router-dom'
import { Transitions } from '../../components/Transitions'
import Communique from '../../assets/Concours/Communiqueing2024.pdf'
import Paiement from '../../assets/Concours/PROCESS_PAIEMENT_INSCRIPTION_INPHB_FINAL.pdf'
import Chronogramme from '../../assets/Concours/Chronoging2024.pdf'
import Calepreuve from '../../assets/Concours/Caling2024.pdf'
import Ficheinfos from '../../assets/Concours/fincheinfoing2024.pdf'
// import COMMUNIQUE2023 from '../../assets/Concours/CONCOURSBAC2023.pdf'
// import INFOSCONCOURS2023 from '../../assets/Concours/INFOSCONCOURS2023.pdf'
// import CHRONOCONCOURS2023 from '../../assets/Concours/CHRONOCONCOURS2023.pdf'

const ProcessusWrapper = styled(Container)`
    background-color: rgba(8, 155, 217, 0.1);
    padding: 20px;
    margin-top: 0;
`
const Mya = styled.a`
    text-decoration: none;
    cursor: pointer;
    color: green;
    font-weight: bold;
`
const MyOl = styled.ol`
    list-style: none;
    counter-reset: my-count;
    > li {
        counter-increment: my-count;
        margin-bottom: 20px;
        &::before {
            content: counter(my-count);
            margin-right: 10px;
            padding: 0.65em;
            background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0.2)
                ),
                linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
                #ee7202;
            color: #fff;
            font-weight: bold;
        }
    }
`
const SchoolNavbarWrapper = styled.div`
    background-color: ${(prop) => prop.color};
    display: flex;
    justify-content: center;
    align-items: center;
`
const SchoolName = styled.div`
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;
    @media (max-width: 730px) {
        flex-direction: column;
        justify-content: center;
    }
`
const SchoolNameText = styled.span`
    font-style: normal;
    font-weight: bold;
    font-size: 2.5em;
    padding-left: 10px;
    border-left: 10px solid #fff;
    text-align: center;
    color: #fff;
`
export const CeConcoursAccueil = () => {
    const location = useLocation()
    const isIngenieur = location.pathname.includes('ingenieur')

    return (
        <Transitions>
            <SchoolNavbarWrapper color={'#008D36'} className="mb-0">
                <SchoolName>
                    <div className="w-100 text-center text-uppercase">
                        <SchoolNameText>Accueil</SchoolNameText>
                    </div>
                </SchoolName>
            </SchoolNavbarWrapper>
            {/* <Container className="mb-5 mt-5" data-aos="fade-up">
                <video
                    title="présentation de l'INP-HB"
                    width="100%"
                    controls
                    autoPlay
                    muted
                >
                    <source src={Concours.videoIntroduction} type="video/mp4" />
                </video>
            </Container> */}
            {isIngenieur ? (
                <ProcessusWrapper fluid>
                    <p className="title-text text-center text-dark fw-bold">
                        CONCOURS INGENIEUR ITA : {' '}
                    </p>
                    <p className="body-text border-0 text-center mb-3">
                        Chers candidats, veuillez prendre connaissance des{' '}
                        <br /> informations importantes  sur <br/> le
                        processus d'inscription <br />
                        au concours ingénieur ITA 2024:{' '}
                        {/* <Mya
                                href="https://ingenieurs.concours.inphb.app/"
                                className="btn btn-success text-white"
                                target={'_blank'}
                                style={{ color: 'white !important' }}
                            >
                                Cliquez ici
                            </Mya> */}
                        
                       
                    </p>
                    {/* <p className="body-text border-0 text-center mb-3">
                        Chers candidats, le concours sera ouvert <br/> sous peu,{' '}
                        merci pour votre patience.
                         <br />
                        Veuillez prendre consulter le ménunu " Présentation ", pour des informations utiles.{' '}
                    </p> */}
                    <br /><br />
                    <div className="d-flex justify-content-center align-items-center">
                        <MyOl className="mb-3 body-text border-0">
                            {/* <li>
                                Lire le communiqué relatif au concours,{' '}
                                <Mya href={Communique} download>
                                    Cliquez ici
                                </Mya>
                            </li> */}
                            <li>
                                Télécharger la fiche d'informations ,{' '}
                                <Mya href='https://adminsite.inphb.app/public/documentspdf/infoita24.pdf' target='_blank'>
                                    Cliquez ici
                                </Mya>
                            </li>
                            {/* <li>
                                Prendre connaissance du chronograme,{' '}
                                <Mya href={Chronogramme} download>
                                    Cliquez ici
                                </Mya>
                            </li> */}
                            <li>
                                Lire le processus de paiement TRÉSORPAY /
                                TRÉSORMONEY,{' '}
                                <Mya href={Paiement} download>
                                    Cliquez ici
                                </Mya>
                            </li>
                            {/* <li>
                                Prendre connaissance de la présentation des
                                concours et filières des écoles,{' '}
                                <span>
                                    <Mya href="/concours/ingenieur/presentation">
                                        Cliquez ici
                                    </Mya>
                                </span>
                            </li> */}
                            

                            <li>
                                Télécharger le Calendrier des épreuves {' '}
                                <strong>
                                <Mya
                                href='https://adminsite.inphb.app/public/documentspdf/ca_lita24.pdf'
                                className="btn btn-success text-white"
                                target={'_blank'}
                                style={{ color: 'white !important' }}
                            >
                                Cliquez ici
                            </Mya>
                                </strong>
                            </li>  
                            <li>   Démarrer la préinscription                       

                            <Mya
                                href="https://ingenieurs.concours.inphb.app/"
                                className="btn btn-success text-white"
                                target={'_blank'}
                                style={{ color: 'white !important', marginLeft:'10px' }}
                            >
                                Cliquez ici
                            </Mya>
                            </li> 
                        </MyOl>
                    </div>
                    <p className="text-center fw-bold">
                        Pour tout renseignement complémentaire: Tél.: +225 05 05 55 01 55 | 07 58 66 18 66
                        
                    </p>
                </ProcessusWrapper>
            ) : (
                <ProcessusWrapper fluid>
                    <p className="title-text text-center text-dark fw-bold">
                        Processus d'inscription
                    </p>
                    <p className="body-text border-0 text-center mb-3">
                        Chers candidats, la création d'un compte <strong> TresorMoney </strong>  est indispensable pour votre
                        préincription au concours bachelier. <br /> <br />  
                        {' '}
                    </p>
                    <div className="d-flex justify-content-center align-items-center">
                         <MyOl className="mb-3 body-text border-0">
                            <li>
                                Lire le communiqué relatif au concours,{' '}
                                <Mya href='https://adminsite.inphb.app/public/documentspdf/Combac24.pdf' target="_blank">
                                    Cliquer ici
                                </Mya>
                            </li>
                            <li>
                                Informations générales relatives  au concours
                                d'entrée à l'INP-HB,{' '}
                                <Mya
                                    href='https://adminsite.inphb.app/public/documentspdf/Infosbac24.pdf' target='_blank'
                                    
                                >
                                    Cliquez ici
                                </Mya>
                            </li>
                            <li>
                                Prendre connaissance du chronogramme,{' '}
                                <Mya href='https://adminsite.inphb.app/public/documentspdf/Chronbac24.pdf' target='_blank' >
                                    Cliquez ici
                                </Mya>
                            </li>
                            <li>
                                Prendre connaissance de la présentation des
                                concours et filières des écoles,{' '}
                                <span>
                                    <Mya href="/concours/bachelier/presentation" target='_blank'>
                                        Cliquez ici
                                    </Mya>
                                </span>
                            </li>
                             <li>
                                La pré-inscription en ligne : {' '}
                                <strong>
                                    du Mer 03 à minuit au  Mar 23 Juillet 2024
                                </strong>
                            </li> 
                            <li>
                                
                                <Mya href="https://bacs.concours.inphb.app/" target='_blank'
                                    className="btn btn-success text-white"
                                >
                                Démarrer votre préinscription
                                </Mya>
                            </li>
                        </MyOl> 
                        
                    </div>
                    
                    <p className="text-center fw-bold">
                        Pour tout renseignement complémentaire : Tél.: +225 05 05 55 01 55 | 07 58 66 18 66
                    </p>
                </ProcessusWrapper>
            )}
        </Transitions>
    )
}
