import styled from 'styled-components'
import '../../utils/style/index.scss'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'
import { Card, Container } from 'react-bootstrap'
import { FaHardHat, FaUserGraduate } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'
import { Transitions } from '../../components/Transitions'
import AppelACandidature from '../../components/AppelACandidature'
import classes from '../Newplaceholder/newplace.module.css'
import Newmenutop from '../Newmenutop/Newmenutopsecond'

function importAll(r) {
    let images = {}
    r.keys().map((item, index) => images[item.replace('./', '')] = r(item))
    return images
}

const concours = importAll(
    require.context('../../assets/Concours', false, /\.(png|jpe?g|svg)$/)
)


const FirstRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 25%;
    @media (max-width: 1000px) {
        width: 50%;
    }
    @media (max-width: 540px) {
        width: auto;
    }
`
const SecondRow = styled(FirstRow)`
    width: 50%;
    @media (max-width: 1000px) {
        width: 70%;
    }
    @media (max-width: 700px) {
        width: auto;
    }
    @media (max-width: 365px) {
        flex-direction: column;
    }
`
const CardText = styled(Card.Title)`
    @media (max-width: 485px) {
        font-size: 0.8em;
    }
`
const MyCard = styled(Card)`
    @media (max-width: 365px) {
        margin: auto !important;
        margin-bottom: 15px !important;
    }
`
const RowItem = styled(NavLink)`
    display: flex;
    text-decoration: none;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`
const AVoirAussi = styled.div`
    width: 100%;
    padding: 50px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    background-color: #e2e2e2;
    align-items: center;
    justify-content: center;
    @media(max-width:500px) {
        padding: 5px;
    }
`

const Top = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    width: 100%;
    font-size: 3em;
    background-color: rgba(0, 141, 54, .8);
`

function ConcoursAccueil() {
    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])
    return (
        <>
            {/* <Transitions> */}
                <div className={classes.container}>
                    <Newmenutop />
                    <Container fluid className="d-flex flex-column align-items-center mt-3">
                        <FirstRow>
                            <RowItem
                                className="me-4 text-dark"
                                to="bachelier"
                            >
                                <Top>
                                    <FaUserGraduate />
                                </Top>
                                <p className="text-uppercase fw-bold p-1">Bachelier</p>
                            </RowItem>
                            

                            {/* <RowItem
                                className="me-4 text-dark"
                                to="https://www.google.com"
                                target="_blank" 
                            >
                                
                                <Top>
                                    <FaUserGraduate />
                                </Top>
                                    
                                <p className="text-uppercase fw-bold p-1">Bachelier <br /> USP</p>
                                        
                            </RowItem> */}
                            

                            
                            <RowItem className="text-dark" to="ingenieur">
                                <Top>
                                    <FaHardHat />
                                </Top>
                                <p className="text-uppercase fw-bold p-1">Ingénieur </p>
                            </RowItem>
                        </FirstRow>

                    </Container>
                    <AVoirAussi>
                        <p className="mt-5 body-highlight text-uppercase">
                            appels à candidatures | Masters | Mastères
                        </p>
                        <AppelACandidature />
                    </AVoirAussi>
                </div>
            {/* </Transitions> */}
        </>
    )
}

export default ConcoursAccueil
