import { useContext, useState, useRef, useEffect } from 'react'
import classes from '../Newplaceholder/newplace.module.css'
import { FaBook, FaRegUserCircle, FaRegLightbulb, FaBars, FaTimes, FaRegUser, FaBed, FaSchool, FaChalkboardTeacher, FaCar, FaHotel, FaCuttlefish } from 'react-icons/fa'
import { FcDepartment, FcAndroidOs, FcNews, FcDiploma1 } from "react-icons/fc";
import { BsNodePlusFill } from "react-icons/bs";
import { SiMicrosoftoutlook } from "react-icons/si";
import { Link } from 'react-router-dom';

function importAll(r) {
    let images = {}
    r.keys().map((item, index) => (images[item.replace('./', '')] = r(item)))
    return images
}

const Slider2 = importAll(
    require.context('../../assets/Placeholder/Slider', false, /\.(png|jpe?g|svg|mp4)$/)
)



export default function Newmenutop() {

    const [isMenuOpen, setIsMenuOpen] = useState(false); // State for mobile menu
    const [counterValue, setCounterValue] = useState(0);
    const [counterValueRooms, setCounterValueRooms] = useState(0);
    const [counterValueSchools, setCounterValueSchools] = useState(0);
    const [counterValueTeachers, setCounterValueTeachers] = useState(0);
    const [counterValueDepart, setCounterValueDepart] = useState(0);


    const handleToggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const [shrink, setShrink] = useState(false);

    const handleScroll = () => {
        const currentScrollPos = window.scrollY;
        const shrinkOn = 100; // Définissez la position de défilement à partir de laquelle les changements sont appliqués
        if (currentScrollPos > shrinkOn) {
            setShrink(true);
        } else {
            setShrink(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [])
    //Pour le décompte des élèves 
    useEffect(() => {
        let interval;
        if (counterValue < 6137) {
            interval = setInterval(() => {
                setCounterValue(prevCount => (prevCount + 40 > 6137 ? 6137 : prevCount + 40)); // Change 40 to adjust the speed
            }, 70); // Change 20 to adjust the speed
        } else if (counterValue === 6137) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [counterValue]);

    //Pour les chambres

    useEffect(() => {
        let interval;
        if (counterValueRooms < 3008) {
            interval = setInterval(() => {
                setCounterValueRooms(prevCount => (prevCount + 40 > 3008 ? 3008 : prevCount + 40)); // Change 40 to adjust the speed
            }, 70); // Change 20 to adjust the speed
        } else if (counterValueRooms === 3008) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [counterValueRooms]);

    // Pour les écoles

    useEffect(() => {
        let interval;
        if (counterValueSchools < 11) {
            interval = setInterval(() => {
                setCounterValueSchools(prevCount => (prevCount + 40 > 11 ? 11 : prevCount + 40)); // Change 40 to adjust the speed
            }, 300); // Change 20 to adjust the speed
        } else if (counterValueSchools === 3008) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [counterValueSchools]);

    // Pour les enseignants
    useEffect(() => {
        let interval;
        if (counterValueTeachers < 520) {
            interval = setInterval(() => {
                setCounterValueTeachers(prevCount => (prevCount + 40 > 520 ? 520 : prevCount + 10)); // Change 40 to adjust the speed
            }, 100); // Change 20 to adjust the speed
        } else if (counterValueTeachers === 3008) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [counterValueTeachers]);

    //Pour les départements
    useEffect(() => {
        let interval;
        if (counterValueDepart < 22) {
            interval = setInterval(() => {
                setCounterValueDepart(prevCount => (prevCount + 40 > 22 ? 22 : prevCount + 22)); // Change 40 to adjust the speed
            }, 300); // Change 20 to adjust the speed
        } else if (counterValueDepart === 22) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [counterValueDepart]);
  return (
        <>
            <div className={classes.container}>
            <div className={classes.lestops}>
                <div className={`${classes.befortop} ${shrink ? classes.topbarShrinkbefor : ''}`}>
                    <div className={`${classes.topbar} ${shrink ? classes.topbarShrink : ''}`}>
                            <div className={`${classes.topmenucontent} ${shrink ? classes.topmenucontentShrink : ''}`}>
                                <div className={classes.topmenucontent1} onClick={() => window.open("/", '_self')}>
                                    <img src="https://www.adminsite.inphb.app/Imagessiteprincipal/Icon.png" alt="Logo INP-HB" />
                                </div>
                                {/* LE MENU DU TELEPHONE  */}
                                <div className={classes.topmenuphone} onClick={handleToggleMenu}>
                                    {isMenuOpen ? <FaTimes className={classes.iconwhite} /> : <FaBars className={classes.iconwhite} />}
                                </div>

                                <div onClick={handleToggleMenu} className={classes.menutelephonelibre} style={{ display: isMenuOpen ? 'block'  : 'none', transition: '6s' }}>
                                    <div className={classes.menutelephonelibreitem1}  onClick={handleToggleMenu}>
                                        <Link className={classes.menutelephonelibreitem1} to='https://etudiants.ent.inphb.app/'>
                                            <FaRegUserCircle style={{fontSize: '25px', marginRight: '15px'}} />
                                            <span>Espace étudiant</span>
                                        </Link>
                                    </div> 
                                    <div className={classes.menutelephonelibreitem2} onClick={handleToggleMenu}>
                                        <Link className={classes.menutelephonelibreitem2} to='https://bibliotheque.inphb.ci/'>
                                            <FaBook style={{fontSize: '25px', marginRight: '15px'}} />
                                            <span>Bibliothèque</span>
                                        </Link>
                                    </div> 
                                    <div className={classes.menutelephonelibreitem3} onClick={handleToggleMenu}>
                                        <Link className={classes.menutelephonelibreitem3} to='https://careercenter.inphb.app' target='_blank'>
                                            <FaRegLightbulb style={{fontSize: '25px', marginRight: '15px'}} />
                                            <span>Career Center</span>
                                        </Link>
                                    </div> 
                                    <div className={classes.menutelephonelibreitem4} onClick={handleToggleMenu}>
                                        <Link className={classes.menutelephonelibreitem4} to='https://actualites.inphb.ci/'>
                                            <FcNews style={{fontSize: '25px', marginRight: '15px'}} />
                                            <span>Actualités</span>
                                        </Link>
                                    </div> 
                                    <div className={classes.menutelephonelibreitem5} onClick={handleToggleMenu}>
                                        <Link className={classes.menutelephonelibreitem5} to='/Erasmus'>
                                            <BsNodePlusFill style={{fontSize: '25px', marginRight: '15px'}} />
                                            <span>Erasmus </span>
                                        </Link>
                                    </div> 
                                    <div className={classes.menutelephonelibreitem6} onClick={handleToggleMenu}>
                                        <a className={classes.menutelephonelibreitem6} href='https://inphb-diplome.verifdiploma.com/verifcenter/' target='_blank'>
                                            <FcDiploma1 style={{fontSize: '25px', marginRight: '15px'}} />
                                            <span>Vérifier un diplôme INPHB</span>
                                        </a>                                      
                                    </div>
                                    <div className={classes.menutelephonelibreitem7} onClick={handleToggleMenu}>
                                        <a className={classes.menutelephonelibreitem7} href='https://mobility.inphb.app/' target='_blank'>
                                            <FaCar style={{fontSize: '25px', marginRight: '15px'}} />
                                            <span>INP-HB </span>
                                            <span>Mobility</span>
                                        </a>
                                        {/* <span> INPHB</span> */}
                                    </div>
                                    <div className={classes.menutelephonelibreitem8} onClick={handleToggleMenu}>
                                        <a className={classes.menutelephonelibreitem8} href='https://outlook.office.com/mail/' target='_blank'>
                                            <SiMicrosoftoutlook style={{fontSize: '25px', marginRight: '15px'}} />
                                            <span>Mail Instit.</span>
                                            {/* <span>Mobility</span> */}
                                        </a>
                                    </div>
                                    <div className={classes.menutelephonelibreitem8a} onClick={handleToggleMenu}>
                                        <a className={classes.menutelephonelibreitem8a} href='https://app0.compilatio.net/v5/login' target='_blank'>
                                            <FaCuttlefish style={{fontSize: '25px', marginRight: '15px'}} />
                                            <span>Compilatio</span>
                                            {/* <span>Mobility</span> */}
                                        </a>
                                    </div>
                                    
                                </div>
                                {/* FIN DU MENU TELEPHONE  */}
                                <div className={classes.topmenucontent2}>
                                    <div className={classes.topmenucontent2item1} >
                                        <Link className={classes.topmenucontent2item1} to='https://etudiants.ent.inphb.app/'>
                                            <FaRegUserCircle style={{fontSize: '25px'}} />
                                            <span>Espace</span>
                                            <span>élèves</span>
                                        </Link>
                                    </div>
                                    <div className={classes.topmenucontent2item2}>
                                        <Link className={classes.topmenucontent2item2} to='https://bibliotheque.inphb.ci/' >
                                            <FaBook style={{fontSize: '25px'}} />
                                            <span>Bibilothèque</span>
                                            {/* <span>étudiant</span> */}
                                        </Link>
                                    </div>
                                    <div className={classes.topmenucontent2item3}>
                                        <Link className={classes.topmenucontent2item3} to='https://careercenter.inphb.app' target='_blank'>
                                            <FaRegLightbulb style={{fontSize: '25px'}} />
                                            <span>Career </span>
                                            <span>Center</span>
                                        </Link>
                                    </div>
                                    <div className={classes.topmenucontent2item4}>
                                        <Link className={classes.topmenucontent2item4} to='https://actualites.inphb.ci/'>
                                            <FcNews style={{fontSize: '25px'}} />
                                            <span>Actualités</span>
                                            {/* <span>Carrière</span> */}
                                        </Link>
                                    </div>
                                    <div className={classes.topmenucontent2item5}>
                                        <Link className={classes.topmenucontent2item5} to='/Erasmus'>
                                            <BsNodePlusFill style={{fontSize: '25px'}} />
                                            <span>Erasmus +</span>
                                        </Link>
                                    </div>
                                    <div className={classes.topmenucontent2item6}>
                                        <a className={classes.topmenucontent2item6} href='https://inphb-diplome.verifdiploma.com/verifcenter/' target='_blank'>
                                            <FcDiploma1 style={{fontSize: '25px'}} />
                                            <span>Vérifier un</span>
                                            <span>Diplôme </span>
                                        </a>
                                        {/* <span> INPHB</span> */}
                                    </div>
                                    <div className={classes.topmenucontent2item7}>
                                        <a className={classes.topmenucontent2item7} href='https://mobility.inphb.app/' target='_blank'>
                                            <FaCar style={{fontSize: '25px'}} />
                                            <span>INP-HB </span>
                                            <span>Mobility</span>
                                        </a>
                                        {/* <span> INPHB</span> */}
                                    </div>
                                    <div className={classes.topmenucontent2item8}>
                                        <a className={classes.topmenucontent2item8} href='https://outlook.office.com/mail/' target='_blank'>
                                            <SiMicrosoftoutlook style={{fontSize: '25px'}} />
                                            <span>Mail</span>
                                            <span>Instit.</span>
                                        </a>
                                        {/* <span> INPHB</span> */}
                                    </div>
                                    <div className={classes.topmenucontent2item9}>
                                        <a className={classes.topmenucontent2item9} href='https://app0.compilatio.net/v5/login' target='_blank'>
                                            <FaCuttlefish style={{fontSize: '25px'}} />
                                            <span>Compilatio</span>
                                            {/* <span>Instit.</span> */}
                                        </a>
                                        {/* <span> INPHB</span> */}
                                    </div>
                                    



                                </div>
                        </div>
                    </div>
                </div>
                <div className={classes.secondtopbar}>
                    <div className={classes.secondtopbarcontent}>
                        <div className={classes.secondtopbarcontent1}>
                            <FaSchool  />
                            <div>
                                {/* <span>{counterValue}</span>  */}
                                <div className={classes.counttext}>{counterValueSchools}</div>
                                <div className={classes.counttext}>écoles</div>
                            </div>
                        </div>
                        <div className={classes.secondtopbarcontent1}>
                            <FaChalkboardTeacher  />
                            <div>
                                {/* <span>{counterValue}</span>  */}
                                <div className={classes.counttext}>+ {counterValueTeachers}</div>
                                <div className={classes.counttext}> Enseignants</div>
                            </div>
                        </div>
                        <div className={classes.secondtopbarcontent1}>
                            <FaRegUser  />
                            <div>
                                {/* <span>{counterValue}</span>  */}
                                <div className={classes.counttext}>+ {counterValue}</div>
                                <div className={classes.counttext}>élèves</div>
                            </div>
                        </div>
                        <div className={classes.secondtopbarcontent1}>
                            <FaBed  />
                            <div>
                                {/* <span>{counterValue}</span>  */}
                                <div className={classes.counttext}>{counterValueRooms}</div>
                                <div className={classes.counttext}>Chambres</div>
                            </div>
                        </div>
                        {/* <div className={classes.secondtopbarcontent1}>
                            <FcDepartment />

                            <div>
                                <div className={classes.counttext}>{counterValueDepart}</div>
                                <div className={classes.counttext}> Fablabs</div>
                            </div>
                        </div> */}
                        {/* <div className={classes.secondtopbarcontent1}>
                            <FaChalkboardTeacher  />
                            <div>
                                <div className={classes.counttext}>+ {counterValueTeachers}</div>
                                <div className={classes.counttext}> UMRI</div>
                            </div>
                        </div> */}
                    </div>
                    
                </div>
            </div>
            </div>
        </>
    
  )
}
