import styled from 'styled-components'
import { Carousel, Col, Container, Row } from 'react-bootstrap'
import Aos from 'aos'
import 'aos/dist/aos.css'
import '../../utils/style/index.scss'
import { NavLink } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import {
    FaBed,
    FaBookOpen,
    FaBuilding,
    FaChalkboardTeacher,
    FaSchool,
    FaUserGraduate,
    FaChartBar,
} from 'react-icons/fa'
import { BsBuilding } from 'react-icons/bs'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { Transitions } from '../../components/Transitions'
import { MyContext } from '../../utils/context'
import { Loader } from '../../components/Loader'
import Error from '../../components/Error'
import AppelACandidature from '../../components/AppelACandidature'
import EvenementsSmartphone from '../../components/EvenementsSmartphone'
import { EvenementsDesktop } from '../../components/EvenementsDesktop'
import { ActualitesDesktop } from '../../components/ActualitesDesktop'
import ActualitesSmartphone from '../../components/ActualitesSmartphone'
import photoDgDG from '../../assets/Portail/dg.png'
import { Border } from 'victory'

function importAll(r) {
    let images = {}
    r.keys().map((item, index) => (images[item.replace('./', '')] = r(item)))
    return images
}

const portail = importAll(
    require.context('../../assets/Portail', false, /\.(png|jpe?g|svg)$/)
)

const Acceuil = styled.div`
    padding: 0px;
    margin: 0px;
    background-image: url(${portail['portailBackground.jpg']});
    background-position: 10% 20%;
    background-repeat: no-repeat;
    background-size: cover;
`
const AccueilText = styled.div`
    height: 25rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 55px;
    justify-content: flex-start;
    padding: 3rem;
    align-items: center;
    margin: 0px;
    background: linear-gradient(145deg, #008d36, transparent);
    @media(max-width: 341px) {
        padding: 1rem;
    }
`
const Titre = styled.p`
    @media(max-width:341px) {
        font-size: 1.2em !important;
    }
    @media(max-width:257px) {
        font-size: 1em !important;
    }
    @media(max-width:220px) {
        font-size: .6em !important;
    }
`
const SousTitres = styled.p`
    @media(max-width:341px) {
        font-size: .4em !important;
    }
`
const MotDirecteurWrapper = styled.div`
    z-index: 1;
    position: absolute;
    width: 100%;
    margin-top: -6%;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 665px) {
        margin-top: -10%;
    }
`
const MotDirecteurDiv = styled.div`
    width: 55%;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
        rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    @media (max-width: 1074px) {
        width: 70%;
    }
    @media (max-width: 700px) {
        width: 80%;
    }
    @media (max-width: 610px) {
        width: 85%;
    }
    @media (max-width: 490px) {
        width: 90%;
    }
`
const MotDirecteurImg = styled.img`
    width: 200px;
    height: 200px;
    border-radius: 50%;
    transition: transform 350ms ease-in-out;
    border: #fff solid 2px;
    @media (max-width: 850px) {
        width: 170px;
        height: 170px;
    }
    @media (max-width: 665px) {
        width: 200px;
        height: 200px;
        margin-bottom: 20px !important;
    }
    @media (max-width: 450px) {
        width: 130px;
        height: 130px;
    }
    &:hover {
        transform: scale(1.03);
    }
`
const LienMotDirecteur = styled(NavLink)`
    text-decoration: none;
    cursor: pointer;
    transition: transform 350ms ease-in-out;
    &:hover {
        transform: scale(1.03);
    }
`
const MotDirecteurTop = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 5px;
    @media (max-width: 650px) {
        font-size: 12px;
    }
    @media (max-width: 535px) {
        font-size: 10px;
    }
`
const MotDirecteurTopItem = styled(MotDirecteurTop)`
    @media (max-width: 490px) {
        flex-direction: column;
    }
    cursor: pointer;
    translate: all 300ms ease-in-out;
    &:hover {
        transform: scale(1.03);
        font-weight: bold;
        color: rgb(244, 121, 32) !important;
    }
`
const MotDirecteurTopItemNavLink = styled(NavLink)`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 5px;
    translate: all 300ms ease-in-out;
    &:hover {
        transform: scale(1.03);
        font-weight: bold;
        color: rgb(244, 121, 32) !important;
    }
    @media (max-width: 650px) {
        font-size: 12px;
    }
    @media (max-width: 535px) {
        font-size: 10px;
    }
    @media (max-width: 490px) {
        flex-direction: column;
    }
`
const MotDirecteurTopItemA = styled.a`
    display: flex;
    text-decoration: none;
    justify-content: space-evenly;
    align-items: center;
    padding: 5px;
    translate: all 300ms ease-in-out;
    &:hover {
        transform: scale(1.03);
        font-weight: bold;
        color: rgb(244, 121, 32) !important;
    }
    @media (max-width: 650px) {
        font-size: 12px;
    }
    @media (max-width: 535px) {
        font-size: 10px;
    }
    @media (max-width: 490px) {
        flex-direction: column;
    }
`
const MotDirecteurBottom = styled.div`
    width: 100%;
    border-radius: 30px;
    background-image: url(${portail['secondbg.jpg']});
    background-position: 10% 10%;
    background-repeat: no-repeat;
    background-size: cover;
`
const MotDirecteurText = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    border-radius: 30px;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin: 0px;
    background: rgba(244, 121, 32, 0.7);
    @media (max-width: 665px) {
        flex-direction: column;
        padding: 10px !important;
    }
`
const MotDirecteurBottomTextTitle = styled.p`
    font-size: 1.5em !important;
    @media (max-width: 850px) {
        font-size: 1.2em !important;
    }
    @media (max-width: 740px) {
        font-size: 0.95em !important;
    }
    @media (max-width: 420px) {
        font-size: 0.9em !important;
    }
`
const MotDirecteurBottomText = styled.div`
    font-size: 1em !important;
    @media (max-width: 850px) {
        font-size: 0.8em !important;
        line-height: 1.2em !important;
    }
    @media (max-width: 740px) {
        font-size: 0.7em !important;
        line-height: 1.5em !important;
    }
    @media (max-width: 420px) {
        font-size: 0.67em !important;
    }
`
const Plus = styled(NavLink)``
const MotDirecteurWithText = styled.div`
    @media (max-width: 400px) {
        flex-direction: column !important;
    }
`
const Actualites = styled(Container)`
    margin-top: 50px;
    margin-bottom: 50px;
`

const ActualitesButton = styled(NavLink)`
    border-radius: 0px;
    background-color: #008d36;
    color: #fff;
    &:hover {
        background-color: #fff;
        color: #008d36;
    }
`

const NosEvenementsOrdi = styled(Container)`
    @media (max-width: 768px) {
        display: none !important;
    }
`
const NosActualitesOrdi = styled(Row)`
    @media (max-width: 768px) {
        display: none !important;
    }
`

const NosEvenementsSmartphone = styled(Container)`
    display: none !important;

    position: relative;
    height: 35rem;
    @media (max-width: 768px) {
        display: block !important;
    }
`
const ChiffresWrapper = styled(Container)`
    margin-top: 100px !important;
    margin-bottom: 100px !important;
`
const AllSitesWrapper = styled(Container)`
    margin-top: 100px !important;
    margin-bottom: 100px !important;
`
const Chiffres = styled(Container)`
    padding: 0px;
    margin: auto;
    background-image: url(${portail['thirdbg.jpg']});
    background-position: 10% 10%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 30px;
`
const ChiffresLink = styled.div``
const ChiffresText = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    padding: 2rem;
    font-size: 1.3em;
    align-items: center;
    margin: 0px;
    background-color: rgba(0, 141, 54, 0.7);
    border-radius: 30px;
    @media (max-width: 516px) {
        flex-direction: column;
    }
`
const ChiffresTextTop = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    @media (max-width: 516px) {
        flex-direction: column;
    }
`
const ChiffresCol = styled(Col)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
const SiteWrapper = styled(Container)`
    border-radius: 30px;
    padding: 40px;
    background-image: linear-gradient(#f47920, #fff);
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
        rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
`
const SiteShow = styled(Container)`
    display: ${(prop) => (prop.open ? 'flex' : 'none')};
    width: 70%;
    flex-direction: column;
    border: 1px solid #fff;
    padding: 0px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 30px;
    overflow: hidden;
    @media (max-width: 850px) {
        width: 100%;
    }
`
const SiteShowTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #008d36;
`
const CloseIcon = styled(AiOutlineCloseCircle)`
    color: #008d36;
    cursor: pointer;
    transition: transform 300ms ease-in-out;
    &:hover {
        transform: scale(2);
    }
`
const MyCarousel = styled(Carousel)`
    padding: 0px !important;
`

const SiteCol = styled(Col)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 300ms ease-in-out;
    &:hover {
        transform: scale(1.1);
    }
    @media (max-width: 992px) {
        margin-bottom: 50px;
        margin-top: 0px !important;
    }
`
const SiteColDiv = styled.div`
    margin-left: 50px;
    @media (max-width: 430px) {
        margin-left: 0px;
    }
`
const SiteColImage = styled.img`
    width: 250px;
    height: 180px;
    border: 2px solid #fff;
    border-radius: 15px;
    cursor: pointer;
    @media (max-width: 992px) {
        width: 100%;
        aspect-ratio: 3/2;
        object-fit: fill;
    }
`
const SiteSpan = styled.span`
    display: flex;
    justify-content: center;
    padding: 20px;
    @media (max-width: 992px) {
        margin-bottom: 50px;
    }
`
const AppelWrapper = styled(Container)`
    margin-top: 18rem;
    @media (max-width: 1265px) {
        margin-top: 20rem;
    }
    @media (max-width: 1070px) {
        margin-top: 22rem;
    }
    @media (max-width: 665px) {
        margin-top: 23rem;
    }
    @media (max-width: 585px) {
        margin-top: 25rem;
    }
`
const NosSites = [
    {
        site: 'Site Sud',
        images: [
            {
                image: portail['SiteSud1.jpg'],
                alt: 'Site Sud',
            },
            {
                image: portail['SiteSud2.jpg'],
                alt: 'Site Sud',
            },
            {
                image: portail['SiteSud3.jpg'],
                alt: 'Site Sud',
            },
            {
                image: portail['SiteSud4.jpg'],
                alt: 'Site Sud',
            },
            {
                image: portail['SiteSud5.jpg'],
                alt: 'Site Sud',
            },
            {
                image: portail['SiteSud6.jpg'],
                alt: 'Site Sud',
            },
        ],
    },
    {
        site: 'Site Centre',
        images: [
            {
                image: portail['SiteCentre.jpg'],
                alt: 'Site Centre',
            },
            {
                image: portail['SiteCentre1.jpg'],
                alt: 'Site Centre',
            },
            {
                image: portail['SiteCentre2.jpg'],
                alt: 'Site Centre',
            },
            {
                image: portail['SiteCentre3.jpg'],
                alt: 'Site Centre',
            },
            {
                image: portail['SiteCentre4.jpg'],
                alt: 'Site Centre',
            },
            {
                image: portail['SiteCentre5.jpg'],
                alt: 'Site Sud',
            },
        ],
    },
    {
        site: 'Site Nord',
        images: [
            {
                image: portail['SiteNord1.jpg'],
                alt: 'Site Nord',
            },
            {
                image: portail['SiteNord2.jpg'],
                alt: 'Site Nord',
            },
            {
                image: portail['SiteNord3.jpg'],
                alt: 'Site Nord',
            },
            {
                image: portail['SiteNord4.jpg'],
                alt: 'Site Nord',
            },
        ],
    },
]

export const PortailAccueil = () => {
    const [isSiteOpen, setisSiteOpen] = useState([false, false, false, false])
    const [ActualSite, setActualSite] = useState(0)

    // Les constantes retournees
    const { isLoading, error, 
        actuData,
         eventData, motDuDG, photoDuDG } =
        useContext(MyContext)

    const HanldeCheck = (i) => {
        setisSiteOpen((prevState) =>
            prevState.map((item, idx) => (idx === i ? !item : false))
        )
        setActualSite(i)
    }
    const closeSites = () => {
        setisSiteOpen((prevState) =>
            prevState.map((item, idx) => (idx === 1 ? false : false))
        )
    }
    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])
    let motDirecteurReduit = motDuDG.split(/\s+/).slice(0, 32).join(' ')
    const debut = motDirecteurReduit.indexOf('<p style="text-align: justify">')
    const nouveauTexte = motDirecteurReduit.substring(
        debut + '<p style="text-align: justify">'.length
    )
    const texteDGReduit = nouveauTexte.replace(/&nbsp;/g, " ")
    //console.log(infoGen[0].motDuDG)

    if (error) {
        return <Error />
    }
   
    return (
        <Transitions>
            {isLoading ? (
                <div className="d-flex justify-content-center align-items-center w-100">
                    <Loader />
                </div>
            ) : (
                <>
                    <Acceuil>
                        <AccueilText>
                            <SousTitres className="fs-4 text-light text-center mb-1">
                                Institut National Polytechnique Félix
                                Houphouët-Boigny
                            </SousTitres>
                            <Titre
                                className="fw-bold text-light mb-1 text-center"
                                style={{ fontSize: '1.3em' }}
                            >
                                INP-HB
                            </Titre>
                            <SousTitres className="fs-4 text-light text-center">
                                Plus qu'un Institut d'Enseignement, un Label
                            </SousTitres>
                        </AccueilText>
                        <MotDirecteurWrapper
                            className="mb-5"
                            data-aos="fade-up"
                        >
                            <MotDirecteurDiv>
                                <MotDirecteurTop style={{ width: '100%' }}>
                                <MotDirecteurTopItemA title="Organigramme"
                                        href="portail/a_propos" className='text-dark' >
                                        <FaChartBar className="me-2 fs-4" />
                                        <span>Organigramme</span>
                                    </MotDirecteurTopItemA>
                                    <MotDirecteurTopItemNavLink
                                        className="text-dark"
                                        title="Bureau des élèves"
                                        to="vie_universitaire/bureau_des_eleves"
                                    >
                                        <FaSchool className="me-2 fs-4" />
                                        <span>BDE</span>
                                    </MotDirecteurTopItemNavLink>
                                    <MotDirecteurTopItemNavLink  className="text-dark"
                                        title="Bureau genre"
                                        to="bureau_genre">
                                        <FaBookOpen className="me-2 fs-4" />
                                        <span>Bureau Genre</span>
                                    </MotDirecteurTopItemNavLink>
                                    
                                    <MotDirecteurTopItem>
                                        <FaBuilding className="me-2 fs-4" />
                                        <span>Alumni </span>
                                    </MotDirecteurTopItem>
                                </MotDirecteurTop>
                                <MotDirecteurBottom>
                                    <MotDirecteurText className="px-5 py-4">
                                        <LienMotDirecteur to="mot_du_directeur">
                                            <MotDirecteurImg
                                                src={photoDgDG}
                                                alt="DG de l'INP-HB"
                                                
                                            />
                                        </LienMotDirecteur>
                                        <MotDirecteurWithText className="d-flex align-items-center text-light flex-column text-start">
                                            <MotDirecteurBottomTextTitle className="fw-bold text-light body-highlight text-start m-0 pb-2 w-100 ps-4">
                                                Mot du Directeur Général
                                            </MotDirecteurBottomTextTitle>
                                            <MotDirecteurBottomText className="text-justify text-light body-text border-0 ps-4">
                                                {texteDGReduit}...
                                                <Plus
                                                    to="mot_du_directeur"
                                                    className="text-light fw-bold"
                                                >
                                                    voir plus
                                                </Plus>
                                            </MotDirecteurBottomText>
                                        </MotDirecteurWithText>
                                    </MotDirecteurText>
                                </MotDirecteurBottom>
                            </MotDirecteurDiv>
                        </MotDirecteurWrapper>
                    </Acceuil>
                    
                    <AppelWrapper data-aos="fade-up">
                    {/* <div>les bouttons bibliothèque et boutique inphb</div> */}
                        <p
                            className="text-uppercase text-start body-highlight ps-2 fw-bold mt-4"
                            style={{ borderLeft: '5px solid #000' }}
                        >
                            À l'affiche
                        </p>
                        <AppelACandidature />
                    </AppelWrapper>
                    {/* <div>C'est ici je vais ajouter mes nouvelles actualites </div> */}
                    {actuData[0] && (
                        <Actualites>
                            <p
                                className="text-uppercase text-start body-highlight ps-2 fw-bold mb-3"
                                style={{ borderLeft: '5px solid #000' }}
                            >
                                actualités 
                            </p>
                            <div className="mb-2">
                                <NosActualitesOrdi>
                                    <ActualitesDesktop actuData={actuData}/>
                                </NosActualitesOrdi>
                                <NosEvenementsSmartphone>
                                     <ActualitesSmartphone data={actuData} />
                                </NosEvenementsSmartphone>
                               
                            </div>
                            <div className="ms-5">
                                <ActualitesButton
                                    className="mybtn border-0"
                                    data-aos="fade-up"
                                    to="actualites"
                                >
                                    Voir plus →
                                </ActualitesButton>
                            </div>
                        </Actualites>
                    )}
                    {/* <div>C'est ici je vais ajouter mes nouveaux évènements</div> */}
                    {eventData[0] && (
                        <>
                            <NosEvenementsOrdi className="mt-1">
                                <p
                                    className="text-uppercase text-start body-highlight ps-2 fw-bold mt-4"
                                    style={{ borderLeft: '5px solid #000' }}
                                >
                                    évènements 
                                </p>
                                <EvenementsDesktop eventData={eventData}/>
                            </NosEvenementsOrdi>

                            <NosEvenementsSmartphone className="my-5">
                                <p
                                    className="text-uppercase text-start body-highlight ps-2 fw-bold mt-4"
                                    style={{ borderLeft: '5px solid #000' }}
                                >
                                    évènements 
                                </p>
                                <EvenementsSmartphone
                                    key={'Nos-Evenements'}
                                    data={eventData}

                                />
                            </NosEvenementsSmartphone>
                        </>
                    )}
                    <ChiffresWrapper data-aos="fade-up" className="mb-5 mt-2">
                        <p
                            className="text-uppercase text-start body-highlight ps-2 fw-bold mt-4"
                            style={{ borderLeft: '5px solid #000' }}
                        >
                            Statistiques 
                        </p>
                        <Chiffres>
                            <ChiffresLink>
                                <ChiffresText>
                                    <ChiffresTextTop>
                                        <ChiffresCol className="text-white">
                                            <FaUserGraduate
                                                style={{ fontSize: '70px' }}
                                            />
                                            <p className="fw-bold mt-1 mb-0">
                                                3256
                                            </p>
                                            <p>Étudiants</p>
                                        </ChiffresCol>
                                        <ChiffresCol className="text-white">
                                            <BsBuilding
                                                style={{ fontSize: '70px' }}
                                            />
                                            <p className="fw-bold mt-1 mb-0">
                                                11
                                            </p>
                                            <p className="text-center">
                                                Grandes Écoles
                                            </p>
                                        </ChiffresCol>
                                        <ChiffresCol className="text-white">
                                            <FaChalkboardTeacher
                                                style={{ fontSize: '70px' }}
                                            />
                                            <p className="fw-bold mt-1 mb-0">
                                                340
                                            </p>
                                            <p>Enseignants</p>
                                        </ChiffresCol>
                                        <ChiffresCol className="text-white">
                                            <FaBed
                                                style={{ fontSize: '70px' }}
                                            />
                                            <p className="fw-bold mt-1 mb-0">
                                                3008
                                            </p>
                                            <p>Chambres</p>
                                        </ChiffresCol>
                                    </ChiffresTextTop>
                                </ChiffresText>
                            </ChiffresLink>
                        </Chiffres>
                        <div className="ms-5 mt-3">
                            <ActualitesButton
                                className="mybtn border-0"
                                data-aos="fade-up"
                                to="statistiques_et_ressources"
                            >
                                Voir plus →
                            </ActualitesButton>
                        </div>
                    </ChiffresWrapper>
                    <AllSitesWrapper>
                        <p
                            className="text-uppercase text-start body-highlight ps-2 fw-bold mt-4"
                            style={{ borderLeft: '5px solid #000' }}
                        >
                            Les Sites de l'INP-HB
                        </p>
                        <SiteWrapper data-aos="fade-up" className="mb-2">
                            <SiteSpan
                                className="text-center text-light"
                                style={{ fontSize: '30px' }}
                            >
                                <p>Explorer les sites</p>
                            </SiteSpan>
                            <Row>
                                <SiteCol
                                    lg={4}
                                    md={12}
                                    style={{ marginTop: '-10%' }}
                                >
                                    <a
                                        href="#site-show"
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <SiteColDiv
                                            onClick={() => HanldeCheck(0)}
                                        >
                                            <SiteColImage
                                                src={portail['SiteSud.jpg']}
                                                alt="Site Sud"
                                            />
                                        </SiteColDiv>
                                        <p className="text-center text-black">
                                            Site Sud
                                        </p>
                                    </a>
                                </SiteCol>
                                <SiteCol lg={4} md={12} href="#site-show">
                                    <a
                                        href="#site-show"
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <SiteColDiv
                                            onClick={() => HanldeCheck(1)}
                                        >
                                            <SiteColImage
                                                src={portail['SiteCentre.jpg']}
                                                alt="Site Centre"
                                            />
                                        </SiteColDiv>
                                        <p className="text-center text-black">
                                            Site Centre
                                        </p>
                                    </a>
                                </SiteCol>
                                <SiteCol
                                    lg={4}
                                    md={12}
                                    style={{ marginTop: '-10%' }}
                                >
                                    <a
                                        href="#site-show"
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <SiteColDiv
                                            onClick={() => HanldeCheck(2)}
                                        >
                                            <SiteColImage
                                                src={portail['SiteNord.jpg']}
                                                alt="Site Nord"
                                            />
                                        </SiteColDiv>
                                        <p className="text-center text-black">
                                            Site Nord
                                        </p>
                                    </a>
                                </SiteCol>
                            </Row>
                        </SiteWrapper>
                        <SiteShow
                            className="mt-5 fade-in"
                            data-aos="fade-down"
                            open={isSiteOpen[ActualSite]}
                            id="site-show"
                        >
                            <SiteShowTop className="mb-0 p-3">
                                <div className="text-uppercase fw-bold">
                                    {NosSites[ActualSite].site}
                                </div>
                                <CloseIcon
                                    className="fs-3"
                                    onClick={closeSites}
                                />
                            </SiteShowTop>
                            <MyCarousel className="mt-0 pt-0">
                                {NosSites[ActualSite].images.map(
                                    (subsite, index) => (
                                        <Carousel.Item
                                            key={`${index}-${subsite.alt}`}
                                        >
                                            <img
                                                className="d-block"
                                                style={{
                                                    width: '100%',
                                                    aspectRatio: 3 / 2,
                                                    objectFit: 'fill',
                                                }}
                                                src={subsite.image}
                                                alt={subsite.alt}
                                            />
                                        </Carousel.Item>
                                    )
                                )}
                            </MyCarousel>
                        </SiteShow>
                    </AllSitesWrapper>
                    
                </>
            )}
        </Transitions>
    )
}
