import React from 'react'
import styled from 'styled-components'
import { Col, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

const LinkPart = styled.div`
    display: flex;
    flex-wrap: wrap;
    jutify-content: space-evenly;
    align-items: center;
    height: 100%;
    width: 100%;
`
const MyNavLink = styled(NavLink)`
    margin-left: 1em;
    font-size: 0.8em;
    text-decoration: none;
    width: 100%;
    padding-bottom: 0.4em;
    color: rgba(33, 20, 8, 0.67);
    border-bottom: 0.1em solid rgba(33, 20, 8, 0.2);
    &:hover {
        color: rgb(33, 20, 8);
        border-color: rgb(33, 20, 8);
    }
`
const Mya= styled.a`
    margin-left: 1em;
    font-size: 0.8em;
    text-decoration: none;
    width: 100%;
    padding-bottom: 0.4em;
    color: rgba(33, 20, 8, 0.67);
    border-bottom: 0.1em solid rgba(33, 20, 8, 0.2);
    &:hover {
        color: rgb(33, 20, 8);
        border-color: rgb(33, 20, 8);
    }
`

export const HeaderPortailLinkHover = ({ SousLiens }) => {
    return (
        <div className="w-100">
            <Row>
                <Col>
                    <img
                        src={SousLiens.image}
                        style={{ width: '100%' }}
                        alt="test"
                    />
                </Col>
                <Col>
                    <LinkPart>
                        {SousLiens.sousLiens.map((sousLien, index) =>
                            sousLien.as ? (
                                <Mya
                                    href={sousLien.lien}
                                    className="text-uppercase"
                                    target="_blank"
                                    key={`sousLien-${index}`}
                                >
                                    {sousLien.nom}
                                </Mya>
                            ) : (
                                <MyNavLink
                                    to={sousLien.lien}
                                    className="text-uppercase"
                                    key={`sousLien-${index}`}
                                >
                                    {sousLien.nom}
                                </MyNavLink>
                            )
                        )}
                    </LinkPart>
                </Col>
            </Row>
        </div>
    )
}
