import { Outlet, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Container, NavbarBrand, Row, Col, Modal } from 'react-bootstrap'
import '../../utils/style/index.scss'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'
import { school } from '../../datas'
import { Transitions } from '../../components/Transitions'
import INPHB from '../../assets/INP-HB.png'
import { useState } from 'react'

const Description = styled(Container)`
    width: 100%;
    padding-top: 75px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const MotDuDirecteurWrapper = styled(Container)`
    width: 100%;
    background-color: ${(prop) => prop.color};
    padding-bottom: 70px;
`
const EtudierButtonA = styled.a`
    border-radius: 0px;
    border: 0px;
    margin: 5px;
    float: right;
    background-color: ${(prop) => prop.color};
    color: #fff;
    &:hover {
        background-color: #fff;
        color: ${(prop) => prop.color};
    }
`
const Footer = styled.footer`
    background: #1a0a00;
    color: #fff;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
const FooterCol = styled(Col)`
    padding: 15px !important;
    @media (max-width: 625px) {
        flex-basis: 100% !important;
    }
`
const FooterRow = styled(Row)`
    @media (max-width: 625px) {
        padding: 20px !important;
    }
`
const Erreur = styled.div`
    color: red;
    @media (max-width: 445px) {
        font-size: 0.8em;
    }
`

function Ecole() {
    const { schoolName } = useParams()
    const [mail, setMail] = useState('')
    const [errorMail, setErrorMail] = useState('')
    const [show, setShow] = useState(false)
    const [prenom, setPreNom] = useState('')
    const mailRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

    const addToNewsletter = () => {
        setErrorMail('')
        setShow(true)
        let parts = mail.split('.')
        setPreNom(parts[0])
    }
    const mailVerification = () => {
        mail === ''
            ? setErrorMail('Veuillez entrer un mail')
            : !mailRegex.test(mail) ? setErrorMail('Entrer un mail correct') : setErrorMail('')
    }
    const mailAccess = () => {
        mailVerification()
        mailRegex.test(mail) && addToNewsletter()
    }
    

    const ecole = school[`${schoolName}`]
    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])
    return ecole ? (
        <Transitions>
            <Outlet />
            <Modal
                size="md"
                show={show}
                onHide={() => setShow(false) & setMail('')}
                aria-labelledby="add-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title
                        id="add-modal"
                        className="d-flex align-items-center"
                    >
                        <img src={INPHB} alt="logo INP-HB" width={'10%'} />
                        <span className="ms-3 fw-bold">Newslatter INP-HB</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        Vous avez bien été ajouté à la Newsletter de l'INP-HB.
                    </span>
                    <p>
                        Merci,{' '}
                        <span className="text-capitalize">{prenom} !!</span>{' '}
                    </p>
                </Modal.Body>
            </Modal>
            <Footer>
                <FooterRow
                    className="d-flex justify-content-center align-items-center p-5"
                    style={{ width: '100%' }}
                >
                    <FooterCol
                        className="text-start"
                        xxl={4}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={6}
                        xs={6}
                    >
                        <p className="text-start text-uppercase fw-bold">
                            {ecole.nom}
                        </p>
                        <p>
                            Contacts <br />
                            Adresse électronique <br /> Nous suivre sur les
                            réseaux
                        </p>
                    </FooterCol>
                    <FooterCol
                        className="d-flex align-items-center"
                        xxl={5}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={6}
                        xs={6}
                        style={{ flexDirection: 'column' }}
                    >
                        <NavbarBrand>
                            <img
                                style={{ width: '100px', height: '100px' }}
                                src={ecole.logo}
                                alt={ecole.alt}
                            />
                        </NavbarBrand>
                        <p>Newslatter</p>
                        <input onBlur={mailVerification}
                            onChange={(e) => setMail(e.target.value)}
                            value={mail} />
                        {errorMail && (
                            <Erreur className="mb-1">{errorMail}</Erreur>
                        )}
                        <EtudierButtonA
                            className="mybtn border-0"
                            color={ecole.couleurPrincipale}
                            onClick={mailAccess}
                        >
                            Soumettre
                        </EtudierButtonA>
                    </FooterCol>
                    <FooterCol
                        className="text-start"
                        xxl={3}
                        xl={4}
                        lg={4}
                        md={4}
                        sm={6}
                        xs={6}
                    >
                        <p className="text-start text-uppercase fw-bold">
                            nos partenaires
                        </p>
                        <p>
                            Contacts <br />
                            Adresse électronique <br /> Nous suivre sur les
                            réseaux
                        </p>
                    </FooterCol>
                </FooterRow>
                <div className="py-2 text-center">
                    By INP-HB Digital Copyright &copy; {new Date().getFullYear()}
                </div>
            </Footer>
        </Transitions>
    ) : (
        window.location.replace('/erreur')
    )
}

export default Ecole
