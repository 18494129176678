import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import '../../utils/style/index.scss'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import styled from 'styled-components'
import Relation from '../../assets/Portail/relation.jpg'
import Recrutement from '../../assets/Portail/recrutement.jpg'
import Collaborateur from '../../assets/Portail/collaborateur.jpg'
import Partenariat from '../../assets/Portail/partenariat.jpg'
import { Transitions } from '../../components/Transitions'

const TitleDiv = styled.div`
    width: 100%;
    background-color: rgb(0, 141, 54);
`
const Item = styled.div`
    padding: 10px;
    background-color: ${(prop) => prop.color};
    color: ${(prop) => prop.textColor};
    display: flex;
    border-bottom: 1px solid #f47920;
    justify-content: space-between;
    align-items: center;
    cursor: pointer !important;
`
const Myimg = styled.img`
    width: 100%;
`
export const RelationEntreprise = () => {
    const [checkBoxState, setcheckBoxState] = useState([false, false, false, false])
    const HanldeCheck = (i) => {
        setcheckBoxState((prevState) =>
            prevState.map((item, idx) => (idx === i ? !item : false))
        )
    }
    return (
        <Transitions>
            <TitleDiv className="d-flex justify-content-center align-items-center text-light mb-5">
                <span
                    className="body-highlight mt-3 text-uppercase ps-2 mb-3 fs-1"
                    style={{ borderLeft: '10px solid #fff' }}
                >
                    Relation entreprise
                </span>
            </TitleDiv>
            <div className="d-flex align-items-center justify-content-center">
                <Myimg src={Relation} alt="" className="my-3" />
            </div>
            <Container>
                <div className="mb-2">
                    <Item
                        className="text-uppercase my-1"
                        onClick={() => HanldeCheck(0)}
                        color={checkBoxState[0] ? '#f47920' : '#fff'}
                        textColor={checkBoxState[0] ? '#fff' : '#000'}
                    >
                        <div
                            style={{ cursor: 'pointer' }}
                            className="text-uppercase"
                        >
                            recrutement des futurs talents
                        </div>
                        {checkBoxState[0] ? (
                            <FaChevronUp
                                style={{ cursor: 'pointer', color: '#fff' }}
                            />
                        ) : (
                            <FaChevronDown
                                style={{ cursor: 'pointer', color: '#f47920' }}
                            />
                        )}
                    </Item>
                    <div
                        className={
                            checkBoxState[0] ? 'd-block mb-5 fade-in' : 'mb-5 d-none'
                        }
                    >
                        <div className="d-flex align-items-center justify-content-center">
                            <Myimg src={Recrutement} alt="" className="my-3" />
                        </div>
                        <div className="body-text border-0">
                            Depuis sa création, l’INP-HB a formé des milliers de
                            managers, ingénieurs et techniciens supérieurs qui
                            sont aujourd’hui, une fierté pour les entreprises
                            aussi bien en Côte d’Ivoire qu’à l’échelle mondiale.{' '}
                            <br />
                            <br />
                            Dans un environnement en constante mutation et face
                            à la concurrence, le recrutement des talents au sein
                            des organisations s’impose. Grace à un partenariat
                            avec l’INP-HB, vous (employeurs) avez la possibilité
                            de travailler avec vos futurs collaborateurs en
                            recrutant des élèves en stage. Cette immersion dans
                            le monde professionnel emprunte d’expériences
                            enrichissantes peut déboucher sur une proposition
                            d’emploi. <br /> <br />
                            Employeurs, être partenaire à l’INP-HB, c’est faire
                            le pari d’avoir dans vos équipes, des jeunes talents
                            qui révolutionneront vos entreprises. Nos formations
                            couvrent les domaines de l’Agronomie, du Commerce,
                            du Bâtiment et des Travaux Publics, des Mines et de
                            Géologie et, de l’Industrie.
                        </div>
                    </div>
                </div>
                <div>
                    <Item
                        className="text-uppercase my-1"
                        onClick={() => HanldeCheck(1)}
                        color={checkBoxState[1] ? '#f47920' : '#fff'}
                        textColor={checkBoxState[1] ? '#fff' : '#000'}
                    >
                        <div
                            style={{ cursor: 'pointer' }}
                            className="text-uppercase"
                        >
                            Formation des collaborateurs
                        </div>
                        {checkBoxState[1] ? (
                            <FaChevronUp
                                style={{ cursor: 'pointer', color: '#fff' }}
                            />
                        ) : (
                            <FaChevronDown
                                style={{ cursor: 'pointer', color: '#f47920' }}
                            />
                        )}
                    </Item>
                    <div
                        className={
                            checkBoxState[1] ? 'd-block mb-5 fade-in' : 'mb-5 d-none'
                        }
                    >
                        <div className="d-flex align-items-center justify-content-center">
                            <Myimg src={Collaborateur} alt="" className="my-3" />
                        </div>
                        <div className="body-text border-0">
                            Employeurs, la formation professionnelle est
                            bénéfique car elle permet non seulement d’améliorer
                            les compétences de vos salariés mais également, les
                            performances de votre entreprise. <br /> L’INP-HB
                            propose des formations sur mesure, susceptibles de
                            booster les compétences de vos ressources humaines.
                            Nos packages de formation sont adaptés à votre
                            système organisationnel, votre orientation
                            stratégique et votre planning de travail (séminaires
                            de formation en entreprise, formations en e-Learning
                            ou vidéo conférence.).
                        </div>
                    </div>
                </div>
                <div>
                    <Item
                        className="text-uppercase my-1"
                        onClick={() => HanldeCheck(2)}
                        color={checkBoxState[2] ? '#f47920' : '#fff'}
                        textColor={checkBoxState[2] ? '#fff' : '#000'}
                    >
                        <div
                            style={{ cursor: 'pointer' }}
                            className="text-uppercase"
                        >
                            Des partenariats pour la mise en œuvre...
                        </div>
                        {checkBoxState[2] ? (
                            <FaChevronUp
                                style={{ cursor: 'pointer', color: '#fff' }}
                            />
                        ) : (
                            <FaChevronDown
                                style={{ cursor: 'pointer', color: '#f47920' }}
                            />
                        )}
                    </Item>
                    <div
                        className={
                            checkBoxState[2] ? 'd-block mb-5 fade-in' : 'mb-5 d-none'
                        }
                    >
                        <div>Des partenariats pour la mise en œuvre de nouveaux
                            programmes de formation des partenariats pour la mise
                            en œuvre de nouveaux programmes de formation</div>
                        <div className="d-flex align-items-center justify-content-center">
                            <Myimg src={Partenariat} alt="" className="my-3" />
                        </div>
                        <div className="body-text border-0">
                            Soucieux d’amener ses cadres techniques à réagir en
                            véritables managers, lorsqu'il leur est confié des
                            responsabilités dans la conduite des travaux ou des
                            études, Pierre Fakoury Operator AFRICA Côte d'Ivoire
                            (PFO AFRICA CI), acteur majeur du secteur de
                            l'industrie de la construction, s'est rapproché de
                            l'INP-HB en novembre 2017. <br />
                            <br />
                            Les échanges entre les deux parties ont abouti à la
                            mise en place d'un parcours de formation spécifique,
                            dénommé "Mastère Professionnel en Management des
                            Grands Projets de Construction", en abrégé MP-MGPC.
                            Financé par PFO, ce Mastère a débuté à l’INP-HB en
                            septembre 2019, à l’issue de la signature d’un
                            partenariat. Il a pour objectif d’allier la culture
                            managériale aux compétences techniques pour une
                            conduite innovante des projets. Il s’agit en clair
                            d’une formation avec embauche à la clé (par PFO),
                            destinée à adosser aux techniques acquises, des
                            compétences managériales nécessaires pour,
                            appréhender et résoudre l’ensemble des problèmes
                            majeurs posés par la complexité des projets dans un
                            environnement socio-économique en pleine mutation.{' '}
                            <br />
                            <br />
                            Employeurs, vous êtes à la recherche de ce type de
                            partenariat ? L’INP-HB vous ouvre ses portes.
                        </div>
                    </div>
                </div>
            </Container>
        </Transitions>
    )
}
