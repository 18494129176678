import React from 'react'
import { Col, NavbarBrand } from 'react-bootstrap'
import styled from 'styled-components'
import {HashLink} from 'react-router-hash-link'

const ActualitesCol = styled(Col)`
    padding: 5px;
`
const ActualitesItems = styled(HashLink)`
    display: flex;
    align-items: center;
    margin: 0px !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 5px;
    @media (max-width: 500px) {
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }
`
const ItemsImage = styled.img`
    width: 180px;
    height: 180px;
    border-radius: 50%;
    transition: transform 350ms ease-in-out;
    @media (max-width: 1265px) {
        width: 180px !important;
        height: 180px !important;
    }
    @media (max-width: 992px) {
        width: 165px !important;
        height: 165px !important;
    }
    &:hover {
        transform: scale(1.06);
    }
`
const ActualitesText = styled.div`
    font-size: 0.75em !important;
`

export const ActualitesDesktop = ({actuData}) => {
  return (
    <>
    {actuData[0]&&<ActualitesCol
        xxl={6}
        xl={6}
        lg={6}
        md={12}
        sm={12}
        xs={12}
    >
        <ActualitesItems
            data-aos="fade-right"
            to={"actualites#actualite-0"}
            className="text-dark flex-row-reverse"
        >
            <NavbarBrand>
                <ItemsImage
                    src={actuData[0].img} //`https://inphb-api.herokuapp.com/${actuData[0].photoMini.bucket}/${actuData[0].photoMini.key}
                    style={{
                        borderRadius: '50%',
                        border: '3px solid #008D36',
                    }}
                    alt=""
                />
            </NavbarBrand>
            <div className="me-2">
                <ActualitesText className="fw-bold body-text border-0 text-start">
                    {actuData[0].titre}
                    <br />
                    <span className="text-muted">
                        le{' '}
                        {new Date(
                            actuData[0].date
                        ).toLocaleString([], {
                            weekday: 'short',
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                        })}{' '}
                    </span>
                </ActualitesText>
                <ActualitesText
                    dangerouslySetInnerHTML={{
                        __html: `${actuData[0].recit
                            .split(/\s+/)
                            .slice(0, 15)
                            .join(' ')}...`,
                    }}
                    className="text-muted body-text border-0 text-start"
                />
            </div>
        </ActualitesItems>
    </ActualitesCol>}
    {actuData[1]&&<ActualitesCol
        xxl={6}
        xl={6}
        lg={6}
        md={12}
        sm={12}
        xs={12}
    >
        <ActualitesItems
            data-aos="fade-left"
            className="text-dark"
            to={{
                pathname: 'actualites',
                hash: '#actualite-1',
                state: { isAdmin: true },
            }}
        >
            <NavbarBrand>
                <ItemsImage
                    style={{
                        borderRadius: '50%',
                        border: '3px solid #008D36',
                    }}
                    src={`https://inphb-api.herokuapp.com/${actuData[1].photoMini.bucket}/${actuData[1].photoMini.key}`}
                    alt="DG de l'INP-HB"
                />
            </NavbarBrand>
            <div>
                <ActualitesText className="fw-bold body-text border-0 text-start">
                    {actuData[1].titre}
                    <br />
                    <span className="text-muted">
                        le{' '}
                        {new Date(
                            actuData[1].date
                        ).toLocaleString([], {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                        })}{' '}
                       
                    </span>
                </ActualitesText>
                <ActualitesText
                    dangerouslySetInnerHTML={{
                        __html: `${actuData[1].recit
                            .split(/\s+/)
                            .slice(0, 15)
                            .join(' ')}...`,
                    }}
                    className="text-muted body-text border-0 text-start"
                />
            </div>
        </ActualitesItems>
    </ActualitesCol>}
    {actuData[2]&& <ActualitesCol
        xxl={6}
        xl={6}
        lg={6}
        md={12}
        sm={12}
        xs={12}
    >
        {' '}
        <ActualitesItems
            className="text-dark my-1 flex-row-reverse"
            data-aos="fade-right"
            to={"actualites#actualite-2"}
        >
            <NavbarBrand>
                <ItemsImage
                    style={{
                        borderRadius: '50%',
                        border: '3px solid #008D36',
                    }}
                    src={`https://inphb-api.herokuapp.com/${actuData[2].photoMini.bucket}/${actuData[2].photoMini.key}`}
                    alt="DG de l'INP-HB"
                />
            </NavbarBrand>
            <div>
                <ActualitesText className="fw-bold body-text border-0 text-start">
                    {actuData[2].titre}
                    <br />
                    <span className="text-muted">
                        le{' '}
                        {new Date(
                            actuData[2].date
                        ).toLocaleString([], {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                        })}{' '}
                        
                    </span>
                </ActualitesText>
                <ActualitesText
                    dangerouslySetInnerHTML={{
                        __html: `${actuData[2].recit
                            .split(/\s+/)
                            .slice(0, 15)
                            .join(' ')}...`,
                    }}
                    className="text-muted body-text border-0 text-start"
                />
            </div>
        </ActualitesItems>
    </ActualitesCol>}
    {actuData[3]&&<ActualitesCol
        xxl={6}
        xl={6}
        lg={6}
        md={12}
        sm={12}
        xs={12}
    >
        <ActualitesItems
            className="my-2 text-dark"
            data-aos="fade-left"
            to={"actualites#actualite-3"}
        >
            <NavbarBrand>
                <ItemsImage
                    style={{
                        borderRadius: '50%',
                        border: '3px solid #008D36',
                    }}
                    src={`https://inphb-api.herokuapp.com/${actuData[3].photoMini.bucket}/${actuData[3].photoMini.key}`}
                    alt="DG de l'INP-HB"
                />
            </NavbarBrand>
            <div>
                <ActualitesText className="fw-bold body-text border-0 text-start">
                    {actuData[3].titre}
                    <br />
                    <span className="text-muted">
                        le{' '}
                        {new Date(
                            actuData[3].date
                        ).toLocaleString([], {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                        })}{' '}
                        
                    </span>
                </ActualitesText>
                <ActualitesText
                    dangerouslySetInnerHTML={{
                        __html: `${actuData[3].recit
                            .split(/\s+/)
                            .slice(0, 15)
                            .join(' ')}...`,
                    }}
                    className="text-muted body-text border-0 text-start"
                />
            </div>
        </ActualitesItems>
    </ActualitesCol>}
    {actuData[4]&&<ActualitesCol
        xxl={6}
        xl={6}
        lg={6}
        md={12}
        sm={12}
        xs={12}
    >
        <ActualitesItems
            className="my-2 text-dark"
            data-aos="fade-left"
            to={"actualites#actualite-3"}
        >
            <NavbarBrand>
                <ItemsImage
                    style={{
                        borderRadius: '50%',
                        border: '3px solid #008D36',
                    }}
                    src={`https://inphb-api.herokuapp.com/${actuData[4].photoMini.bucket}/${actuData[4].photoMini.key}`}
                    alt="DG de l'INP-HB"
                />
            </NavbarBrand>
            <div>
                <ActualitesText className="fw-bold body-text border-0 text-start">
                    {actuData[4].titre}
                    <br />
                    <span className="text-muted">
                        le{' '}
                        {new Date(
                            actuData[4].date
                        ).toLocaleString([], {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                        })}{' '}
                        
                    </span>
                </ActualitesText>
                <ActualitesText
                    dangerouslySetInnerHTML={{
                        __html: `${actuData[4].recit
                            .split(/\s+/)
                            .slice(0, 15)
                            .join(' ')}...`,
                    }}
                    className="text-muted body-text border-0 text-start"
                />
            </div>
        </ActualitesItems>
    </ActualitesCol>}
    {actuData[5]&&<ActualitesCol
        xxl={6}
        xl={6}
        lg={6}
        md={12}
        sm={12}
        xs={12}
    >
        <ActualitesItems
            className="my-2 text-dark"
            data-aos="fade-left"
            to={"actualites#actualite-3"}
        >
            <NavbarBrand>
                <ItemsImage
                    style={{
                        borderRadius: '50%',
                        border: '3px solid #008D36',
                    }}
                    src={`https://inphb-api.herokuapp.com/${actuData[5].photoMini.bucket}/${actuData[5].photoMini.key}`}
                    alt="DG de l'INP-HB"
                />
            </NavbarBrand>
            <div>
                <ActualitesText className="fw-bold body-text border-0 text-start">
                    {actuData[5].titre}
                    <br />
                    <span className="text-muted">
                        le{' '}
                        {new Date(
                            actuData[5].date
                        ).toLocaleString([], {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                        })}{' '}
                        
                    </span>
                </ActualitesText>
                <ActualitesText
                    dangerouslySetInnerHTML={{
                        __html: `${actuData[5].recit
                            .split(/\s+/)
                            .slice(0, 15)
                            .join(' ')}...`,
                    }}
                    className="text-muted body-text border-0 text-start"
                />
            </div>
        </ActualitesItems>
    </ActualitesCol>}   
    </>
  )
}
