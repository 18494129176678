import {React, useContext, useState} from 'react'
import { MyContext } from '../../utils/context'
import { Transitions } from '../../components/Transitions'
import classes from './emarus.module.css'
// import './emarus.module.css'
import Logo from '../../assets/INP-HB.png'
import FooterPortailConcours from '../../components/FooterPortailConcours'

function Erasmus() {
    const {isLoading} = useContext(MyContext)

  return (
    <>
        <Transitions>
            <div className={classes.emacontainer}>
                <div className={classes.emahead}>
                    <div className={classes.ematexthead}>ERASMUS+</div>
                </div>
                <div className={classes.emaka}>
                    <img src={Logo} alt='Logo INP-HB' />
                </div>
                <div className={classes.ematitre}>
                    <h2>Projets ERASMUS+ de l'INP-HB</h2>
                    {/* <span>(KA171 mobilité internationale de crédits)</span> */}
                </div>
                <div className={classes.emaprojets}>
                    <div className={classes.emaprojets1}>
                        <div className={classes.emaprojettitle}>PROJET BIO4AFRICA</div>
                        <div className={classes.emaprojettitle2}>
                            COORDINATEUR : <br />
                            Centre de Coopération Internationale en Recherche Agronomique <br /> pour le Développement -
                             C.I.R.A.D. EPIC- CIRAD
                        </div>
                        <div className={classes.ematableau}>
                            <table className={classes.tableauema}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        {/* <th>COLB</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr >
                                        <td style={{borderTopLeftRadius:'15px'}} className={classes.colcol}>1</td>
                                        <td style={{borderTopRightRadius:'15px'}} className={classes.colcol2} >Q-PLAN International Advisors PC-Q-PLAN INTERNATIONAL</td>
                                    </tr>
                                    <tr >
                                        <td className={classes.colcol} >2</td>
                                        <td className={classes.colcol2}>Stichting IHE Delft Institute For Water Education - IHE DELFT</td>
                                    </tr>
                                    <tr >
                                        <td className={classes.colcol}>3</td>
                                        <td className={classes.colcol2}>Grassa BV</td>
                                    </tr>
                                    <tr >
                                        <td className={classes.colcol}>4</td>
                                        <td className={classes.colcol2}>Munster Technological University</td>
                                    </tr>
                                    <tr >
                                        <td className={classes.colcol}>5</td>
                                        <td className={classes.colcol2}>Celignis Limited-CELIGNIS LIMITED</td>
                                    </tr>
                                    <tr >
                                        <td className={classes.colcol}>6</td>
                                        <td className={classes.colcol2}>RAGT ENERGIE SAS-RAGT Energie</td>
                                    </tr>
                                    <tr >
                                        <td className={classes.colcol}>7</td>
                                        <td className={classes.colcol2}>Savannah Young Farmers Network</td>
                                    </tr>
                                    <tr >
                                        <td className={classes.colcol}>8</td>
                                        <td className={classes.colcol2}>Okmnomads.Org</td>
                                    </tr>
                                    <tr >
                                        <td className={classes.colcol}>9</td>
                                        <td className={classes.colcol2}>Agri Business Innovation Hub</td>
                                    </tr>
                                    <tr >
                                        <td className={classes.colcol}>10</td>
                                        <td className={classes.colcol2}>Institut National Polytechnique Félix HOUPHOUËT-BOIGNY</td>
                                    </tr>
                                    <tr >
                                        <td className={classes.colcol}>11</td>
                                        <td className={classes.colcol2}>Eastern Africa Farmers Fédération Society - EAFF</td>
                                    </tr>                                    
                                    <tr >
                                        <td className={classes.colcol}>12</td>
                                        <td className={classes.colcol2}>African Agricultural Technology Foundation</td>
                                    </tr>                                    
                                    <tr >
                                        <td className={classes.colcol}>13</td>
                                        <td className={classes.colcol2}>Kabarole Research and Ressource Centre</td>
                                    </tr>                                    
                                    <tr >
                                        <td className={classes.colcol}>14</td>
                                        <td className={classes.colcol2}>African Forum for Agricultural Advisory Services-AFRICA FORUM FOR AGRIC ADV SERVICES</td>
                                    </tr>                                    
                                    <tr >
                                        <td className={classes.colcol}>15</td>
                                        <td className={classes.colcol2}>Université ASSANE SECK de Ziguinchor-UASZ</td>
                                    </tr>                                    
                                    <tr >
                                        <td className={classes.colcol}>16</td>
                                        <td className={classes.colcol2}>SCPL SA-DELI CAJOU</td>
                                    </tr>                                    
                                    <tr >
                                        <td className={classes.colcol}>17</td>
                                        <td className={classes.colcol2}>Association d’Appui aux Initiatives de Développement</td>
                                    </tr>                                    
                                    <tr >
                                        <td className={classes.colcol}>18</td>
                                        <td className={classes.colcol2}>Société d’Economie d’Energie et d’Electro-Mécanique Energeco</td>
                                    </tr>                                    
                                    <tr >
                                        <td className={classes.colcol}>19</td>
                                        <td className={classes.colcol2}>GIE Country Farm</td>
                                    </tr>                                    
                                    <tr >
                                        <td className={classes.colcol}>20</td>
                                        <td className={classes.colcol2}>Draxis Environnemental SA - DRAXIS</td>
                                    </tr>
                                    <tr >
                                        <td className={classes.colcol}>21</td>
                                        <td className={classes.colcol2}>Barcelona Plataforma Empresarial SL-BARCELONA PLATAFORMA IMPRESARIAL</td>
                                    </tr>                                    
                                    <tr >
                                        <td className={classes.colcol}>22</td>
                                        <td className={classes.colcol2}>Fundacion Corporacion Technologica de Andalucia - FCTA</td>
                                    </tr>                                    <tr >
                                        <td className={classes.colcol}>23</td>
                                        <td className={classes.colcol2}>Sustainable Innovations Europe SL-SUSTAINABLE INNOVATIONS</td>
                                    </tr>
                                    <tr >
                                        <td className={classes.colcol}>24</td>
                                        <td className={classes.colcol2}>FBCD AS - FBCD</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={classes.emaprojets2}>
                        <div className={classes.emaprojettitlea}>PROJET PEP</div>
                        <div className={classes.emaprojettitle2a}>
                            COORDINATEUR : <br />
                            UPPSALA UNIVERSITET - UU
                        </div>
                        <div className={classes.ematableau}>
                            <table className={classes.ematableau}>
                                <thead>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr >
                                        <td style={{borderTopLeftRadius:'15px'}} className={classes.colcol}>1</td>
                                        <td style={{borderTopRightRadius:'15px'}} className={classes.colcol3}>National University of Ireland Galway - NUI GALWAY</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>2</td>
                                        <td className={classes.colcol3}>Université des Sciences des Techniques et des Technologies de Bamako - USTTB</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>3</td>
                                        <td className={classes.colcol3}>Université des Lettres et des Sciences Humaines de Bamako</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>4</td>
                                        <td className={classes.colcol3}>Institut National Polytechnique Félix HOUPHOUËT-BOIGNY</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>5</td>
                                        <td className={classes.colcol3}>Université de San-Pedro - UNIVERSITY of San-Pedro</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>6</td>
                                        <td className={classes.colcol3}>Université Ouaga 1 - PROFESSEUR JOSEPH KI-ZERBO</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>7</td>
                                        <td className={classes.colcol3}>Université NAZI BONI</td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    </div>
                    <div className={classes.emaprojets2}>
                        <div className={classes.emaprojettitlea}>PROJET DEEP FARM</div>
                        <div className={classes.emaprojettitle2a}>
                            COORDINATEUR : <br />
                            Ecole Supérieure des Technologies Industrielles Avancées - ESTIA
                        </div>
                        <div className={classes.ematableau}>
                            <table className={classes.ematableau}>
                                <thead>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr >
                                        <td style={{borderTopLeftRadius:'15px'}} className={classes.colcol}>1</td>
                                        <td style={{borderTopRightRadius:'15px'}} className={classes.colcol3}>Universita Degli Studi DI Siena - UNISI</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>2</td>
                                        <td className={classes.colcol3}>YASSAR Universitesi</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>3</td>
                                        <td className={classes.colcol3}>IT University - ITU</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>4</td>
                                        <td className={classes.colcol3}>University of Antananarivo</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>5</td>
                                        <td className={classes.colcol3}>Ecole Supérieure Africaine des Technologies de l’Information de la Communication - ESATIC</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>6</td>
                                        <td className={classes.colcol3}>Institut National Polytechnique Félix HOUPHOUËT-BOIGNY</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>7</td>
                                        <td className={classes.colcol3}>Université d’Etat d’Haïti - UEH</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>8</td>
                                        <td className={classes.colcol3}>Ecole Supérieure d’Infotronique d’Haïti SA - ESIH</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>9</td>
                                        <td className={classes.colcol3}>Universidad Technologica de Santiago</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>10</td>
                                        <td className={classes.colcol3}>Universidad ISa Inc Associacion Sinfines de Lucro - ISA</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>11</td>
                                        <td className={classes.colcol3}>Agence pour la Formation et la Recherche en Intelligence Artificielle - AFRA</td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    </div>

                    <div className={classes.emaprojets2}>
                        <div className={classes.emaprojettitlea}>PROJET JOINT QA AFRICA</div>
                        <div className={classes.emaprojettitle2a}>
                            COORDINATEUR : <br />
                            Asociacion observatorio de las Relaciones Union Europea - America Latina (OBREAL- GLOBAL OBSERVATORY) – OBREAL – GLOBAL - OBSERVATORY
                        </div>
                        <div className={classes.ematableau}>
                            <table className={classes.ematableau}>
                                <thead>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr >
                                        <td style={{borderTopLeftRadius:'15px'}} className={classes.colcol}>1</td>
                                        <td style={{borderTopRightRadius:'15px'}} className={classes.colcol3}>Universitat Politécnica de Catalunya - UPC</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>2</td>
                                        <td className={classes.colcol3}>Université de Liège - ULIEGE</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>3</td>
                                        <td className={classes.colcol3}>Université de Montpellier - UNIVERSITE DE MONTPELLIER</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>4</td>
                                        <td className={classes.colcol3}>Université Catholique de Louvain - UCLOUVAIN</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>5</td>
                                        <td className={classes.colcol3}>Agence pour l’Evaluation de la Qualité de l’Enseignement Supérieur organisé ou Subventionné par la Communauté Française - AEQES</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>6</td>
                                        <td className={classes.colcol3}>Université ALASSANE OUATTARA - UAO</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>7</td>
                                        <td className={classes.colcol3}>Institut National Polytechnique Félix HOUPHOUËT-BOIGNY</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>8</td>
                                        <td className={classes.colcol3}>Ministère de l’Enseignement Supérieur et de la Recherche Scientifique de Côte d’Ivoire -MINISTRY OF HIGHER EDUCATION AND SCIENTIFIC REASEARCH</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>9</td>
                                        <td className={classes.colcol3}>Conseil Africain et Malgache pour l’Enseignement Supérieur- CAMES</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>10</td>
                                        <td className={classes.colcol3}>Université NOBERT ZONGO - NOBERT ZONGO UNIVERSITY</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>11</td>
                                        <td className={classes.colcol3}>Université THOMAS SANKARA</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>12</td>
                                        <td className={classes.colcol3}>Ministère de l’Enseignement Supérieur, de la Recherche et de l’Innovation- MINISTERE DE L’ENSEIGNEMENT SUPERIEUR, DE LA RECHERCHE ET DE L’INNOVATION</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>13</td>
                                        <td className={classes.colcol3}>Université de Dschang-UDS</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>14</td>
                                        <td className={classes.colcol3}>Université de Douala-UNIVERSITY OF DOUALA</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>15</td>
                                        <td className={classes.colcol3}>Ministry of Scientific research and Innovation</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>16</td>
                                        <td className={classes.colcol3}>Ministère de l’Enseignement Supérieur-MINESUP</td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    </div>

                        <div ClassName={classes.emaprojets2}>
                        <div className={classes.emaprojettitlea}>PROJET EQOSAF</div>
                        <div className={classes.emaprojettitle2a}>
                            COORDINATEUR : <br />
                            UNIVERSITE D’ANGERS - UA
                        </div>
                        <div className={classes.ematableau}>
                            <table className={classes.ematableau}>
                                <thead>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr >
                                        <td style={{borderTopLeftRadius:'15px'}} className={classes.colcol}>1</td>
                                        <td style={{borderTopRightRadius:'15px'}} className={classes.colcol3}>Comité Africain de Métrologie – CAFMET</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>2</td>
                                        <td className={classes.colcol3}>Centre de Coopération Internationale en Recherche Agronomique pour le Développement – C.I.R.A.D. EPIC - CIRAD</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>3</td>
                                        <td className={classes.colcol3}>Association pour la qualité en Recherche et en Enseignement Supérieur – ASSOCIATION QUARES</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>4</td>
                                        <td className={classes.colcol3}>France Université Numérique – FRANCE UNIVERSITE NUMERIQUE</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>5</td>
                                        <td className={classes.colcol3}>Université de Liège – ULIEGE</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>6</td>
                                        <td className={classes.colcol3}>Universita Degli Studi DI Sassari – UNISS</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>7</td>
                                        <td className={classes.colcol3}>Carthage University</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>8</td>
                                        <td className={classes.colcol3}>Université de la Manouba – UNIVERSITE DE LA MANOUBA</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>9</td>
                                        <td className={classes.colcol3}>Université MOHAMED PREMIER 1 – UMP -UMP</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>10</td>
                                        <td className={classes.colcol3}>Institut Agronomique et Vétérinaire HASSAN II - IAV</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>11</td>
                                        <td className={classes.colcol3}>Université MOHAMED VI Polytechnique - UM6P</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>12</td>
                                        <td className={classes.colcol3}>Université CADI AYYAD – UCAM</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>13</td>
                                        <td className={classes.colcol3}>Université NANGUI Abrogoua – UNIVERSITE NANGUI ABROGOUA</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.colcol}>14</td>
                                        <td className={classes.colcol3}>Institut National Polytechnique Félix HOUPHOUËT-BOIGNY</td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    </div>
                    <div className={classes.emaprojets1}></div>
                    <div className={classes.emaprojets1}></div>



                </div>
            </div>
            <FooterPortailConcours/>
        </Transitions>
    </>

  )
}

export default Erasmus
