import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { NavLink, Outlet } from 'react-router-dom'
import styled from 'styled-components'
import { Transitions } from '../../components/Transitions'

function importAll(r) {
    let images = {}
    r.keys().map((item, index) => images[item.replace('./', '')] = r(item))
    return images
}

const LinkWrapper = styled(Container)`
    overflow: auto;
    @media (max-width: 535px) {
        padding-left: 30%;
    }
    @media (max-width: 368px) {
        padding-left: 50%;
    }
    @media (max-width: 323px) {
        padding-left: 80%;
    }
    @media (max-width: 268px) {
        padding-left: 100%;
    }
    & >a {
        transition: transform 250ms ease-in-out;
        &:hover {
            transform: scale(1.09) !important;
            font-weight: bold !important;
        }
        @media (max-width: 433px) {
            margin: 5px !important;
        }
        @media (max-width: 403px) {
            font-size: 9px !important;
        }
    }
`
const SchoolNavbarWrapper = styled.div`
    background-color: ${(prop) => prop.color};
    display: flex;
    justify-content: center;
    align-items: center;
`
const SchoolName = styled.div`
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;
    @media (max-width: 730px) {
        flex-direction: column;
        justify-content: center;
    }
`
const SchoolNameText = styled.span`
    font-style: normal;
    font-weight: bold;
    font-size: 2.5em;
    padding-left: 10px;
    border-left: 10px solid #fff;
    text-align: center;
    color: #fff;
`

export const Sujets = () => {
    const navLinkStyles = ({ isActive }) => {
        return {
            fontWeight: isActive ? 'bold' : 'normal',
            textDecoration: 'none',
            color: isActive ? '#fff' : '#008D36',
            background: isActive ? '#008D36': '#fff',
            padding: '10px 20px',
            fontSize: '12px',
            letterSpacing: '2px',
            borderRadius: '5px',
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        }
    }
    return (
        <>
            <SchoolNavbarWrapper color={'#008D36'} className="mb-3">
                <SchoolName>
                    <div className="w-100 text-center text-uppercase">
                        <SchoolNameText>
                            Sujets
                        </SchoolNameText>
                    </div>
                </SchoolName>
            </SchoolNavbarWrapper>
            <LinkWrapper className="d-flex justify-content-center align-items-center py-3" fluid>
                {/* <NavLink
                    to="2013"
                    className={'text-uppercase mx-2'}
                    style={navLinkStyles}
                >
                    2013
                </NavLink> */}
                {/* <NavLink
                    to="2014"
                    className={'text-uppercase mx-2'}
                    style={navLinkStyles}
                >
                    2014
                </NavLink> */}
                {/* <NavLink
                    to="2015"
                    className={'text-uppercase mx-2'}
                    style={navLinkStyles}
                >
                    2015
                </NavLink>
                <NavLink
                    to="2016"
                    className={'text-uppercase mx-2'}
                    style={navLinkStyles}
                >
                    2016
                </NavLink>
                <NavLink
                    to="2017"
                    className={'text-uppercase mx-2'}
                    style={navLinkStyles}
                >
                    2017
                </NavLink>
                <NavLink
                    to="2018"
                    className={'text-uppercase mx-2'}
                    style={navLinkStyles}
                >
                    2018
                </NavLink> */}
                <NavLink
                    to="2019"
                    className={'text-uppercase mx-2'}
                    style={navLinkStyles}
                >
                    BAC 2019
                </NavLink>
                <NavLink
                    to="2022"
                    className={'text-uppercase mx-2'}
                    style={navLinkStyles}
                >
                    ING 2022
                </NavLink>

            </LinkWrapper>
            <Outlet />
        </>
    )
}
