import React from 'react'
import { useContext } from 'react'
import { Container } from 'react-bootstrap'
import styled from 'styled-components'
import { Transitions } from '../../components/Transitions'
import '../../utils/style/index.scss'
import { MyContext } from '../../utils/context'
import Error from '../../components/Error'
import {Loader} from '../../components/Loader'
import DOMPurify from 'dompurify'
import Venir from '../../assets/Portail/venirEtudierIci.jpg'

const TitleDiv = styled.div`
    width: 100%;
    background-color: rgb(0, 141, 54);
`
const Myimg = styled.img`
    width: 100%;
`

export const VenirEtudierIci = () => {

    const {isLoading, error, infoGen} = useContext(MyContext)

    const venirTexte = infoGen[0] ? infoGen[0].venirEtudierTexte : null
    
    if(error) {
        return (
            <div>
                <Error/>
                {venirTexte && <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(venirTexte) }}/>}
            </div>
        )
    }

    return (
        <Transitions>
            <TitleDiv className="d-flex justify-content-center align-items-center text-light mb-5">
                <span
                    className="body-highlight mt-3 text-uppercase ps-2 mb-3 fs-1"
                    style={{ borderLeft: '10px solid #fff' }}
                >
                    Venir étudier à l'inp-hb
                </span>
            </TitleDiv>
            
            <div className="d-flex align-items-center justify-content-center">
                    <Myimg src={Venir} 
                    alt="" className="my-3" />
             </div>
            <Container>

            <div className="body-text border-0" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(venirTexte) }}/>

            </Container>
        </Transitions>
    )
}