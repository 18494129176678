import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { FaMailBulk, FaPhone } from 'react-icons/fa'
import Restauration1 from '../../assets/Portail/restauration1.jpg'
import Restauration2 from '../../assets/Portail/restauration2.jpg'
import Restauration3 from '../../assets/Portail/restauration3.jpg'
import Restauration4 from '../../assets/Portail/restauration4.jpg'
import Chef1 from '../../assets/Portail/chef1.jpg'
import Chef2 from '../../assets/Portail/chef2.jpg'
import styled from 'styled-components'
import { FcBusinessman } from 'react-icons/fc'
import { NavLink } from 'react-router-dom'
import { Transitions } from '../../components/Transitions'

const MyCard = styled(Card)`
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    @media (max-width: 590px) {
        font-size: 0.9em;
    }
`
const MyCol = styled(Col)`
    @media (max-width: 475px) {
        flex-basis: 80% !important;
        margin-bottom: 10px;
    }
    @media (max-width: 330px) {
        flex-basis: 90% !important;
    }
`
export const Restauration = () => {
    return (
        <Transitions>
            <div className="text-dark text-start ms-4 my-2 d-flex">
               <NavLink to='/portail/vie_universitaire' className="me-2">Vie à l'INP-HB </NavLink> | Restauration
            </div>
            <Container className='mb-5'>
                <div
                    style={{ backgroundColor: '#E6E6E6' }}
                    className="p-3 py-4 text-justify text-center fs-3 body-text border-0 text-capitalize"
                >
                    Restauration
                </div>
                <div
                style={{ backgroundColor: '#E6E6E6' }}
                className="p-3 text-start body-text border-0"
            >L’INP-HB dispose de deux grands restaurants universitaires, logés sur les sites Centre et Sud.
            Ces restaurants proposent aux étudiants des mets équilibrés à des prix très étudiés. Subventionnés, les trois repas de la journée reviennent à 225 Francs CFA par étudiant.
            Pour se restaurer, les élèves doivent se munir de leur badge (à retirer à la scolarité) et ouvrir un « compte restobadge » au service restauration. Ce compte leur donne accès au restaurant (Petit Déjeuner, Déjeuner, Dîner).
             <br /> <br />
            <strong>NB : L’ouverture du compte restobadge ne peut se faire que par le propriétaire de la carte étudiant.</strong> </div>
                <Row>
                    <Col>
                        <MyCard style={{width:"100%"}}>
                            <img src={Chef1} alt="" style={{width:"100%"}}/>
                        </MyCard>
                    </Col>
                    <Col>
                        <MyCard style={{width:"100%"}}>
                            <img src={Chef2} alt="" style={{width:"100%"}}/>
                        </MyCard>
                    </Col>
                </Row>
            </Container>
            <p className='body-text border-0 mb-3'>Le Restobadge, un système de badge électronique pour vous restaurer en toute quiétude. Deux entreprises sont chargées de la restauration des élèves:</p>
            <Row>
                <Col className="p-0 pe-1">
                    <img src={Restauration1} alt="" style={{ width: '100%' }} />
                </Col>
                <Col className="p-0 pe-1">
                    <img src={Restauration2} alt="" style={{ width: '100%' }} />
                </Col>
                <Col className="p-0 pe-1">
                    <img src={Restauration3} alt="" style={{ width: '100%' }} />
                </Col>
                <Col className="p-0">
                    <img src={Restauration4} alt="" style={{ width: '100%' }} />
                </Col>
            </Row>
            <p className="mt-5 text-center fw-bold text-uppercase">
                responsable service restauration
            </p>
            <Row className="d-flex flex-wrap align-items-center justify-content-center">
                <MyCol xxl={3} xl={3} lg={4} md={5} sm={6} xs={6}>
                    <MyCard
                        className="d-flex flex-column align-items-center justify-content-center p-2 mb-5"
                        style={{
                            width: '100%',
                            height: '100%',
                            border: '0px',
                        }}
                    >
                        <div
                                style={{
                                    borderRadius: '50%',
                                    backgroundColor: '#E6E6E6',
                                }}
                                className="p-2"
                            >
                                <FcBusinessman style={{ fontSize: '4.5em' }} />
                            </div>
                        <p className="mb-3 mt-2 text-center">
                            M BROU Dihyé
                        </p>
                        <div className="mb-3 text-center">
                            <FaPhone style={{ color: '#0DBA1E' }} />  (00225) 0708906795
                        </div>
                        <div className="mb-5 text-center">
                            <FaMailBulk style={{ color: '#0DBA1E' }} /> dihye.brou@inphb.ci</div>
                        <p className="text-uppercase text-center fw-bold">
                            site sud/centre
                        </p>
                    </MyCard>
                </MyCol>
            </Row>
        </Transitions>
    )
}
