import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import INPHB from '../../assets/INP-HB.png'
import PresentationImage from '../../assets/Portail/presentationImage.jpg'
import FormationImage from '../../assets/Portail/formationImage.jpg'
import VieImage from '../../assets/Portail/vieImage.jpg'
import ExterieurImage from '../../assets/Portail/exterieurImage.jpg'
import RechercheImage from '../../assets/Portail/rechercheImage.png'
import { useState } from 'react'
import { HeaderPortailLinkHover } from '../HeaderPortailLinkHover'
import { HamburgerMenuPortail } from '../HamburgerMenuPortail'

const HeaderPortailWrapper = styled.div``
const Down = styled.div`
    padding: 0px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const BottomDiv = styled.div`
    position: absolute;
    z-index: 3 !important;
    width: max-content;
    max-width: 430px;
    background-color: #fff;
    box-shadow: 0 3px 16px rgb(0, 0, 0, 0.18);
    padding: 20px 16px;
    top: 70px;
    margin-left: -70px;
`
const MyNavbar = styled.ul`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 90%;
    height: 70px !important;
    z-index: 3;
    text-transform: uppercase;
    margin: 0px;
    @media (max-width: 1090px) {
        display: none;
    }
    & > li {
        list-style: none;
        height: 70px !important;
        display: flex;
        align-items: center;
        &:hover {
            transform: scale(1.1);
            font-weight: bold;
            color: #000 !important;
        }
        @media (max-width: 1090px) {
            display: none;
        }
    }
`
const Myimg = styled.img`
    aspect-ratio: 3/2;
    object-fit: contain;
    @media (max-width: 400px) {
        height: 70px !important;
        width: 70px !important;
        margin-left: 10px !important;
    }
`
const SousLienPresentation = {
    image: PresentationImage,
    sousLiens: [
        {
            nom: "À propos de l'inp-hb",
            lien: 'a_propos',
        },
        {
            nom: 'Vision, Missions et valeurs',
            lien: 'missions_vision_valeurs',
        },
        {
            nom: 'diplômés emblématiques',
            lien: 'diplomes_emblematiques',
        },
        {
            nom: 'Sites et Campus',
            lien: 'sites_et_campus',
        },
    ],
}
const SousLienFormation = {
    image: FormationImage,
    sousLiens: [
        {
            nom: 'Écoles',
            lien: '/ecoles',
        },
        {
            nom: 'Centres',
            lien: 'centres',
        },
        {
            nom: 'Départements',
            lien: "departements",
        },
    ],
}
const SousLienRecherche = {
    image: RechercheImage,
    sousLiens: [
        {
            nom: 'École doctorale',
            lien: 'https://edp.inphb.ci',
            as: true,
        },
        /* {
            nom: 'UMRI',
            lien: 'https://inphb.ci/2/vues/recherche/',
            as: true,
        }, */
        {
            nom: 'Grands projets',
            lien: 'grands_projets',
        },
    ],
}
const SousLienExterieur = {
    image: ExterieurImage,
    sousLiens: [
        {
            nom: "Venir étudier à l'inp-hb",
            lien: 'venir_etudier_ici',
        },
        {
            nom: "Bourses à l'international",
            lien: 'bourses_internationales',
        },
    ],
}
const SousLienVie = {
    image: VieImage,
    sousLiens: [
        {
            nom: 'Hébergement',
            lien: 'vie_universitaire/hebergement',
        },
        {
            nom: 'Restauration',
            lien: 'vie_universitaire/restauration',
        },
        {
            nom: 'Vie Associative',
            lien: 'vie_universitaire/vie_associative',
        },
        {
            nom: 'Activités Sportives',
            lien: 'vie_universitaire/activites_sportives',
        },
        {
            nom: 'Assistance et Santé',
            lien: 'vie_universitaire/assistance_sociale_et_sante',
        },
        {
            nom: 'Bureau des Elèves',
            lien: 'vie_universitaire/bureau_des_eleves',
        },
    ],
}

export const MenuItems = [
    {
        titre: "Présentation",
        items: SousLienPresentation.sousLiens,
    },
    {
        titre: "Formation",
        items: SousLienFormation.sousLiens,
    },
    {
        titre: "Enseignement et Recherche",
        items: SousLienRecherche.sousLiens,
    },
    {
        titre: "Relations Extérieures",
        items: SousLienExterieur.sousLiens,
    },
    {
        titre: "Vie à l'INP-HB",
        items: SousLienVie.sousLiens,
    },
]

export default function HeaderPortail() {
    const [isStatusOpen, setisStatusOpen] = useState([
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
    ])
    const HanldeCheck = (i) => {
        setisStatusOpen((prevState) =>
            prevState.map((item, idx) => (idx === i ? !item : false))
        )
    }
    const HanldeCheckFalse = (i) => {
        setisStatusOpen((prevState) =>
            prevState.map((item, idx) => (idx === i ? false : item))
        )
    }
    const navLinkStyles = ({ isActive }) => {
        return {
            fontWeight: isActive ? 'bold' : 'normal',
            textDecoration: 'none',
            color: isActive ? '#000' : 'rgba(33, 20, 8, 0.67)',
            transform: isActive ? 'scale(1.1)' : 'scale(1)',
            borderBottom: isActive ? '2px solid rgba(244, 121, 32)' : 'none',
        }
    }
    return (
        <HeaderPortailWrapper>
            <Down bg="light" expand="lg">
                <HamburgerMenuPortail/>
                {/* <NavLink to="">
                    <Myimg
                        className="ms-5 my-1"
                        style={{ width: '80px', height: '80px' }}
                        src={INPHB}
                        alt="INP-HB"
                    />
                </NavLink> */}
                <MyNavbar>
                    <li
                        onMouseEnter={() => HanldeCheck(0)}
                        onMouseLeave={() => HanldeCheckFalse(0)}
                        style={{ cursor: 'pointer' }}
                    >
                        Présentation
                        <BottomDiv
                            className={isStatusOpen[0] ? 'd-flex fade-in' : 'd-none'}
                            onMouseLeave={() => HanldeCheckFalse(0)}
                            
                        >
                            <HeaderPortailLinkHover
                                SousLiens={SousLienPresentation}
                            />
                        </BottomDiv>
                    </li>
                    <li
                        onMouseEnter={() => HanldeCheck(1)}
                        onMouseLeave={() => HanldeCheckFalse(1)}
                        style={{ cursor: 'pointer' }}
                    >
                        Formation
                        <BottomDiv
                            className={isStatusOpen[1] ? 'd-flex fade-in' : 'd-none'}
                            onMouseLeave={() => HanldeCheckFalse(1)}
                        >
                            <HeaderPortailLinkHover
                                SousLiens={SousLienFormation}
                            />
                        </BottomDiv>
                    </li>
                    <li
                        onMouseEnter={() => HanldeCheck(2)}
                        onMouseLeave={() => HanldeCheckFalse(2)}
                        style={{ cursor: 'pointer' }}
                    >
                        Enseignement et Recherche
                        <BottomDiv
                            className={isStatusOpen[2] ? 'd-flex fade-in' : 'd-none'}
                            onMouseLeave={() => HanldeCheckFalse(2)}
                        >
                            <HeaderPortailLinkHover
                                SousLiens={SousLienRecherche}
                            />
                        </BottomDiv>
                    </li>
                    <li
                        onMouseEnter={() => HanldeCheck(3)}
                        onMouseLeave={() => HanldeCheckFalse(3)}
                        style={{ cursor: 'pointer' }}
                    >
                        Relations Extérieures
                        <BottomDiv
                            className={isStatusOpen[3] ? 'd-flex fade-in' : 'd-none'}
                            onMouseLeave={() => HanldeCheckFalse(3)}
                        >
                            <HeaderPortailLinkHover
                                SousLiens={SousLienExterieur}
                            />
                        </BottomDiv>
                    </li>
                    <li
                        onMouseEnter={() => HanldeCheck(4)}
                        onMouseLeave={() => HanldeCheckFalse(4)}
                        style={{ cursor: 'pointer' }}
                    >
                        <NavLink
                            {...navLinkStyles}
                            to="vie_universitaire"
                            className="text-dark"
                        >
                            Vie à l'INP-HB
                        </NavLink>
                        <BottomDiv
                            className={isStatusOpen[4] ? 'd-flex fade-in' : 'd-none'}
                            onMouseLeave={() => HanldeCheckFalse(4)}
                        >
                            <HeaderPortailLinkHover SousLiens={SousLienVie} />
                        </BottomDiv>
                    </li>
                    <li> <NavLink to="relation_entreprise" className="text-dark">Relations Entreprises</NavLink> </li>
                </MyNavbar>
            </Down>
        </HeaderPortailWrapper>
    )
}
