import { NavLink, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Container, NavbarBrand, Card, Row, Col } from 'react-bootstrap'
import '../../utils/style/index.scss'
import { FaBookOpen, FaPhoneAlt } from 'react-icons/fa'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'
import MeilleursEleves from '../../components/MeilleursEleves'
import { school } from '../../datas'
import HeaderEcole from '../../components/HeaderEcole'
import { TbArrowBigTop } from 'react-icons/tb'
import { Transitions } from '../../components/Transitions'
import Partenaires from '../../components/Partenaires'
import Contacts from '../../assets/Ecoles/CONTACTS.pdf'
import { HashLink } from 'react-router-hash-link'

// const EcoleWrapper = styled.div`
//     overflow: hidden !important;
// `
const ConcoursWrapper = styled(Container)`
    background-color: #fff9f5;
    overflow: hidden !important;
`
const ConcoursPosterText = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 55px;
    justify-content: center;
    align-items: center;
    padding: 4rem;
    align-items: space-around;
    margin: 0px;
`
const ConcoursPoster = styled.div`
    padding: 0px;
    margin: 0px;
    height: 100vh;
    background-image: url(${(prop) => prop.image});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`
const BrochureWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    margin-top: -3%;
    @media (max-width: 500px) {
        margin-top: -5%;
    }
`
const BrochureItem = styled.a`
    display: flex;
    cursor: pointer !important;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
        rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    padding: 5px;
    background-color: ${(props) => props.bg};
    color: ${(props) => props.color};
    font-size: 20px;
    text-decoration: none;
    &:hover {
        background-color: ${(props) => props.bg};
        color: ${(props) => props.color};
    }
    @media (max-width: 400px) {
        font-size: 15px;
    }
`
const Description = styled(Container)`
    width: 100%;
    padding-top: 75px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
const Presentation = styled(Description)``
const Objectifs = styled(Description)``
const MotDuDirecteurWrapper = styled(Container)`
    width: 100%;
    background-color: ${(prop) => prop.color};
    padding-bottom: 70px;
`
const FilieresIngenieures = styled(MotDuDirecteurWrapper)``
const CyclesEtFilieres = styled(Description)``
const CommentIntegrer = styled(Description)``
const NosMeilleursEleves = styled(MotDuDirecteurWrapper)``
const PartenairesAcademiques = styled.div`
    width: 100%;
    background-color: ${(prop) => prop.color};
`
const PartenairesEntreprises = styled.div`
    width: 100%;
    padding-top: 75px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
const Debouches = styled(Description)``
const MotDuDirecteurBottom = styled(Container)`
    @media (max-width: 630px) {
        flex-direction: column !important;
    }
`
const Text = styled.p`
    @media (max-width: 936px) {
        text-align: justify !important;
    }
`
const Titles = styled.p`
    position: relative;
    font-weight: bold;
    &::after {
        content: '';
        height: 5px;
        width: 50%;
        background-color: ${(prop) => prop.color};
        position: absolute;
        bottom: -0.3em;
        left: 50%;
        transform: translate(-50%);
    }
`
const Objectif = styled.div`
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 15px;
    border-top: 10px solid ${(prop) => prop.color};
`
const EtudierButtonDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
const EtudierButton = styled(NavLink)`
    border-radius: 0px;
    border: 0px;
    margin: 5px;
    float: right;
    background-color: ${(prop) => prop.color};
    color: #fff;
    &:hover {
        background-color: #fff;
        color: ${(prop) => prop.color};
    }
`
const EtudierButtonHash = styled(HashLink)`
    border-radius: 0px;
    border: 0px;
    margin: 5px;
    float: right;
    background-color: ${(prop) => prop.color};
    color: #fff;
    &:hover {
        background-color: #fff;
        color: ${(prop) => prop.color};
    }
`

const FiliereWrapper = styled.div`
    width: 100%;
    flex-wrap: wrap;
`
const FiliereCard = styled(Col)`
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    @media (max-width: 440px) {
        flex-basis: 100% !important;
    }
`
const CardText = styled.p``
const FiliereContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 0px !important;
    padding-right: 0px !important;
`
const NomDirecteur = styled.div``

const IntegrerText = styled.div`
    font-size: 0.85em !important;
`
const MobilitesRow = styled(Row)`
    display: flex;
    justify-content: center;
    align-items: center;
`
const MyCardTitle = styled(Card.Title)`
    color: ${(props) => props.color};
`
const MyCardBody = styled(Card.Body)`
    font-size: 0.75em;
`
const Myimg = styled.img`
    width: 100%;
`
const PhotoDirecteur = styled.img`
    width: 100%;
    border-radius: 50%;
    border: 5px solid ${(props) => props.color};
    background-color: #fff;
`

const GoTop = styled.div`
    position: fixed;
    z-index: 9;
    bottom: 50px;
    left: 20px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    background-color: #fff;
    color: ${(props) => props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5em;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 50%;
    transition: all 1s ease-in-out;
    /* &:hover {
        color: #079edb;
        background-color: #fff;
    } */
`

function EcoleAccueil() {
    const { schoolName } = useParams()
    const ecole = school[`${schoolName}`]
    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])
    return ecole ? (
        <Transitions>
            <HeaderEcole
                couleurPrincipale={ecole.couleurPrincipale}
                logo={ecole.logo}
                acronyme={ecole.alt}
                nom={ecole.nom}
                EcoleItems={ecole.menus}
            />
            <GoTop
                onClick={() => {
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth',
                    })
                }}
                color={ecole.couleurPrincipale}
                data-aos="fade-right"
                title="Aller plus haut"
            >
                <TbArrowBigTop style={{ width: '50px', height: '50px' }} />
            </GoTop>
            <ConcoursWrapper className="p-0" fluid>
                <ConcoursPoster image={ecole.backgroundAccueil}>
                    <ConcoursPosterText className="text-center" />
                    <BrochureWrapper>
                        <BrochureItem
                            data-aos="fade-right"
                            color={ecole.couleurSecondaire}
                            bg="#fff"
                            href={ecole.plaquette}
                            download
                        >
                            <span>
                                <FaBookOpen />
                            </span>
                            <p>Plaquette</p>
                        </BrochureItem>
                        <BrochureItem
                            data-aos="fade-left"
                            bg={ecole.couleurSecondaire}
                            href={Contacts}
                            color="#fff"
                            download
                        >
                            <span>
                                <FaPhoneAlt />
                            </span>
                            <p>Contacts</p>
                        </BrochureItem>
                    </BrochureWrapper>
                </ConcoursPoster>
                <Presentation
                    className="py-5"
                    data-aos="fade-up"
                    id="presentation"
                >
                    <Titles
                        color={ecole.couleurSecondaire}
                        className="text-uppercase body-highlight mt-0 mb-5"
                    >
                        Présentation
                    </Titles>
                    <Text
                        className="body-text text-justify"
                        style={{ border: '0px' }}
                    >
                        {ecole.presentation}
                    </Text>
                </Presentation>
                <MotDuDirecteurWrapper
                    className="py-3"
                    fluid
                    data-aos="fade-up"
                    color={ecole.couleurSecondaire}
                    id="mot_du_directeur"
                >
                    <p className="body-highlight text-light text-center fw-bold mt-0">
                        MOT DU DIRECTEUR
                    </p>
                    <MotDuDirecteurBottom
                        className="d-flex justify-content-center align-items-center"
                        fluid
                    >
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <div style={{ width: '10em' }}>
                                <PhotoDirecteur
                                    src={ecole.photoDirecteur}
                                    alt={ecole.nomDirecteur}
                                    color={ecole.couleurPrincipale}
                                />
                            </div>
                            <NomDirecteur className="fw-bold text-light my-2 text-center">
                                {ecole.nomDirecteur}
                            </NomDirecteur>
                        </div>
                        <IntegrerText
                            className="body-text text-light"
                            style={{ border: '0px' }}
                        >
                            {ecole.motsDirecteur}
                        </IntegrerText>
                    </MotDuDirecteurBottom>
                </MotDuDirecteurWrapper>
                {ecole.objectifs ? (
                    <Objectifs
                        className="pb-5"
                        data-aos="fade-up"
                        id="objectifs"
                    >
                        <Titles
                            color={ecole.couleurSecondaire}
                            className="text-uppercase body-highlight mt-0 mb-5"
                        >
                            OBJECTIFS
                        </Titles>
                        <Objectif
                            color={ecole.couleurSecondaire}
                            className="px-5 body-text border-start-0 pb-5 pt-3 text-justify"
                        >
                            {ecole.objectifs}
                        </Objectif>
                    </Objectifs>
                ) : null}
                {ecole.formation ? (
                    <Objectifs
                        className="pb-5"
                        data-aos="fade-up"
                        id="formation"
                    >
                        <Titles
                            color={ecole.couleurSecondaire}
                            className="text-uppercase body-highlight mt-0 mb-5"
                        >
                            FORMATIONS
                        </Titles>
                        <Objectif
                            color={ecole.couleurSecondaire}
                            className="px-5 body-text border-start-0 pb-5 pt-3 text-justify"
                        >
                            {ecole.formation}
                        </Objectif>
                    </Objectifs>
                ) : null}
                {ecole.missions ? (
                    <Objectifs
                        className="pb-5"
                        data-aos="fade-up"
                        id="missions"
                    >
                        <Titles
                            color={ecole.couleurSecondaire}
                            className="text-uppercase body-highlight mt-0 mb-5"
                        >
                            Missions
                        </Titles>
                        <Objectif
                            color={ecole.couleurSecondaire}
                            className="px-5 body-text border-start-0 pb-5 pt-3 text-justify"
                        >
                            {ecole.missions}
                        </Objectif>
                    </Objectifs>
                ) : null}
                {ecole.filieresIngenieures ? (
                    <FilieresIngenieures
                        className="pt-1"
                        fluid
                        data-aos="fade-up"
                        color={ecole.couleurSecondaire}
                        id="filieres"
                    >
                        <FiliereContainer fluid>
                            <p className="body-highlight text-center text-light text-uppercase fw-bold ">
                                filières ingénieures
                            </p>
                            <FiliereWrapper className=" d-flex align-items-center justify-content-center">
                                {ecole.filieresIngenieures.map(
                                    (filiere, index) => (
                                        <FiliereCard
                                            key={`${filiere.nom}-${index}`}
                                            xxl={3}
                                            xl={3}
                                            lg={3}
                                            md={6}
                                            sm={6}
                                            xs={6}
                                        >
                                            <Card
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    border: '0px',
                                                    boxShadow:
                                                        'rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
                                                }}
                                            >
                                                <Card.Img
                                                    variant="top"
                                                    src={filiere.image}
                                                />
                                                <Card.Body>
                                                    <CardText className="text-center">
                                                        {filiere.nom}
                                                    </CardText>
                                                </Card.Body>
                                            </Card>
                                        </FiliereCard>
                                    )
                                )}
                            </FiliereWrapper>
                            <EtudierButtonDiv>
                                <EtudierButtonHash
                                    className="mybtn mt-5 mb-0"
                                    color={ecole.couleurPrincipale}
                                    to={`cycles_et_filieres#filieres_concours_ingenieur`}
                                >
                                    Plus d'infos →{' '}
                                </EtudierButtonHash>
                            </EtudierButtonDiv>
                        </FiliereContainer>
                    </FilieresIngenieures>
                ) : null}
                {ecole.cyclesEtFilieres ? (
                    <CyclesEtFilieres
                        className="mb-2"
                        data-aos="fade-up"
                        id="cycles_et_filieres"
                    >
                        <Titles
                            color={ecole.couleurSecondaire}
                            className="text-uppercase body-highlight mt-0 mb-5"
                        >
                            Cycles et filières
                        </Titles>
                        <div className="d-flex align-items-center justify-content-center">
                            <Myimg
                                src={ecole.cyclesEtFilieres}
                                alt=""
                                className="my-3"
                            />
                        </div>
                        {ecole.alt.includes('ESPE') ||
                                      ecole.alt.includes('EFCPC') ? null :<EtudierButtonDiv>
                            <EtudierButton
                                className="mybtn mt-5 mb-0"
                                color={ecole.couleurPrincipale}
                                to="cycles_et_filieres"
                            >
                                Plus d'infos →{' '}
                            </EtudierButton>
                        </EtudierButtonDiv>}
                        
                    </CyclesEtFilieres>
                ) : null}
                {ecole.filieres ? (
                    <CyclesEtFilieres
                        className="mb-2"
                        data-aos="fade-up"
                        id="filieres"
                    >
                        <Titles
                            color={ecole.couleurSecondaire}
                            className="text-uppercase body-highlight mt-0 mb-5"
                        >
                            Filières
                        </Titles>
                        <div className="d-flex align-items-center justify-content-center">
                            <Myimg
                                src={ecole.filieres}
                                alt=""
                                className="my-3"
                            />
                        </div>
                        <EtudierButtonDiv>
                            <EtudierButton
                                className="mybtn mt-5 mb-0"
                                color={ecole.couleurPrincipale}
                                to="cycles_et_filieres"
                            >
                                Plus d'infos →{' '}
                            </EtudierButton>
                        </EtudierButtonDiv>
                    </CyclesEtFilieres>
                ) : null}
                {ecole.filieresEDP ? (
                    <CyclesEtFilieres
                        className="mb-2"
                        data-aos="fade-up"
                        id="filieres"
                    >
                        <Titles
                            color={ecole.couleurSecondaire}
                            className="text-uppercase body-highlight mt-0 mb-5"
                        >
                            Filières
                        </Titles>
                        <div className="d-flex align-items-center justify-content-center">
                            <Myimg
                                src={ecole.filieresEDP}
                                alt=""
                                className="my-3"
                            />
                        </div>
                    </CyclesEtFilieres>
                ) : null}
                {ecole.cyclesDeFormation ? (
                    <CommentIntegrer
                        className="mb-5 mt-0"
                        data-aos="fade-up"
                        id="cycles_de_formation"
                    >
                        <Titles
                            color={ecole.couleurSecondaire}
                            className="text-uppercase body-highlight mt-0 mb-5"
                        >
                            Cycles de Formation
                        </Titles>
                        <MobilitesRow>
                            {ecole.cyclesDeFormation.map((recherche, index) => (
                                <Col
                                    key={`recherche-${index}`}
                                    lg={4}
                                    xxl={4}
                                    xl={4}
                                    md={6}
                                    sm={6}
                                    className="mt-2"
                                >
                                    <Card
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            border: '0px',
                                            boxShadow:
                                                'rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
                                        }}
                                        className="me-2"
                                    >
                                        <Card.Img
                                            variant="top"
                                            src={recherche.image}
                                        />
                                        <MyCardTitle
                                            className="ms-3 text-capitalize mt-3 mb-0"
                                            color={ecole.couleurPrincipale}
                                        >
                                            {recherche.titre}
                                        </MyCardTitle>
                                        <MyCardBody className="my-0 text-justify">
                                            {recherche.corps}
                                        </MyCardBody>
                                    </Card>
                                </Col>
                            ))}
                        </MobilitesRow>
                        <EtudierButtonDiv>
                            <EtudierButton
                                className="mybtn mt-5 mb-0"
                                color={ecole.couleurPrincipale}
                                to="cycles_et_filieres"
                            >
                                Plus d'infos →{' '}
                            </EtudierButton>
                        </EtudierButtonDiv>
                    </CommentIntegrer>
                ) : null}
                {ecole.commentIntegrer ? (
                    <CommentIntegrer
                        className="mb-5 mt-0"
                        data-aos="fade-up"
                        id="comment_integrer"
                    >
                        <Titles
                            color={ecole.couleurSecondaire}
                            className="text-uppercase body-highlight mt-0 mb-5"
                        >
                            Comment intégrer notre école
                        </Titles>
                        <MobilitesRow>
                            {ecole.commentIntegrer.map((recherche, index) => (
                                <Col
                                    key={`comment-integrer-${index}`}
                                    lg={4}
                                    xxl={4}
                                    xl={4}
                                    md={6}
                                    sm={6}
                                    className="mt-2"
                                >
                                    <Card
                                        style={{
                                            width: '100%',
                                            height: '27rem',
                                            border: '0px',
                                            boxShadow:
                                                'rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
                                        }}
                                        className="me-2"
                                    >
                                        <Card.Img
                                            variant="top"
                                            src={recherche.image}
                                        />
                                        <MyCardTitle
                                            className="ms-3 text-capitalize mt-3 mb-0"
                                            color={ecole.couleurPrincipale}
                                        >
                                            {recherche.titre}
                                        </MyCardTitle>
                                        <MyCardBody className="my-0 text-justify">
                                            {recherche.sousTitre && (
                                                <>
                                                    {' '}
                                                    <strong>
                                                        {recherche.sousTitre}{' '}
                                                    </strong>{' '}
                                                    <br />
                                                </>
                                            )}
                                            {recherche.corps
                                                .toString()
                                                .split(/\s+/)
                                                .slice(0, 32)
                                                .join(' ')}
                                            ...
                                        </MyCardBody>
                                    </Card>
                                </Col>
                            ))}
                        </MobilitesRow>
                        <EtudierButtonDiv>
                            <EtudierButton
                                className="mybtn mt-5 mb-0"
                                color={ecole.couleurPrincipale}
                                to="comment_integrer_notre_ecole"
                            >
                                Plus d'infos →{' '}
                            </EtudierButton>
                        </EtudierButtonDiv>
                    </CommentIntegrer>
                ) : null}
                {ecole.commentIntegrerEDP ? (
                    <CommentIntegrer
                        className="mb-5 mt-0"
                        data-aos="fade-up"
                        id="comment_integrer"
                    >
                        <Titles
                            color={ecole.couleurSecondaire}
                            className="text-uppercase body-highlight mt-0 mb-5"
                        >
                            Comment intégrer notre école
                        </Titles>
                        <MobilitesRow>
                            {ecole.commentIntegrerEDP.map(
                                (recherche, index) => (
                                    <Col
                                        key={`comment-integrer-${index}`}
                                        lg={4}
                                        xxl={4}
                                        xl={6}
                                        md={6}
                                        sm={12}
                                        className="mt-2"
                                    >
                                        <Card
                                            style={{
                                                width: '100%',
                                                border: '0px',
                                                height: "100%",
                                                boxShadow:
                                                    'rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
                                            }}
                                            className="me-2"
                                        >
                                            <MyCardBody className="my-0 text-justify">
                                                {recherche.sousTitre && (
                                                    <>
                                                        {' '}
                                                        <strong>
                                                            {
                                                                recherche.sousTitre
                                                            }{' '}
                                                        </strong>{' '}
                                                        <br />
                                                    </>
                                                )}
                                                {recherche.corps}
                                            </MyCardBody>
                                        </Card>
                                    </Col>
                                )
                            )}
                        </MobilitesRow>
                    </CommentIntegrer>
                ) : null}
                {ecole.commentIntegrerNotreEcole ? (
                    <Objectifs
                        className="pb-5"
                        data-aos="fade-up"
                        id="comment_integrer"
                    >
                        <Titles
                            color={ecole.couleurSecondaire}
                            className="text-uppercase body-highlight mt-0 mb-5"
                        >
                            Comment intégrer notre école
                        </Titles>
                        <Objectif
                            color={ecole.couleurSecondaire}
                            className="px-5 body-text border-start-0 pb-5 pt-3 text-justify"
                        >
                            {ecole.commentIntegrerNotreEcole}
                        </Objectif>
                    </Objectifs>
                ) : null}
                {ecole.atouts ? (
                    <Objectifs className="pb-5" data-aos="fade-up" id="atouts">
                        <Titles
                            color={ecole.couleurSecondaire}
                            className="text-uppercase body-highlight mt-0 mb-5"
                        >
                            Atouts
                        </Titles>
                        <Objectif
                            color={ecole.couleurSecondaire}
                            className="px-5 body-text border-start-0 pb-5 pt-3 text-justify"
                        >
                            {ecole.atouts}
                        </Objectif>
                    </Objectifs>
                ) : null}
                {ecole.cotutelleDeThese ? (
                    <Objectifs
                        className="pb-5"
                        data-aos="fade-up"
                        id="cotutelleDeThese"
                    >
                        <Titles
                            color={ecole.couleurSecondaire}
                            className="text-uppercase body-highlight mt-0 mb-5"
                        >
                            cotutelle de thèse
                        </Titles>
                        <Objectif
                            color={ecole.couleurSecondaire}
                            className="px-5 body-text border-start-0 pb-5 pt-3 text-justify"
                        >
                            {ecole.cotutelleDeThese}
                        </Objectif>
                    </Objectifs>
                ) : null}
                {/* <NosMeilleursEleves
                    className="pb-3 pt-5 mb-2"
                    fluid
                    data-aos="fade-up"
                    color={ecole.couleurSecondaire}
                    id="meilleurs-eleves"
                >
                    <Container>
                        <p className="body-highlight text-center text-light text-uppercase fw-bold pt-0 mt-0">
                            nos meilleurs élèves
                        </p>
                        <MeilleursEleves eleves={ecole.meilleursEleves} />
                    </Container>
                </NosMeilleursEleves> */}
                {ecole.debouches ? (
                    <Debouches
                        className="mb-5 mt-0"
                        data-aos="fade-up"
                        id="debouches"
                    >
                        <Titles
                            color={ecole.couleurSecondaire}
                            className="text-uppercase body-highlight mt-0 mb-5"
                        >
                            Débouchés
                        </Titles>
                        <div className="d-flex align-items-center justify-content-center">
                            <Myimg
                                src={ecole.debouches}
                                alt=""
                                className="my-3"
                            />
                        </div>
                    </Debouches>
                ) : null}
                {ecole.partenairesAcademiques && (
                    <PartenairesAcademiques
                        className="pb-3 pt-5 mb-2"
                        fluid
                        data-aos="fade-up"
                        color={ecole.couleurSecondaire}
                        id="meilleurs-eleves"
                    >
                        <Container>
                            <p className="body-highlight text-center text-light text-uppercase fw-bold pt-0 mt-0">
                                partenaires académiques
                            </p>
                            <Partenaires
                                partenaires={ecole.partenairesAcademiques}
                            />
                        </Container>
                    </PartenairesAcademiques>
                )}
                {ecole.partenairesEntreprises ? (
                    <PartenairesEntreprises
                        className="mb-5 mt-0"
                        data-aos="fade-up"
                        id="debouches"
                    >
                        <Titles
                            color={ecole.couleurSecondaire}
                            className="text-uppercase body-highlight mt-0 mb-5"
                        >
                            Partenaires Entreprises
                        </Titles>
                        <Partenaires
                            partenaires={ecole.partenairesEntreprises}
                        />
                    </PartenairesEntreprises>
                ) : null}
            </ConcoursWrapper>
        </Transitions>
    ) : (
        window.location.replace('/erreur')
    )
}

export default EcoleAccueil
