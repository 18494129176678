import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import '../../utils/style/index.scss'
import styled from 'styled-components'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Transitions } from '../../components/Transitions'

function importAll(r) {
    let images = {}
    r.keys().map((item, index) => (images[item.replace('./', '')] = r(item)))
    return images
}

const portail = importAll(
    require.context('../../assets/Portail', false, /\.(png|jpe?g|svg)$/)
)
const MyCard = styled(Card)`
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 90% !important;
    font-size: 0.85em;
    @media (max-width: 590px) {
        font-size: 0.7em;
    }
`
const associations = [
    {
        nom: 'Agriculture et Resources Animales (ARA)',
        lien: "agriculture_et_ressources_animales",
        image: portail["depAgriculture.jpg"],
        description: '',
        president: '',
    },
    {
        nom: 'Eaux, Forêt et Environnement(FOREN)',
        lien: "eaux_foret_et_environnement",
        image: portail["depEaux.jpg"],
        description: '',
        president: '',
    },
    {
        nom: 'Génie Rural et Sciences Géographiques (GRSG)',
        lien: "genie_rural_et_sciences_geographiques",
        image: portail["depRural.jpg"],
        description: '',
        president: '',
    },
    {
        nom: 'Bâtiment et Urbanisme (BU)',
        lien: "batiment_et_urbanisme",
        image: portail["depBatiment.jpg"],
        description: '',
        president: '',
    },
    {
        nom: 'Sciences de la Terre et des Ressources Minières (STERMI)',
        lien: "science_de_la_terre_et_des_ressources_minieres",
        image: portail["depTerre.jpg"],
        description: '',
        president: '',
    },
    {
        nom: 'Génie Chimique et Agro-Alimentaire (GCAA)',
        lien: "genie_chimique_et_agroalimentaire",
        image: portail["depChimie.jpg"],
        description: '',
        president: '',
    },
    {
        nom: 'Génie Electrique et Electronique (GEE)',
        lien: "genie_electrique_et_electronique",
        image: portail["depElectrique.jpg"],
        description: '',
        president: '',
    },
    {
        nom: 'Finance – Comptabilité - Droit (FCD)',
        lien: "finance_comptabilite_droit",
        image: portail["depFinance.jpg"],
        description: '',
        president: '',
    },
    {
        nom: 'Gestion Commerce et Economie Appliquée (GCEA)',
        lien: "gestion_commerce_et_economie_appliquee",
        image: portail["depEconomie.jpg"],
        description: '',
        president: '',
    },
    {
        nom: 'Langues et Sciences Humaines (LSH)',
        lien: "langues_et_sciences_humaines",
        image: portail["depLangue.jpg"],
        description: '',
        president: '',
    },
    {
        nom: 'Mathématiques et Informatique (MI)',
        lien: "mathematiques_et_informatique",
        image: portail["depMath.jpg"],
        description: '',
        president: '',
    },
    {
        nom: 'Sciences Administratives (SA)',
        lien: "sciences_administratives",
        image: portail["depAdministrative.jpg"],
        description: '',
        president: '',
    },
]
export const DepartementsAccueil = () => {
    return (
        <Transitions>
            <Container className='mt-5'>
                <Row className="d-flex flex-wrap align-items-center justify-content-center">
                    {associations.map((association, index) => (
                        <Col
                            xxl={4}
                            xl={4}
                            lg={4}
                            md={6}
                            sm={6}
                            className="mb-3"
                            key={`Departements-${index}`}
                        >
                            <Link to={association.lien} className='text-dark'>
                                <MyCard>
                                    <Card.Img
                                        src={association.image}
                                        alt=""
                                        className="border-0"
                                        style={{ width: '100%' }}
                                    />

                                    <Card.Body
                                        style={{
                                            borderLeft: '2px solid #EE7202',
                                        }}
                                        className="ps-1 py-3"
                                    >
                                        <div className="mb-2">
                                            <strong>{association.nom}</strong>
                                        </div>
                                    </Card.Body>
                                </MyCard>
                            </Link>
                        </Col>
                    ))}
                </Row>
            </Container>
        </Transitions>
    )
}
