import { useEffect, useState } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { GiHamburgerMenu } from 'react-icons/gi'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { NavLink } from 'react-router-dom'

const HamburgerMenuWrapper = styled.div`
    position: absolute;
    padding: 2em;
    width: 100%;
    right: 0;
    top:0;
    z-index: 100;
    display: none;
    background-color: rgba(255, 255, 255, 0.5) !important;
    -webkit-backdrop-filter: blur(2em) !important;
    backdrop-filter: blur(2em) !important;
    @media (max-width: ${(prop) => prop.show}) {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 100;
    }
`
const HamburgerMenuItems = styled.div`
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin-left: 5%;
    @media (max-width: 580px) {
        width: 95%;
    }
    @media (max-width: 493px) {
        width: 92%;
    }
    @media (max-width: 341px) {
        width: 95%;
    }
`
const HamburgerMenuItem = styled(NavLink)`
    color: ${prop => prop.color};
    background-color: #fff;
    border-radius: 30px;
    padding: 0.7em;
    font-size: 0.8em;
    width: 10em;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    transition: all 2ms ease-in-out;
    text-decoration: none;
    &:hover {
        color: #fff;
        background-color: ${prop => prop.color};
    }
    @media (max-width: 493px) {
        padding: 0.6em;
        font-size: 0.7em;
        width: 9em;
    }
    @media (max-width: 420px) {
        padding: 0.4em;
        font-size: 0.6em;
        width: 9em;
    }
    @media (max-width: 375px) {
        padding: 0.4em;
        font-size: 0.6em;
        width: 8em;
        border-radius: 25px;
    }
`
const OpenIcon = styled(GiHamburgerMenu)`
    display: none;
    font-size: ${prop=>prop.taille};
    position: absolute;
    right: 0;
    top: ${(prop) => prop.top};
    cursor: pointer;
    color: ${(prop) => prop.color};
    @media (max-width: ${(prop) => prop.show}) {
        display: flex;
    }
`
const CloseIcon = styled(AiOutlineCloseCircle)`
    font-size: 30px;
    cursor: pointer;
    color: ${(prop) => prop.color};
    position: absolute;
    right: 0;
    top: 40px;
    @media (max-width: 515px) {
        margin-right: 8px !important;
    }
` 

const MenyItems = [
    {
        text: 'À propos',
        link: '#',
    },
    {
        text: 'étudier ici',
        link: '#',
    },
    {
        text: 'nos filières',
        link: '#',
    },
    {
        text: 'recherche et innovation',
        link: '#',
    },
    {
        text: 'mobilités',
        link: '#',
    },
    {
        text: 'campus',
        link: '#',
    },
    {
        text: 'débouchés',
        link: '#',
    },
    {
        text: `technicien supérieur`,
        link: '#',
    },
]

const HamburgerMenu = ({ items, burgercolor, itemcolor, showWidth, topSize, Size }) => {
    const [isOpen, setisOpen] = useState(false)
    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])
    return isOpen ? (
        <HamburgerMenuWrapper className="backdrop-blur" show={showWidth} data-aos="fade-down">
            <HamburgerMenuItems>
                {items.map((item, index) => (
                    <HamburgerMenuItem
                        key={`${item.text}-${index}`}
                        to={`${item.link}`}
                        color={itemcolor}
                        className="text-uppercase py-4 text-center m-1"
                    >
                        {item.text}
                    </HamburgerMenuItem>
                ))}
                <CloseIcon
                    onClick={() => setisOpen(!isOpen)}
                    className="me-3"
                    color={burgercolor}
                />
            </HamburgerMenuItems>
        </HamburgerMenuWrapper>
    ) : (
        <OpenIcon
            onClick={() => setisOpen(!isOpen)}
            className="mt-3 me-3"
            show={showWidth}
            color={burgercolor}
            top = {topSize}
            taille = {Size}
        />
    )
}

HamburgerMenu.propTypes = {
    burgercolor: PropTypes.string,
    itemcolor: PropTypes.string,
    showWidth: PropTypes.string,
    topSize: PropTypes.string,
    Size: PropTypes.string,
}

HamburgerMenu.defaultProps = {
    items: MenyItems,
    burgercolor: '#cc580a',
    itemcolor: '#cc580a',
    showWidth: '936px',
    topSize: 'auto',
    Size: '40px',
}
export default HamburgerMenu
