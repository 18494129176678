import styled from 'styled-components'
import { Outlet } from 'react-router-dom'
import '../../utils/style/index.scss'
import HomeFlot from '../../components/HomeFlot'
import { ChangeLanguage } from '../../components/ChangeLanguage'

const EcolesWrapper = styled.div`
    overflow: hidden;
`
function Ecoles() {
    return (
        <EcolesWrapper>
            <HomeFlot />
            <ChangeLanguage />
            <Outlet />
        </EcolesWrapper>
    )
}

export default Ecoles
