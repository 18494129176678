import { useEffect, useState } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { GiHamburgerMenu } from 'react-icons/gi'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { HashLink } from 'react-router-hash-link'

const HamburgerMenuWrapper = styled.div`
    padding: 2em;
    width: 100%;
    z-index: 10;
    display: none;
    /* background-color: transparent !important; */
    -webkit-backdrop-filter: blur(2px) !important;
    backdrop-filter: blur(2px) !important;
    @media (max-width: ${(prop) => prop.show}) {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 100;
    }
`
const HamburgerMenuItems = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 5%;
    @media (max-width: 580px) {
        width: 95%;
    }
    @media (max-width: 493px) {
        width: 92%;
    }
    @media (max-width: 341px) {
        width: 95%;
    }
`
const HamburgerMenuItem = styled(HashLink)`
    color: #fff !important;
    margin-left: 1em;
    font-size: 0.8em;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 0.6em !important;
    cursor: pointer;
    padding-left: 10px;
    border-left: 1em solid ${(prop) => prop.color};
    border-bottom: 0.1em solid ${(prop) => prop.color+'9a'};
    border-right: 0.1em solid ${(prop) => prop.color+'9a'};
    border-top: 0.1em solid ${(prop) => prop.color+'9a'};
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    &:hover {
        color: ${(prop) => prop.color} !important;
        border-color: ${(prop) => prop.color};
    }
`
const OpenIcon = styled(GiHamburgerMenu)`
    display: none;
    font-size: ${prop=>prop.taille};
    position: absolute;
    right: 0;
    top: ${(prop) => prop.top};
    cursor: pointer;
    color: ${(prop) => prop.color};
    @media (max-width: ${(prop) => prop.show}) {
        display: flex;
    }
`
const CloseIcon = styled(AiOutlineCloseCircle)`
    font-size: 30px;
    cursor: pointer;
    color: ${(prop) => prop.color};
    position: absolute;
    left: 20px;
    top: 0;
    @media (max-width: 515px) {
        margin-right: 8px !important;
    }
` 

const MenyItems = [
    {
        text: 'À propos',
        link: '#',
    },
    {
        text: 'étudier ici',
        link: '#',
    },
    {
        text: 'nos filières',
        link: '#',
    },
    {
        text: 'recherche et innovation',
        link: '#',
    },
    {
        text: 'mobilités',
        link: '#',
    },
    {
        text: 'campus',
        link: '#',
    },
    {
        text: 'débouchés',
        link: '#',
    },
    {
        text: `technicien supérieur`,
        link: '#',
    },
]

const HamburgerMenuEcole = ({ items, burgercolor, itemcolor, showWidth, topSize, Size }) => {
    const [isOpen, setisOpen] = useState(false)
    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])
    return isOpen ? (
        <HamburgerMenuWrapper className="backdrop-blur" show={showWidth} data-aos="fade-down">
            <HamburgerMenuItems>
                {items.map((item, index) => (
                    <HamburgerMenuItem
                        key={`${item.text}-${index}`}
                        to={`${item.link}`}
                        onClick={() => setisOpen(false)}
                        color={itemcolor}
                        className="text-uppercase py-3 text-center m-1"
                    >
                        {item.text}
                    </HamburgerMenuItem>
                ))}
                <CloseIcon
                    onClick={() => setisOpen(!isOpen)}
                    className="me-3"
                    color={burgercolor}
                />
            </HamburgerMenuItems>
        </HamburgerMenuWrapper>
    ) : (
        <OpenIcon
            onClick={() => setisOpen(!isOpen)}
            className="mt-3 me-3"
            show={showWidth}
            color={burgercolor}
            top = {topSize}
            taille = {Size}
        />
    )
}

HamburgerMenuEcole.propTypes = {
    burgercolor: PropTypes.string,
    itemcolor: PropTypes.string,
    showWidth: PropTypes.string,
    topSize: PropTypes.string,
    Size: PropTypes.string,
}

HamburgerMenuEcole.defaultProps = {
    items: MenyItems,
    burgercolor: '#cc580a',
    itemcolor: '#cc580a',
    showWidth: '936px',
    topSize: 'auto',
    Size: '40px',
}
export default HamburgerMenuEcole
