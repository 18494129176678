import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { FaMailBulk, FaPhone } from 'react-icons/fa'
import Assitance from '../../assets/Portail/assistance.jpg'
import Santes from '../../assets/Portail/sante.jpg'
import styled from 'styled-components'
import { FcBusinesswoman } from 'react-icons/fc'
import { NavLink } from 'react-router-dom'
import { Transitions } from '../../components/Transitions'

const Studios = styled.div`
    background-color: #089bd9;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
`
const MyCard = styled(Card)`
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    @media (max-width: 590px) {
        font-size: 0.9em;
    }
`
const MyCol = styled(Col)`
    @media (max-width: 475px) {
        flex-basis: 80% !important;
        margin-bottom: 10px;
    }
    @media (max-width: 330px) {
        flex-basis: 90% !important;
    }
`
const Horaires = styled.div`
    border-radius: 30px;
    border: 1px solid #ee7202;
    background-color: #fff;
    color: #000;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`
const MyText = styled.div`
    &::before {
        content: '.';
        font-size: 40px;
        color: #089bd9;
    }
`
const Myimg = styled.img`
    width: 100%;
`

export const Sante = () => {
    return (
        <Transitions>
            <div className="text-dark text-start ms-4 my-2 d-flex">
                <NavLink to="/portail/vie_universitaire" className="me-2">
                    Vie à l'INP-HB{' '}
                </NavLink>{' '}
                | Assistance Sociale et Santé
            </div>
            <Container className="mt-5">
                <div
                    style={{ backgroundColor: '#E6E6E6' }}
                    className="p-3 py-4 text-justify text-center fs-3 body-text border-0 text-capitalize"
                >
                    Assistance Sociale
                </div>
                <p className="text-center text-uppercase fw-bold mb-3 mt-5">
                    nous écoutons, nous analysons, nous donnons des solutions.
                </p>
                <div
                    style={{ backgroundColor: '#EE7202' }}
                    className="p-4 text-start body-text border-0 m-0 text-light"
                >
                    Besoin de parler? Difficultés personnelles, familiales ou
                    scolaires? Trop de stress? Problèmes psychologiques ou
                    autres?
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    <Myimg className="mb-3" src={Assitance} alt="" />
                </div>
                <div className="p-3 text-start body-text border-0 m-0">
                    L'Assistance Sociale de l'INP-HB vous reçoit et vous écoute{' '}
                    <span style={{ color: '#EE7202' }}>24h/24 et 7jrs/7.</span>{' '}
                    En toute confidentialité, nous vous prêtons une oreille
                    attentive, nous vous laissons vous exprimer. Nos conseils,
                    notre assistance font la différence.
                </div>
            </Container>
            <Row className="d-flex flex-wrap align-items-center justify-content-center mt-5 mb-5">
                <p className="text-center fw-bold text-uppercase">
                    responsable assistance sociale
                </p>
                <MyCol xxl={4} xl={5} lg={6} md={8} sm={10} xs={12}>
                    <MyCard
                        className="d-flex flex-row align-items-center justify-content-evenly p-2"
                        style={{
                            width: '100%',
                            height: '100%',
                            border: '0px',
                        }}
                    >
                        <div>
                            <div
                                style={{
                                    borderRadius: '50%',
                                    backgroundColor: '#E6E6E6',
                                }}
                                className="p-2"
                            >
                                <FcBusinesswoman
                                    style={{ fontSize: '4.5em' }}
                                />
                            </div>
                            <p className="mb-3 mt-2 text-center">Mme Koné</p>
                        </div>
                        <div className="d-flex flex-column align-items-start justify-content-center ms-2">
                            <div className="text-start">
                                <FaPhone style={{ color: '#0DBA1E' }} /> (00225)
                                0708518979 / 0708078218 / 0777233278
                            </div>
                            <div className="mt-3 text-start">
                                <FaMailBulk style={{ color: '#0DBA1E' }} />{' '}
                                servicesocial@inphb.ci
                            </div>
                        </div>
                    </MyCard>
                </MyCol>
            </Row>
            <Container>
                <div
                    style={{
                        width: '100%',
                        height: '.5px',
                        backgroundColor: '#0000004D',
                    }}
                />
                <div
                    style={{ backgroundColor: '#E6E6E6' }}
                    className="p-3 py-4 text-justify text-center fs-3 body-text border-0 text-capitalize"
                >
                    Santé
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    <Myimg className="mb-3" src={Santes} alt="" />
                </div>
                <div className='my-2'>
                    Le Service Médical de l’INP-HB est composé de deux (2)
                    infirmeries localisées sur les sites Sud et Centre de
                    l’Institut à Yamoussoukro. Dès que vous sentez un malaise,
                    rendez-vous immédiatement dans l’infirmerie la plus proche
                    afin de recevoir des soins de première nécessité. Une
                    permanence est assurée dans les infirmeries du Centre et du
                    Sud pour faire face aux urgences à tout moment. Le service
                    santé est doté également d’une ambulance qui permet de faire
                    l’évacuation des patients présentant un cas plus alarmant.
                </div>
            </Container>
            <Studios className='my-3'>
                <div className="d-flex flex-column align-items-center justify-content-around fs-3 ">
                    <div className="body-highlight border-0 text-center text-light">
                        les horaires de reception
                    </div>
                    <span className="fs-5 body-text border-0 text-light">
                        Les infirmeries sont ouvertes selon le programme
                        ci-après :
                    </span>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-around mt-3 fs-4 mb-3">
                    <Horaires>de 07 h 30 à 12 h 30</Horaires>
                    <Horaires className="mt-5">de 13 h 30 à 16 h 30</Horaires>
                </div>
                <div className="text-light mb-5 body-text border-0">
                    Les élèves sont invités à respecter ces horaires afin
                    d'éviter des désagréments. Dès que vous sentez un malaise,
                    rendez-vous immédiatement dans l'infirmerie la plus proche.
                    Une permanence est assurée dans les infirmeries du Centre et
                    du Sud pour faire face aux urgences à tout moment.
                </div>
            </Studios>
            <Row className="d-flex flex-wrap align-items-center justify-content-center mt-5">
                <p className="text-center fw-bold text-uppercase">
                    Responsable du Service Santé et Hygiène
                </p>
                <MyCol xxl={4} xl={5} lg={6} md={8} sm={10} xs={12}>
                    <MyCard
                        className="d-flex flex-row align-items-center justify-content-evenly p-2 mb-5"
                        style={{
                            width: '100%',
                            height: '100%',
                            border: '0px',
                        }}
                    >
                        <div>
                            <div
                                style={{
                                    borderRadius: '50%',
                                    backgroundColor: '#E6E6E6',
                                }}
                                className="p-2 w-75 d-flex align-items-center justify-content-center"
                            >
                                <FcBusinesswoman style={{ fontSize: '4em' }} />
                            </div>
                            <p className="mb-3 mt-2 text-center">
                                Dr Yapo Marina
                            </p>
                        </div>
                        <div className="d-flex flex-column align-items-start justify-content-center ms-2">
                            <div className="text-start">
                                <FaPhone style={{ color: '#0DBA1E' }} /> (00225)
                                07 08 33 45 52
                            </div>
                            <div className="mt-3 text-start">
                                <FaMailBulk style={{ color: '#0DBA1E' }} />{' '}
                                sante@inphb.ci
                            </div>
                        </div>
                    </MyCard>
                </MyCol>
            </Row> 
        </Transitions>
    )
}
