import styled from 'styled-components'
import { Col, Container, Row } from 'react-bootstrap'
import Erreur from '../../assets/Erreur.png'
import '../../utils/style/index.scss'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { Transitions } from '../Transitions'

const Retourner = styled(motion.button)`
    color: #cc580a;
    padding: 1em 2em;
    font-size: 1em;
    background: transparent;
    border-radius: 15px;
    border: 1px solid #cc580a;
    cursor: pointer;
    letter-spacing: 2px;
    opacity: 0.7;
    transition: all 400ms ease-in-out;
`

function Error() {
    const settings = {
        xxl: 6,
        xl: 6,
        lg: 6,
        md: 12,
        sm: 12,
        xs: 12,
    } 
    return (
        <Transitions>
            <Container className="p-2">
                <Row>
                    <Col
                        className="d-flex flex-column align-items-center justify-content-center"
                        {...settings}
                    >
                        <p className="text-uppercase fs-1">
                            OUPS ! <br />
                            On dirait qu'il y a <br />
                            un petit{' '}
                            <span style={{ color: '#cc580a' }}>problème</span>
                        </p>

                        <motion.div
                            initial={{ x: '-100vw' }}
                            animate={{ x: 0 }}
                            transition={{ type: 'spring', stiffness: 120 }}
                        >
                            <Link to="/">
                                <Retourner
                                    whileHover={{
                                        scale: 1.1,
                                        textShadow: '0px 0px 8px #cc580a',
                                        boxShadow: '0px 0px 8px #cc580a',
                                        transition: {
                                            repeat: Infinity,
                                            duration: 0.1,
                                            repeatDelay: 0.3,
                                            repeatType: 'reverse',
                                        },
                                    }}
                                >
                                    Retourner à page d'accueil
                                </Retourner>
                            </Link>
                        </motion.div>
                    </Col>
                    <Col {...settings}>
                        <img
                            src={Erreur}
                            alt="Erreur"
                            style={{ width: '100%' }}
                        />
                    </Col>
                </Row>
            </Container>
        </Transitions>
    )
}

export default Error
