import styled from 'styled-components'
import { Card } from 'react-bootstrap'
import '../../utils/style/index.scss'
import Slider from 'react-slick'

const MySlider = styled.div`
    width: 70%;
    margin: auto;
    @media (max-width: 1400px) {
        width: 80%;
    }
    @media (max-width: 1200px) {
        width: 100%;
    }
    @media (max-width: 1010px) {
        width: 70%;
        padding: 20px;
    }
    @media (max-width: 992px) {
        width: 100%;
    }
    @media (max-width: 770px) {
        width: 60% !important;
    }
    @media (max-width: 560px) {
        width: 80% !important;
    }
    @media (max-width: 425px) {
        width: 100% !important;
    }
` 
const Slide = styled.div`
    width: 70%;
`

function Partenaires({partenaires}) {
    let settings_1 = {
        className: 'mb-5',
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1010,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    dots: false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    }
    return ( 
        <MySlider>
            <Slider {...settings_1}>
                {partenaires.map((partenaire, index) => (
                    <Slide key={`meilleus-${index}`} >
                        <Card
                            key={`${partenaire.name}-${index}`}
                            className="d-flex justify-content-center align-items-center"
                            style={{height:"17rem", overflow:"hidden"}}
                        >
                            <a
                                href={partenaire.lien ? partenaire.lien : null}
                                target="_blank"
                                rel='noreferrer'
                                style={{
                                    border: '0px',
                                }}
                            >
                                <Card.Img
                                    variant="top"
                                    src={partenaire.image}
                                    alt={partenaire.alt}
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </a>
                        </Card>
                    </Slide>
                ))}
            </Slider>
        </MySlider>
    )
}

export default Partenaires
