import styled from 'styled-components'
import { useContext, useState, useRef } from 'react'
import { MyContext } from '../../utils/context'
import { useEffect } from 'react'

const LanguageWrapper = styled.div`
    position: fixed;
    display: none;
    z-index: 9;
    top: 100px;
    left: 20px;
    cursor: pointer;
`
const Language = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`
const LanguageSlected = styled(Language)`
    border-radius: 50%;
    /* border: 1px solid #7ac034; */
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    color: #079edb;
    background-color: #fff;
    &:hover {
        text-shadow: 0px 0px 8px #079edb;
        box-shadow: 0px 0px 8px #079edb;
    }
`
const ChooseLanguage = styled.div`
    position: absolute;
    z-index: 3;
    display: ${(props) => (props.open ? 'flex' : 'none')};
    display: none;
    border: 1px solid #fff;
    background-color: #fff;
    /* background-color: rgba(255, 255, 255, 0.5); */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    /* backdrop-filter: Blur(3px); */
    border-radius: 15px;
    margin-top: 5px;
    padding: 10px !important;
    color: #079edb;
    flex-direction: column;
    justify-content: center;
    align-items: space-around;
    &:hover {
        text-shadow: 0px 0px 8px #079edb;
        box-shadow: 0px 0px 8px #079edb;
    }
`
const langue = [
    {
        title: 'FRA',
    },
    {
        title: 'ANG',
    },
    {
        title: 'ARB',
    },
]

export const ChangeLanguage = () => {
    const [chooseLang, setChooseLang] = useState(false)
    const { lang, addLang } = useContext(MyContext)
    let lang1, lang2
    switch (lang) {
        case 0:
            
            lang1 = 1
            lang2 = 2
            break
        case 1:
            
            lang1 = 0
            lang2 = 2
            break
        case 2:
            
            lang1 = 0
            lang2 = 1
            break
        default:
            
            lang1 = 1
            lang2 = 2
            break
    }
    const setLanguage = (i) => {
        addLang(i)
        setChooseLang(!chooseLang)
    }
    const refOne = useRef(null)
    useEffect(() => {
        document.addEventListener('click', HandleClickOutside, true)
    }, [])
    const HandleClickOutside = (e) => {
        try {
            if (!refOne.current.contains(e.target)) {
                setChooseLang(false)
            }
        } catch (error) {}
    }
    return (
        <LanguageWrapper className="ms-3" ref={refOne}>
            <LanguageSlected
                onClick={() => setLanguage(lang)}
                className="fw-bold"
            >
                <div className="cursor-pointer">{langue[lang].title}</div>
            </LanguageSlected>
            <ChooseLanguage
                open={chooseLang}
                onMouseLeave={() => setChooseLang(false)}
                className="p-1 fade-in"
            >
                <Language
                    onClick={() => setLanguage(lang1)}
                    className="fw-bold"
                >
                    <div className="cursor-pointer">{langue[lang1].title}</div>
                </Language>
                <Language
                    onClick={() => setLanguage(lang2)}
                    className="fw-bold"
                >
                    <div className="cursor-pointer">{langue[lang2].title}</div>
                </Language>
            </ChooseLanguage>
        </LanguageWrapper>
    )
}
